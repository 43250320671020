import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { useAuthStore } from '../store/useAuthStore'
import {
  getShippingFeeByQTY,
  parseShippingFeeDetail,
} from '../pages/product_list/services/productService'
import { ShippingFeeDetailVO, ShippingMethod } from '../TypeDeclare'
import { CircularProgress } from '@mui/material'
import { useDebounceFn } from 'ahooks'

export type ShippingFeeDetailProps = {
  productId: string
  unitPrice: number
  quantity: number
  shippingMethod?: ShippingMethod
  discount?: number
}
const ShippingFeeDetail: FC<ShippingFeeDetailProps> = ({
  productId,
  quantity,
  unitPrice,
  shippingMethod,
  discount
}) => {
  const authStore = useAuthStore()
  const [detail, setDetail] = useState<ShippingFeeDetailVO>()
  const getShippingFee = (): number => {
    if (detail === undefined || shippingMethod === undefined) {
      return 0
    }
    const target = detail.totalShippingCost.find(
      (tsc) => tsc.shippingMethodId === shippingMethod.id,
    )
    return target ? target.cost : 0
  }
  const getTargetProduction = (): number => {
    if(discount) return Number((unitPrice).toFixed(2))
    return Number(Number(unitPrice * quantity).toFixed(2))
  }
  const getTargetTotal = ():string =>{
    return Number(getTargetProduction() + getShippingFee()).toFixed(2)
  }
  const { run: runGetShippingFeeByQTY } = useDebounceFn(
    () => {
      if (shippingMethod) {
        getShippingFeeByQTY(
          productId,
          authStore.token,
          shippingMethod.id,
          quantity,
        ).then((res) => {
          const d = parseShippingFeeDetail(res['data'])
          setDetail(d)
        })
      }
    },
    {
      wait: 500,
    },
  )
  useEffect(() => {
    if (shippingMethod) {
      setDetail(undefined)
      runGetShippingFeeByQTY()
    }
  }, [authStore, quantity, runGetShippingFeeByQTY, shippingMethod, discount])
  if (detail === undefined || shippingMethod === undefined) {
    return (
      <Box column toCenterX spaceY5 p5>
        <CircularProgress size={16} />
      </Box>
    )
  }
  return (
    <Box m4 toRight column spaceY3>
      <Box w={'100%'} toLeft gray500>
        Order Breakdown
      </Box>
      <Box textSM fontSemibold>
        Production cost: ${getTargetProduction()}
      </Box>
      <Box textSM fontSemibold>
        {shippingMethod.id} {shippingMethod.weightComment}: ${getShippingFee()}
      </Box>
      <Box textXL fontBold>
        Total: ${getTargetTotal()}
      </Box>
      {/* <Box w={'100%'} column spaceY2>
        <Box w={'100%'} row toBetween textSM>
          <Box>Box</Box>
          <Box>Quantity</Box>
          <Box>Subtotal</Box>
        </Box>
        {detail.packageList.map((p, i) => {
          const targetCost = p.costList.find(
            (c) => c.shippingMethodId === shippingMethod.id,
          )
          return (
            <Box key={`package-${i}`} w={'100%'} row toBetween>
              <Box>#{i + 1}</Box>
              <Box>{p.quantity}</Box>
              <Box>${targetCost ? targetCost.cost : 0}</Box>
            </Box>
          )
        })}
      </Box> */}
      
    </Box>
  )
}
export default ShippingFeeDetail
