import { Box } from '@fower/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useEditorStore } from '../../store/editor/useEditorStore';
import { Button } from '@mui/material';
const ToolBar: FC = observer(() => {
    const store = useEditorStore();

    return (
        <Box zIndex='100' toCenter>
            {store.product?.images.length! >= 2 && (
                <Button
                    variant={store.targetFace === 'back' ? 'contained' : 'outlined'}
                    size='small'
                    sx={{
                        borderRadius: '20px',
                        height: '40px',
                        width: '128px',
                        mr: '1rem',
                    }}
                    onClick={() => {
                        store.toTeeBack();
                    }}
                >
                    2
                </Button>
            )}
            <Button
                variant={store.targetFace === 'front' ? 'contained' : 'outlined'}
                size='small'
                sx={{
                    borderRadius: '20px',
                    height: '40px',
                    width: '128px',
                    ml: '1rem',
                }}
                onClick={() => {
                    store.toTeeFront();
                }}
            >
                1
            </Button>
        </Box>
    );
});
export default ToolBar;
