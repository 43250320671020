import { FC, SetStateAction, useEffect, useState } from "react";
import { IconButton, Popover } from "@mui/material";
import { ChromePicker } from "react-color";
import { styled } from "@fower/styled";

interface ColorPickerProps {
    onChangeComplete: (color: string) => void
    selectedColor: string
    defaultValue: string,
    cssStyle?: React.CSSProperties
}
const ColorPicker: FC<ColorPickerProps> = ({ onChangeComplete, selectedColor, defaultValue, cssStyle }) => {
    const colorList = ['#FFFFFF', '#000000', '#518DD9', '#BB0020', '#F4AE38', '#ff51dc', '#6b6b6b', '#d19e8d']

    const [active, setActive] = useState(-1)

    useEffect(() => {
        const res = colorList.find(item => item === defaultValue)
        if (res) {
            setActive(colorList.indexOf(res))
        }
    }, [defaultValue])

    const [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null);

    const isColorWheelOpen = Boolean(anchorEL);
    const colorWheelID = isColorWheelOpen ? 'simple-popover' : undefined;
    const StyledImage = styled('img');

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                ...cssStyle
            }}
        >

            {
                colorList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                                width: '37px',
                                height: '37px',
                                borderRadius: '50%',
                                boxSizing: 'border-box',
                                border: active === index ? '3px solid #518DD9' : '3px solid #fff',
                                marginRight: '12px',
                                marginBottom: '5px'
                            }}
                            onClick={() => {
                                setActive(index)
                                onChangeComplete(item)
                            }}
                        >

                            <div
                                style={{
                                    width: '27px',
                                    height: '27px',
                                    borderRadius: '50%',
                                    background: item,
                                    boxSizing: 'border-box',
                                    border: item === '#FFFFFF' ? '1px solid #707070' : 'none'
                                }}
                            ></div>


                        </div>
                    )
                })
            }

            <>
                <IconButton
                    size='large'
                    aria-label='choose color'
                    component='div'
                    onClick={(event: {
                        currentTarget: SetStateAction<HTMLElement | null>;
                    }) => {
                        setAnchorEL(event.currentTarget);
                        setActive(-1)
                    }}
                    sx={{
                        padding: '0px',
                        margin: '5px 0px 0px 5px',
                        position: 'relative',
                        width: '34px',
                        height: '34px',
                    }}
                    edge='end'
                >
                    <StyledImage
                        aria-describedby={colorWheelID}
                        left0
                        top0
                        absolute
                        w='28px'
                        h='28px'
                        src='/icons/btn_color_select_ui.webp'
                        alt='color wheel'
                    />
                </IconButton>

                <Popover
                    id={colorWheelID}
                    anchorEl={anchorEL}
                    open={isColorWheelOpen}
                    onClose={() => {
                        setAnchorEL(null);
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <ChromePicker
                        color={selectedColor}
                        onChange={(color) => {
                            console.log(color)
                            onChangeComplete(color.hex);
                        }}
                    />
                </Popover>
            </>
        </div>

    )
}

export default ColorPicker