import { Box } from '@fower/react';
import { FC } from 'react';
import ReorderIcon from '@mui/icons-material/Reorder';
import InventoryIcon from '@mui/icons-material/Inventory';
import HubIcon from '@mui/icons-material/Hub';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Payments from '@mui/icons-material/Payments';
import Uploader from '@mui/icons-material/Upload'

export type SideBarTabProps = {
    index: number;
    icon: string;
    name: string;
    selected: boolean;
    onClick: (index: number) => void;
};
const getIconClass = (icon: string) => {
    if (icon === 'order') {
        return ReorderIcon
    }
    if (icon === 'product') {
        return InventoryIcon
    }
    if (icon === 'connect') {
        return HubIcon
    }
    if (icon === 'account') {
        return AccountCircleIcon
    }
    if (icon === 'payments') {
        return Payments
    }
    if (icon === 'uploader') {
        return Uploader
    }

    return ReorderIcon
}
const SideBarTab: FC<SideBarTabProps> = ({
    index,
    icon,
    selected,
    name,
    onClick,
}) => {
    const IconClass = getIconClass(icon)
    const iconColorSelected = '#4384D6'
    const iconcolor = 'rgba(0,0,0,0.6)'
    return (
        <Box
            h12
            row
            toCenterY
            cursorPointer
            onClick={() => {
                onClick(index)
            }}
            borderRight={selected ? 'solid 4px #4384D6' : '0px'}
        >
            <IconClass htmlColor={selected ? iconColorSelected : iconcolor} />
            <Box ml6 color={selected ? '#4384D6' : 'gray500'} fontBold={selected}>
                {name}
            </Box>
        </Box>
    )
}
export default SideBarTab
