import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
    MenuItem,
    Select,
    Switch,
} from '@mui/material'
import {
    ColorOption,
    DEFAULT_SHIPPINGMETHOD,
    LinkedShop,
    MyProductItemVO,
    ShippingMethod,
} from '../TypeDeclare'
import ProductSliderBaseOnColor from './ProductSliderBaseOnColor'
import EditableTextArea from './EditableTextArea'
import NumericInput from 'react-numeric-input'
import { useProductStore } from '../pages/product_list/store/useProdustListStore'
import { Close } from '@mui/icons-material'
import { useDashbardStore } from '../pages/dashboard/store/useDashboardStore'
import { useAuthStore } from '../store/useAuthStore'
import { observer } from 'mobx-react-lite'
import ProductShippingMothedPicker from './ProductShippingMothedPicker'
import QuillEditor from './../pages/dashboard/upload_product/components/Editor'

export type SetProductPropsModalProps = {
    product?: MyProductItemVO
    isOpen: boolean
    hasImage?: boolean
    onSubmit: (
        title: string,
        desc: string,
        price: number,
        toCT: boolean,
        toShopify: boolean,
        descriptionHtml: string,
        shopTo?: LinkedShop,

    ) => void
    onClose: () => void
    canShopify?: boolean
    toConnect?: () => void
}
const SetProductPropsModal: FC<SetProductPropsModalProps> = observer(
    ({
        isOpen,
        hasImage = true,
        product,
        onSubmit,
        onClose,
        canShopify = false,
        toConnect,
    }) => {
        const store = useProductStore()
        const dashbardStore = useDashbardStore()
        const authStore = useAuthStore()

        const [selectedColor, setSelectedColor] = useState<ColorOption>({
            id: '2',
            value: '#ffffff',
            name: 'White',
        })
        const [title, setTitle] = useState<string>(product?.name || '')
        const [desc, setDesc] = useState<string>(product?.desc || '')
        const [descriptionHtml, setDescriptionHtml] = useState<string>(product?.description_html || '')

        const [publishToCustomtee, setPublishToCustomtee] = useState<boolean>(true)
        const [publishToShopify, setPublishToShopify] = useState<boolean>(true)
        const [selectedShop, setSelectedShop] = useState<LinkedShop | undefined>()
        const [selectedShippingMethod, setSelectedShippingMethod] = useState<
            ShippingMethod
        >()
        const dynamicCost = Number(
            (
                Number(product?.pairPrize) + Number(selectedShippingMethod?.fee)
            ).toFixed(2),
        )
        const [price, setPrice] = useState<number>(dynamicCost)

        const validPrice = (): boolean => {
            // return true
            return price - dynamicCost >= 0
        }

        const validShopify = (): boolean => {
            if (!canShopify) return true
            if (!publishToShopify) return true
            return authStore.hasBindedShop
        }

        const validAll = (): boolean => {
            return validPrice() && validShopify()
        }

        const getShopifyView = () => {
            if (!publishToShopify) {
                return <></>
            }

            if (!authStore.hasBindedShop) {
                return (
                    <Button
                        onClick={() => {
                            toConnect && toConnect()
                        }}
                    >
                        Connect Shopify
                    </Button>
                )
            }
            return (
                <Select
                    value={selectedShop?.url}
                    label="Shops"
                    onChange={(event) => {
                        const targetShop = dashbardStore.linkedShops.find(
                            (s) => s.url === event.target.value,
                        )
                        if (targetShop) {
                            setSelectedShop(targetShop)
                        }
                    }}
                >
                    {dashbardStore.linkedShops.map((shop) => {
                        return (
                            <MenuItem key={shop.url} value={shop.url}>
                                {shop.url}
                            </MenuItem>
                        )
                    })}
                </Select>
            )
        }
        useEffect(() => {
            if (product) {
                console.log(product)
                setTitle(product.name)
                setDesc(product.desc)
                setDescriptionHtml(product.description_html!)
                setSelectedColor(store.colorOptions[0])
                const defaultShippingMethod = product.shippingMethods.find(
                    (sm) => sm.id === DEFAULT_SHIPPINGMETHOD,
                )
                setSelectedShippingMethod(defaultShippingMethod)
                const presetPrice =
                    Number(product?.pairPrize) + Number(defaultShippingMethod?.fee)
                setPrice(presetPrice)
            }
        }, [store, product])
        useEffect(() => {
            if (dashbardStore && dashbardStore.linkedShops.length > 0) {
                setSelectedShop(dashbardStore.linkedShops[0])
            }
        }, [dashbardStore, dashbardStore.linkedShops])
        useEffect(() => {
            setPublishToCustomtee(!product?.onSaleCustomtee)
            setPublishToShopify(!product?.onSaleShopify)
        }, [product, product?.onSaleCustomtee, product?.onSaleShopify])
        return (
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
                <Box row toCenterY mt3 ml5 toRight>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            mr: 1,
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box w-100p row toTop>
                        {hasImage && (
                            <Box column w-40p>
                                <ProductSliderBaseOnColor
                                    selectedColor={selectedColor}
                                    product={product}
                                />
                            </Box>
                        )}

                        <Box column w-60p={hasImage} w-100p={!hasImage} h-100p p3 spaceY3>
                            <EditableTextArea
                                text={title}
                                label="Change title"
                                onUpdate={(newValue) => {
                                    setTitle(newValue)
                                }}
                                immediateUpdate={true}
                            />
                            {/* <EditableTextArea
                                text={desc}
                                label="Change description"
                                onUpdate={(newValue) => {
                                    setDesc(newValue)
                                }}
                                immediateUpdate={true}
                            /> */}
                            <QuillEditor
                                html={descriptionHtml}
                                onGetHtml={(data) => setDescriptionHtml(data)}
                            />
                            <Box fontExtrabold mr2 black>
                                Set Price
                            </Box>
                            <NumericInput
                                step={0.1}
                                value={price}
                                precision={2}
                                snap
                                strict
                                onChange={(value) => {
                                    if (value !== null) {
                                        setPrice(value)
                                    }
                                }}
                                style={{
                                    input: {
                                        padding: '10px',
                                    },
                                }}
                            />
                            <Box fontExtrabold mr2 black>
                                Shipping Method
                            </Box>
                            <ProductShippingMothedPicker
                                value={selectedShippingMethod?.id}
                                options={product?.shippingMethods || []}
                                onChange={(newValue) => {
                                    const newShippingMethod = product?.shippingMethods.find(
                                        (sm) => sm.id === newValue,
                                    )
                                    setSelectedShippingMethod(newShippingMethod)
                                }}
                                p={0}
                            />
                            <Box column red400={!validPrice()} spaceY2>
                                <Box textXS>
                                    {' '}
                                    Note: Your cost ${dynamicCost} = Production cost $
                                    {product?.pairPrize} + Shipping Fee $
                                    {selectedShippingMethod?.fee}
                                </Box>

                                <Box textXS>Profit = ${(price - dynamicCost).toFixed(2)}</Box>
                            </Box>
                            <Box column p0 toLeft>
                                <Box row toLeft toCenterY>
                                    <Box>Publish to Customtee</Box>
                                    <Switch
                                        color="primary"
                                        checked={publishToCustomtee}
                                        onChange={(_event, checked) => {
                                            setPublishToCustomtee(checked)
                                        }}
                                    />
                                </Box>

                                {canShopify && (
                                    <Box row toLeft toCenterY>
                                        <Box>Publish to Shopify</Box>
                                        <Switch
                                            color="primary"
                                            checked={publishToShopify}
                                            onChange={(_event, checked) => {
                                                setPublishToShopify(checked)
                                            }}
                                        />
                                        {getShopifyView()}
                                    </Box>
                                )}
                            </Box>

                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                sx={{ m: 2 }}
                                onClick={() => {
                                    onSubmit(
                                        title,
                                        desc,
                                        price,
                                        publishToCustomtee,
                                        publishToShopify,
                                        descriptionHtml,
                                        selectedShop,
                                    )
                                }}
                                disabled={!validAll()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    },
)
export default SetProductPropsModal
