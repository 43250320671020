import { FC, useContext } from 'react';
import { createContext } from 'react';
import { CheckoutStore } from './CheckoutStore';

const CheckoutContext = createContext<CheckoutStore | null>(null);

export const CheckoutProvider: FC = ({ children }) => {
  const store = new CheckoutStore();
  return (
    <CheckoutContext.Provider value={store}>{children}</CheckoutContext.Provider>
  );
};


export const useCheckoutStore = (): CheckoutStore => {
  const store = useContext(CheckoutContext)
  if (store == null) {
    throw new Error(`useCheckoutStore must be used within a CheckoutProvider`)
  }
  return store
}
