import { FC } from 'react';
import { Box } from '@fower/react';
import { Step, StepLabel, Stepper } from '@mui/material';

export type UploaderStepperProps = {
  step: number;
  onSelect: (index: number) => void;
};

const UploaderStepper: FC<UploaderStepperProps> = ({ step, onSelect }) => {
  const stepList = [
    { name: 'Start' },
    { name: 'Basic Info' },
    { name: 'Cover & Images' },
    { name: 'Preview' },
  ];

  return (
    <Box h='67px' w='70%'>
      <Stepper alternativeLabel activeStep={step}>
        {stepList.map((item, index) => {
          return (
            <Step key={index}>
              <StepLabel color='#6E8FA0'>{item.name}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
export default UploaderStepper;
