import { FC } from 'react'
import { Box } from '@fower/react'
import Platforms from './Platforms'
import StoresList from './StoresList'

export type StoresProps = {}
const Stores: FC<StoresProps> = () => {
  return (
    <Box w-100p p10>
      <StoresList />
      <Platforms />
    </Box>
  )
}
export default Stores
