import { Box } from '@fower/react'
import { Button, Dialog, MenuItem, Select, TextField } from '@mui/material'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import FullSizeBox from '../editor/components/common/FullSizeBox'
import { PaypalButtonWrapper } from './PaypalButtonWrapper'
import Viewer from 'react-viewer'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import {
    CheckoutProvider,
    useCheckoutStore,
} from './store/checkout/useCheckoutStore'
import { useProductStore } from '../product_list/store/useProdustListStore'
import { getURLParam, initCTKey } from '../../common/StringUtils'
import { useNavigate } from 'react-router-dom'
import countryCodeList from './country-code.json'
import OrderDetailItem from './OrderDetailItem'
import { doGet } from '../../common/commonService'
import { useAuthStore } from '../../store/useAuthStore'


const CheckoutWrapper: FC = observer(() => {
    const store = useCheckoutStore()
    const productStore = useProductStore()

    store.setProductStore(productStore)

    const authStore = useAuthStore()

    const sku = getURLParam('sku')
    const quantity = getURLParam('quantity')

    const [detailList, setDetailList] = useState<{ title: string, value: string }[]>([])

    useEffect(() => {
        initCTKey()
        const aRunner = async () => {

            const res = await doGet(`https://api.customtee.co/api/customteeShopView/checkout/`, {
                shop: localStorage.getItem('profile_path'),
                sku, quantity
            }, authStore.token)

            setDetailList([
                {
                    title: 'Product',
                    value: res.data.product_name
                },
                {
                    title: 'Price',
                    value: '$' + res.data.unit_price
                },
                {
                    title: 'Quantity',
                    value: res.data.quantity
                },
                {
                    title: 'Total amount',
                    value: '$' + res.data.total_price
                },
                {
                    title: 'Product Info',
                    value: res.data.sku_text
                },
            ])
            console.log(res)
        }
        aRunner()
        productStore.setSku(sku)
    }, [sku, quantity])

    const [showViewer, setShowViewer] = useState<boolean>(false)
    const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false)
    const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
    const [viewIndex, setViewIndex] = useState<number>(0)
    const navigator = useNavigate()
    return (
        <PayPalScriptProvider
            options={{
                'client-id':
                    'ARmLyCJhXWnakQc8b0dm5hsYXRzVTpPfegTPMNtUScQjwsNfU17JahzPtEvoQtphenVgbAlJ7bcP9Y5p',
                components: 'buttons',
                currency: 'USD',
            }}
        >
            <FullSizeBox toCenterX toTop column>
                {/* Top Banner */}
                <Box w="100%" h="180px" bg="#4384D6" toCenter>
                    <Box w="80%" h="100%" toCenterY>
                        <Box column toLeft h20 toAround>
                            <Box text3XL white>
                                Checkout
                            </Box>
                            <Box
                                textSM
                                white
                                cursorPointer
                                onClick={() => {
                                    global.history.back()
                                }}
                            >
                                Back
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* Detail Info */}
                <Box w="80%" flexDirection={['column', 'column', 'column', 'row']}>
                    <Box w={['100%', '100%', '100%', '50%']} toLeft column>
                        <Box textXL mt5>
                            Order Details
                        </Box>
                        <Box bgGray100 w="100%" mt5 column>
                            {detailList.map((item, index) => {

                                return (
                                    <OrderDetailItem
                                        key={`${index}-${item.title}`}
                                        title={item.title}
                                        value={item.value}

                                    />
                                )
                            })}
                        </Box>

                    </Box>
                    <Box
                        w={['100%', '100%', '100%', '50%']}
                        toLeft
                        column
                        pl5--lg
                        pr5--lg
                    >
                        <Box textXL mt5>
                            Shipping Address
                        </Box>
                        <Box w="100%" mt5 column space6 toLeft>
                            <TextField
                                size="small"
                                required
                                error={store.user.hasUserNameError !== null}
                                helperText={store.user.hasUserNameError}
                                label="Full Name"
                                placeholder="Name"
                                fullWidth
                                value={store.user.userName}
                                onChange={(event) => {
                                    store.user.setUserName(event.target.value)
                                }}
                            />
                            <Box
                                flexDirection={['column', 'column', 'column', 'column', 'row']}
                                toLeft
                                w="100%"
                                space6
                            >
                                <TextField
                                    size="small"
                                    required
                                    label="Email"
                                    placeholder="Email"
                                    fullWidth
                                    type={'email'}
                                    error={store.user.hasEmailError !== null}
                                    helperText={store.user.hasEmailError}
                                    value={store.user.email}
                                    onChange={(event) => {
                                        store.user.setEmail(event.target.value)
                                    }}
                                />
                                <TextField
                                    size="small"
                                    required
                                    label="Phone"
                                    placeholder="Phone"
                                    error={store.user.hasPhoneError !== null}
                                    helperText={store.user.hasPhoneError}
                                    fullWidth
                                    type={'tel'}
                                    value={store.user.phone}
                                    onChange={(event) => {
                                        store.user.setPhone(event.target.value)
                                    }}
                                />
                            </Box>
                            <TextField
                                size="small"
                                required
                                label="Address"
                                placeholder="Address"
                                error={store.user.hasAddressError !== null}
                                helperText={store.user.hasAddressError}
                                fullWidth
                                value={store.user.address}
                                onChange={(event) => {
                                    store.user.setAddress(event.target.value)
                                }}
                            />
                            <TextField
                                size="small"
                                required
                                label="City"
                                placeholder="City"
                                fullWidth
                                value={store.user.city}
                                error={store.user.hasCityError !== null}
                                helperText={store.user.hasCityError}
                                onChange={(event) => {
                                    store.user.setCity(event.target.value)
                                }}
                            />
                            <Box
                                flexDirection={['column', 'column', 'column', 'column', 'row']}
                                toLeft
                                w="100%"
                                space6
                            >
                                <Select
                                    size="small"
                                    value={productStore.countryCode}
                                    label="Country"
                                    onChange={(event) => {
                                        productStore.setCountryCode(event.target.value)
                                    }}
                                >
                                    {countryCodeList.map((c) => {
                                        return (
                                            <MenuItem value={c.code} key={c.name}>
                                                {c.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                <TextField
                                    size="small"
                                    required
                                    label="State/Region"
                                    placeholder="State/Region"
                                    fullWidth
                                    value={store.user.state}
                                    error={store.user.hasStateError !== null}
                                    helperText={store.user.hasStateError}
                                    onChange={(event) => {
                                        store.user.setState(event.target.value)
                                    }}
                                />

                                <TextField
                                    size="small"
                                    required
                                    label="Zip code"
                                    placeholder="Zip code"
                                    fullWidth
                                    value={store.user.zipcode}
                                    error={store.user.hasZipcodeError !== null}
                                    helperText={store.user.hasZipcodeError}
                                    onChange={(event) => {
                                        store.user.setZipcode(event.target.value)
                                    }}
                                />
                            </Box>
                        </Box>
                        <PaypalButtonWrapper
                            disabled={false}
                            type={2}
                            amount="0.01"
                            currency="USD"
                            onValidate={() => {
                                store.user.setValidating(true)
                                return store.error === null
                            }}
                            onComplete={(success: boolean) => {
                                setShowCompleteDialog(true)
                                setIsPaymentSuccess(success)
                            }}
                        />
                    </Box>
                </Box>
                <Viewer
                    activeIndex={viewIndex}
                    visible={showViewer}
                    images={store.product.viewerData}
                    rotatable={false}
                    scalable={false}
                    noNavbar={true}
                    downloadable={true}
                    downloadInNewWindow={true}
                    disableMouseZoom={true}
                    onClose={() => {
                        setShowViewer(false)
                    }}
                    onMaskClick={() => {
                        setShowViewer(false)
                    }}
                />
                <Dialog open={showCompleteDialog} onClose={() => { }}>
                    <Box w="100%" w--md="400px" h="300px" column toCenter spaceY10>
                        <Box column toCenter spaceY6>
                            {!isPaymentSuccess ? (
                                <ErrorIcon
                                    sx={{
                                        color: 'red',
                                        fontSize: '64px',
                                    }}
                                />
                            ) : (
                                <CheckCircleIcon
                                    sx={{
                                        color: 'green',
                                        fontSize: '64px',
                                    }}
                                />
                            )}

                            <Box>
                                {isPaymentSuccess
                                    ? 'ORDER PLACED SUCCESSFULLY'
                                    : 'SOMETHING IS WRONG'}
                            </Box>
                        </Box>
                        <Button
                            onClick={() => {
                                navigator('/catalog/all/')
                            }}
                        >
                            Back to Catalog
                        </Button>
                    </Box>
                </Dialog>
            </FullSizeBox>
        </PayPalScriptProvider>
    )
})
const ShopCheckout: FC = () => {
    return (
        <CheckoutProvider>
            <CheckoutWrapper />
        </CheckoutProvider>
    )
}
export default ShopCheckout
