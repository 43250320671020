import { FC } from 'react'
import { Box } from '@fower/react'
import { TagOption } from '../../../../TypeDeclare'
import { Autocomplete, TextField } from '@mui/material'

export type TagPadProps = {
    tagOptions: Array<TagOption>
    loading?: boolean
    selectedTags: Array<TagOption>
    onTagsUpdated?: (tag: Array<TagOption>) => void
    disabled?: boolean
}
const TagPad: FC<TagPadProps> = ({
    selectedTags,
    tagOptions,
    loading = false,
    onTagsUpdated,
    disabled = false,
}) => {
    console.log(selectedTags)
    return (
        <Box column spaceY2 p5 w="100%" overflowYScroll overflowYAuto>
            <Box alignSelf="end">{selectedTags.length} tags(s) picked</Box>
            <Autocomplete
                disabled={disabled}
                multiple
                options={tagOptions}
                getOptionLabel={(option) => option.value}
                defaultValue={selectedTags}
                value={selectedTags}
                loading={loading}
                renderInput={(params) => (
                    <TextField {...params} placeholder={'Pick Tags'} />
                )}
                size="medium"
                onChange={(_, value) => {
                    onTagsUpdated && onTagsUpdated(value)
                }}
                renderOption={(props: any, option, { selected }) => (
                    <li {...props} key={`${option.value}-${props['data-option-index']}`}>
                        {option.value}
                    </li>
                )}
            />
        </Box>
    )
}
export default TagPad
