import { FC } from "react";
import { Dialog } from '@mui/material';
import { StyledImage } from '../../TypeDeclare';

export type NewPopupGuideProps = {
    isOpen: boolean
    onClose: () => void
}
const NewPopupGuide: FC<NewPopupGuideProps> = ({ isOpen, onClose }) => {
    return (
        <Dialog
            aria-labelledby="popup-guide-title" // 添加无障碍名称
            aria-describedby="popup-guide-description" // 添加内容描述
            sx={{
                '& .MuiDialog-paper': {
                    background: 'transparent',
                    boxShadow: 'none',
                    position: 'relative'
                }
            }}
            open={isOpen}>

            <StyledImage src='/images/register.webp' alt="register" onClick={onClose} w={['100%', '100%', '100%', '32rem', '32rem']} />

        </Dialog>
    )
}

export default NewPopupGuide;