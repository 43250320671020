import { Box } from "@fower/react";
import { FC, useEffect, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode } from "../../TypeDeclare";
import LoginModal from "../../common/LoginModal";
import HomeFooter from "../home/Footer";
import { LinearProgress } from "@mui/material";
import { doGet } from "../../common/commonService";
import { useParams } from "react-router-dom";
import { useAuthStore } from "../../store/useAuthStore";
import { Helmet } from "react-helmet";


const BlobDetail: FC = () => {
    const authStore = useAuthStore()

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    const [loading, setLoading] = useState<boolean>(false)
    const [descriptionHtml, setDescriptionHtml] = useState<string>('')

    const [seoObj, setSeoObj] = useState<{
        title: string,
        description: string
    }>({
        title: '',
        description: ''
    })

    const [title, setTitle] = useState<string>('')

    const { id } = useParams()

    async function getInfo() {
        setLoading(true)
        const res = await doGet(`https://api.customtee.co/api/customteeBlogDetail/${id}/`, null, undefined)
        console.log(res)
        if (res.success) {
            const { title, description_html, seo_title, seo_description } = res.data
            setTitle(title)
            setDescriptionHtml(description_html)
            setSeoObj({ title: seo_title, description: seo_description })
            setLoading(false)
        }
    }

    useEffect(() => {
        getInfo()
    }, [id])

    useEffect(() => {
        // 获取所有带有 data-href 属性的图片
        const imagesWithLinks: NodeListOf<HTMLImageElement> = document.querySelectorAll('img[data-href]');

        // 定义点击事件处理函数
        const handleClick: EventListener = (event: Event) => {
            const target = event.currentTarget as HTMLImageElement;
            const href = target.getAttribute('data-href'); // 获取 data-href 属性
            if (href) {
                window.open(href, '_blank'); // 打开链接并在新窗口中打开
            }
        };

        // 遍历这些图片并添加点击事件
        imagesWithLinks.forEach((img: HTMLImageElement) => {
            img.addEventListener('click', handleClick);
        });

        // 清除副作用，移除事件监听器
        return () => {
            imagesWithLinks.forEach((img: HTMLImageElement) => {
                img.removeEventListener('click', handleClick);
            });
        };
    }, [descriptionHtml])

    return (
        <>
            <Helmet>
                <title>{seoObj.title}</title>
                {/* Meta Description: */}
                <meta name="description" content={seoObj.description} />

            </Helmet>
            <Box w="100%" toCenter column>
                <HomeHeader
                    fullWidth={false}
                    page={'home'}
                    onLogin={(mode: LoginMode) => {
                        setPreMode(mode)
                        setShowLogin(true)
                    }}
                    onShowMenu={() => { }}
                />

                {
                    loading && <Box w='100%'>
                        <LinearProgress />
                    </Box>
                }

                <h1
                    style={{
                        width: '80%',
                        lineHeight: '40px'
                    }}
                >{title}</h1>
                {/* <img src="/images/zm-back.png" alt="back" style={{
                    width: '80%'
                }} /> */}

                <Box
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                    style={{
                        width: '80%'
                    }}></Box>


                <LoginModal
                    isOpen={showLogin}
                    refreshAfter={false}
                    redirectToDashboardAfter={true}
                    preMode={preMode}
                    onClose={() => {
                        setShowLogin(false)
                    }}
                />

                <HomeFooter />
            </Box>
        </>
    )
}

export default BlobDetail;