import { FC } from 'react';
import { Box } from '@fower/react';
import { OPCategory } from '../../../../TypeDeclare';
import { Autocomplete, TextField } from '@mui/material';
import { OriginalProductProvider } from '../../store/useOriginalProductStore';

type CatalogSelectorProps = {
  selectedParent: OPCategory | undefined;
  selectedChild: OPCategory | undefined;
  onChangeMain: (category: OPCategory) => void;
  mainCategories: OPCategory[];
  onChangeSub: (category: OPCategory) => void;
  subCategories: OPCategory[] | undefined;
  onLoad: boolean;
};
const CatalogSelector: FC<CatalogSelectorProps> = ({
  mainCategories,
  subCategories,
  onChangeMain,
  onChangeSub,
  onLoad,
  selectedParent,
  selectedChild,
}) => {
  const getMainCategories = mainCategories.map((item) => {
    return item.name;
  });
  const getSubCategories = () => {
    if (subCategories !== undefined) {
      return subCategories.map((item) => {
        return item.name;
      });
    } else {
      return [];
    }
  };

  return (
    <OriginalProductProvider>
      <Box mt4 toCenterX w='100%' h='60%' column toEvenly>
        <Box textLG mt10 textAlign='center'>
          Choose categories of your new product
        </Box>
        <Autocomplete
          value={selectedParent ? selectedParent.name : ''}
          disablePortal
          disableClearable
          options={getMainCategories}
          loading={!onLoad}
          loadingText='Loading...'
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label='Main categories' />
          )}
          onChange={(event, value) => {
            const selectedValue = value;
            const selectedCategory = mainCategories.find(
              (item) => item.name === selectedValue
            );
            onChangeMain(selectedCategory!);
          }}
        />
        <Autocomplete
          value={selectedChild ? selectedChild.name : ''}
          disabled={selectedParent === undefined}
          disablePortal
          disableClearable
          options={getSubCategories()}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label='Sub-categories' />
          )}
          onChange={(event, value) => {
            const selectedValue = value;
            const selectedCategory = subCategories!.find(
              (item) => item.name === selectedValue
            );
            onChangeSub(selectedCategory!);
          }}
        />
      </Box>
    </OriginalProductProvider>
  );
};

export default CatalogSelector;
