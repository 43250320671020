import { Box } from "@fower/react";
import { Typography } from "@mui/material";
import { FC } from "react"
import { useNavigate } from "react-router-dom";

export type BlobMenuProps = {
    onAction: () => void
}
const BlobMenu: FC<BlobMenuProps> = ({ onAction }) => {

    const navigate = useNavigate()

    return (
        <Box cursorPointer onClick={() => {
            onAction();
            navigate("/blog")
        }}>
            <Typography variant="h3" color={'#4384D6'}>
                Blog
            </Typography>
        </Box>
    )
}

export default BlobMenu