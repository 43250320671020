

export const doPost = (
  url: string,
  formData: FormData,
  token?: string,
): Promise<any> => {
  return new Promise((resolve) => {
    const options:RequestInit = {
      method: 'POST',
      mode: 'cors',
      body: formData,
    }
    if (token) {
      options['headers'] = { Authorization: `Token ${token}` } as HeadersInit
    }
    fetch(url, options)
      .then(async (response) => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then((result) => Promise.reject(result))
        }
      })
      .then((result) => {
        resolve({
          success: true,
          data: result,
        })
      })
      .catch((err) => {
        resolve({
          success: false,
          data: err,
        })
      })
  })
}

export const doDelete = (
  url: string,
  formData: FormData,
  token?: string,
): Promise<any> => {
  return new Promise((resolve) => {
    const options:RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      body: formData,
    }
    if (token) {
      options['headers'] = { Authorization: `Token ${token}` } as HeadersInit
    }
    fetch(url, options)
      .then(async (response) => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then((result) => Promise.reject(result))
        }
      })
      .then((result) => {
        resolve({
          success: true,
          data: result,
        })
      })
      .catch((err) => {
        resolve({
          success: false,
          data: err,
        })
      })
  })
}

const makeUrlString = (url:string,params:any)=>{
  if(params === null){
    return url
  }
  return `${url}?${new URLSearchParams(params).toString()}`
}
export const doGet = async (
  url: string,
  params: any,
  token?: string,
): Promise<any> => {
  
  const urlTo: string = makeUrlString(url, params)
  try {
    const options: RequestInit = {
      method: 'GET',
      mode: 'cors' as RequestMode,
    }
    if (token) {
      options['headers'] = { Authorization: `Token ${token}` } as HeadersInit
    }
    const response = await fetch(urlTo, options)
    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      return null
    }
  } catch (error) {
    console.log('error', error)
  }
}
