import { Box } from '@fower/react'
import { Alert, Divider, Snackbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import CopyBar from '../../common/CopyBar'
import { OrderInStoreVO, ShopifyFulfillmentDetailVO } from '../../TypeDeclare'
import { useAuthStore } from '../../store/useAuthStore'
import {
  addShopifyTrackLink,
  getShopifyOrderDetail,
} from './services/orderListService'
import ShopifyOrderDetailItem from '../dashboard/orders/ShopifyOrderDetailItem'

const ShopifyOrderDetail: FC<{ data?: OrderInStoreVO }> = observer(
  ({ data }) => {
    const authStore = useAuthStore()
    const [details, setDetails] = useState<Array<ShopifyFulfillmentDetailVO>>(
      [],
    )
    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [hasError, setHasError] = useState<boolean>(false)
    const [msg, setMsg] = useState<string>('')
    const handleClose = () => {
      setShowMessage(false)
    }
    useEffect(() => {
      if (data?.order_id) {
        console.log('ShopifyOrderDetail',data?.order_id);
        
        getShopifyOrderDetail(data?.order_id, authStore.token).then((res) => {
          const orders: Array<ShopifyFulfillmentDetailVO> = res.data.map(
            (d: any) => {
              return d as ShopifyFulfillmentDetailVO
            },
          )
          setDetails(orders)
        })
      }
    }, [authStore.token, data?.order_id])
    return (
      <Box w="640px" h="100%" bgWarmGray100 p5 spaceY1>
        <CopyBar label="Fulfillment Order Id" text={data?.order_id} />
        <CopyBar label="Status" text={data?.status} />
        <CopyBar
          label="Paid time"
          text={new Date(Date.parse(data?.paid_time!)).toLocaleString()}
        />
        <Divider />
        <Box column w-100p pr2 pl2 pt2 spaceY2>
          {details.map((detail) => {
            return (
              <ShopifyOrderDetailItem
                data={detail}
                key={detail.product_sku}
                order={data?.shopifyOrder!}
                hasTrackingLink={true}
                onAddTrackingLink={async (newLink) => {
                  const res = await addShopifyTrackLink(
                    newLink,
                    detail.product_sku,
                    detail.fulfillment_order_id,
                    authStore.token!,
                  )
                  setShowMessage(true)  
                  if (res['data']['error']) {
                    setHasError(true)
                    setMsg(String(res['data']['error']))
                    return false
                  } else {
                    setHasError(false)
                    setMsg('Tracking Link Updated')
                    return true
                  }
                }}
              />
            )
          })}
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showMessage}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={hasError ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </Box>
    )
  },
)
export default ShopifyOrderDetail
