import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { OrderInStoreVO, OrderType } from '../../../TypeDeclare';
import { getOrders, getShopifyOrders, parseDBShopifyOrderVO } from '../services/orderListService';

export class OrderListStore {
    @observable orders: OrderInStoreVO[] = [];
    @observable totalCount: number = 0;
    @observable currentPage: number = 1;
    constructor() {
        makeObservable(this);
    }
    parseVO(item: any, type: OrderType): OrderInStoreVO {
        return {
            order_id: item.order_id,
            order_name: item.order_name,
            quantity: item.quantity,
            product_price: item.product_price,
            amount_charged: item.amount_charged,
            status: item.status,
            status_comment: item.status_comment,
            recipient: item.recipient,
            email: item.email,
            address: item.address,
            city: item.city,
            state: item.state,
            zip: item.zip,
            paid_time: item.paid_time,
            product_id: item.product_id,
            product_sku: item.product_sku,
            product_name: item.product_name,
            cover_image: item.cover_image,
            product_images: item.product_images.split(','),
            trackingLink: item.tracking_link || '',
            type,
        };
    }
    parseShopifyVO(item: any): OrderInStoreVO {
        return {
            order_id: item.fulfillment_order_id,
            order_name: `#${item.fulfillment_order_id}`,
            quantity: item.total_items,
            product_price: item.production_cost,
            amount_charged: item.order_amount,
            status: item.customtee_status,
            status_comment: item.customtee_status,
            recipient: `${item.destination['first_name']} ${item.destination['last_name']}`,
            email: item.destination['email'],
            address: item.destination['address1'],
            city: item.destination['city'],
            state: item.destination['province'],
            zip: item.destination['zip'],
            paid_time: item.updated_time,
            product_id: '',
            product_sku: '',
            product_name: '',
            cover_image: '',
            product_images: [],
            type: 'shopify',
            shopifyOrder: parseDBShopifyOrderVO(item),
            order_status_url: item.order_status_url,
            //TODO: need api add tracking link
        };
    }


    @computed
    get pages(): number {
        return Math.ceil(this.totalCount / 20);
    }
    @action
    getOrders(
        type: OrderType,
        page: number = 1,
        token: string
    ): Promise<OrderInStoreVO[]> {
        return new Promise(async (resolve) => {
            if (type === 'shopify') {
                getShopifyOrders(type, page, token).then((res) => {
                    const result = res.data.map((item: any) => {
                        return this.parseShopifyVO(item);
                    });
                    runInAction(() => {
                        this.orders = result;
                        this.totalCount = res.total_count;
                        this.currentPage = page;
                    });
                    resolve(result);
                });
            } else {
                getOrders(type, page).then((res) => {
                    const result = res.data.map((item: any) => {
                        return this.parseVO(item, type);
                    });
                    runInAction(() => {
                        this.orders = result;
                        this.totalCount = res.total_count;
                        this.currentPage = page;
                    });
                    resolve(result);
                });
            }
        });
    }
    @action
    setPage(page: number) {
        runInAction(() => {
            this.currentPage = page;
        });
    }
}
