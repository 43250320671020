import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    const [progress, setProgress] = React.useState<number>(0)
    React.useEffect(() => {
        setProgress(props.value * 100)
    }, [props.value])
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} value={Math.round(progress)} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(progress)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function CircularWithValueLabel(props: { progress: number }) {

    const { progress } = props
    return <CircularProgressWithLabel value={progress} />;
}
