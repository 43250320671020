import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'

import { useDashbardStore } from '../store/useDashboardStore'
import { useAuthStore } from '../../../store/useAuthStore'
import { observer } from 'mobx-react-lite'
import {
    DBShopifyOrderVO,
    LinkedShop,
    PaymentCardInfoVO,
    StyledPaper,
} from '../../../TypeDeclare'
import LinkedStoreSelect from '../../../common/LinkedStoreSelect'
import ShopifyOrderDetailModal from './ShopifyOrderDetailModal'
import { AlertColor, Backdrop, CircularProgress } from '@mui/material'
import PaymentPicker from '../payments/PaymentPicker'
import ProgressIndicator from '../../../common/ProgressIndicator'
import SearchOrder from './SearchOrder'
import OrdersTable from './OrdersTable'
import { getPaymentInfoList } from '../services/dashboardService'
import AddPaymentInfo from '../payments/AddPaymentInfo'

export type OrdersProps = {
    onAddNewCard: () => void
}

const ShopifyOrders: FC<OrdersProps> = observer(({ onAddNewCard }) => {
    const dashboardStore = useDashbardStore()
    const authStore = useAuthStore()
    const [selectedShop, setSelectedShop] = useState<LinkedShop | undefined>()
    const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>()
    const [selectedOrder, setSelectedOrder] = useState<
        DBShopifyOrderVO | undefined
    >()
    const [shopifyOrders, setShopifyOrders] = useState<Array<DBShopifyOrderVO>>(
        [],
    )
    const [showOrderDetail, setShowOrderDetail] = useState<boolean>(false)
    const [showPaymentPicker, setShowPaymentPicker] = useState<boolean>(false)
    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messageType, setMessageType] = useState<AlertColor>('info')

    const [open, setOpen] = useState(false)
    const [list, setList] = useState<PaymentCardInfoVO[]>([])
    const [showAddNewCard, setShowAddNewCard] = useState<boolean>(false)

    const refreshProfile = useCallback(async () => {
        const result = await authStore.loadUserProfile()
        dashboardStore.setLinkedShops(result.rawLinkedShops)
        if (dashboardStore.linkedShops.length !== 0) {
            const shopTo = dashboardStore.linkedShops[0]
            setSelectedShop(shopTo)
        }
    }, [authStore, dashboardStore])
    const refreshOrders = useCallback((fulfillment_order_id = '') => {
        if (selectedShop) {
            setOpen(true)
            dashboardStore
                .getShopifyOrders(authStore.token, selectedShop.ctToken, fulfillment_order_id)
                .then((list) => {
                    setShopifyOrders(list)
                    setOpen(false)
                })
        }
    }, [authStore.token, dashboardStore, selectedShop])
    const submitOrder = useCallback(
        async (order: DBShopifyOrderVO, card: PaymentCardInfoVO) => {
            setShowProgress(true)
            setUpdating(true)
            const result = await dashboardStore.payByStripe(card.customerId, order.id)
            setUpdating(false)
            if (result) {
                setMessage('Order Submitted!')
                setMessageType('success')
            } else {
                setMessage('Error on Submitting Order!')
                setMessageType('error')
            }
            return result
        },
        [dashboardStore],
    )

    const onShowDetail = (item: DBShopifyOrderVO) => {
        console.log('onShowDetail', item)

        setSelectedOrderId(item.id)
        setSelectedOrder(item)
        setShowOrderDetail(true)
    }
    const onSubmitOrder = (item: DBShopifyOrderVO) => {
        setSelectedOrder(item)
        setShowPaymentPicker(true)
    }

    useEffect(() => {
        refreshOrders()
    }, [dashboardStore, authStore.token, selectedShop, refreshOrders])

    useEffect(() => {
        refreshProfile().then(null)
    }, [dashboardStore, refreshProfile])

    const reloadCards = useCallback(async () => {
        const cards = await getPaymentInfoList(authStore.token!);
        setList(cards)
    }, [dashboardStore, authStore.token])
    useEffect(() => {
        if (authStore.token) {
            reloadCards()
        }
    }, [dashboardStore, reloadCards, authStore.token])

    return (
        <Box p10 column spaceY2>
            <LinkedStoreSelect
                selectedShop={selectedShop}
                linkedShops={dashboardStore.linkedShops}
                onSelectedNewShop={(targetShop) => {
                    setSelectedShop(targetShop)
                }}
            />

            <SearchOrder
                onSearch={(orderNum) => {
                    refreshOrders(orderNum)
                }}
            />

            <OrdersTable
                data={shopifyOrders}
                onSubmitOrder={(row) => {
                    onSubmitOrder(row)
                }}
                onRowClick={(row) => {
                    onShowDetail(row)
                }}
                header={[
                    {
                        title: 'Order',
                        width: '50%'
                    },
                    {
                        title: 'Sent to production',
                        width: '30%'
                    },
                    {
                        title: 'Customer',
                        width: '30%'
                    },
                    {
                        title: 'Total Cost',
                        width: '30%'
                    },
                    {
                        title: 'Items',
                        width: '30%'
                    },
                    {
                        title: 'Fulfillment Status',
                        width: '30%'
                    },
                    {
                        title: 'Custom Tee Status',
                        width: '30%'
                    },
                    {
                        title: 'Details',
                        width: '30%'
                    },
                ]}
            />

            {shopifyOrders.length === 0 && (
                <StyledPaper toCenter elevation={1}>
                    <Box h96 toCenter>
                        No orders yet
                    </Box>
                </StyledPaper>
            )}

            {/* {shopifyOrders.length !== 0 && (
                <DataGrid
                    rows={shopifyOrders}
                    columns={columns}
                    isRowSelectable={() => false}
                    isCellEditable={() => false}
                    checkboxSelection={false}
                    rowsPerPageOptions={[5]}
                    pageSize={5}
                    rowHeight={100}
                    disableSelectionOnClick={false}
                />
            )} */}
            <ShopifyOrderDetailModal
                isOpen={showOrderDetail}
                onClose={() => {
                    setShowOrderDetail(false)
                }}
                shopifyToken={selectedShop?.ctToken}
                orderId={selectedOrderId}
                orderData={selectedOrder}
            />
            <PaymentPicker
                isOpen={showPaymentPicker}
                onClose={async (card) => {
                    setShowPaymentPicker(false)
                    if (card && selectedOrder) {
                        const result = await submitOrder(selectedOrder, card)
                        result && refreshOrders()
                    }
                }}
                onAddNewCard={() => {
                    setShowAddNewCard(true)
                    // onAddNewCard()
                }}
                cards={list}
            />

            <AddPaymentInfo
                isShow={showAddNewCard}
                onComplete={(updated) => {
                    if (updated) {
                        reloadCards()
                    }
                    setShowAddNewCard(false)
                }}
            />

            <ProgressIndicator
                isOpen={showProgress}
                text={'Submiting Order'}
                loading={updating}
                message={message}
                messageType={messageType}
                onClose={() => {
                    setShowProgress(false)
                }}
            />

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
})
export default ShopifyOrders
