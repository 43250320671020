import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ColorOption, ProductItemVO } from '../TypeDeclare'
import { useProductStore } from '../pages/product_list/store/useProdustListStore'
import AliceCarousel from 'react-alice-carousel'
import IndicatorDot from './IndicatorDot'
import { observer } from 'mobx-react-lite'

export type ProductSliderBaseOnColorProps = {
    selectedColor: ColorOption
    product?: ProductItemVO
    border?: boolean
}
const ProductSliderBaseOnColor: FC<ProductSliderBaseOnColorProps> = observer(
    ({ selectedColor, product, border }) => {
        const store = useProductStore()
        const targetProduct = product || store.product
        const [activeIndex, setActiveIndex] = useState<number>(0)
        const [sliderRef, setSliderRef] = useState<AliceCarousel | null>(null)
        const [indicatorDot, setIndicatorDot] = useState<JSX.Element[]>([])

        const updateImages = useCallback(() => {
            if (!targetProduct) return []
            const fcs = store.getSelectionColorSubSet(selectedColor, targetProduct)
            if (fcs.length === 0) {

                return targetProduct.images.map((ci, index) => {
                    return (
                        <img
                            draggable={false}
                            src={ci.url}
                            alt={`${targetProduct!.name}-${index}`}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '480px',
                            }}
                        />
                    )
                })
            }
            store.uppdateCachedImageByColor(fcs)
            const arr = fcs.map((item, index) => {
                return (
                    <img
                        draggable={false}
                        src={item.url}
                        alt={`${targetProduct!.name}-${index}`}
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '480px',
                        }}
                    />
                )
            })
            return arr
        }, [targetProduct, store, selectedColor])
        const canGoNext = () => {
            if (!targetProduct) return false
            return (
                targetProduct!.images.length > 1 &&
                activeIndex < targetProduct!.images.length - 1
            )
        }

        const canGoPrev = () => {
            if (!targetProduct) return false
            return targetProduct!.images.length > 1 && activeIndex !== 0
        }

        useEffect(() => {
            setIndicatorDot(updateImages())
        }, [updateImages])
        return (
            <>
                <Box border={!border ? 'solid 1px rgba(0,0,0,0.2)' : 'none'} relative h="480px">
                    <AliceCarousel
                        activeIndex={activeIndex}
                        mouseTracking={true}
                        disableButtonsControls
                        disableDotsControls
                        infinite
                        items={updateImages()}
                        ref={(el) => {
                            setSliderRef(el)
                        }}
                        onSlideChanged={(e) => {
                            setActiveIndex(e.item)
                        }}
                    />

                    {canGoPrev() && (
                        <Box
                            onClick={() => {
                                sliderRef?.slidePrev({})
                            }}
                        >
                            <ChevronLeftIcon
                                fontSize="large"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    cursor: 'pointer',
                                }}
                            />
                        </Box>
                    )}
                    {canGoNext() && (
                        <Box
                            onClick={() => {
                                sliderRef?.slideNext({})
                            }}
                        >
                            <ChevronRightIcon
                                fontSize="large"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: 0,
                                    cursor: 'pointer',
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <Box h6 toCenter row spaceX1>
                    {indicatorDot.map((image, index) => {
                        return (
                            <IndicatorDot
                                key={index}
                                selected={index === activeIndex}
                                onClick={() => {
                                    setActiveIndex(index)
                                    sliderRef?.slideTo(index)
                                }}
                            />
                        )
                    })}
                </Box>
            </>
        )
    },
)
export default ProductSliderBaseOnColor
