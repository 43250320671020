import { Box } from '@fower/react'
import AddIcon from '@mui/icons-material/Add'
import { FC, useRef, useState } from 'react'
import { useEditorStore } from '../../store/editor/useEditorStore'
import AddTextDialog from './AddTextDialog'
import { Alert, Divider, Snackbar, Typography } from '@mui/material'
import AddImageIcon from '../svg-icons/AddImageIcon'
import AddTextIcon from '../svg-icons/AddTextIcon'
import { observer } from 'mobx-react-lite'
import imageCompression from 'browser-image-compression'
import { LARGE_PNG, LARGE_JPG } from '../../../../utils/ColorPreset'
import { HiddeFilePicker, ProductItemVO } from '../../../../TypeDeclare'

const AddElementButton: FC<{
    type: 'image' | 'text'
    onClick: () => void
    isMobile: boolean
}> = (props) => {
    const Icon = props.type === 'image' ? <AddImageIcon /> : <AddTextIcon />
    return (
        <Box
            h16
            border={props.isMobile ? '' : '1px solid black'}
            rounded
            row
            toCenterY
            toBetween
            pl5
            m2
            cursor="pointer"
            onClick={props.onClick}
        >
            <Box row toCenterY>
                {Icon} <Box ml3>{props.children}</Box>
            </Box>

            <Box mr5 toCenterY>
                {!props.isMobile && <AddIcon />}
            </Box>
        </Box>
    )
}

export const NewAddElementActionBar: FC<{
    styles: any
    product?: ProductItemVO
    isMobile?: boolean
    onSelected?: (type: 'image' | 'text') => void
    onAddImage: () => void
    onAddText: () => void
}> = observer(({ styles, isMobile = false, onSelected, product, onAddImage, onAddText }) => {
    const store = useEditorStore()
    const [showAddText, setShowAddText] = useState<boolean>(false)
    const errorMessage = 'The image file can not be larger than 20MB'
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
    const filePicker = useRef(null)

    const onCloseErrorMessage = () => {
        setShowErrorMessage(false)
    }

    const getTopView = () => {
        if (product?.readyToShip) {
            return (
                <>
                    <Box ml6 mr4 mt2 mb2 gray600 flex row spaceX4>
                        <Box flex row spaceX2>
                            <Box gray600>Dropship Cost:</Box>
                            <Box gray600 black>
                                ${product.pairPrize}
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }
        return (
            <>
                <AddElementButton onClick={onAddImage} type="image" isMobile={isMobile}>
                    Add Image
                </AddElementButton>
                {isMobile && <Divider />}
                <AddElementButton onClick={onAddText} type="text" isMobile={isMobile}>
                    Add Text
                </AddElementButton>
            </>
        )
    }
    return (
        <Box column w="100%" {...styles} toAround mb={isMobile ? '0px' : '12px'}>
            {!isMobile && (
                <Box ml5 mt5 mb2>
                    <Typography variant="h5">
                        {product?.readyToShip ? product.name : 'Add Design'}
                    </Typography>
                </Box>
            )}

            {getTopView()}
            <HiddeFilePicker
                type="file"
                ref={filePicker}
                accept="image/png, image/jpeg"
                onChange={async (event) => {
                    if (event.target.files && event.target.files.length > 0) {
                        let compressedFile = event.target.files[0]
                        if (
                            compressedFile.type === 'image/jpeg' &&
                            compressedFile.size > LARGE_JPG
                        ) {
                            setShowErrorMessage(true)
                            return
                        }
                        if (
                            compressedFile.type === 'image/png' &&
                            compressedFile.size > LARGE_PNG
                        ) {
                            setShowErrorMessage(true)
                            return
                        }
                        if (compressedFile.size > LARGE_PNG / 2) {
                            const options = {
                                maxSizeMB: 20,
                                maxWidthOrHeight: 3840,
                                useWebWorker: true,
                            }
                            compressedFile = await imageCompression(compressedFile, options)
                        }
                        console.log('type', compressedFile.type)

                        const reader: FileReader = new FileReader()
                        reader.onload = (e) => {
                            if (e.target?.result && typeof e.target.result === 'string') {
                                store.addImage(e.target?.result)
                            }
                        }
                        reader.readAsDataURL(compressedFile)
                    }
                    onSelected && onSelected('image')
                    if (filePicker && filePicker.current) {
                        const element = filePicker.current as HTMLInputElement
                        element.value = ''
                    }
                }}
                hidden
            />
            <AddTextDialog
                showAddText={showAddText}
                onComplete={() => {
                    setShowAddText(false)
                    if (onSelected !== undefined) {
                        onSelected('text')
                    }
                }}
            />
            <Snackbar
                open={showErrorMessage}
                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    backgroundColor: 'rgba(0,0,0,0)',
                }}
                onClose={onCloseErrorMessage}
            >
                <Alert onClose={onCloseErrorMessage} severity="error" variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
})
