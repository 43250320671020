import { Box } from '@fower/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useEditorStore } from '../../store/editor/useEditorStore';
import { Button } from '@mui/material';

export interface HistoryBarProps {
    onRedo: () => void;
    onUndo: () => void;
    onDelete: () => void;
    onOk: () => void;
    disabled: boolean;
}
const HistoryBar: FC<HistoryBarProps> = observer(({ onRedo, onUndo, onDelete, onOk, disabled }) => {
    const store = useEditorStore();
    const canRedo = store.actionRecorder ? store.actionRecorder.canRedo : false;
    const canUndo = store.actionRecorder ? store.actionRecorder.canUndo : false;

    return (
        <>
            <Box
                pr4
                toLeft
                toCenterY
                spaceX2
                display='inline-flex'
            >

                {/* <img src='/icons/icon-arrow-left.png' style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => {
                    onUndo()
                }} />

                <img src='/icons/icon-arrow-right.png' style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => {
                    onRedo()
                }} /> */}

                {
                    disabled ? <img src='/icons/icon-delete.webp' alt='icon_delete' style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => {

                    }} /> : <img src='/images/icon-del.webp' alt='icon_delete' style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => {
                        onDelete()
                    }} />
                }

                <Button variant='contained'
                    onClick={onOk}
                    style={{
                        background: '#518DD9',
                        borderRadius: '20px',
                        width: '90px'
                    }}>Continue</Button>
            </Box>

        </>
    );
});
export default HistoryBar;
