import { FC, useContext } from 'react';
import { createContext } from 'react';
import { EditorStore } from './EditorStore';
const store = new EditorStore();
const EditorContext = createContext<EditorStore>(store);

export const EditorProvider: FC = ({ children }) => {
  return (
    <EditorContext.Provider value={store}>{children}</EditorContext.Provider>
  );
};


export const useEditorStore = (): EditorStore => {
  const store = useContext(EditorContext)
  if (store == null) {
    throw new Error(`useEditorStore must be used within a EditorProvider`)
  }
  return store
}
