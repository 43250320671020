import { doGet, doPost } from '../common/commonService';
import { Profile, User } from '../TypeDeclare';

export type AuthResult = {
    success: boolean;
    data?: any;
    error?: string;
};
export const login = (userName: string, pwd: string): Promise<AuthResult> => {
    const url = 'https://api.pairdrop.com/api/login/';
    return new Promise((resolve) => {
        const formData = new FormData();
        formData.append('username', userName);
        formData.append('password', pwd);
        doPost(url, formData).then((res) => {
            if (res.success) {
                console.log(res.data);

                resolve({
                    success: res.success,
                    data: res.data,
                });
            } else {
                resolve({
                    success: res.success,
                    error: 'Incorrect username or password',
                });
            }
        });
    });
};

export const signUp = (userName: string, pwd: string): Promise<AuthResult> => {
    const url = 'https://api.pairdrop.com/api/users/';
    return new Promise((resolve) => {
        const formData = new FormData();
        formData.append('username', userName);
        formData.append('password', pwd);
        doPost(url, formData).then((res) => {
            if (res.success) {
                resolve({
                    success: res.success,
                    data: res.data,
                });
            } else {
                resolve({
                    success: res.success,
                    error: res.data.username[0],
                });
            }
        });
    });
};
export const loadUserProfile = async (token: string): Promise<any> => {
    const url = 'https://api.pairdrop.com/api/profileInfo/';
    const result = await doGet(url, null, token);
    localStorage.setItem('profile_path', result.profile_path);
    return result;
};
export const updateProfile = async (
    profile: Profile,
    user: User,
    token: string,
    file?: File
) => {
    const url = `https://api.pairdrop.com/api/user/${user.id}/`;
    const formData = new FormData();
    if (file) {
        formData.append('profile_image', file, 'avatar.png')
    }
    formData.append(
        'profile',
        JSON.stringify({
            instagram_id: profile.instagram,
            profile_path: profile.profilePath,
        })
    );
    // formData.append('profile_image', profile.profileImage);
    return doPost(url, formData, token);
};
