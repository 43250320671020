import { PreviewImageItem } from '../../../TypeDeclare';
import axios from 'axios';

export class EditorService {
  public async uploadImageItems(
    params: PreviewImageItem[],
    onProgress: (p: number) => void
  ): Promise<PreviewImageItem[]> {
    const host = 'https://api.pairdrop.com/api/base64imageuploader/';
    const totalSize = params.reduce((prev, currentItem) => {
      if (currentItem.data) {
        return prev + currentItem.data.length;
      }
      return prev;
    }, 0);
    return new Promise(async (resolve) => {
      let loaded = 0;
      for (let index = 0; index < params.length; index++) {
        const item = params[index];
        if(!item.data){
          continue
        }
        const pv = item.data!.length / totalSize;
        const formdata = new FormData();
        //the upper limitation is 8
        formdata.append('base64_image_1', item.data!);
        formdata.append('format_mapping', JSON.stringify({'base64_image_1':item.format}))
        
        try {
          const result = await axios.post(host, formdata, {
            // eslint-disable-next-line no-loop-func
            onUploadProgress: (p: ProgressEvent) => {
              const percent = p.loaded / p.total;
              const localP = pv * percent;
              onProgress(loaded + localP);
            },
          });
          loaded += pv;
          item.url = result.data['base64_image_1']
          delete item['data']
        } catch (error) {
          resolve([]) 
        }     
      }
      resolve(params);
    });
  }
  public fileToBase64(file:File):Promise<string>{
    return new Promise((resolve)=>{
      const reader: FileReader = new FileReader()
      reader.onload = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          resolve(e.target?.result)
        }
      }
      reader.readAsDataURL(file)
    })
            
  }
}
