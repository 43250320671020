const COLORS = new Map<string, string>([
  ['Red', '#EE3C40'],
  ['Orange', '#EF7940'],
  ['White', '#FFFFFF'],
  ['Black', '#000000'],
  ['Navy', '#3F56F0'],
  ['Ash', '#D5D6CE'],
  ['Forest', '#203622'],
  ['Kelly', '#115832'],
  ['Olive', '#323B1C'],
  ['SportGrey', '#B4B5B8'],
  ['Maroon', '#2A0212'],
  ['BlueDust', '#212E42'],
  ['CherryRed', '#AF1222'],
  ['Daisy', '#F3C436'],
  ['IrishGreen', '#1C8C48'],
  ['LightPink', '#FBBCBC'],
  ['Lime', '#8BE864'],
  ['Natural', '#F8E7CE'],
  ['Sand', '#C9B597'],
  ['Sapphire', '#227AA9'],
  ['Sky', '#A9E1F9'],
])

const FONT_COLORS = [
  '#EE3C40',
  '#FFFFFF',
  '#000000',
  '#227AA9',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#F3C436',
  '#8BE864',
  '#1C8C48',
]
export const LARGE_JPG: number = 20000000
export const LARGE_PNG: number = 20000000

export const getColorValues = (): string[] => {
  const fromMap = Array.from(COLORS.entries())
  return fromMap.map((e) => e[1])
}

export const getFontColorValues = (): string[] => {
  return FONT_COLORS
}
