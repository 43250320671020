import { Box } from '@fower/react'
import { FC } from 'react'
import { ShippingMethodOption } from '../../../../TypeDeclare'
import ShippingMethodItem from './ShippingMethodItem'

export type ShippingMethodsPickerProps = {
  shippingMethodsOptions: Array<ShippingMethodOption>
  onUpdate: (item: ShippingMethodOption, to: string) => void
  disable: boolean
}
const ShippingMethodsPicker: FC<ShippingMethodsPickerProps> = ({
  shippingMethodsOptions,
  onUpdate,
  disable,
}) => {
  return (
    <>
      <Box column toLeft w="100%">
        <Box textXS gray500>
          Shipping Methods
        </Box>
        {shippingMethodsOptions.map((smo) => {
          return (
            <ShippingMethodItem
              key={smo.id}
              item={smo}
              onUpdate={onUpdate}
              disable={disable}
            />
          )
        })}
      </Box>
    </>
  )
}
export default ShippingMethodsPicker
