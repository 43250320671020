import { Box } from "@fower/react";
import { FC, useCallback, useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IndicatorDot from "../../common/IndicatorDot";

interface ProductSliderOnColorProps {
    imgList: string[]
}
const ProductSliderOnColor: FC<ProductSliderOnColorProps> = ({ imgList }) => {

    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [sliderRef, setSliderRef] = useState<AliceCarousel | null>(null)
    const [indicatorDot, setIndicatorDot] = useState<JSX.Element[]>([])

    const updateImages = useCallback(() => {
        if (!imgList) return []
        return imgList.map((ci, index) => {
            return (
                <img
                    draggable={false}
                    src={ci}
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '480px',
                    }}
                />
            )
        })
    }, [imgList])
    const canGoNext = () => {
        if (!imgList) return false
        return (
            imgList.length > 1 &&
            activeIndex < imgList!.length - 1
        )
    }

    const canGoPrev = () => {
        if (!imgList) return false
        return imgList.length > 1 && activeIndex !== 0
    }

    useEffect(() => {
        setIndicatorDot(updateImages())
    }, [updateImages])

    return (
        <>
            <Box relative h="480px">
                <AliceCarousel
                    activeIndex={activeIndex}
                    mouseTracking={true}
                    disableButtonsControls
                    disableDotsControls
                    infinite
                    items={updateImages()}
                    ref={(el) => {
                        setSliderRef(el)
                    }}
                    onSlideChanged={(e) => {
                        setActiveIndex(e.item)
                    }}
                />

                {canGoPrev() && (
                    <Box
                        onClick={() => {
                            sliderRef?.slidePrev({})
                        }}
                    >
                        <ChevronLeftIcon
                            fontSize="large"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                cursor: 'pointer',
                            }}
                        />
                    </Box>
                )}
                {canGoNext() && (
                    <Box
                        onClick={() => {
                            sliderRef?.slideNext({})
                        }}
                    >
                        <ChevronRightIcon
                            fontSize="large"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                cursor: 'pointer',
                            }}
                        />
                    </Box>
                )}
            </Box>
            <Box h6 toCenter row spaceX1>
                {indicatorDot.map((image, index) => {
                    return (
                        <IndicatorDot
                            key={index}
                            selected={index === activeIndex}
                            onClick={() => {
                                setActiveIndex(index)
                                sliderRef?.slideTo(index)
                            }}
                        />
                    )
                })}
            </Box>
        </>
    )
}

export default ProductSliderOnColor;