import { Box } from "@fower/react"
import { FC, useEffect, useState } from "react"
import HomeHeader from "../../home/Header"
import { LoginMode } from "../../../TypeDeclare"
import HomeFooter from "../../home/Footer"
import LoginModal from "../../../common/LoginModal"
import { Button } from "@mui/material"
import styles from './index.module.css'
import FeaturedList from "../../home/FeaturedList"
import { useNavigate } from "react-router-dom"
import { parseFeaturedProduct } from "../../product_list/services/productService"
import { useAuthStore } from "../../../store/useAuthStore"

const ExploreBody: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const navigator = useNavigate()
    const authStore = useAuthStore()

    function navigateToUrl() {
        if (!authStore.isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator('/dashboard?section=Account')
        }
    }

    function extractProductId(url: string, defaultName: string) {
        if (url) {
            if (!url.includes('/')) {
                // 如果输入的字符串不包含斜杠，直接返回该字符串
                return url;
            }
            // 匹配最后一个斜杠后面的内容
            const regex = /\/([^/]+)\/?$/;
            const match = url.match(regex);
            return match ? match[1] : null;
        }
        return defaultName

    }


    const feature = {
        name: 'These products might be suitable for you',
        desc: '',
        "products": [
            {
                "id": 52348,
                "product_id": "GB1Y7K53P1",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "24.95",
                "cost_price": "24.95",
                "pair_price": "12.95",
                "original_price": "29.14",
                "name": "17 oz Trendy Stainless Steel Insulated Bottle",
                "short_description": "Stay stylish and hydrated with our trendy 500ml stainless steel insulated bottle. Featuring a secure sealing lid to prevent spills and a smooth, rounded rim for comfortable sipping, this bottle is perfect for any adventure.\r\n\r\nThe non-slip base ensures stability, while the durable stainless steel inner lining provides excellent insulation, keeping your drinks hot or cold for hours. Upgrade your hydration game with this practical and fashionable bottle!",
                "description": "Stay stylish and hydrated with our trendy 500ml stainless steel insulated bottle. Featuring a secure sealing lid to prevent spills and a smooth, rounded rim for comfortable sipping, this bottle is perfect for any adventure.\r\n\r\nThe non-slip base ensures stability, while the durable stainless steel inner lining provides excellent insulation, keeping your drinks hot or cold for hours. Upgrade your hydration game with this practical and fashionable bottle!",
                "target_count": 2,
                "category_info": {
                    "id": 110,
                    "name": "Cup"
                },
                "category": 110,
                "salesend_time": null,
                "created_time": "Oct 30, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GB1Y7K53P1/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "One size - One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_7BPLrlqDjA4P.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_kwqPqIBUsNg0.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_7BPLrlqDjA4P.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV83QlBMcmxxRGpBNFAucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -12.0
            },
            {
                "id": 52003,
                "product_id": "GBZGWWS13W",
                "seo_url": "https://www.customtee.co/products/productId//mens-cotton-t-shirts-casual-wear",
                "seo_title": "Mens Cotton T Shirts Soft Comfortable Breathable Everyday Wear",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "17.90",
                "cost_price": "17.90",
                "pair_price": "11.95",
                "original_price": "26.89",
                "name": "180GSM Unisex Premium T-Shirt",
                "short_description": "The 100% cotton fabric is super soft and ensures comfy wear whatever the occasion. Thanks to its durable fabric, it maintains sharp lines along the edges and lasts a long time.",
                "description": "The 100% cotton fabric is super soft and ensures comfy wear whatever the occasion. Thanks to its durable fabric, it maintains sharp lines along the edges and lasts a long time.",
                "target_count": 2,
                "category_info": {
                    "id": 38,
                    "name": "Tee"
                },
                "category": 38,
                "salesend_time": null,
                "created_time": "Sep 29, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBZGWWS13W/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "XS - 4XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_jm8fEB7HGCF4.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_RZBWPysB6HAC.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_aRASOSpIhzg9.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_jm8fEB7HGCF4.jpeg",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9qbThmRUI3SEdDRjQuanBlZyIsICJlZGl0cyI6IHsicmVzaXplIjogeyJ3aWR0aCI6IDY0MCwgImhlaWdodCI6IDY0MCwgImZpdCI6ICJpbnNpZGUifX19",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -5.95
            },
            {
                "id": 52019,
                "product_id": "GBLVPAN9XU",
                "seo_url": "https://www.customtee.co/products/productId/ /unisex-fleece-hoodie-cotton-polyester-blend",
                "seo_title": "Unisex Fleece Hoodie Soft and Warm Cotton Polyester Blend Durable Fit",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "35.40",
                "cost_price": "35.40",
                "pair_price": "16.45",
                "original_price": "37.01",
                "name": "Unisex 250g Premium Fleece Hoodie",
                "short_description": "Premium unisex fleece hoodie with a front pouch pocket and matching flat drawstrings. It’s soft, stylish, and perfect for the cooler evenings. 50%cotton, 50% polyester",
                "description": "Premium unisex fleece hoodie with a front pouch pocket and matching flat drawstrings. It’s soft, stylish, and perfect for the cooler evenings. 50%cotton, 50% polyester",
                "target_count": 2,
                "category_info": {
                    "id": 78,
                    "name": "Hoodies"
                },
                "category": 78,
                "salesend_time": null,
                "created_time": "Sep 30, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBLVPAN9XU/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "S - 5XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_i6Krq92SvbDl.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_fcuuvjM1fByw.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_uynt6QjYbh68.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_i6Krq92SvbDl.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9pNktycTkyU3ZiRGwucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 42202,
                "product_id": "GBV186DDS5",
                "seo_url": null,
                "seo_title": null,
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "28.90",
                "cost_price": "28.90",
                "pair_price": "9.95",
                "original_price": "22.39",
                "name": "Unisex Hooded Zip-Up Sweater (Double-Layered Hood)",
                "short_description": "· Classic style with a zip-up closure, providing a casual and comfortable fit.\r\n· Designed with an adjustable hood and drawstrings for easy customization.\r\n· Features two large kangaroo pockets on the sides, offering ample storage for small daily essentials.\r\n· Ribbed cuff design ensures a snug and tailored fit.\r\n· Meticulous craftsmanship is evident in the neat stitching, ensuring minimal shrinkage, pilling, and deformation.\r\n· For care instructions, we recommend either hand-washing or machine-washing the sweater. Avoid prolonged soaking, bleaching, and use a detergent with a temperature not exceeding 45℃.",
                "description": "· Classic style with a zip-up closure, providing a casual and comfortable fit.\r\n· Designed with an adjustable hood and drawstrings for easy customization.\r\n· Features two large kangaroo pockets on the sides, offering ample storage for small daily essentials.\r\n· Ribbed cuff design ensures a snug and tailored fit.\r\n· Meticulous craftsmanship is evident in the neat stitching, ensuring minimal shrinkage, pilling, and deformation.\r\n· For care instructions, we recommend either hand-washing or machine-washing the sweater. Avoid prolonged soaking, bleaching, and use a detergent with a temperature not exceeding 45℃.",
                "target_count": 2,
                "category_info": {
                    "id": 78,
                    "name": "Hoodies"
                },
                "category": 78,
                "salesend_time": null,
                "created_time": "Jul 07, 2023",
                "product_link": "https://www.pairdrop.com/campaigns/GBV186DDS5/",
                "cpc": null,
                "shipping_fee": 4.0,
                "size_range": "XS - 5XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_MFF7njceU10B.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_B9HZFwSjJOpe.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_TmYVgZ0jhqS3.jpeg"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_qAQloQ1lrD6I.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9xQVFsb1ExbHJENkkucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 28432,
                "product_id": "GBHWYDQ9KB",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": null,
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "33.90",
                "cost_price": "33.90",
                "pair_price": "14.95",
                "original_price": "33.64",
                "name": "All-Over Print Backpack",
                "short_description": "This premium backpack has plenty of room with a big inner pocket, separate section for a 15'' laptop, front pocket, and a hidden pocket at the back for valuables. The bag is made out of a water-resistant material, which means your all-over print designs will stay crisp and fresh no matter the weather! 100% polyester",
                "description": "Verified by Lookbooks",
                "target_count": 2,
                "category_info": {
                    "id": 17,
                    "name": "Bags"
                },
                "category": 17,
                "salesend_time": "Jun 01, 2023",
                "created_time": "Jun 01, 2022",
                "product_link": "https://www.pairdrop.com/campaigns/GBHWYDQ9KB/",
                "cpc": null,
                "shipping_fee": 8.0,
                "size_range": "One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_LJ5wsm4FMtjb.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_skL2iq9g5Ytn.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/public/uu0_202b7a90628c4e51a3c87ccab6f80c0b_cover_image.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvcHVibGljL3V1MF8yMDJiN2E5MDYyOGM0ZTUxYTNjODdjY2FiNmY4MGMwYl9jb3Zlcl9pbWFnZS5wbmciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA2NDAsICJoZWlnaHQiOiA2NDAsICJmaXQiOiAiaW5zaWRlIn19fQ==",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 27757,
                "product_id": "GBLCO15FIR",
                "seo_url": null,
                "seo_title": null,
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "13.90",
                "cost_price": "13.90",
                "pair_price": "4.95",
                "original_price": "11.14",
                "name": "All-Over Print Drawstring Bag",
                "short_description": "Gym bag, drawstring bag, or backpack? Call it as you like! 100% spun polyester fabric. One size: 16.5″ × 14″ (42cm × 36cm). It's a must-have gym essential that can be worn as a backpack with drawstring closure at the top, and narrow, contrasting shoulder straps. Combine your love for vibrant prints and a sporty style",
                "description": "Verified by Lookbooks",
                "target_count": 2,
                "category_info": {
                    "id": 17,
                    "name": "Bags"
                },
                "category": 17,
                "salesend_time": "May 14, 2023",
                "created_time": "May 14, 2022",
                "product_link": "https://www.pairdrop.com/campaigns/GBLCO15FIR/",
                "cpc": null,
                "shipping_fee": 4.0,
                "size_range": "16\"x 14\"",
                "days_in_production": 1,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_nkCzSX3ZwCJq.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_4yUbeyBMyHtm.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_PpH5fIocCEmZ.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_tNBndX43BMqD.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_PXS2Nfn9e2UD.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_BjHjUBT0rJaw.jpeg"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/public/uu0_b302e798ee8a42409603a19ac3308b8b_cover_image.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvcHVibGljL3V1MF9iMzAyZTc5OGVlOGE0MjQwOTYwM2ExOWFjMzMwOGI4Yl9jb3Zlcl9pbWFnZS5wbmciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA2NDAsICJoZWlnaHQiOiA2NDAsICJmaXQiOiAiaW5zaWRlIn19fQ==",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -8.95
            },
        ]
    }

    const [data, setData] = useState<any>(undefined)


    // ======================


    useEffect(() => {
        const ps = feature.products
        const targetProducts = ps.map((p: any) => {
            return parseFeaturedProduct(p);
        });
        setData({
            name: 'These products might be suitable for you',
            desc: '',
            products: targetProducts
        })

    }, [])
    // ======================

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => {
                }}
            />

            <Box
                w='100%'
                // h='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#D8E8EE',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                // toCenter
                >
                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >
                        <h1
                            className={styles.title}
                        >
                            Support Business Growth
                        </h1>
                        <p
                            className={styles.description}
                        >
                            On-Demand Customization Allows You to Easily Create a Unified Image for Your Team, Enhancing
                            Team Cohesion, Showcasing Professionalism, and Meeting the Individual Needs of Team Members
                            to Strengthen Their Sense of Belonging.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigateToUrl()
                            }}
                        >
                            Get started
                        </Button>
                    </Box>


                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/explore-banner.webp"
                            alt="explore_banner"
                            width={'100%'}
                            loading="lazy"
                        />
                    </Box>
                </Box>


            </Box>

            <Box
                flex
                mt14
            >
                <Box
                    display--lg="block"
                    hidden
                >
                    <img
                        src="/images/explore-man.webp"
                        alt="explore_man"
                        className={styles.exploreMan}
                    />
                </Box>

                <Box
                    w={['100%', '100%', '100%', '850px', '850px']}
                    h={['auto', 'auto', 'auto', "480px", "480px"]}
                    boxSizing="border-box"
                    css={{
                        background: '#D8E8EE',
                        borderRadius: '18px',
                        padding: '30px 70px'
                    }}
                >

                    <h2 className={styles.exploreTitle}>
                        Corporate Merchandise
                    </h2>
                    <p className={styles.exploreText}>
                        Whether your business is small or medium-sized, whether you want to enhance team cohesion,
                        improve corporate image, or strengthen customer trust, customizing personalized products is an
                        excellent choice for you.
                    </p>
                    <p className={styles.exploreText}>
                        On-demand printing services are suitable for small-batch customization, allowing businesses to
                        produce according to actual needs. Customized items can serve as promotional tools for the
                        brand, enhancing brand exposure through unified logos and designs.
                    </p>
                </Box>
            </Box>

            <Box w="80%" css={{ marginTop: '60px' }}>
                {
                    data && <FeaturedList
                        // @ts-ignore
                        feature={data}
                        onSelect={(item) => {

                            const { seo_url, id, name } = item
                            const cpName = name.toLocaleLowerCase().split(' ').join('-')

                            const newName = extractProductId(seo_url!, cpName)

                            navigator(`/products/${id}/${newName}`)
                        }}
                    />
                }
            </Box>

            <Button
                variant="outlined"
                style={{ margin: '0 0 50px 0' }}
                onClick={() => {
                    navigator('/catalog')
                }}
            >
                View more
            </Button>


            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default ExploreBody