import { Box } from "@fower/react";
import { Button, IconButton } from "@mui/material";
import { FC } from "react";
import { DBShopifyOrderVO } from "../../../TypeDeclare";
import { format } from "date-fns";

interface OrdersTableProps {
    header: {
        title: string,
        width: string
    }[]
    data: Array<DBShopifyOrderVO>
    onRowClick: (row: DBShopifyOrderVO) => void
    onSubmitOrder: (row: DBShopifyOrderVO) => void
}

const OrdersTable: FC<OrdersTableProps> = ({
    header, data, onRowClick, onSubmitOrder
}) => {


    console.log(data)
    return (
        <>
            <Box
                w="100%"
                h="50px"
                flex

                alignItems="center"
                p2
                css={{
                    background: '#F5F5F5',
                    borderRadius: '8px',
                    border: '1px solid #8E8E8E',
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontWeight: '600',
                    color: '#6D6D6D',
                    fontSize: '16px',

                }}
            >
                {
                    header.map(item => {
                        return (
                            <Box
                                flex
                                justifyContent="center"
                                w={item.width}
                                key={item.title}
                            >
                                {item.title}
                            </Box>
                        )
                    })
                }


            </Box>

            {
                data.map(item => {
                    return (
                        <Box
                            key={item.id}
                            w="100%"
                            h="150px"
                            flex

                            alignItems="center"
                            css={{
                                borderRadius: '8px',
                                border: '1px solid #518DD9',

                                overflowX: 'auto'
                            }}
                        >

                            <Box
                                display="flex"
                                w="100%"
                                alignItems="center"
                                css={{
                                    whiteSpace: 'nowrap',  // Prevents wrapping of content
                                    minWidth: '1000px',    // Adjust as needed
                                }}
                            >
                                <Box
                                    w="50%"
                                    textCenter
                                    color="#6D6D6D"
                                    mr2
                                >
                                    {item.id}
                                </Box>

                                <Box
                                    w="30%"
                                    textCenter
                                    color="#6D6D6D"
                                    mr2
                                >
                                    {format(item.updateTime, 'MM/dd/yy HH:mm:ss')}
                                </Box>

                                <Box
                                    w="30%"
                                    color="#6D6D6D"
                                    mr2
                                    textCenter>
                                    {item.destination.first_name} {item.destination.last_name}
                                </Box>

                                <Box
                                    w="30%"
                                    color="#6D6D6D"
                                    mr2
                                    textCenter>
                                    ${item.productionCost}
                                </Box>

                                <Box
                                    w="30%"
                                    color="#6D6D6D"
                                    mr2
                                    textCenter>
                                    {item.totalItems}
                                </Box>


                                <Box
                                    w="30%"
                                    color="#6D6D6D"
                                    mr2
                                    textCenter>
                                    {item.fulfillmentStatus}
                                </Box>


                                <Box
                                    w="30%"
                                    color="#6D6D6D"
                                    textCenter>
                                    {
                                        item.customteeStatus === 'Unpaid' ?
                                            (
                                                <Box
                                                    column
                                                >
                                                    <Box p2 rounded red500 fontBold>
                                                        {item.customteeStatus}
                                                    </Box>

                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            window.open(item.orderStatusURL, '_blank')
                                                        }}
                                                    >Track Shipment</Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            borderRadius: '20px'
                                                        }}
                                                        onClick={() => {
                                                            onSubmitOrder(item)
                                                        }}
                                                    >
                                                        Submit Order
                                                    </Button>

                                                </Box>
                                            ) :
                                            (
                                                <>
                                                    <Box p2 rounded borderGreen500>
                                                        {item.customteeStatus}
                                                    </Box>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            window.open(item.orderStatusURL, '_blank')
                                                        }}
                                                    >Track Shipment</Button>
                                                </>
                                            )

                                    }
                                </Box>

                                <Box
                                    w="30%"
                                    textCenter>
                                    <IconButton
                                        aria-label="detail"
                                        onClick={() => onRowClick(item)}
                                    >
                                        <img
                                            src="/icons/icon-detail.webp"
                                            style={{
                                                width: '36px',
                                                height: '38px',
                                                cursor: 'pointer'
                                            }}
                                            alt="icon_detail"
                                        />
                                    </IconButton>

                                </Box>

                            </Box>


                        </Box>
                    )
                })
            }
        </>
    )
}

export default OrdersTable;