import { FC } from 'react'
import { Box } from '@fower/react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export type ContactUSProps = {
    onAction: () => void
}
const ContactUS: FC<ContactUSProps> = ({ onAction }) => {

    const navigate = useNavigate()

    return (
        <Box cursorPointer onClick={() => {
            onAction();
            global.location.href = global.location.origin + '/aboutUs'
        }}>
            <Typography variant="h3" color={'#4384D6'}>
                Contact Us
            </Typography>
        </Box>
    )
}
export default ContactUS
