import { Box } from '@fower/react';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SizeOption } from '../../../../TypeDeclare';
export type SizePickerProps = {
    sizes: SizeOption[];
    selectedSize: SizeOption;
    onSelect: (newValue: SizeOption) => void;
};
const SizePicker: FC<SizePickerProps> = observer((props) => {
    return (
        <Box m2 mt4 toLeft column>
            <Box toLeft row mb3>
                <Typography variant='h6'>Select a size:</Typography>
            </Box>
            <Box row flexWrap spaceX1>
                {props.sizes.map((s) => {
                    const selected = s.id === props.selectedSize.id
                    return (

                        <Button
                            key={s.id}
                            size='small'
                            onClick={() => {
                                props.onSelect(s)
                            }}
                            sx={{
                                mt: 1,
                                // backgroundColor: selected ? '#4384D6' : '#ffffff',
                                // color: selected ? '#ffffff' : '#4384D6',
                                // border: selected ? '1px solid #4384D6' : '1px solid rgb(212, 212, 212)',
                            }}
                            variant={selected ? 'contained' : 'outlined'}
                        >
                            {s.value}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
});
export default SizePicker;
