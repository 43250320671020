import { FC, useContext } from 'react';
import { createContext } from 'react';
import { OrderListStore } from './OrderListStore';

const OrderListContext = createContext<OrderListStore | null>(null);

export const OrderListProvider: FC = ({ children }) => {
  const store = new OrderListStore();
  return (
    <OrderListContext.Provider value={store}>{children}</OrderListContext.Provider>
  );
};


export const useOrderListStore = (): OrderListStore => {
  const store = useContext(OrderListContext)
  if (store == null) {
    throw new Error(`useOrderListStore must be used within a OrderListProvider`)
  }
  return store
}