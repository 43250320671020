import { Box } from '@fower/react';
import {
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { ColorOption, SUPPORTED_FONTS } from '../../../../TypeDeclare';
import CirclePicker from '../common/CirclePicker';

export interface TextElementInspectorProps {
  text: string;
  size: number;
  font: string;
  color: ColorOption;
  minValue: number;
  maxValue: number;
  colors: string[];
  onTextChange: (newText: string) => void;
  onSizeChange: (newSize: number) => void;
  onColorChange?: (newColor: ColorOption) => void;
  onFontChange: (newFont: string) => void;
}
const TextElementInspector: FC<TextElementInspectorProps> = observer(
  ({
    text,
    size,
    font,
    color,
    colors,
    minValue,
    maxValue,
    onTextChange,
    onSizeChange,
    onColorChange,
    onFontChange,
  }) => {
    const colorOptions: ColorOption[] = colors.map((c, i) => {
      return { id: i.toString(), value: c, name: c };
    });
    return (
      
      <Box m2 toLeft column>
        <Typography variant='h6'>Text</Typography>
        <TextField
          sx={{
            mt: 1,
            mb: 2,
          }}
          size='small'
          autoFocus
          fullWidth
          variant='outlined'
          value={text}
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
        />
        <Typography variant='h6'>Font</Typography>
        <Select
          value={font}
          fullWidth
          size='small'
          onChange={(event) => {
            onFontChange(event.target.value);
          }}
          sx={{
            mt: 1,
            mb: 2,
            fontFamily: font,
          }}
        >
          {SUPPORTED_FONTS.map((f) => {
            return (
              <MenuItem
                key={f}
                value={f}
                sx={{
                  fontFamily: f,
                }}
              >
                {f}
              </MenuItem>
            );
          })}
        </Select>
        <Typography variant='h6'>Font Size</Typography>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          sx={{
            width: '100%',
          }}
        >
          <Slider
            value={size}
            onChange={(_, newValue: number | number[]) => {
              const newSize = newValue as number;
              onSizeChange(newSize);
            }}
            min={minValue}
            max={maxValue}
          />
          <TextField
            sx={{
              width: '80px',
              textAlign: 'center',
            }}
            size='small'
            variant='outlined'
            value={size}
            type='number'
            onChange={(event) => {
              const newSize = Number(event.target.value);
              onSizeChange(newSize);
            }}
          />
        </Stack>
        <Typography
          sx={{
            mt: 1,
            mb: 2,
          }}
          variant='h6'
        >
          Font Color {color.value}
        </Typography>
        <CirclePicker
          circleSize={28}
          selectedColor={color}
          colors={colorOptions}
          onChangeComplete={(color) => {
            onColorChange && onColorChange(color);
          }}
          colorWheel={true}
        ></CirclePicker>
      </Box>
    );
  }
);

export default TextElementInspector;
