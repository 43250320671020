import { FC } from 'react'
import { Box } from '@fower/react'
import { StyledSnackbar } from '../TypeDeclare'
import { Alert, AlertColor, CircularProgress, Dialog } from '@mui/material'

export type ProgressIndicatorProps = {
  isOpen: boolean
  text:string
  message?: string
  loading?: boolean
  messageType: AlertColor
  onClose: () => void
}
const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  isOpen,
  loading,
  text,
  messageType,
  message,
  onClose,
}) => {
  return (
    <Dialog open={isOpen}>
      {loading && (
        <Box p10 column toCenter spaceY10>
          <CircularProgress />
          <Box>{text}</Box>
        </Box>
      )}

      <StyledSnackbar
        autoHideDuration={3000}
        open={!loading}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={messageType} sx={{ width: '100%' }} onClose={onClose}>
          {message}
        </Alert>
      </StyledSnackbar>
    </Dialog>
  )
}
export default ProgressIndicator
