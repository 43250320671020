import { Box } from "@fower/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode, StyledImage } from "../../TypeDeclare";
import LoginModal from "../../common/LoginModal";
import { Grid, ImageList, ImageListItem, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeFooter from "../home/Footer";
import LinearProgress from '@mui/material/LinearProgress';
import { doGet } from "../../common/commonService";
import { useAuthStore } from "../../store/useAuthStore";

interface blob {
    id: number
    title: string
    description: string
    description_html: string
    cover_image: string
    seo_url: string
}

const BlobList: FC = () => {
    const getCV = () => {
        if (global.innerWidth > 1075) return 3
        if (global.innerWidth > 640) return 2
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    const navigate = useNavigate()

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    const [loading, setLoading] = useState<boolean>(true)

    const page = useRef<number>(0)
    const loadMoreRef = useRef(null);

    const [hasMore, setHasMore] = useState<boolean>(true)

    const [list, setList] = useState<blob[]>([])

    async function getBlogList() {
        setLoading(true)
        const res = await doGet(`https://api.customtee.co/api/customteeBlogList/`, { page: page.current }, undefined)

        if (res.success) {
            setList(pre => {
                const newList = [...pre, ...res.data]
                return newList
            })

            if (res.total_count < (page.current * 20)) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }

            setLoading(false)
        }
    }

    useEffect(() => {
        // getBlogList()

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 当 'loadMoreRef' 进入可视区域时，调用加载更多操作
                    loadMoreContent();
                }
            });
        }, {
            root: null, // 使用视口作为根元素
            rootMargin: '0px',
            threshold: 1.0 // 完全可见时触发
        });

        const currentRef = loadMoreRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        // 清理 observer
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [])


    const loadMoreContent = useCallback(() => {
        if (hasMore) {
            page.current = page.current + 1  // 翻页
            getBlogList()
        }
    }, [hasMore]);

    return (
        <Box w="100%" toCenter column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />

            {
                loading && <Box w='100%'>
                    <LinearProgress />
                </Box>
            }


            <Box w="80%">

                <img style={{ width: '100%' }} src='/images/home-main.webp' alt="home-main" />
            </Box>

            <h1 style={{ fontSize: '50px', fontWeight: 'bold', margin: '70px 0' }}>Custom Tee Blog</h1>

            <ImageList
                sx={{
                    width: '80%',
                    height: '100%',
                    marginTop: '0px',
                    padding: '5px',
                }}
                variant="quilted"
                cols={columns}
                rowHeight={500}
            >
                {list.map((item, index) => {
                    if (!item.id) {
                        return (
                            <ImageListItem key={index.toString()}>
                                <Grid key={index.toString()} height={480} width={'100%'}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={'100%'}
                                        height={296}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={'100%'}
                                        height={184}
                                        sx={{
                                            bgcolor: 'grey.200',
                                        }}
                                    />
                                </Grid>
                            </ImageListItem>
                        )
                    }

                    return (
                        <ImageListItem key={item.id}>
                            <Box
                                minW="280px"
                                h="480px"
                                column
                                overflowHidden

                                relative
                                cursorPointer
                                onClick={() => {
                                    // onClick(data)
                                    // console.log(item.title)

                                    navigate(`/blog/${item.id}/${item.seo_url}`)
                                }}
                            >
                                <StyledImage
                                    src={item.cover_image}
                                    alt={item.title}
                                    // className={styles.itemImage}
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '296px',
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    title={item.title}
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        // whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        // maxWidth: '280px',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '2',
                                        height: '50px'
                                    }}
                                    sx={{
                                        m: 1,
                                    }}
                                >
                                    {item.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    title={item.description}
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: '#636363',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        // maxWidth: '280px',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '6',
                                    }}
                                >{item.description}
                                </Typography>

                            </Box>
                        </ImageListItem>
                    )

                })}
            </ImageList>

            <Box
                ref={loadMoreRef}
                style={{
                    height: '50px',
                    textAlign: 'center',
                    marginTop: '10px',
                    display: hasMore ? 'block' : 'none'
                }}
            >Load more...</Box>

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />

            <HomeFooter />
        </Box >
    )
}

export default BlobList;