import { FC } from 'react'
import { Box } from '@fower/react'
import { CircularProgress, Dialog, Typography } from '@mui/material'

export type SimpleUploadingProps = {
  isOpen: boolean,
  text:string
}
const SimpleUploading: FC<SimpleUploadingProps> = ({ isOpen,text }) => {
  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <Box column toCenterX spaceY5 p5>
        <CircularProgress />
        <Typography variant="h5">{text}</Typography>
      </Box>
    </Dialog>
  )
}
export default SimpleUploading
