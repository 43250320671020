import { Box } from '@fower/react';
import { styled } from '@fower/styled';
import { ImageList, ImageListItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { PreviewImageItem } from '../../TypeDeclare';
import styles from './PreviewImagesBar.module.css';
export type PreviewImagesBarProps = {
  imageData: PreviewImageItem[];
  onClick: (index: number) => void;
};

const PreviewImageElement: FC<{
  item: PreviewImageItem;
  onClick: () => void;
}> = observer(({ item, onClick }) => {
  const StyledImage = styled('img');
  return (
    <Box
      square48
      rounded
      relative
      border='solid 1px rgba(0,0,0,0.2)'
      cursorPointer
      onClick={onClick}
    >
      {/* <Box
        absolute
        toCenter
        white
        p2
        h6
        top0
        left0
        bg='#6E8FA0'
        roundedBottomRightLarge
      >
        {item.name}
      </Box> */}
      <StyledImage
        src={item.url!}
        loading='lazy'
        className={styles.imageItem}
      ></StyledImage>
    </Box>
  );
});
export const PreviewImagesBar: FC<PreviewImagesBarProps> = observer(
  ({ imageData, onClick }) => {
    return (
      <ImageList
        sx={{ width: '100%', height: 260, border: 'solid 1px rgba(0,0,0,0)' }}
        variant='quilted'
        cols={3}
        rowHeight={200}
      >
        {imageData.map((item, index) => (
          <ImageListItem key={item.name}>
            <PreviewImageElement
              item={item}
              onClick={() => {
                onClick(index);
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  }
);
