import { Box } from '@fower/react';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { getColorValues } from '../../../../utils/ColorPreset';
import { useEditorStore } from '../../store/editor/useEditorStore';
import TextElementInspector from './TextElementInspector';

const AddTextDialog: FC<{
  showAddText: boolean;
  onComplete: () => void;
}> = observer(({ showAddText, onComplete }) => {
  const store = useEditorStore();
  const onAddText = () => {
    store.addText();
    onComplete();
  };

  return (
    <Dialog maxWidth='xs' fullWidth={false} open={showAddText}>
      {/* Title */}
      <Box row toCenterY mt3 ml5 toBetween>
        <Typography variant='h5'>Add Text</Typography>
        <IconButton
          onClick={onComplete}
          sx={{
            mr: 1,
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {/* Inspector */}
      <Box p3>
        <TextElementInspector
          onColorChange={store.newTextFont.setColor.bind(store.newTextFont)}
          onSizeChange={store.newTextFont.setSize.bind(store.newTextFont)}
          onTextChange={store.newTextFont.setText.bind(store.newTextFont)}
          onFontChange={store.newTextFont.setFont.bind(store.newTextFont)}
          text={store.newTextFont.text}
          size={store.newTextFont.size}
          color={store.newTextFont.color}
          font={store.newTextFont.font}
          colors={getColorValues()}
          minValue={store.newTextFont.minValue}
          maxValue={store.newTextFont.maxValue}
        />
      </Box>

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            onAddText();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AddTextDialog;
