import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import OrderList from './pages/order_list/OrderList';

import NewEditor from './pages/editor/NewEditor'
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { ProductListProvider } from './pages/product_list/store/useProdustListStore'
import { DashboardProvider } from './pages/dashboard/store/useDashboardStore'
import { AuthProvider } from './store/useAuthStore'
import ProductDetail from './pages/productDetail';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BlobList from './pages/blob/BlobList';
import Home from './pages/home/Home'
import DashBoard from './pages/dashboard/Dashboard'
import Editor from './pages/editor/Editor'
import Checkout from './pages/checkout/Checkout'
import ProductList from './pages/product_list/ProductList'
import Connect from './pages/connect/Connect'
import Betterstyle from './pages/betterstyle/Betterstyle';
import BlobDetail from './pages/blob/BlobDetail';
import BetterstyleDetail from './pages/betterstyle/BetterstyleDetail';
import AboutUs from './pages/aboutUs/AboutUs';
import ShopCheckout from './pages/checkout/ShopCheckout';
import NotFound from './pages/notfound/NotFound'
import PrivacyPolicy from './pages/privacyPolicy';
import Terms from './pages/terms';
import ReturnPolicy from './pages/returnPolicy';
import Copyright from './pages/copyright';
import MerchantGuide from './pages/merchantGuide';
import MerchantTerms from './pages/merchantTerms';
import ExploreBody from './pages/Explore/corporate/ExploreBody';
import Personal from './pages/Explore/personal/Personal';
import EventOrganier from './pages/Explore/eventOrganier/EventOrganier';
import ShopifySellers from "./pages/Explore/shopifySellers/ShopifySellers";
import Creators from './pages/Explore/creators/Creators';

const stripePromise = loadStripe('pk_live_dRgH33f91P2rI3c0z2UGIOXM');
const theme = createTheme({
    palette: {
        primary: {
            main: '#4384D6',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: ['Segoe UI, Segoe UI'].join(','),
        button: {
            textTransform: 'capitalize',
        },
        h4: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 500,
            fontSize: '18px',
        },
        h5: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 600,
            fontSize: '20px',
        },
        h6: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 500,
            fontSize: '14px',
        },
        h3: {
            fontFamily: 'Segoe UI, Segoe UI',
            fontWeight: 700,
            fontSize: '18px',
        },
    },
    components: {
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    backgroundColor: '#fff',
                    border: '2px solid #6E8FA0',
                },
            },
        },
    },
})

function App() {
    useEffect(() => {
        ReactGA.initialize('UA-78117459-5')
    }, [])
    return (
        <div>
            <Elements stripe={stripePromise} options={{ locale: 'en' }}>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <ProductListProvider>
                            <DashboardProvider>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/oldEditor" element={<Editor />} />
                                        <Route path="/editor" element={<NewEditor />} />
                                        <Route path="/products/:id/:name" element={<ProductDetail />} />
                                        <Route path="/blog" element={<BlobList />} />
                                        <Route path="/checkout" element={<Checkout />} />
                                        <Route path="/shopCheckout" element={<ShopCheckout />} />
                                        <Route path="/catalog/:section/:product" element={<ProductList />} />
                                        <Route path="/catalog/:section/" element={<ProductList />} />
                                        <Route path="/catalog" element={<ProductList />} />
                                        <Route path="/dashboard" element={<DashBoard />} />
                                        <Route path="/connect" element={<Connect />} />
                                        <Route path='/orders' element={<OrderList />} />
                                        <Route path='/shop/:shop' element={<Betterstyle />} />
                                        {/* betterstyle */}
                                        <Route path='/shop/:shop/:productId' element={<BetterstyleDetail />} />
                                        {/* betterstyleDetail */}
                                        <Route path='/blog/:id/:title' element={<BlobDetail />} />
                                        <Route path='/aboutUs' element={<AboutUs />} />
                                        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
                                        <Route path='/terms' element={<Terms />} />
                                        <Route path='/returnPolicy' element={<ReturnPolicy />} />
                                        <Route path='/copyright/' element={<Copyright />} />
                                        <Route path='/merchant_guide/' element={<MerchantGuide />} />
                                        <Route path='/merchant_terms/' element={<MerchantTerms />} />
                                        <Route path='/corporate' element={<ExploreBody />} />
                                        <Route path='/personal' element={<Personal />} />
                                        <Route path='/eventOrganier' element={<EventOrganier />} />
                                        <Route path='/shopifySellers' element={<ShopifySellers />} />
                                        <Route path='/creators' element={<Creators />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </BrowserRouter>
                            </DashboardProvider>
                        </ProductListProvider>
                    </AuthProvider>
                </ThemeProvider>
            </Elements>
        </div>
    )
}

export default App
