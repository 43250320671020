import { Box } from '@fower/react'
import { Divider, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export type OrderDetailItemProps = {
  title: string
  value: string
  hasBottomDivider?: boolean
  discount?: number
  rawSubtotal?: string
}
const OrderDetailItem: FC<{
  title: string
  value: string
  hasBottomDivider?: boolean
  discount?: number
  rawSubtotal?: string
}> = observer(
  ({ title, value, discount, rawSubtotal, hasBottomDivider = true }) => {
    const hasDiscount = discount !== undefined && discount !== 0
    return (
      <>
        <Box toCenterY p3 w="100%" row toBetween>
          <Typography
            variant="h6"
            sx={{
              color: 'gray',
            }}
          >
            {title}
          </Typography>
          <Box w="50%" toRight>
            {!hasDiscount ? (
              <Typography variant="h6" sx={{}}>
                {value}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    textDecorationLine: 'line-through',
                    color: 'gray',
                  }}
                >
                  {rawSubtotal}
                </Typography>
              </>
            )}

            {hasDiscount && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'gray',
                    ml: 2,
                  }}
                >
                  {(discount * 100).toFixed(0)}%
                </Typography>
                <GridArrowDownwardIcon fontSize="small" />
                <Typography
                  variant="h6"
                  sx={{
                    ml: 1,
                  }}
                >
                  {value}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {hasBottomDivider && <Divider variant="middle" />}
      </>
    )
  },
)
export default OrderDetailItem
