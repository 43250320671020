import { FC, SetStateAction, useState } from 'react';
import { Box } from '@fower/react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from '@fower/styled';
import HistoryBar from '../bottom/NewHistoryBar';
import { Button, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useEditorStore } from '../../store/editor/useEditorStore';
import { NewAddElementActionBar } from '../right/NewAddElementActionBar';
import { ProductItemVO } from '../../../../TypeDeclare';

export interface TopBarProps {
    onRedo: () => void,
    onUndo: () => void,
    onDelete: () => void,
    onOk: () => void,
    disabled: boolean,
    onAddImage: () => void,
    onAddText: () => void,
    product?: ProductItemVO
}

const TopBar: FC<TopBarProps> = ({
    onRedo, onUndo, onDelete, onOk, disabled, onAddImage, product, onAddText
}) => {
    const navigator = useNavigate();
    const StyledArrowBack = styled(KeyboardBackspaceIcon);
    const store = useEditorStore()
    const [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null)
    const [isAddButtonsOpen, setIsAddButtonsOpen] = useState(false)

    function isMobileDevice() {
        return window.innerWidth <= 768;
    }


    return (
        <Box
            pt4
            pb4
            toBetween
            toCenterY
            bgWhite
            w='100%'
            borderBottomBlack
            borderBottom-1
        >
            <Box row toCenterY cursorPointer onClick={() => {
                navigator(-1);
            }}>
                <StyledArrowBack ml5 />
                <Button sx={{
                    'color': '#000'
                }}>Back</Button>
            </Box>

            <Box flex>
                {
                    isMobileDevice() &&
                    <Box
                        ml2
                        row
                        toCenterY
                        spaceX2
                        onClick={(event: {
                            currentTarget: SetStateAction<HTMLElement | null>
                        }) => {
                            if (store.uploading) return
                            setAnchorEL(event.currentTarget)
                            setIsAddButtonsOpen(true)
                        }}
                    >
                        <AddCircleIcon style={{
                            color: 'rgb(81, 141, 217)'
                        }} fontSize="large" />
                        <Box h="80%"></Box>
                    </Box>
                }

                <HistoryBar
                    onOk={onOk}
                    disabled={disabled}
                    onDelete={onDelete}
                    onRedo={onRedo}
                    onUndo={onUndo}
                />
            </Box>

            <Popover
                open={isAddButtonsOpen}
                onClose={() => {
                    setIsAddButtonsOpen(false)
                    setAnchorEL(null)
                }}
                anchorEl={anchorEL}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock
            >
                <NewAddElementActionBar
                    styles={{}}
                    isMobile={true}
                    onAddImage={onAddImage}
                    onAddText={onAddText}
                    onSelected={(type) => {
                        setIsAddButtonsOpen(false)
                        setAnchorEL(null)
                    }}
                    product={product}
                />
            </Popover>
        </Box>
    );
};
export default TopBar;
