import { action, computed, makeObservable, observable } from 'mobx';
import { LS } from '../../../../common/LocalStorage';
import {
    DEFAULT_SHIPPINGMETHOD,
    OrderDetailVO,
    OrderVO,
    ShippingMethod,
} from '../../../../TypeDeclare';
import { getPromotion } from '../../../product_list/services/productService';
import { ProductStore } from '../../../product_list/store/ProductStore';
import { ProductInfoModule } from './ProductInfoModule';
import { UserInfoModule } from './UserInfoModule';

export class CheckoutStore extends LS {
    @observable product: ProductInfoModule = new ProductInfoModule();
    @observable user: UserInfoModule = new UserInfoModule();
    @observable productStore?: ProductStore;
    constructor() {
        super();
        makeObservable(this);
        // this.user.makeDemo();
        this.recoverFromLS();
    }
    recoverFromLS() {
        const localData = this.load('uploadedImages');
        if (localData) {
            const data = JSON.parse(localData);
            this.product.setPreviewImage(data as any[]);
        }
    }
    @computed
    get error(): string | null {
        if (this.user.userName === '') {
            return 'Please fill your name';
        }
        if (this.user.email === '') {
            return 'Please fill your email';
        }
        if (this.user.phone === '') {
            return 'Please fill your phone';
        }
        if (this.user.address === '') {
            return 'Please fill your address';
        }
        if (this.user.city === '') {
            return 'Please fill your city';
        }
        if (this.user.state === '') {
            return 'Please fill your state';
        }
        if (this.user.zipcode === '') {
            return 'Please fill your zip code';
        }
        return null;
    }
    @computed
    get tableData(): Array<OrderDetailVO> {
        if (!this.productStore) return [];
        const list: Array<OrderDetailVO> = [];
        list.push({ title: 'Product', value: this.product.name });
        list.push({ title: 'Size', value: this.product.size.value });
        list.push({ title: 'Color', value: this.product.color.name });
        list.push({
            title: 'Quantity',
            value: this.productStore.quantity.toString(),
        });
        list.push({
            title: 'Subtotal',
            value: `${this.product.currency}${this.product.subtotal}`,
            discount: this.product.discount,
            rawSubTotal: `${this.product.currency}${this.product.rawSubtotal}`,
        });
        list.push({
            title: 'Shipping Method',
            value: this.product.subtotal,
            discount: this.product.discount,
            shippingMethod: this.product.shippingMethod,
            shippingMethodOptions: this.product.shippingMethodOptions,
        });
        // list.push({
        //   title: 'Total',
        //   value: `${this.product.currency}${this.product.totalAmount.toFixed(2)}`,
        // });
        return list;
    }
    @action
    setProductStore(store: ProductStore) {
        this.productStore = store;
    }
    @action
    setShippingMethod(shippingMethod: ShippingMethod) {
        this.product.shippingMethod = shippingMethod;
    }
    @computed
    get order(): OrderVO {
        return {
            product_sku: this.productStore?.sku!,
            recipient: this.user.userName,
            email: this.user.email,
            phone: this.user.phone,
            quantity: this.product.quantity.toString(),
            address: this.user.address,
            city: this.user.city,
            state: this.user.state,
            zip: this.user.zipcode,
            country: 'US',
            promotion_code: this.user.promotionCode,
            shipping_option:
                this.product.shippingMethod?.id || DEFAULT_SHIPPINGMETHOD,
        };
    }

    getPromotion() {
        getPromotion({
            product_sku: this.productStore?.sku,
            quantity: this.product.quantity,
            promotion_code: this.user.promotionCode,
        }).then((result) => {
            this.product.parseFromPromotion(result.data || result);
        });
    }
}
