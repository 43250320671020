import React, { FC, useState } from 'react'
import { SxProps, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'

export type EmptyNumberInputProps = {
  value: string | number,
  label:string,
  previewMode: boolean,
  sx?:SxProps,
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  onUpdate?: (newValue: number) => void
}
const EmptyNumberInput: FC<EmptyNumberInputProps> = observer(({
  value,
  label,
  previewMode,
  onUpdate,
  sx,
  startAdornment,
  endAdornment
}) => {
  const [emptyString, setEmptyString] = useState<boolean>(false)
  
  return (
    <TextField
      value={emptyString ? '' : value}
      InputProps={{
        readOnly: previewMode,
        startAdornment: startAdornment,
        endAdornment: endAdornment
      }}
      type="number"
      label={label}
      variant="standard"
      sx={sx}
      onChange={(event) => {
        onUpdate && onUpdate(Number(event.target.value))
        setEmptyString(event.target.value === '')
      }}
    />
  )
})
export default EmptyNumberInput
