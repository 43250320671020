import { Box } from '@fower/react';
import { Slider, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
export type ImageInspectorProps = {
  rx: number;
  ry: number;
  onRadiusChange: (newSize: number, type:'x'|'y') => void;
};
const ImageInspector: FC<ImageInspectorProps> = observer((props) => {
  return (
    <Box m2 w='100%' toLeft column>
      <Typography variant='h6'>Adjust</Typography>
      <Stack
        sx={{
          width: '100%',
          pr:'12px',
        }}
        direction='row'
        spacing={2}
        alignItems='center'
      >
        <Slider
          onChange={(_, newValue: number | number[]) => {
            const v = newValue as number;
            props.onRadiusChange(v, 'x')
          }}
          value={props.rx}
          step={0.01}
          max={0.5}
          min={0}
        />
        <Box
          w='80px'
          h='40px'
          toCenter
          rounded
          border
          borderColor='#A8BCC6'
          textXS
        >
          {Number(props.rx * 100).toFixed(0)}%
        </Box>
      </Stack>
    </Box>
  );
});
export default ImageInspector;
