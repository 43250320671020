import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import CirclePicker from '../common/CirclePicker'
import { ColorOption } from '../../../../TypeDeclare'

export type ColorPaletteProps = {
    selectedColor: ColorOption
    colorOptions: ColorOption[]
    onColorChanged: (newColor: ColorOption) => void
    loadingImage: boolean
    colorWheel?: boolean
}

const ColorPalette: FC<ColorPaletteProps> = observer(
    ({ selectedColor, colorOptions, onColorChanged, loadingImage, colorWheel }) => {

        return (
            <Box m2 toLeft column>
                {/* <Box toLeft gray700 row mb3 toCenterY>
          <Box>
            <Typography variant="h6">Choose a color:</Typography>
          </Box>
          <Box ml2>
            <Typography variant="h5">{selectedColor.name}</Typography>
          </Box>
        </Box> */}
                <CirclePicker
                    colors={colorOptions}
                    selectedColor={selectedColor}
                    circleSize={32}
                    colorWheel={colorWheel}
                    onChangeComplete={(color) => {
                        if (!loadingImage) {
                            onColorChanged(color)
                        }
                    }}
                />
            </Box>
        )
    }
)

export default ColorPalette
