/* eslint-disable react/style-prop-object */
import { SvgIcon } from '@mui/material';
import { FC } from 'react';

const ElectronicsIcon: FC<{iconcolor:string}> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='cut_common' transform='translate(-1397.000000, -298.000000)'>
          <g
            id='icon_Electronics_check'
            transform='translate(1397.000000, 298.000000)'
          >
            <rect id='矩形' x='0' y='0' width='24' height='24'></rect>
            <path
              d='M16.7689394,2 C17.4484848,2 18,2.56942417 18,3.27103609 L18,3.27103609 L18,20.7289639 C18,21.4305758 17.4484848,22 16.7689394,22 L16.7689394,22 L6.23106061,22 C5.55151515,22 5,21.4305758 5,20.7289639 L5,20.7289639 L5,3.27103609 C5,2.56942417 5.55151515,2 6.23106061,2 L6.23106061,2 Z M12.921875,11.5652174 C12.3217253,11.5652174 11.7980013,11.8997585 11.5201432,12.3961353 C11.5112565,12.4124396 11.488151,12.4124396 11.4792643,12.3961353 C11.2019987,11.8997585 10.6782747,11.5652174 10.078125,11.5652174 C9.18826823,11.5652174 8.46666667,12.3007246 8.46666667,13.2077295 C8.46666667,15.2487923 11.5,16.7826087 11.5,16.7826087 C11.5,16.7826087 14.5333333,15.2427536 14.5333333,13.2077295 C14.5333333,12.3007246 13.8117318,11.5652174 12.921875,11.5652174 Z M7.53844697,3.60404754 C7.02386364,3.60404754 6.60776515,4.03365774 6.60776515,4.56495083 L6.60776515,4.56495083 L6.60776515,7.09431264 C6.60776515,7.35868815 6.71117424,7.60018501 6.88106061,7.77304591 C7.04848485,7.94844889 7.28238636,8.05521593 7.53844697,8.05521593 C8.0530303,8.05521593 8.46912879,7.62560573 8.46912879,7.09431264 L8.46912879,7.09431264 L8.46912879,4.56495083 C8.46912879,4.30057532 8.3657197,4.05907846 8.19583333,3.88621756 C8.02840909,3.71081458 7.79450758,3.60404754 7.53844697,3.60404754 Z'
              id='形状结合'
              fill={props.iconcolor}
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ElectronicsIcon;
