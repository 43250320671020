import { Box } from '@fower/react'
import { FC, useState } from 'react'
import InfoInput from './components/InfoInput'
import { Alert, Button, Snackbar, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useOriginalProductStore } from '../store/useOriginalProductStore'
import InfoCard from './components/InfoCard'
import { useAuthStore } from '../../../store/useAuthStore'
import SimpleUploading from './components/SimpleUploading'
import Tags from './components/Tags'
import ShippingInfo from './components/ShippingInfo'

export type PreviewProps = {
    onCreated: () => void
    onBack: () => void
}
const Preview: FC<PreviewProps> = observer(({ onCreated, onBack }) => {
    const opStore = useOriginalProductStore()
    const auth = useAuthStore()
    const [creating, setCreating] = useState<boolean>(false)
    const onSubmit = async () => {
        setCreating(true)
        const result = await opStore.uploadProduct(auth.token)
        setCreating(false)

        if (result.success) {
            opStore.reset()
            onCreated()
        }
    }

    return (
        <Box w="100%" h="100%" column mt2 p3 spaceX2>
            <Box w="100%" h="100%" row mt2 p3 spaceX2>
                {/* Basic Info */}
                <Box
                    w="30%"
                    h="90%"
                    p2
                    border
                    borderGray300
                    rounded
                    shadow
                    overflowYScroll
                    column
                    toCenterX
                >
                    <Box my4 textXL>
                        INFORMATION
                    </Box>
                    <InfoInput
                        previewMode={true}
                        productName={opStore.productName}
                        updateProductName={(productName) =>
                            opStore.setProductName(productName)
                        }
                        categories={`${opStore.selectedParentCategory
                            ? opStore.selectedParentCategory?.name
                            : ''
                            } ${opStore.selectedChildCategory
                                ? opStore.selectedChildCategory?.name
                                : ''
                            }`}
                        pairPrice={opStore.pairPrice}
                        updatePairPrice={(pairPrice) => opStore.setPairPrice(pairPrice)}
                        designYourOwn={opStore.designYourOwn}
                        updateDesignYourOwn={(designYourOwn) =>
                            opStore.setDesignYourOwn(designYourOwn)
                        }
                        fullPrint={opStore.fullPrint}
                        updateFullPrint={(fullPrint) => opStore.setFullPrint(fullPrint)}
                        printOnDemand={opStore.printOnDemand}
                        updatePrintOnDemand={(printOnDemand) =>
                            opStore.setPrintOnDemand(printOnDemand)
                        }
                        shipsFrom={opStore.shipsFrom}
                        updateShipsFrom={(shipsFrom) => opStore.setShipsFrom(shipsFrom)}
                        description={opStore.description}
                        updateDescription={(description) =>
                            opStore.setDescription(description)
                        }
                        descriptionHtml={opStore.descriptionHtml}
                        updateDescriptionHtml={(descriptionHtml) =>
                            opStore.setDescriptionHtml(descriptionHtml)
                        }
                        shippingMethodsOptions={opStore.shippingMethodsOptions}

                        seoTitle={opStore.seoTitle}
                        updateSeoTitle={(seoTitle) => opStore.setSeoTitle(seoTitle)}
                        seoDescription={opStore.seoDescription}
                        updateSeoDescription={(seoDescription) =>
                            opStore.setSeoDescription(seoDescription)
                        }
                        seoUrl={opStore.seoUrl}
                        updateSeoUrl={(seoUrl) => opStore.setSeoUrl(seoUrl)}
                    />
                    <ShippingInfo
                        previewMode={true}
                        weight={opStore.weight}
                        volume={opStore.volume}
                    />
                </Box>
                {/* SKU Matrix */}
                <Box w="50%" h="81%" toCenterX column toBetween>
                    <Box
                        w="100%"
                        h="100%"
                        overflowYScroll
                        overflowYAuto
                        toCenterX
                        column
                        border
                        borderGray300
                        rounded
                        shadow
                    >
                        <Box w="100%" textXL py2 pl8 mt4>
                            SKUs:
                        </Box>
                        {opStore.allSubSKUProducts.map((item, index) => {
                            return (
                                <InfoCard
                                    key={index}
                                    type="stripe"
                                    previewMode={true}
                                    color={item.color.value}
                                    size={item.size.id}
                                    quantity={item.quantity}
                                    onQuantityUpdate={(quantity) => {
                                        opStore.updateQuantityOfItem(item, quantity)
                                    }}
                                    skuPrice={item.skuPrice}
                                    onSkuPriceUpdate={(skuPrice) => {
                                        opStore.updateSKUPriceOfItem(item, skuPrice)
                                    }}
                                    sku={item.sku}
                                />
                            )
                        })}
                    </Box>
                    <Box
                        border
                        borderGray300
                        roundedSmall
                        flex
                        row
                        flexWrap
                        p3
                        m2
                        shadow
                        w="100%"
                    >
                        Size Range: {opStore.sizeRange}
                    </Box>
                    <Tags tags={opStore.tags} />
                </Box>
                {/* Images */}
                <Box
                    w="20%"
                    h="80%"
                    toCenterX
                    column
                    spaceY1
                    border
                    borderGray300
                    rounded
                    shadow
                    overflowYAuto
                >
                    <Box textXL my6>
                        Image Thumbnails
                    </Box>
                    <Box w="90%" mb2>
                        Cover:
                    </Box>
                    {opStore.coverImages.length > 0 ? (
                        opStore.coverImages.map((cover, i) => {
                            return (
                                <Box
                                    key={`cover-${i}`}
                                    as="img"
                                    h="23%"
                                    w="90%"
                                    borderGray300
                                    border-1
                                    roundedLarge
                                    style={{ objectFit: 'contain' }}
                                    src={cover}
                                />
                            )
                        })
                    ) : (
                        <Box h="23%" w="90%" borderGray300 border-1 roundedLarge toCenter>
                            No image found
                        </Box>
                    )}

                    {opStore.sizeTableImages.length > 0 && (
                        <>
                            <Box w="90%" mb2 mt6>
                                SizeTable:
                            </Box>
                            {opStore.sizeTableImages.map((st, i) => {
                                return (
                                    <Box
                                        key={`sizetable-${i}`}
                                        as="img"
                                        h="23%"
                                        w="90%"
                                        borderGray300
                                        border-1
                                        roundedLarge
                                        style={{ objectFit: 'contain' }}
                                        src={st}
                                    />
                                )
                            })}
                        </>
                    )}
                    <Box w="90%" mt6 mb2>
                        Editable Image:
                    </Box>
                    {opStore.editableImages.map((i, index) => {
                        return (
                            <Box
                                key={index}
                                mb2
                                as="img"
                                h="23%"
                                w="90%"
                                borderGray300
                                border-1
                                roundedLarge
                                style={{ objectFit: 'contain' }}
                                src={i}
                            />
                        )
                    })}
                </Box>


                <Box fixed bottom5 right3 w="25%" toEvenly row>
                    <Button
                        size="large"
                        variant="outlined"
                        sx={{ width: '45%', height: 45 }}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        sx={{ width: '45%', height: 45 }}
                        disabled={opStore.checker.hasError}
                        onClick={onSubmit}
                    >
                        Confirm & Create
                    </Button>
                </Box>
                {opStore.checker.hasError && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                    >
                        <Alert severity="error">{opStore.checker.currentError?.reason}</Alert>
                    </Snackbar>
                )}
                <SimpleUploading isOpen={creating} text="Creating new raw product" />
            </Box>

            <Box w="100%" h="100%" p10 border
                borderGray300
                rounded
                shadow>
                <Box w="90%" mt6 mb2>
                    PSD and Layer diagram:
                </Box>
                {
                    opStore.formItemList.map((item, index) => {
                        return (
                            <Box flex flexWrap mt14 key={index}>
                                <Box h="115px" w="25%">
                                    <Box>
                                        psdsku:
                                    </Box>
                                    <Box row h="100%" overflowXAuto flexWrap>
                                        {item.psdSku}
                                    </Box>
                                </Box>

                                <Box h="115px"
                                    w="25%">
                                    <Box>
                                        layer Images:
                                    </Box>
                                    <Box row h="100%" w="30%">
                                        <Box
                                            key={index}
                                            mb2
                                            as="img"
                                            h="115px"
                                            w="170px"
                                            borderGray300
                                            border-1
                                            roundedLarge
                                            style={{ objectFit: 'contain' }}
                                            src={opStore.layerImageFiles[index]}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }


            </Box>

            <Box w="100%" h="100%" p10 border
                borderGray300
                mt5
                rounded
                shadow>
                <Box w="90%" mt6 mb2>
                    Add MaskImage*
                </Box>

                {
                    opStore.maskItemList.map((item, index) => {
                        return (
                            <Box flex flexWrap key={index}>

                                <Box row h="100%" w="15%">

                                    <Box
                                        key={index}
                                        mb2
                                        as="img"
                                        h="115px"
                                        w="170px"
                                        borderGray300
                                        border-1
                                        roundedLarge
                                        style={{ objectFit: 'contain' }}
                                        src={opStore.maskImageFiles[index]}
                                    />

                                </Box>
                                <Box row h="100%" w="15%" flex alignItems="center">
                                    <TextField label="layer Name" variant="outlined"
                                        value={item.mask.comment}
                                        disabled />
                                </Box>

                                <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                    <TextField label="Production height" variant="outlined"
                                        value={item.mask.productSize[0]}
                                        type="number"
                                        disabled />
                                    <span style={{ fontSize: '24px' }}>*</span>
                                    <TextField label="Production width" variant="outlined"
                                        value={item.mask.productSize[1]}
                                        type="number"
                                        disabled />
                                </Box>
                                <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                    <TextField label="Actual height" variant="outlined"
                                        value={item.mask.actualSize[0]}
                                        type="number"
                                        disabled />
                                    <span style={{ fontSize: '24px' }}>*</span>
                                    <TextField label="Actual width" variant="outlined"
                                        value={item.mask.actualSize[1]}
                                        type="number"
                                        disabled />
                                </Box>

                            </Box>
                        )
                    })
                }

            </Box>
        </Box>
    )
})

export default Preview
