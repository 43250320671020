import { action, computed, makeObservable, observable } from 'mobx';
import { getURLParams } from '../../../../common/StringUtils';
import {
  ColorOption,
  Currency,
  DEFAULT_SHIPPINGMETHOD,
  PreviewImageItem,
  ProductItemVO,
  ShippingMethod,
  SizeOption,
} from '../../../../TypeDeclare';

export class ProductInfoModule {
  @observable name: string = '';
  @observable unitPrice: number = 0;
  @observable currency: Currency = Currency.USD;
  // @observable _shippingFee: number = 0;
  @observable shippingMethod?: ShippingMethod;
  @observable shippingMethodOptions?: ShippingMethod[];
  @observable quantity: number = 1;
  @observable size: SizeOption = { id: '', value: '' };
  @observable color: ColorOption = { id: '2', value: '#ffffff', name: 'White' };
  @observable previewImages: PreviewImageItem[] = [];
  @observable discount: number = 0;
  @observable promotionTotal: number = 0;

  constructor() {
    makeObservable(this);
  }
  @computed
  get shippingFee(): number {
    if (this.shippingMethod) return this.shippingMethod?.fee;
    return 0;
  }
  @computed
  get totalAmount(): number {
    if (this.promotionTotal !== 0) return this.promotionTotal;
    return Number(
      Number(this.quantity * this.unitPrice + this.quantity * this.shippingFee).toFixed(2)
    );
  }
  @computed
  get subtotal(): string {
    if (this.promotionTotal !== 0) {
      const v = (1 - this.discount) * this.unitPrice * this.quantity;
      return v.toFixed(2);
    }
    return this.rawSubtotal;
  }
  @computed
  get rawSubtotal(): string {
    return Number(this.unitPrice * this.quantity).toFixed(2);
  }
  @computed
  get viewerData(): { src: string }[] {
    return this.previewImages.map((p) => {
      return {
        src: p.url!,
        downloadUrl: p.url!,
      };
    });
  }
  @action
  parseFromProduct(productVO: ProductItemVO, selectedShippingMethod: string) {
    this.name = productVO.name;
    this.unitPrice = Number(productVO.pairPrize);
    this.shippingMethodOptions = productVO.shippingMethods;
    if (selectedShippingMethod === '') {
      this.shippingMethod = {
        id: DEFAULT_SHIPPINGMETHOD,
        weightComment: '6 - 12 Days',
        fee: Number(productVO.shippingFee),
      };
      console.log(productVO.shippingMethods);
    } else {
      this.shippingMethod = productVO.shippingMethods.find(
        (sms) => sms.id === selectedShippingMethod
      );
    }
  }
  @action
  parseFromURLParams() {
    const params = getURLParams();
    this.quantity = Number(params['quantity']);
  }

  @action
  parseFromPromotion(data: any) {
    this.unitPrice = Number(data['unit_price']);
    // this._shippingFee = Number(data['shipping_fee']);
    this.discount = Number(data['discount']) / (this.unitPrice * this.quantity);
    this.promotionTotal = Number(data['final_price']);
  }
  @action
  setColor(value: ColorOption) {
    this.color = value;
  }
  @action
  setSize(value: SizeOption) {
    this.size = value;
  }
  @action
  setPreviewImage(list: any[]) {
    list.forEach((element) => {
      this.previewImages.push({
        fileName: element['fileName'] as string,
        name: element['name'] as string,
        url: element['url'] as string,
        alias: element['alias'] as string,
        format: 'png', //TODO: verify it
      });
    });
  }
}
