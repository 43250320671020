import { Box } from '@fower/react';
import { Search } from '@mui/icons-material';
import { IconButton, InputBase } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const SearchBar: FC<{
  keyword: string;
  onChange: (newValue: string) => void;
}> = observer(({ keyword , onChange}) => {
  return (
    <Box
      row
      rounded
      border='1px solid rgba(0,0,0,0.2)'
      ml4
      flexGrow='2'
    >
      <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
        <Search />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Search for products'
        value={keyword}
        onChange={(event) => {
          onChange(event.target.value as string);
        }}
      />
    </Box>
  );
});

export default SearchBar;
