import { Box } from '@fower/react'
import { TextField } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'

export type PQuantityPickerProps = {
  onUpdate: (newNumber: number) => void
  value: number
  maxValue: number
  minValue: number
}
const PQuantityPicker: FC<PQuantityPickerProps> = ({
  value,
  maxValue,
  minValue,
  onUpdate,
}) => {
  const [emptyString, setEmptyString] = useState<boolean>(false)
  const textfieldRef = useRef(null)
  const onWheel = (e:WheelEvent)=>{
    e.preventDefault()
  }
  let textfieldRefValue:HTMLInputElement|null = null
  useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    textfieldRefValue = textfieldRef.current
    if(textfieldRef !== null && textfieldRef.current !== null){
      const target = textfieldRef.current as HTMLInputElement
      
      
      target.addEventListener('wheel', onWheel, { passive: false });
    }
    return ()=>{
      if(textfieldRefValue !== null){
        textfieldRefValue.removeEventListener('wheel', onWheel);
      }
    }
  },[textfieldRef])
  return (
    <Box m2 mt4 toLeft column>
      <TextField
        ref={textfieldRef}
        value={emptyString ? '' : value}
        InputProps={{
          readOnly: false,
        }}
        type="number"
        label={'QTY'}
        variant="outlined"
        onChange={(event) => {
          const nv = Number(event.target.value)
          if (nv >= minValue) {
            onUpdate(nv)
          }
          setEmptyString(event.target.value === '')
        }}
        onBlur={() => {
          setEmptyString(false)
        }}
      />
    </Box>
  )
}
export default PQuantityPicker
