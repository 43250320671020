import { FC } from 'react'
import { Box } from '@fower/react'
import { StoreType, StyledImage } from '../../../TypeDeclare'
import { Breadcrumbs, Button, Link, Typography } from '@mui/material'

export type GuideDocProps = {
    type: StoreType
    onBack: () => void
}
const GuideDoc: FC<GuideDocProps> = ({ type, onBack }) => {
    return (
        <Box column w-100p space10 p10>
            <Breadcrumbs>
                <Link underline="hover" color="inherit" onClick={onBack}>
                    Stores
                </Link>
                <Typography color="text.primary">Connect to {type}</Typography>
            </Breadcrumbs>
            <Box gray600>
                Follow these steps to connect your Shopify store to your Customtee
                account:
            </Box>
            <Box w-100p column spaceY5 p5 border="rgba(0,0,0,0.3) 1px solid" rounded>
                <Box textXL fontBold row spaceX5>
                    <Box underline>Step 1:</Box> <Box>Create your Shopify account</Box>
                </Box>
                <Box>
                    Head over to <a href="https://www.shopify.com/">Shopify.com</a> and
                    create an account if you don’t have one yet.
                </Box>
            </Box>
            <Box w-100p column spaceY5 p5 border="rgba(0,0,0,0.3) 1px solid" rounded>
                <Box textXL fontBold row spaceX5>
                    <Box underline>Step 2:</Box>{' '}
                    <Box>Install the Customtee app on Shopify</Box>
                </Box>
                <Box>1. Click the button below:</Box>
                <Box>
                    <Button variant="contained" onClick={() => {
                        global.open('https://apps.shopify.com/custom-tee', '_blank');
                    }}>Get the App </Button>
                </Box>
                <Box>2. Log in to your Shopify account.</Box>
                <Box>3. Click on the Customtee app.</Box>
                <StyledImage src='/images/customtee-shopify.webp' alt='customtee-shopify' w={'800px'} />
                <Box>4. Next, click “Add app” and confirm the installation.</Box>
            </Box>
            <Box w-100p column spaceY5 p5 border="rgba(0,0,0,0.3) 1px solid" rounded>
                <Box textXL fontBold row spaceX5>
                    <Box underline>Step 3:</Box> <Box>Connect your store</Box>
                    {/* TODO: need image */}
                </Box>
                <Box>
                    After you install the app, you’ll be asked to log into both your
                    Shopify and Customtee accounts. Just follow the steps and connect the
                    two together.
                </Box>
                <StyledImage src='/images/connect-to-shopify.webp' alt='connect-to-shopify' w={'800px'} />
            </Box>
            <Box w-100p column spaceY5 p5 border="rgba(0,0,0,0.3) 1px solid" rounded>
                <Box textXL fontBold row spaceX5>
                    <Box underline>Step 4:</Box> <Box>Add your products</Box>
                    {/* TODO: need image */}
                </Box>
                <Box style={{
                    lineHeight: '30px'
                }}>
                    {`To start accepting orders you need to add your merch to Shopify. On Customtee, head over to Dashboard > Products and choose the produt you want to sell on Shopify. Click “Add to Store” and follow the steps.`}
                </Box>
                <Box text2XL fontBold>That’s it. You’re done! 🍻</Box>
            </Box>
        </Box>
    )
}
export default GuideDoc
