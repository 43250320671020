import { Box } from "@fower/react";
import { FC, useEffect, useState } from "react";
import HomeHeader from "../../home/Header";
import { LoginMode } from "../../../TypeDeclare";
import styles from '../corporate/index.module.css'
import { Button } from "@mui/material";
import HomeFooter from "../../home/Footer";
import LoginModal from "../../../common/LoginModal";
import FeaturedList from "../../home/FeaturedList";
import { useNavigate } from "react-router-dom";
import { parseFeaturedProduct } from "../../product_list/services/productService";
import { useAuthStore } from "../../../store/useAuthStore";

const EventOrganier: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const navigator = useNavigate()
    const authStore = useAuthStore()

    function navigateToUrl() {
        if (!authStore.isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator('/dashboard?section=Account')
        }
    }

    function extractProductId(url: string, defaultName: string) {
        if (url) {
            if (!url.includes('/')) {
                // 如果输入的字符串不包含斜杠，直接返回该字符串
                return url;
            }
            // 匹配最后一个斜杠后面的内容
            const regex = /\/([^/]+)\/?$/;
            const match = url.match(regex);
            return match ? match[1] : null;
        }
        return defaultName

    }

    const feature = {
        name: 'These products might be suitable for you',
        desc: '',
        "products": [
            {
                "id": 52310,
                "product_id": "GBA1GWPFFS",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "23.60",
                "cost_price": "23.60",
                "pair_price": "4.65",
                "original_price": "10.46",
                "name": "Mug 15oz",
                "short_description": "Introducing our 15oz ceramic mug, designed for both style and functionality. With a user-friendly, safe design, this mug is made from eco-friendly materials, ensuring it's both beautiful and practical. The thickened, anti-slip base provides stability, making it safe and convenient for daily use.\r\n\r\nThe solid, thick-walled body is simple yet elegant, odorless, and safe for everyday enjoyment. Its smooth and refined rim enhances your drinking experience, while the uniquely designed handle offers a comfortable grip. The perfect balance of weight and durability makes this mug a long-lasting addition to your kitchen, bringing you and your family a healthier lifestyle.",
                "description": "Introducing our 15oz ceramic mug, designed for both style and functionality. With a user-friendly, safe design, this mug is made from eco-friendly materials, ensuring it's both beautiful and practical. The thickened, anti-slip base provides stability, making it safe and convenient for daily use.\r\n\r\nThe solid, thick-walled body is simple yet elegant, odorless, and safe for everyday enjoyment. Its smooth and refined rim enhances your drinking experience, while the uniquely designed handle offers a comfortable grip. The perfect balance of weight and durability makes this mug a long-lasting addition to your kitchen, bringing you and your family a healthier lifestyle.",
                "target_count": 2,
                "category_info": {
                    "id": 110,
                    "name": "Cup"
                },
                "category": 110,
                "salesend_time": null,
                "created_time": "Oct 24, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBA1GWPFFS/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "One size - One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_A9PGoPoSXTMJ.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_KGCZFzkZFxM1.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_A9PGoPoSXTMJ.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9BOVBHb1BvU1hUTUoucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 52206,
                "product_id": "GBLVZOFBI0",
                "seo_url": "https://www.customtee.co/products/productId/Baseball-Cap-Best-baseball-caps-for-sun-protection-Minimalist-design-hats",
                "seo_title": "Baseball Cap-Best baseball caps for sun protection-Minimalist design hats",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "18.90",
                "cost_price": "18.90",
                "pair_price": "9.95",
                "original_price": "22.39",
                "name": "Baseball Cap-Best baseball caps for sun protection",
                "short_description": "perfect for any active lifestyle! This low-profile cap is designed to fit seamlessly into various outdoor activities, whether you're hitting the gym, camping under the stars, relaxing at the beach, hiking in the mountains, or playing a round of golf.\r\n\r\nWith a wide range of colors available, this cap suits everyone—men and women alike. Its breathable fabric ensures comfort during workouts or long days outdoors, making it an essential accessory for any outdoor sport or activity. Stay stylish and protected from the sun with this all-purpose baseball cap!",
                "description": "perfect for any active lifestyle! This low-profile cap is designed to fit seamlessly into various outdoor activities, whether you're hitting the gym, camping under the stars, relaxing at the beach, hiking in the mountains, or playing a round of golf.\r\n\r\nWith a wide range of colors available, this cap suits everyone—men and women alike. Its breathable fabric ensures comfort during workouts or long days outdoors, making it an essential accessory for any outdoor sport or activity. Stay stylish and protected from the sun with this all-purpose baseball cap!",
                "target_count": 2,
                "category_info": {
                    "id": 21,
                    "name": "Hats"
                },
                "category": 21,
                "salesend_time": null,
                "created_time": "Oct 15, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBLVZOFBI0/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "One size - One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_jYFp7yI6lM5z.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_fYf1iFPouPDR.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_6tk4Nqfol9HX.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV82dGs0TnFmb2w5SFgucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -8.95
            },
            {
                "id": 52003,
                "product_id": "GBZGWWS13W",
                "seo_url": "https://www.customtee.co/products/productId//mens-cotton-t-shirts-casual-wear",
                "seo_title": "Mens Cotton T Shirts Soft Comfortable Breathable Everyday Wear",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "17.90",
                "cost_price": "17.90",
                "pair_price": "11.95",
                "original_price": "26.89",
                "name": "180GSM Unisex Premium T-Shirt",
                "short_description": "The 100% cotton fabric is super soft and ensures comfy wear whatever the occasion. Thanks to its durable fabric, it maintains sharp lines along the edges and lasts a long time.",
                "description": "The 100% cotton fabric is super soft and ensures comfy wear whatever the occasion. Thanks to its durable fabric, it maintains sharp lines along the edges and lasts a long time.",
                "target_count": 2,
                "category_info": {
                    "id": 38,
                    "name": "Tee"
                },
                "category": 38,
                "salesend_time": null,
                "created_time": "Sep 29, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBZGWWS13W/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "XS - 4XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_jm8fEB7HGCF4.jpeg",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_RZBWPysB6HAC.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_aRASOSpIhzg9.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_jm8fEB7HGCF4.jpeg",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9qbThmRUI3SEdDRjQuanBlZyIsICJlZGl0cyI6IHsicmVzaXplIjogeyJ3aWR0aCI6IDY0MCwgImhlaWdodCI6IDY0MCwgImZpdCI6ICJpbnNpZGUifX19",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -5.95
            },
            {
                "id": 52218,
                "product_id": "GB2U8K31ZF",
                "seo_url": "https://www.customtee.co/products/productId/Women's Midweight Cotton Tee-casual t shirt",
                "seo_title": "Women's Midweight Cotton Tee-casual t shirt",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "14.60",
                "cost_price": "14.60",
                "pair_price": "5.65",
                "original_price": "12.71",
                "name": "Women's Midweight Cotton Tee",
                "short_description": "made from 100% pure cotton for the ultimate in comfort and softness. Available in a vibrant array of colors, this t-shirt is designed to suit any style and occasion. The skin-friendly fabric feels delicate against your skin, making it perfect for all-day wear. With its classic and versatile design, this essential piece is a must-have for every wardrobe. Dress it up or down—it's the perfect foundation for any outfit!",
                "description": "made from 100% pure cotton for the ultimate in comfort and softness. Available in a vibrant array of colors, this t-shirt is designed to suit any style and occasion. The skin-friendly fabric feels delicate against your skin, making it perfect for all-day wear. With its classic and versatile design, this essential piece is a must-have for every wardrobe. Dress it up or down—it's the perfect foundation for any outfit!",
                "target_count": 2,
                "category_info": {
                    "id": 38,
                    "name": "Tee"
                },
                "category": 38,
                "salesend_time": null,
                "created_time": "Oct 16, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GB2U8K31ZF/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "XXS - 2XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_m77NsSKp3nV5.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_0YIugvQQR531.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_3jpPRaZ5neAI.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_m77NsSKp3nV5.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9tNzdOc1NLcDNuVjUucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -8.95
            },
            {
                "id": 52340,
                "product_id": "GBFH2L7DAY",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "27.20",
                "cost_price": "27.20",
                "pair_price": "8.25",
                "original_price": "18.56",
                "name": "5x8ft Lightweight Double-Sided Flag",
                "short_description": "Experience the beauty and grace of our 5x8ft Lightweight Double-Sided Flag. Crafted from airy, flowing fabric, this flag dances effortlessly even in the gentlest breeze, allowing you to proudly display your message anywhere. Printed with fade-resistant dyes, it maintains its vibrant colors and top-quality appearance for years to come. Whether you’re decorating your garden, promoting an event, or simply showing off your brand, this flag is the perfect choice for any occasion!",
                "description": "Experience the beauty and grace of our 5x8ft Lightweight Double-Sided Flag. Crafted from airy, flowing fabric, this flag dances effortlessly even in the gentlest breeze, allowing you to proudly display your message anywhere. Printed with fade-resistant dyes, it maintains its vibrant colors and top-quality appearance for years to come. Whether you’re decorating your garden, promoting an event, or simply showing off your brand, this flag is the perfect choice for any occasion!",
                "target_count": 2,
                "category_info": {
                    "id": 12,
                    "name": "Home Deco"
                },
                "category": 12,
                "salesend_time": null,
                "created_time": "Oct 29, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBFH2L7DAY/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "One size - One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_4KGCw546sSzB.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_wTD5uXSWXpbl.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_Wf67YOxLeFsl.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9XZjY3WU94TGVGc2wucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 52321,
                "product_id": "GBVQRSIG6D",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "16.90",
                "cost_price": "16.90",
                "pair_price": "10.95",
                "original_price": "24.64",
                "name": "Feather Flag",
                "short_description": "Our beach flags are designed to stand out, whether indoors or outdoors, with vibrant colors that grab attention from afar, drawing new customers to your store or event. Measuring large, these flags are easily visible and can be placed at your storefront, entrance, or sidewalk—making them a cost-effective way to boost foot traffic and enhance your business visibility. Made from high-quality, tear-resistant polyester fabric, they are durable and can withstand rain, snow, and strong winds, ensuring they look great in any weather.",
                "description": "Our beach flags are designed to stand out, whether indoors or outdoors, with vibrant colors that grab attention from afar, drawing new customers to your store or event. Measuring large, these flags are easily visible and can be placed at your storefront, entrance, or sidewalk—making them a cost-effective way to boost foot traffic and enhance your business visibility. Made from high-quality, tear-resistant polyester fabric, they are durable and can withstand rain, snow, and strong winds, ensuring they look great in any weather.",
                "target_count": 2,
                "category_info": {
                    "id": 26,
                    "name": "Travel"
                },
                "category": 26,
                "salesend_time": null,
                "created_time": "Oct 25, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBVQRSIG6D/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "S - XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_5sRbIMdaB9mL.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_pquasSVV8yjD.jpeg"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_5sRbIMdaB9mL.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV81c1JiSU1kYUI5bUwucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -5.95
            },
        ]
    }

    const [data, setData] = useState<any>(undefined)
    // ======================


    useEffect(() => {
        const ps = feature.products
        const targetProducts = ps.map((p: any) => {
            return parseFeaturedProduct(p);
        });
        setData({
            name: 'These products might be suitable for you',
            desc: '',
            products: targetProducts
        })

    }, [])
    // ======================

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => {
                }}
            />

            <Box
                w='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#DAF8E1',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                // toCenter
                >
                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >
                        <h1
                            className={styles.title}
                        >
                            Create Memorable Moments
                        </h1>
                        <p
                            className={styles.description}
                        >
                            By Using Personalized Clothing and Items, You Can Enhance Participants' Sense of Belonging
                            and Engagement. This Not Only Adds Unique Charm to the Event but Also Creates a Lasting
                            Impression for the Brand.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigateToUrl()
                            }}
                        >
                            Get started
                        </Button>
                    </Box>


                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                        h="100%"
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/explore-event-banner.webp"
                            alt="explore_personal_banner"
                            width={'100%'}
                            loading="lazy"
                        />
                    </Box>
                </Box>


            </Box>

            <Box
                flex
                mt14
            >
                <Box
                    w={['100%', '100%', '100%', '850px', '850px']}
                    h={['auto', 'auto', 'auto', "480px", "480px"]}
                    boxSizing="border-box"
                    css={{
                        background: '#DAF8E1',
                        borderRadius: '18px',
                        padding: '30px 70px',
                        marginRight: '-110px'
                    }}
                >

                    <h2 className={styles.exploreTitle}>
                        Event Organizer
                    </h2>
                    <p className={styles.exploreText}>
                        Whether you are organizing entertainment events or fundraising activities, your personalized
                        products play a crucial role.
                    </p>
                    <p className={styles.exploreText}>
                        Personalized custom products not only enhance brand recognition and strengthen participants'
                        sense of belonging, but they can also serve as effective fundraising tools and showcase your
                        creativity to a wider audience.
                    </p>
                </Box>

                <Box
                    display--lg="block"
                    hidden
                >
                    <img
                        src="/images/explore-event-one.webp"
                        alt="explore_man"
                        className={styles.exploreManOther}
                    />
                </Box>
            </Box>

            <Box
                flex
                mt14
            >
                <Box
                    display--lg="block"
                    hidden
                >
                    <img
                        src="/images/explore-event-two.webp"
                        alt="explore_man"
                        className={styles.exploreMan}
                    />
                </Box>

                <Box
                    w={['100%', '100%', '100%', '850px', '850px']}
                    h={['auto', 'auto', 'auto', "480px", "480px"]}
                    boxSizing="border-box"
                    css={{
                        background: '#EEECD8',
                        borderRadius: '18px',
                        padding: '30px 70px',
                    }}
                >

                    <h2 className={styles.exploreTitle}>
                        Nonprofit Organizations & Charities
                    </h2>
                    <p className={styles.exploreText}>
                        If you are a nonprofit organization or a small animal charity, using customized products for
                        event promotion and fundraising can attract more supporters and donors, effectively helping to
                        achieve your organization's goals.
                    </p>
                    <p className={styles.exploreText}>
                        This flexible solution also effectively controls costs, avoids inventory waste, and makes
                        resource utilization more efficient.
                    </p>
                </Box>
            </Box>

            <Box
                flex
                mt14
            >


                <Box
                    w={['100%', '100%', '100%', '850px', '850px']}
                    h={['auto', 'auto', 'auto', "480px", "480px"]}
                    boxSizing="border-box"
                    css={{
                        background: '#F6DAE2',
                        borderRadius: '18px',
                        padding: '30px 70px',
                        marginRight: '-110px'
                    }}
                >

                    <h2 className={styles.exploreTitle}>
                        Creator Products
                    </h2>
                    <p className={styles.exploreText}>
                        If you have a certain level of influence, whether it's building connections with fans or
                        increasing their loyalty to attract more attention and shares, customizing personalized products
                        for gifting or selling is crucial.
                    </p>
                    <p className={styles.exploreText}>
                        Customized products can serve as an additional source of income, whether through sales or
                        collaborative promotions, increasing economic returns.
                    </p>
                </Box>

                <Box
                    display--lg="block"
                    hidden
                >
                    <img
                        src="/images/explore-event-three.webp"
                        alt="explore_man"
                        className={styles.exploreManOther}
                    />
                </Box>
            </Box>

            <Box
                w="80%"
                css={{ marginTop: '60px' }}
            >
                {
                    data && <FeaturedList
                        // @ts-ignore
                        feature={data}
                        onSelect={(item) => {

                            const { seo_url, id, name } = item
                            const cpName = name.toLocaleLowerCase().split(' ').join('-')

                            const newName = extractProductId(seo_url!, cpName)

                            navigator(`/products/${id}/${newName}`)
                        }}
                    />
                }
            </Box>

            <Button
                variant="outlined"
                style={{ margin: '0 0 50px 0' }}
                onClick={() => {
                    navigator('/catalog')
                }}
            >
                View more
            </Button>


            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default EventOrganier;