import { FC, useContext } from 'react';
import { createContext } from 'react';
import { ProductStore } from './ProductStore';

const ProductListContext = createContext<ProductStore | null>(null);

export const ProductListProvider: FC = ({ children }) => {
  const store = new ProductStore();
  return (
    <ProductListContext.Provider value={store}>{children}</ProductListContext.Provider>
  );
};


export const useProductStore = (): ProductStore => {
  const store = useContext(ProductListContext)
  if (store == null) {
    throw new Error(`useProductStore must be used within a ProductListProvider`)
  }
  return store
}
