import { FC } from 'react'
import { ShippingMethodOption } from '../../../../TypeDeclare'
import { Box } from '@fower/react'
import { MenuItem, Select } from '@mui/material'

export type ShippingMethodItemProps = {
  item: ShippingMethodOption
  onUpdate: (item: ShippingMethodOption, to: string) => void
  disable: boolean
}
const ShippingMethodItem: FC<ShippingMethodItemProps> = ({
  item,
  onUpdate,
  disable,
}) => {
  return (
    <Box row p2 toCenterY w="100%" toBetween>
      <Box textXS>{item.name} | {item.comment}</Box>
      <Select
        value={item.selected}
        onChange={(e) => onUpdate(item, e.target.value)}
        inputProps={{
          'aria-label': 'Without label',
          readOnly: disable,
        }}
        sx={{ height: '28px' }}
      >
        {item.options.map((o) => {
          return <MenuItem key={o.id} value={o.id}>{o.weightComment} ${o.fee}</MenuItem>
        })}
      </Select>
    </Box>
  )
}
export default ShippingMethodItem
