import { styled } from '@fower/styled';
import { Pagination, Paper, Snackbar } from '@mui/material';

export type User = {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    username: string;
    shopify_connected: boolean;
};

export type Profile = {
    instagram: string;
    profileImage: string;
    profilePath: string;
};
export type LoginMode = 'login' | 'signup';

//当前作用的模板面，正面或者反面
export type FaceType = 'front' | 'back';
//可编辑的图片元素类型
export type EditableFace = {
    url: string;
    face: string;
    xOff: number;
    yOff: number;
    pW: number;
    pH: number;
};
export type ColorOption = {
    id: string;
    value: string;
    name: string;
};
export type SizeOption = {
    id: string;
    value: string;
};
export type TagOption = {
    id: string;
    value: string;
};
export type BooleanResponse = {
    success: boolean;
    err?: string;
};
//种类类型
export type CategoryInfo = {
    id: string;
    parentName: string;
    category: number;
    tag: number;
};
export type TagInfo = {
    id: number;
    name: string;
    comment: string;
};
//产品类型
export type ProductItemVO = ShipmentInfo & {
    id: string;
    name: string;
    category: CategoryInfo;
    type: string;
    images: EditableFace[];
    descShorten: string;
    desc: string;
    sizeRange: string;
    price: string;

    daysInProduction: number;
    sizeTableImage: string;
    colorId: string;
    shippingFee: string;
    isFullPrint: boolean;

    productId: string;
    shippingMethods: ShippingMethod[];
    //unify new price format
    defaultPrice?: string;
    pairPrize?: string;
    originalPrize?: string;
    costPrice?: string;
    fullColorSet?: ColorSetVO[];
    customteeProductUrl?: string;
    coverImages: string[];
    readyToShip: boolean;
    tags?: Array<TagInfo>;
    description_html?: string;
    seo_title?: string;
    seo_description?: string;
    seo_url?: string;
};
export type FeaturedProductListItem = {
    name: string;
    desc: string;
    products: Array<ProductItemVO>;
};
export type RawImageElemet = {
    comment: string;
    id: number;
    image: string;
    action?: 'add' | 'delete' | 'keep';
};
//
export type OriginalProductItemVO = ShipmentInfo & {
    id: string;
    name: string;
    categoryName: string;
    costPrice: number;
    defaultPrice: number;
    descShorten: string;
    productId: string;
    coverImages: string[];
    pairPrice: number;
    images: string[];
    shippingOptions: ShippingMethodOption[];
    rawImageURLs: RawImageElemet[];
    psdSku: string[];
    psdClippingMasks: { image: string, comment: string }[];
    psdLayerImages: { image: string, comment: string }[];
    description_html: string;
    description_image: string;
    status?: number;
    seo_title?: string;
    seo_description?: string;
    seo_url?: string;
    is_featured?: number;
};

export type DBProductItemVO = {
    onSaleCustomtee: boolean;
    onSaleShopify: boolean;
};

//产品类型
export type MyProductItemVO = ProductItemVO & DBProductItemVO;

export type ColorSetVO = {
    colorId: string;
    colorName: string;
    face: string;
    url: string;
};
export type OrderVO = {
    product_sku: string;
    recipient: string;
    email: string;
    phone: string;
    quantity: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    promotion_code: string;
    shipping_option: string;
};
export type SizeTableItemProps = {
    id: string;
    size: string;
    length: string;
    chest: string;
    sleeveLength: string;
};
//预览用的图片对象
export type PreviewImageItem = {
    fileName: string;
    name: string;
    url?: string;
    data?: string;
    alias: string;
    format: string;
};
//支持的货币
export enum Currency {
    USD = '$',
}
//支持的服装尺寸
export enum SIZE {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
    XXL = '2XL',
    XXXL = '3XL',
    XXXXL = '4XL',
}

export const SUPPORTED_FONTS = [
    'Hey November',
    'Avenir Black',
    'Cinzel',
    'Crimson Text',
    'Dancing Script',
    'High Tide',
    'Pacifico',
    'Permanent Marker',
];

export const CATEGORIES: CategoryInfo[] = [
    { id: '0', tag: 0, category: 0, parentName: 'All' },
    { id: '1', tag: 11, category: 0, parentName: 'Women' },
    { id: '2', tag: 10, category: 0, parentName: 'Men' },
    { id: '3', tag: 0, category: 1, parentName: 'Apparel' },
    { id: '4', tag: 0, category: 15, parentName: 'Accessories' },
    { id: '5', tag: 0, category: 29, parentName: 'Electronics' },
    { id: '6', tag: 0, category: 8, parentName: 'Home' },
    { id: '7', tag: 12, category: 0, parentName: 'Kids' },
];

export const DEVELOPER_CHOICE: string[] = [
    '28357',
    '28341',
    '31860',

    // '27587',
    '28540',
    '28432',

    '31229',
    '31745',
    '28547',
    '27799',
];

export type FooterElement = {
    group: 'OUR COMPANY' | 'SUPPORT' | 'MERCHANTS' | 'FOLLOW ME';
    name: string;
    action: 'url' | 'click' | 'icon';
    data: string;
};

export const FooterElements: FooterElement[] = [
    {
        group: 'OUR COMPANY',
        name: 'About Us',
        action: 'url',
        data: global.location.origin + '/aboutUs',
    },
    // {
    //     group: 'OUR COMPANY',
    //     name: 'Affiliates',
    //     action: 'url',
    //     data: 'https://www.lookbooks.us/',
    // },
    {
        group: 'OUR COMPANY',
        name: 'Privacy Policy',
        action: 'url',
        data: global.location.origin + '/privacyPolicy',
    },
    {
        group: 'OUR COMPANY',
        name: 'Terms of Service',
        action: 'url',
        data: global.location.origin + '/terms/',
    },
    // {
    //     group: 'SUPPORT',
    //     name: 'Help',
    //     action: 'url',
    //     data: 'https://www.customtee.co/faq/',
    // },
    // {
    //     group: 'SUPPORT',
    //     name: 'How it works',
    //     action: 'url',
    //     data: 'https://www.customtee.co/shop_guidance/',
    // },
    {
        group: 'SUPPORT',
        name: 'Return Policy',
        action: 'url',
        data: global.location.origin + '/returnPolicy/',
    },

    {
        group: 'MERCHANTS',
        name: 'Copyright',
        action: 'url',
        data: global.location.origin + '/copyright/',
    },
    {
        group: 'MERCHANTS',
        name: 'Merchant Guide',
        action: 'url',
        data: global.location.origin + '/merchant_guide/',
    },
    {
        group: 'MERCHANTS',
        name: 'Terms of Service',
        action: 'url',
        data: global.location.origin + '/merchant_terms/',
    },
    {
        group: 'FOLLOW ME',
        name: '/icons/instagram.png',
        action: 'icon',
        data: 'https://www.instagram.com/mycustomtee',
    },
];

export enum TEE_TEMP {
    M_LONG_TEE = '/product-template/m-long-tee.png',
    M_LONG_TEE_BACK = '/product-template/m-long-tee-back.png',
}
//TODO: temporary form need resharpe
export type DBOrderVO = {
    id: string;
    customerName: string;
    updateTime: string;
    orderType: string;
    paidTime: string;
    quantity: number;
    status: string;
    totalAmount: number;
};

export type ShopifyOrderDestination = {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    phone: string;
    province: string;
    zip: string;
};
//TODO: temporary form need resharpe
export type DBShopifyOrderVO = {
    id: string;
    updateTime: Date;
    totalItems: number;
    orderAmount: number;
    productionCost: number;
    fulfillmentStatus: string;
    requestStatus: string;
    customteeStatus: string;
    orderStatusURL: string;
    destination: ShopifyOrderDestination;
    action: string;
    info: string;
};

export type StoreType = 'shopify' | 'wix' | undefined;
export type ProductType = 'all' | 'customtee' | 'shopified' | undefined;
export type ShopifyActionType = 'add' | 'remove' | 'view';
export type LinkedShop = {
    id: string;
    url: string;
    ctToken: string;
    platform: StoreType;
};

export type LinkedProduct = {
    shop: LinkedShop;
    ctProductId: string;
    url: string;
};

export type PaymentCardInfoVO = {
    id: string;
    cardNumber: string;
    expiryMonth: string;
    expiryYear: string;
    cardType: string;
    customerId: string;
    newCard?: boolean;
    name?: string;
};

export type ShopifyFulfillmentDetailVO = {
    cost_price: number;
    name: string;
    fulfillment_order_id: string;
    product_sku: string;
    quantity: number;
    sale_price: number;
    shipping: number;
    variant_title: string;
    cover_image: string;
    shipping_label: string | null;
    product_images: string;
};

export const StyledImage = styled('img');
export const StyledPaper = styled(Paper);
export const StyledSnackbar = styled(Snackbar);
export const HiddeFilePicker = styled('input');
export const StyledPagination = styled(Pagination, [
    'bgWhite',
    'p2',
    'rounded',
    'shadowLarge',
    'border',
]);

//种类类型
export type Volume = {
    length: number;
    width: number;
    height: number;
};

export type ShipmentInfo = {
    volume: Volume;
    weight: number;
};
export type CostVO = {
    shippingMethodId: string;
    cost: number;
};
export type PackageInfoVO = {
    quantity: number;
    costList: Array<CostVO>;
};
export type ShippingFeeDetailVO = {
    totalShippingCost: Array<CostVO>;
    packageList: Array<PackageInfoVO>;
};

// New original product to upload
export type NewOriginalProduct = ShipmentInfo & {
    category: string;
    productName: string;
    pairPrice: number;
    description: string;
    descriptionHtml?: string;
    tags: Array<TagOption>;
    designYourOwn: boolean;
    fullPrint: boolean;
    printOnDemand: boolean;
    shipsFrom: string;
    sizes: Array<SizeOption>;
    colors: Array<ColorOption>;
    coverImages: Array<string>;
    editableImages: Array<string>;
    seoUrl: string;
};

export type SubSKUProduct = {
    color: ColorOption;
    size: SizeOption;
    quantity: number;
    sku: string;
    skuFull: string;
    skuPrice: number;
};

//种类类型
export type OPCategory = {
    id: string;
    name: string;
    parentId: string;
};

export type OPImage = {
    file?: File;
    url?: string;
};

export const DEFAULT_SHIPPINGMETHOD = 'Economy Shipping';
export type ShippingMethod = {
    id: string;
    fee: number;
    weightComment: string;
};

export type ShippingMethodOption = {
    id: string;
    name: string;
    comment: string;
    options: Array<ShippingMethod>;
    selected: string;
};

export type OrderDetailVO = {
    title: string;
    value: string;
    discount?: number;
    rawSubTotal?: string;
    shippingMethod?: ShippingMethod;
    shippingMethodOptions?: ShippingMethod[];
};

export type ChoosenShippingMethod = { [key: string]: number };

export const ZeroVolume: Volume = {
    width: 0,
    height: 0,
    length: 0,
};
export type OrderType = 'web' | 'app' | 'shopify';
export type OrderInStoreVO = {
    order_id: string; //
    order_name: string;
    quantity: number;
    product_price: number;
    amount_charged: number;
    status: string;
    status_comment: string;
    recipient: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    paid_time: string;
    product_id: string;
    product_sku: string;
    product_name: string;
    cover_image: string;
    product_images: string[];
    type: OrderType;
    trackingLink?: string;
    shopifyOrder?: DBShopifyOrderVO;
    order_status_url?: string;
};
