import { FC, useState } from "react";
import HomeHeader from "../home/Header";
import { Box } from "@fower/react";
import HomeFooter from "../home/Footer";
import { LoginMode } from "../../TypeDeclare";
import LoginModal from "../../common/LoginModal";


const Terms: FC = () => {
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />
            <Box
                w="80%"
            >

                <p className="p1"><span className="s1"><strong>PLEASE NOTE: THE SECTION OF THESE TERMS OF USE ENTITLED “DISPUTE RESOLUTION” CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES DISPUTES TO BE ARBITRATED ON AN INDIVIDUAL BASIS, AND PROHIBITS CLASS ACTION CLAIMS. IT AFFECTS HOW DISPUTES BETWEEN YOU AND PAIRDROP ARE RESOLVED. BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO BE BOUND BY THIS ARBITRATION PROVISION. PLEASE READ IT CAREFULLY.</strong></span></p>
                <p className="p1"><span className="s1"><strong>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</strong></span></p>
                <p className="p2"><span className="s1">Terms Of Use</span></p>
                <p className="p1"><span className="s1">Effective date: Aug 15, 2018</span></p>
                <p className="p1"><span className="s1">Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at </span><span className="s2">support@customtee.com</span><span className="s1">, or at PO BOX 1902, Burlingame, CA 94011.</span></p>Custom Tee.co
                <p className="p1"><span className="s1">These Terms of Use (the “Terms”) are a binding contract between you and LargeGo, Inc. (“Custom Tee,” “we” and “us”). You must agree to and accept all of the Terms, or you don’t have the right to use the Services. Your using the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document, as well as those in the </span><span className="s2">Privacy Policy</span><span className="s1"> and </span><span className="s2">Copyright Dispute Policy</span><span className="s1">.</span></p>
                <p className="p2"><span className="s1">Will these Terms ever change?</span></p>
                <p className="p1"><span className="s1">We are constantly trying to improve our Services, so these Terms may need to change along with the Services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the Services, by sending you an email, and/or by some other means. If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</span></p>
                <p className="p1"><span className="s1">Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</span></p>
                <p className="p2"><span className="s1">What about my privacy?</span></p>
                <p className="p1"><span className="s1">Custom Tee takes the privacy of its users very seriously. For the current Custom Tee Privacy Policy.</span></p>
                <p className="p1"><span className="s1">The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13. We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any personal information about yourself to us. Parents and legal guardians may not agree to these Terms or register for the Services on behalf of any children under the age of 13. If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at support@customtee.us</span></p>
                <p className="p2"><span className="s1">What are the basics of using the Services?</span></p>
                <p className="p1"><span className="s1">You may be required to sign up for an account, and select a password and user name (“Custom Tee User ID”). You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Custom Tee User ID a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</span></p>
                <p className="p1"><span className="s1">You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and gotten your parent or guardian to agree to these Terms on your behalf).</span></p>
                <p className="p1"><span className="s1">You will only use the Services for your own internal, personal, use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by any applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible for your using the Services in a way that breaks the law.</span></p>
                <p className="p1"><span className="s1">You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account.</span></p>
                <p className="p1"><span className="s1">You may have multiple accounts on Custom Tee but Custom Tee strictly forbids the use of multiple accounts for the same sole user or the use of one account for multiple users for the purpose of earning more rewards. Custom Tee will not honor rewards earned from such accounts.</span></p>
                <p className="p1"><span className="s1">By using the Services, you will automatically be deemed to have opted in to receive e-mail communication from us. When you sign in for the first time, your preferences will be set to automatically publish all social interactions to other social networks. However, you will be able to edit these preferences by emailing us at <a style={{
                    color: '#555555',

                }} href="mailto:support@customtee.emd"><span className="s3">support@customtee.us</span></a></span></p>
                <p className="p1"><span className="s1">Your use of the Services is subject to the following additional restrictions:</span></p>
                <p className="p1"><span className="s1">You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that:</span></p>
                <p className="p3"><span className="s1"> A Infringes or violates the intellectual property rights or any other rights of anyone else (including Custom Tee);</span></p>
                <p className="p3"><span className="s1"> B Violates any law or regulation, including any applicable export control laws;</span></p>
                <p className="p3"><span className="s1"> C Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</span></p>
                <p className="p3"><span className="s1"> D Jeopardizes the security of your Custom Tee account or anyone else’s (such as allowing someone else to log in to the Services as you);</span></p>
                <p className="p3"><span className="s1"> E Attempts, in any manner, to obtain the password, account, or other security information from any other user;</span></p>
                <p className="p3"><span className="s1"> F Violates the security of any computer network, or cracks any passwords or security encryption codes;</span></p>
                <p className="p3"><span className="s1"> G Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</span></p>
                <p className="p3"><span className="s1"> H “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</span></p>
                <p className="p3"><span className="s1"> I Copies or stores any significant portion of the Content;</span></p>
                <p className="p3"><span className="s1"> J Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services; or</span></p>
                <p className="p3"><span className="s1"> K Upload, transmit, distribute, store, create, or otherwise sell or offer for sale any </span><span className="s2">Prohibited Items</span><span className="s1">.</span></p>
                <p className="p1"><span className="s1">A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</span></p>
                <p className="p2"><span className="s1">What are my rights in the Services?</span></p>
                <p className="p1"><span className="s1">The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions, and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Custom Tee’s) rights.</span></p>
                <p className="p1"><span className="s1">You understand that Custom Tee owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.</span></p>
                <p className="p1"><span className="s1">The Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn’t mean that all the restrictions above don’t apply – they do!</span></p>
                <p className="p2"><span className="s1">Do I have to grant any licenses to Custom Tee or to other users?</span></p>
                <p className="p1"><span className="s1">Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission.” Some User Submissions are viewable by other users. In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our </span><span className="s2">Privacy Policy</span><span className="s1"> to the extent they relate to User Submissions that are also your personally-identifiable information.</span></p>
                <p className="p1"><span className="s1">For all User Submissions, you hereby grant Custom Tee a license to translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a license only – your ownership in User Submissions is not affected.</span></p>
                <p className="p1"><span className="s1">If you store a User Submission in your own personal Custom Tee account, in a manner that is not viewable by any other user except you (a “Personal User Submission”), you grant Custom Tee the license above, as well as a license to display, perform, and distribute your Personal User Submission for the sole purpose of making that Personal User Submission accessible to you and providing the Services necessary to do so.</span></p>
                <p className="p1"><span className="s1">If you share a User Submission only in a manner that only certain specified users can view (for example, a private message to one or more other users)(a “Limited Audience User Submission”), then you grant Custom Tee the licenses above, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the Services necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</span></p>
                <p className="p1"><span className="s1">If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain specified users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Services (each of the foregoing, a “Public User Submission”), then you grant Custom Tee the licenses above, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all Custom Tee users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Services and/or otherwise in connection with Custom Tee’s business, provided that Custom Tee will try to notify you if it uses your Public User Submission for any reason other than displaying it on the Services. Also, you grant all other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</span></p>
                <p className="p1"><span className="s1">You agree that the licenses you grant are royalty-free, perpetual, sublicenseable, irrevocable, and worldwide.</span></p>
                <p className="p1"><span className="s1">Finally, you understand and agree that Custom Tee, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</span></p>
                <p className="p2"><span className="s1">What if I see something on the Services that infringes my copyright?</span></p>
                <p className="p1"><span className="s1">You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to online service providers, like Custom Tee, being asked to remove material that allegedly violates someone’s copyright. We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, click </span><span className="s2">here</span><span className="s1">. To learn more about the DMCA, click <a style={{
                    color: '#555555',

                }} href="http://www.copyright.gov/legislation/dmca.pdf"><span className="s2">here</span></a>.</span></p>
                <p className="p2"><span className="s1">Who is responsible for what I see and do on the Services?</span></p>
                <p className="p1"><span className="s1">Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk, and we aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</span></p>
                <p className="p1"><span className="s1">You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.</span></p>
                <p className="p1"><span className="s1">The Services may contain links or connections to third party websites or services that are not owned or controlled by Custom Tee. When you access third party websites or use third party services, you accept that there are risks in doing so, and that Custom Tee is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.</span></p>
                <p className="p1"><span className="s1">Custom Tee has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, Custom Tee will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</span></p>
                <p className="p1"><span className="s1">Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Custom Tee shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings. In particular, and without limiting the foregoing, you agree that Custom Tee does not facilitate (and bears no responsibility or liability for) any transaction (or payment or refund therefore) arranged through Custom Tee Local.</span></p>
                <p className="p1"><span className="s1">If there is a dispute between participants on this site, or between users and any third party, you agree that Custom Tee is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Custom Tee, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services.</span></p>
                <p className="p1"><span className="s1">YOU SHALL AND HEREBY DO WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."</span></p>
                <p className="p2"><span className="s1">Will Custom Tee ever change the Services?</span></p>
                <p className="p1"><span className="s1">We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. Similarly, we reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</span></p>
                <p className="p2"><span className="s1">Do the Services cost anything?</span></p>
                <p className="p1"><span className="s1">It is currently free to access and browse Custom Tee.com or any other Services. However, you may choose to purchase products through the Services, and you alone will be responsible for paying the purchase price of any products you buy, in addition to any taxes and shipping costs. Custom Tee may limit or cancel quantities of products purchased, and it reserves the right to refuse any order. In the event Custom Tee needs to make a change to an order, it will attempt to notify you by contacting the email address, billing address, and/or phone number provided at the time the order was made. Prices are subject to change at any time. Due to separate and applicable tax jurisdictions, your purchases may be subject to specific sales, custom or value-added taxes, and the shipping time and associated cost may increase accordingly.</span></p>
                <p className="p1"><span className="s1">Custom Tee reserves the right to require payment of fees for certain or all Services, in addition to the fees charged for the purchase of products. You shall pay all such fees, as described on the website in connection with such Services selected by you. Custom Tee reserves the right to change its price list and to institute new charges at any time, upon notice to you, which may be sent by email or posted on the Website. Your use of the Services following such notification constitutes your acceptance of any new or increased charges.</span></p>
                <p className="p4">&nbsp;</p>
                <p className="p2"><span className="s1">What if I need to return a purchase?</span></p>
                <p className="p1"><span className="s1">We want you to be completely satisfied with your purchase on the Services. If for any reason you are unhappy, please contact Custom Tee support at&nbsp;</span><span className="s2">support@customtee.us</span><span className="s1">.</span></p>
                <p className="p1"><span className="s1">If the product is imperfect, you may return within 30 days of delivery. You can initiate a return or a refund on items by email us at support@look.com. Simply click on ‘Contact Support’ next to the item you pairdrop to request a return or a refund and Custom Tee Support will be ready to assist.</span></p>
                <p className="p1"><span className="s1">We aim to process all requests within 72 hours upon receiving. Refunds are issued back to the original form of payment used to purchase the order. Should you have any questions about the status of your refund, your return or your account, please feel free to contact Custom Tee support at support@customtee.us For more information on our Return Policy.</span></p>
                <p className="p1"><span className="s1">If a Custom Tee promotional credit is applied to an order and that order is refunded for any reason, any promotional credit used in that order will not be refunded.</span></p>
                <p className="p1"><span className="s1">Custom Tee cannot refund, reimburse, cover, or otherwise be responsible for any fees not paid to Custom Tee. This includes any customs taxes or VAT as well as any return shipping costs you may incur in the refund process.</span></p>
                <p className="p2"><span className="s1">What if I want to receive Custom Tee mobile text alerts or opt-out?</span></p>
                <p className="p1"><span className="s1">If you would like to enroll to receive mobile texts and alerts about Custom Tee products and services, you may sign up to do so on the checkout page for purchasing a product through Custom Tee by providing your consent to receive recurring autodialed marketing texts from or on behalf of us at the mobile number you’ve provided to opt-in. You understand that consent is not a condition of purchase. Message and data rates may apply. If you would like to be removed from the Custom Tee text list, you can follow the instructions provided in those messages or otherwise reply STOP to any Custom Tee alert. If you have any questions, you may reply HELP to any Custom Tee alert or contact customer care at support@customtee.us.</span></p>
                <p className="p2"><span className="s1">What if I want to stop using the Services?</span></p>
                <p className="p1"><span className="s1">You’re free to do that at any time, by following the steps at FAQ. please refer to our </span><span className="s2">Privacy Policy</span><span className="s1">, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services. Custom Tee is also free to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. Custom Tee has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</span></p>
                <p className="p1"><span className="s1">Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</span></p>
                <p className="p1"><span className="s1">Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us.</span></p>
                <p className="p2"><span className="s1">I use the Custom Tee App via the Apple App Store – should I know anything about that?</span></p>
                <p className="p1"><span className="s1">These Terms apply to your use of all the Services, including the applications available via the Apple, Inc. (“Apple”) App Store (including, without limitation, for iPhone, iPad, iTouch, and iWatch) (each an “Application” and, collectively, the “Applications”), but the following additional terms also apply to each Application:</span></p>
                <p className="p3"><span className="s1"> A Both you and Custom Tee acknowledge that the Terms are concluded between you and Custom Tee only, and not with Apple, and that Apple is not responsible for the Application or the Content;</span></p>
                <p className="p3"><span className="s1"> B The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</span></p>
                <p className="p3"><span className="s1"> C You will only use the Application in connection with an Apple device that you own or control;</span></p>
                <p className="p3"><span className="s1"> D You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</span></p>
                <p className="p3"><span className="s1"> E In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple’s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</span></p>
                <p className="p3"><span className="s1"> F You acknowledge and agree that Custom Tee, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</span></p>
                <p className="p3"><span className="s1"> G You acknowledge and agree that, in the event of any third party claim that the Application or your possession and use of the Application infringes that third party’s intellectual property rights, Custom Tee, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</span></p>
                <p className="p3"><span className="s1"> H You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</span></p>
                <p className="p3"><span className="s1"> I Both you and Custom Tee acknowledge and agree that, in your use of the Application, you will comply with any applicable third party terms of agreement which may affect or be affected by such use; and</span></p>
                <p className="p3"><span className="s1"> J Both you and Custom Tee acknowledge and agree that Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third party beneficiary hereof.</span></p>
                <p className="p2"><span className="s1">What else do I need to know?</span></p>
                <p className="p1"><span className="s1"><strong>Warranty Disclaimer.</strong> Neither Custom Tee nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from Custom Tee or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY PAIRDROP (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</span></p>
                <p className="p1"><span className="s1"><strong>Limitation of Liability.</strong> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL PAIRDROP (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO PAIRDROP IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (III) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</span></p>
                <p className="p1"><span className="s1"><strong>Indemnity.</strong> To the fullest extent allowed by applicable law, You agree to indemnify and hold Custom Tee, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</span></p>
                <p className="p1"><span className="s1"><strong>Assignment.</strong> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Custom Tee’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</span></p>
                <p className="p1"><span className="s1"><strong>Choice Of Law &amp; Jurisdiction.</strong></span></p>
                <p className="p1"><span className="s1">These Terms and your use of the Services will be interpreted in accordance with the law of the State of California and the United States of America, without regard to their conflict-of-law provisions. You and Custom Tee agree to submit to the personal jurisdiction of a state court located in San Francisco County, San Francisco, California or a United States District court, Northern District of California located in San Francisco, California (collectively, the “San Francisco Courts”) for any actions which the parties retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights, as set forth in the Dispute Resolution provision below.</span></p>
                <p className="p1"><span className="s1"><strong>Miscellaneous.</strong> You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Custom Tee may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Custom Tee agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Custom Tee, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Custom Tee, and you do not have any authority of any kind to bind Custom Tee in any respect whatsoever. Except as expressly set forth in the section above regarding the Apple Applications, you and Custom Tee agree there are no third party beneficiaries intended under these Terms.</span></p>
                <p className="p1"><span className="s1"><strong>Dispute Resolution.</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Mandatory, Bilateral Arbitration and Waiver of Class Actions.</strong></span></p>
                <p className="p1"><span className="s1"><strong>Please read this carefully. It affects your rights. YOU AND PAIRDROP AGREE THAT ANY DISPUTE, CONTROVERSY, OR CLAIM ARISING OUT OF, OR RELATING TO YOUR USE OF PAIRDROP, TO ANY PRODUCTS OR SERVICES SOLD OR DISTRIBUTED BY OR THROUGH PAIRDROP, TO THIS AGREEMENT, OR TO THE CONTENT, AND/OR USER SUBMISSION (PUBLIC, PERSONAL AND/OR LIMITED AUDIENCE) ON PAIRDROP SHALL BE RESOLVED ONLY BY FINAL AND BINDING, BILATERAL ARBITRATION, except that (1) you may assert claims in small claims court if your claims qualify; and (2) this agreement to arbitrate does not include your or Custom Tee’s right to seek injunctive or other equitable relief in state or federal court in San Francisco to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights. The Federal Arbitration Act, 9 U.S.C. § 1, et seq., and federal arbitration law apply to this agreement and govern all questions as to whether a dispute is subject to arbitration.</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>There is no judge or jury in arbitration, and court review of an arbitration award is limited. An arbitrator, however, can award on individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these Terms of Use.</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">“Disputes” shall include, but are not limited to, any claims or controversies between you and Custom Tee against each other related in any way to or arising out of in any way from the Service, the Content, User Submission (Public, Personal, and/or Limited Audience), including but not limited to sales, returns, refunds, cancellations, defects, policies, privacy, advertising, or any communications between you and Custom Tee, even if the claim arises after you or Custom Tee has terminated Service or a user account. Disputes also include, but are not limited to, claims that: (a) you bring against our employees, agents, affiliates, or other representatives; or (b) that Custom Tee brings against you. Disputes also include, but are not limited to, (i) claims in any way related to or arising out of any aspect of the relationship between you and Custom Tee, whether based in contract, tort, statute, fraud, misrepresentation, advertising claims, or any other legal theory; (ii) claims that arose before these Terms or out of a prior set of Terms with Custom Tee; (iii) claims that are subject to on-going litigation where you are not a party or a className member; and/or (iv) claims that arise after the termination of these Terms.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Dispute Notice</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">Before initiating an arbitration, you and Custom Tee each agree to first provide the other a written notice (“Notice of Dispute”), which shall contain: (a) a written description of the problem and relevant documents and supporting information; and (b) a statement of the specific relief sought. A Notice of Dispute should be sent to: PO BOX 1902, Burlingame, CA 94011 or emailed at support@customtee.us. Custom Tee will provide a Notice of Dispute to you via the email address associated with your Custom Tee User ID. You and Custom Tee agree to make attempts to resolve the Dispute prior to commencing an arbitration and not to commence an arbitration proceeding until this 45-day post-notice resolution period expires. If an agreement cannot be reached within forty-five (45) days of receipt of the Notice of Dispute, you or Custom Tee may commence an arbitration proceeding.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Arbitration Process and Procedure</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">Unless you and Custom Tee agree otherwise in writing, arbitration shall (1) be administered by the Judicial Arbitration and Mediation Services, Inc. (“JAMS”), pursuant to the JAMS Streamlined Arbitration Rules &amp; Procedures then in effect (the “JAMS Rules”) and as modified by this agreement to arbitrate, including the rules regarding filing, administration, discovery, and arbitrator fees; (2) be conducted by a single, neutral arbitrator; and (3) take place in the county where you reside. To the extent that this agreement to arbitrate conflicts with the JAMS Policy on Consumer Arbitrations Pursuant to Pre-Dispute Clauses Minimum Standards for Procedural Fairness (the “Minimum Standards”), the Minimum Standards in that regard will apply.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a className or representative action.</strong> Further, unless both you and Custom Tee expressly agree otherwise, the arbitrator may not consolidate more than one person’s claims. If this prohibition of className, representative, or consolidated arbitration is found to be unenforceable, then the entirety of this arbitration provision shall be null and void.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">If, for any reason, a claim proceeds in court rather than in arbitration, <strong>we each waive our right to a jury trial.</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>YOU THEREFORE UNDERSTAND AND AGREE THAT BY ENTERING INTO THIS AGREEMENT, YOU AND PAIRDROP ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION FOR ANY CLAIMS COVERED BY THIS AGREEMENT.</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">The JAMS Rules are available on its website at <a style={{
                    color: '#555555',

                }} href="https://www.jamsadr.com/rules-streamlined-arbitration/"><span className="s2">https://www.jamsadr.com/rules-streamlined-arbitration/</span></a>. Notwithstanding any JAMS Rules to the contrary or any other provision in the arbitration rules chosen, by agreement, to govern, you and Custom Tee each agree that all issues regarding the Dispute are delegated to the arbitrator to decide, except that only a court (and not the arbitrator) shall decide any disagreements regarding the scope and enforceability of this agreement to arbitrate.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Hearing</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">If your claim does not exceed $5,000, you and Custom Tee agree to waive an oral hearing by the arbitrator and the arbitration will be conducted solely on the basis of documents you and Custom Tee submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. To the extent an oral hearing is requested by you or Custom Tee, or deemed necessary by the arbitrator, you and Custom Tee agree that the hearing will be conducted telephonically or videographically.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Arbitrator’s Decision</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">An arbitrator’s award will be a written statement of the disposition of each claim and will also provide a concise written statement of the essential findings and conclusions which form the basis of the award. The arbitrator’s decision and award is final and binding, with some limited court review under the FAA, and judgment on the award may be entered in any court of competent jurisdiction.</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Fees</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">It is each parties’ responsibility to pay any JAMS filing, case management/administrative, and arbitrator fees as set forth in the JAMS Rules. If your claim for damages does not exceed $5,000, Custom Tee will pay all such fees unless the arbitrator finds that either the substance of your Dispute or the relief sought was frivolous or was brought for an improper purpose (as measured by the standards set forth in the Federal Rule of Civil Procedure 11(b)).</span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Small Claims &amp; Government Actions</strong></span></p>
                <p className="p5">&nbsp;</p>
                <p className="p1"><span className="s1">As an alternative to arbitration, you or Custom Tee may resolve Disputes in a small claims court that has jurisdiction over your claim. These Terms and this arbitration agreement do not prevent you from bringing your Dispute to the attention of any federal, state, or local government agency. Such agencies can, if the law allows, seek relief against Custom Tee on your behalf.</span></p>


            </Box>
            <HomeFooter />

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default Terms;