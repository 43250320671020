import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { LS } from '../common/LocalStorage';
import { Profile, User } from '../TypeDeclare';
import {
  AuthResult,
  loadUserProfile,
  login,
  signUp,
  updateProfile,
} from './authService';

const LC_KEY_TOKEN = 'ctToken';
const LC_USER = 'ctUser';

export class AuthStore extends LS {
  @observable isLogin: boolean = false;
  @observable showLoginModalOnEditor: boolean = false;
  @observable user: User | undefined;
  @observable profile: Profile | undefined;
  token: string = '';
  shopBinded: boolean = false;
  newFile?: File;

  constructor() {
    super();
    makeObservable(this);
    this.token = this.load(LC_KEY_TOKEN) || '';
    const localUserData = this.load(LC_USER);
    runInAction(() => {
      if (localUserData) {
        try {
          this.user = JSON.parse(localUserData) as User;
        } catch (error) {}
      }
      console.log('Init Auth', this.token);
      
      this.isLogin = this.token !== '';
    });
  }
  get role():string {
    if(this.user?.id === 58) return 'pm'
    return 'user'
  }
  get isPM():boolean{
    return this.role === 'pm'
  }

  async login(userName: string, pwd: string): Promise<AuthResult> {
    const result = await login(userName, pwd);
    
    runInAction(() => {
      //TODO: find out if user connected to shopify
      this.isLogin = result.success;
      this.token = result.data?.token;
      this.user = result.data?.user;
      this.save(LC_USER, JSON.stringify(result.data?.user));
      this.save(LC_KEY_TOKEN, result.data?.token);
    });
    return result;
  }
  async logout(refresh: boolean = true) {
    this.delete(LC_KEY_TOKEN);
    this.delete(LC_USER);
    this.token = '';
    this.user = undefined;
    runInAction(() => {
      this.isLogin = false;
    });
    if (refresh) {
      global.location.reload();
    }
  }
  async signUp(
    userName: string,
    pwd: string,
    pwd2: string
  ): Promise<AuthResult> {
    const result = await signUp(userName, pwd);
    runInAction(() => {
      //TODO: find out if user connected to shopify
      this.isLogin = result.success;
      this.token = result.data?.token;
      this.user = result.data?.user;
    });

    this.save(LC_USER, JSON.stringify(result.data?.user));
    this.save(LC_KEY_TOKEN, result.data?.token);
    return result;
  }
  @action
  setShowLoginModalOnEditor(v: boolean) {
    runInAction(() => {
      this.showLoginModalOnEditor = v;
    });
  }
  @action
  async loadUserProfile() {
    const result = await loadUserProfile(this.token);
    const profileData = result.profile || result;
    runInAction(() => {
      this.profile = {
        instagram: String(profileData.instagram_id).replace(
          'https://www.instagram.com/',
          ''
        ),
        profileImage: profileData.profile_image,
        profilePath: profileData.profile_path,
      };
      if(this.user){
        this.user.shopify_connected = profileData.shopify_connected
      }
    });
    //Datastructure adhere
    const rawLinkedShops: any[] = profileData['shopify_list'] || [];
    return { rawLinkedShops };
  }
  @action
  updateProfileImage(url: string, file: File) {
    this.profile!.profileImage = url;
    this.newFile = file;
  }
  @action
  updateInstagramID(insID: string) {
    this.profile!.instagram = insID;
  }
  @action
  updateProfilePath(path: string) {
    this.profile!.profilePath = path;
  }
  @computed
  get hasBindedShop(): boolean {
    if (this.user) return this.user.shopify_connected;
    return false;
  }

  async updateProfile(): Promise<boolean> {
    try {
      await updateProfile(this.profile!, this.user!, this.token, this.newFile);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
