import mm from 'memoizee';
import { doGet, doPost } from '../../../common/commonService';
import { DBShopifyOrderVO, OrderType } from '../../../TypeDeclare';
export const getOrders = mm(
    async (type: string, page: number = 1): Promise<any> => {
        let url = `https://api.customtee.co/api/customteeOrders/?type=${type}&page=${page}&token=bxR3Qud6FC9ZrYYhv0CTL4lGqMFxgloO9JqqgE876Kg6rseTkZs1c18mGTPL8qgO`;
        return new Promise(async (resolve) => {
            const respose = await fetch(url);
            const result = await respose.json();
            resolve(result);
        });
    },
    { async: true }
);

export const getShopifyOrders = mm(
    async (type: string, page: number = 1, token: string): Promise<any> => {
        const url = `https://api.customtee.co/api/customteeShopifyStoreOrders/`;
        return doGet(url, { type, page }, token);
    },
    { async: true }
);
export const getShopifyOrderDetail = async (
    fulfillmentOrderId: string,
    token: string
) => {
    const url = `https://api.customtee.co/api/customteeShopifyStoreOrderDetails/${fulfillmentOrderId}`;
    return doGet(url, {}, token);
};

export const addShopifyTrackLink = async (
    trackingLink: string,
    sku: string,
    fulfillmentOrderId: string,
    token: string
) => {
    const url = `https://api.customtee.co/api/updateShopifyStoreOrder/${fulfillmentOrderId}`;
    const formData: FormData = new FormData();
    formData.append('product_sku', sku);
    formData.append('shipping_label', trackingLink);
    return doPost(url, formData, token);
};

export const addCTTrackLink = async (
    trackingLink: string,
    orderName: string,
    orderType: OrderType,
    token: string
) => {
    const url = `https://api.customtee.co/api/customteeOrderProcess/`;
    const formData: FormData = new FormData();
    formData.append('order_name', orderName);
    formData.append('order_type', orderType);
    formData.append('tracking_link', trackingLink);
    return doPost(url, formData, token);
};

export const parseDBShopifyOrderVO = (o: any): DBShopifyOrderVO => {
    return {
        id: String(o['fulfillment_order_id']),
        updateTime: new Date(o['updated_time']),
        totalItems: Number(o['total_items']),
        orderAmount: Number(o['order_amount']),
        productionCost: Number(o['production_cost']),
        fulfillmentStatus: String(o['fulfillment_order_status']),
        requestStatus: String(o['request_status']),
        customteeStatus: String(o['customtee_status']),
        orderStatusURL: String(o['order_status_url']),
        destination: o['destination'],
        action: 'action',
        info: '',
    }
}
