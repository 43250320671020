import { FC, useState } from 'react';
import { Box } from '@fower/react';
import { observer } from 'mobx-react-lite';
import UploaderStepper from './components/UploaderStepper';
import Catalog from './Catalog';
import UploadImage from './UploadImage';
import SizeNColor from './SizeNColor';
import Preview from './Preview';
import { OriginalProductProvider } from '../store/useOriginalProductStore';

const UploadProduct: FC = observer(() => {
    const [step, setStep] = useState<number>(0);
    return (
        <OriginalProductProvider>
            <Box h-92vh w-100p row toCenterX relative>
                <Box h-100p w-100p column toCenterX>
                    <UploaderStepper
                        step={step}
                        onSelect={(index) => {
                            setStep(index);
                        }}
                    />
                    {step === 0 && <Catalog onNext={() => setStep(1)} />}
                    {step === 1 && (
                        <SizeNColor onNext={() => setStep(2)} onBack={() => setStep(0)} />
                    )}
                    {step === 2 && (
                        <UploadImage onNext={() => setStep(3)} onBack={() => setStep(1)} />
                    )}
                    {step === 3 && (
                        <Preview onCreated={() => setStep(0)} onBack={() => setStep(2)} />
                    )}
                </Box>
            </Box>
        </OriginalProductProvider>
    );
});
export default UploadProduct;
