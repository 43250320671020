import { Box } from '@fower/react'
import {
    Alert,
    AlertTitle,
    Divider,
    Drawer,
    Switch,
    Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import LoginModal from '../../common/LoginModal'
import {
    CategoryInfo,
    LoginMode,
    ProductItemVO,
    StyledPagination,
} from '../../TypeDeclare'
import HomeHeader from '../home/Header'
import DetailedBar from './DetailedBar'
import ItemList from './ItemList'
import SearchBar from './SearchBar'
import SideTabBar from './SideTabBar'
import { useProductStore } from './store/useProdustListStore'
// import { LiveChatWidget } from '@livechat/widget-react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
export type AssembledProductListProps = {
    sectionId: string
    productId: string
}
const AssembledProductList: FC<AssembledProductListProps> = observer(
    ({ sectionId, productId }) => {
        const store = useProductStore()
        const navigator = useNavigate()
        const [selectedItem, setSelectedItem] = useState<ProductItemVO | undefined>(
            undefined,
        )

        const canonicalUrl = global.location.href;

        const [showDetailedBar, setShowDetailedBar] = useState<boolean>(false)
        const [showMenuBar, setShowMenuBarBar] = useState<boolean>(false)
        const [showLogin, setShowLogin] = useState<boolean>(false)
        const [preMode, setPreMode] = useState<LoginMode>('signup')
        const [fetchDataError, setFetchDataError] = useState<boolean>(false)
        const [loading, setLoading] = useState<boolean>(false)

        function extractProductId(url: string, defaultName: string) {
            if (url) {
                if (!url.includes('/')) {
                    // 如果输入的字符串不包含斜杠，直接返回该字符串
                    return url;
                }

                // 匹配最后一个斜杠后面的内容
                const regex = /\/([^/]+)\/?$/;
                const match = url.match(regex);
                return match ? match[1] : null;
            }
            return defaultName
        }

        useEffect(() => {
            setFetchDataError(false)
            setLoading(true)
            console.log(store.targetCategory)
            store.reloadByCategory({
                ...store.targetCategory,
                // tag: 0,
                // category: 0,
            }, store.currentPage).then((success) => {
                setLoading(false)
                setFetchDataError(!success)
            })
            store.clearEditorData()
        }, [store, store.readyToShip])
        useEffect(() => {
            if (productId !== '') {

                const pId = productId.split('_')[1]
                if (pId) {

                    const target = store.list.find((p) => {
                        return p.id === pId
                    })

                    if (!target) {

                        store.loadSingleProdcut(pId).then((result) => {
                            store.setProduct(result)
                            setSelectedItem(result)
                            setShowDetailedBar(true)
                        })
                    }
                }
            }
        }, [productId, store, store.list])
        return (
            <div>
                <Helmet>

                    <title>Custom T-Shirts & Personalized Apparel | CustomTee Catalog</title>


                    <meta name="description" content="Discover high-quality custom T-shirts, personalized apparel, and accessories at CustomTee. Create your own designs or choose from our vast catalog. Perfect for individuals, businesses, and events. Shop now!" />


                    <meta name="keywords" content="custom T-shirts, personalized apparel, custom clothing, print on demand, custom designs, custom hats, custom mugs, high-quality apparel, custom accessories, CustomTee catalog" />

                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
                <Box w="100%" w--xl="80%" toCenterX h="100%" column>
                    <HomeHeader
                        fullWidth={true}
                        page={'catalog'}
                        onLogin={(mode: LoginMode) => {
                            setPreMode(mode)
                            setShowLogin(true)
                        }}
                    />
                    <Box w="100%" h="100%" row toCenterX pr3 pr0--xl>
                        <Box w="20%" minW="180px" hidden display--lg="block">
                            <SideTabBar
                                defaultValue={store.targetCategory}
                                onChange={(category: CategoryInfo) => {
                                    setFetchDataError(false)
                                    store.setCategory(category)
                                    store
                                        .reloadByCategory(category, 1)
                                        .then((success) => setFetchDataError(!success))
                                }}
                            />
                        </Box>
                        <Box w--lg="80%" w="100%" column>
                            <Box row toLeft w="100%" spaceX2 mb4>
                                <SearchBar
                                    keyword={store.keyword}
                                    onChange={(newValue) => {
                                        store.setKeyword(newValue)
                                    }}
                                />
                                <Box row toLeft toCenterY>
                                    <Box>Ready to Ship</Box>
                                    <Switch
                                        disabled={loading}
                                        color="primary"
                                        checked={store.readyToShip}
                                        onChange={(_event, checked) => {
                                            store.setReadyToShip(checked)
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box pl5 pb5>
                                All-inclusive pricing - no hidden fees, prints and techniques
                                included
                            </Box>
                            {fetchDataError ? (
                                <Alert severity="error">
                                    <AlertTitle>Load Data Error</AlertTitle>
                                    Please check your network status and refresh the page.
                                </Alert>
                            ) : (
                                <ItemList
                                    list={store.list}
                                    onSelect={async (item) => {
                                        store.setProduct(item)
                                        setSelectedItem(store.product)
                                        // setShowDetailedBar(true)
                                        // const seopath = item.name
                                        //     .toLocaleLowerCase()
                                        //     .replaceAll("'", '-')
                                        //     .split(' ')
                                        //     .join('-')
                                        const { seo_url, id, name } = item
                                        const cpName = name.toLocaleLowerCase().split(' ').join('-')

                                        const newName = extractProductId(seo_url!, cpName)
                                        // navigator(`/detail?productId=${item.id}&data=${selectedItem ? JSON.stringify(selectedItem) : ''}`)
                                        navigator(`/products/${id}/${newName}`)
                                    }}
                                />
                            )}
                        </Box>
                        {!fetchDataError && (
                            <StyledPagination
                                count={store.pageCount}
                                page={store.currentPage}
                                onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
                                    console.log(value)
                                    store.setCurrentPage(value)
                                    store.reloadByCategory(store.targetCategory, value)
                                }}
                                variant="outlined"
                                color="primary"
                                fixed
                                right2
                                bottom2
                            />
                        )}
                    </Box>
                    <Drawer
                        anchor="right"
                        open={showDetailedBar}
                        onClose={() => {
                            setShowDetailedBar(false)
                            setSelectedItem(undefined)
                            navigator(`/catalog/${sectionId}`)
                        }}
                    >
                        <DetailedBar
                            data={selectedItem}
                            sizeTableData={store.sizeTableList}
                            onClose={() => {
                                setShowDetailedBar(false)
                                setSelectedItem(undefined)
                                navigator(`/catalog/${sectionId}`)
                            }}
                        />
                    </Drawer>

                    <Drawer
                        anchor="left"
                        open={showMenuBar}
                        onClose={() => {
                            setShowMenuBarBar(false)
                        }}
                    >
                        <Box minW="280px" column>
                            <Box h16 toCenter>
                                <Typography variant="h5" color={'#527282'}>
                                    Menu
                                </Typography>
                            </Box>
                            <Divider />
                            <SideTabBar
                                onChange={(category: CategoryInfo) => {
                                    setShowMenuBarBar(false)
                                    store.reloadByCategory(category, 1)
                                }}
                            />
                        </Box>
                    </Drawer>
                    <LoginModal
                        isOpen={showLogin}
                        refreshAfter={true}
                        preMode={preMode}
                        onClose={() => {
                            setShowLogin(false)
                        }}
                    />
                    {/* <LiveChatWidget license="15929757" /> */}
                </Box>
            </div>
        )
    },
)

const ProductList: FC = () => {
    const { section, product } = useParams()
    const sectionId = section || 'all'
    const productId = product || ''

    return <AssembledProductList sectionId={sectionId} productId={productId} />
}
export default ProductList
