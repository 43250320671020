import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import LoginModal from '../../common/LoginModal'
import { useAuthStore } from '../../store/useAuthStore'
import HomeHeader from '../home/Header'
import Stores from './stores/Stores'
import DashboardSideBar, { SideBarNames } from './DashboardSideBar'
import ProductsPool from './products/ProductsPool'
import Account from './account/Account'
import Payments from './payments/Payments'
import UploadProduct from './upload_product/UploadProduct'
import { useDashbardStore } from './store/useDashboardStore'
import { useNavigate } from 'react-router-dom'
import ShopifyOrders from './orders/ShopifyOrders'
import { getURLParam } from '../../common/StringUtils'
// import { LiveChatWidget } from '@livechat/widget-react'
import OrderList from '../order_list/OrderList'
import BlobManage from '../blob/BlobManage'
import NewDashboardHome from './home/NewDashboardHome'
import { useProductStore } from '../product_list/store/useProdustListStore'
import { CATEGORIES } from '../../TypeDeclare'

const AssembledDashBoard = observer(() => {
    const authStore = useAuthStore()
    const dashboardStore = useDashbardStore()
    const productStore = useProductStore()
    const section = getURLParam('section')
    const sectionTo: SideBarNames =
        section === '' ? 'Home' : (section as SideBarNames)
    const navigator = useNavigate()

    const [sideBarName, setSideBarName] = useState<SideBarNames>(sectionTo)

    const onToStore = () => {
        setSideBarName('Stores')
    }
    const getView = () => {
        if (sideBarName === 'Orders') {
            return (
                <ShopifyOrders
                    onAddNewCard={() => {
                        setSideBarName('Payments')
                    }}
                />
            )
        }
        if (sideBarName === 'Products') {
            return <ProductsPool onToStore={onToStore} />
        }
        if (sideBarName === 'AdminOrders') {
            return <OrderList />
        }
        if (sideBarName === 'Stores') {
            return <Stores />
        }
        if (sideBarName === 'Upload Product') {
            return <UploadProduct />
        }
        if (sideBarName === 'Payments') {
            return <Payments />
        }
        if (sideBarName === 'Blog') {
            return <BlobManage />
        }
        if (sideBarName === 'Home') {
            return (
                <NewDashboardHome
                    onToConnect={onToStore}
                    onToCreate={() => {
                        productStore.setCurrentPage(1)
                        productStore.setCategory(CATEGORIES[0])
                        navigator('/catalog/all')
                    }}
                />
            )
        }
        return <Account />
    }
    useEffect(() => {
        dashboardStore.setAuth(authStore)
    }, [authStore, dashboardStore])

    return (
        <Box w="100%" h="100%" column>
            <HomeHeader
                fullWidth={true}
                page={'dashboard'}
                onShowMenu={() => { }}
                reloadAfterLogout={false}
                onLogout={() => {
                    navigator('/')
                }}
            />
            <Box w="100%" toLeft pr3 pr0--xl>
                <Box w="15%" minW="180px" hidden display--lg="block">
                    <DashboardSideBar
                        onTabChange={(index: number, name) => {

                            setSideBarName(name)
                        }}
                        currentName={sideBarName}
                    />
                </Box>
                <Box w="100%" column>
                    {getView()}
                </Box>
            </Box>
            {/* <LiveChatWidget license="15929757" /> */}
        </Box>
    )
})

const DashBoard = observer(() => {
    const authStore = useAuthStore()
    const [showLoginModal, setShowLoginModal] = useState<boolean>(
        !authStore.isLogin,
    )
    if (!authStore.isLogin) {
        console.log('Empty')
        return (
            <LoginModal
                preMode="login"
                isOpen={showLoginModal}
                onClose={() => {
                    setShowLoginModal(false)
                }}
                refreshAfter={false}
            />
        )
    }
    return <AssembledDashBoard />
})

export default DashBoard
