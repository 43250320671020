import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { Button, Divider, Drawer, Typography } from '@mui/material'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import {
    ColorOption,
    DEFAULT_SHIPPINGMETHOD,
    ProductItemVO,
    ShippingMethod,
    SizeOption,
} from '../../../TypeDeclare'
import { useProductStore } from '../../product_list/store/useProdustListStore'
import ColorPalette from '../../editor/components/right/ColorPalette'
import SizePicker from '../../editor/components/right/SizePicker'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import ProductSliderBaseOnColor from '../../../common/ProductSliderBaseOnColor'
import ProductShippingMothedPicker from '../../../common/ProductShippingMothedPicker'
import PQuantityPicker from '../../editor/components/right/PQuantityPicker'
// import ShippingInfo from '../upload_product/components/ShippingInfo'
import ShippingFeeDetail from '../../../common/ShippingFeeDetail'

export type CartProps = {
    isOpen: boolean
    onClose: () => void
    item?: ProductItemVO
}
const Cart: FC<CartProps> = observer(({ isOpen, item, onClose }) => {

    const store = useProductStore()
    const navigator = useNavigate()
    const [selectedColor, setSelectedColor] = useState<ColorOption>({
        id: '2',
        value: '#ffffff',
        name: 'White',
    })
    const [selectedSize, setSelectedSize] = useState<SizeOption>({
        id: '',
        value: '',
    })
    const [selectedShippingMethod, setSelectedShippingMethod] = useState<
        ShippingMethod
    >()
    useEffect(() => {
        if (item) {
            setSelectedColor(store.colorOptions[0])
            setSelectedSize(store.sizeOptions[0])
            setSelectedShippingMethod(
                item.shippingMethods.find((sm) => sm.id === DEFAULT_SHIPPINGMETHOD),
            )
            store.setQuantity(1)
        }
    }, [store, item])
    if (!item) return <></>

    return (
        <Drawer anchor="right" open={isOpen} onClose={() => { }}>
            <Box w="100vw" w--sm="480px" bgWhite column toTop relative>
                <Box absolute right2 top2 zIndex="1000" cursorPointer onClick={onClose}>
                    <CloseTwoToneIcon fontSize="large" />
                </Box>
                {/* Images */}
                <ProductSliderBaseOnColor selectedColor={selectedColor} />
                <Box ml6 mt2 color="#527282" fontBold text2XL>
                    ${item?.pairPrize}
                </Box>
                <Typography
                    variant="h4"
                    sx={{
                        ml: 3,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    {item.name}
                </Typography>
                <Box
                    textXS
                    ml6
                    mr4
                    gray600
                    style={{
                        wordWrap: 'break-word',
                        lineHeight: '24px',
                        overflowY: 'auto',
                    }}
                >
                    {item.desc}
                </Box>
                <Box ml4 column>
                    <ColorPalette
                        loadingImage={false}
                        selectedColor={selectedColor}
                        colorOptions={store.colorOptions}
                        onColorChanged={(newColor) => {
                            setSelectedColor(newColor)
                        }}
                    />
                    <SizePicker
                        sizes={store.sizeOptions}
                        selectedSize={selectedSize}
                        onSelect={(newSize) => {
                            setSelectedSize(newSize)
                        }}
                    />
                    {/* <ShippingInfo
            previewMode={true}
            weight={item.weight}
            volume={item.volume}
          /> */}
                    <Box row alignItems="center">
                        <PQuantityPicker
                            value={store.quantity}
                            onUpdate={(value) => {
                                store.setQuantity(value)
                            }}
                            maxValue={100}
                            minValue={1}
                        />

                        <ProductShippingMothedPicker
                            value={selectedShippingMethod?.id}
                            options={item?.shippingMethods}
                            onChange={(newValue) => {
                                const newShippingMethod = item?.shippingMethods.find(
                                    (sm) => sm.id === newValue,
                                )
                                setSelectedShippingMethod(newShippingMethod)
                            }}
                            p={1}
                        />
                    </Box>
                </Box>

                <Divider />
                <ShippingFeeDetail
                    quantity={store.quantity}
                    shippingMethod={selectedShippingMethod}
                    productId={item.id}
                    unitPrice={Number(item.pairPrize || 0)}
                />
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ m: 2 }}
                    onClick={() => {
                        const sku = `${item.productId}-${selectedSize.id}-${selectedColor.id}`
                        const urlTo = `/checkout?productId=${item.id}&sku=${sku}&quantity=${store.quantity}&size=${selectedSize.id}&colorId=${selectedColor.id}&shippingMethod=${selectedShippingMethod?.id}`
                        navigator(urlTo)
                    }}
                >
                    Continue to review
                </Button>
            </Box>
        </Drawer>
    )
})
export default Cart
