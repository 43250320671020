import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@fower/react";
import { Button, Divider, Pagination, Skeleton } from "@mui/material";
import { doGet } from "../../../common/commonService";
import { styled } from '@fower/styled'
import { useNavigate } from "react-router-dom";
import ItemList from "../../product_list/ItemList";
import { ProductItemVO } from "../../../TypeDeclare";
import { parseProduct } from "../../product_list/services/productService";

export type NewDashBoardHomeProps = {
    onToConnect: () => void
    onToCreate: () => void
}

const StyledIframe = styled('iframe')

const NewDashboardHome: FC<NewDashBoardHomeProps> = ({ onToConnect, onToCreate }) => {

    const navigator = useNavigate()

    const [blog, setBlog] = useState({
        cover_image: '',
        title: '',
        id: '',
        seo_url: ''
    })

    const skeletonData = [
        '-1',
        '-1',
        '-1'
    ].map((id, i) => {
        return {
            id: id,
            name: '',
            category: { id, parentName: '', category: 0, tag: 0 },
            type: '',
            images: [],
            descShorten: '',
            desc: '',
            sizeRange: '',
            price: '',
            daysInProduction: 0,
            sizeTableImage: '',
            colorId: '',
            shippingFee: '',
            isFullPrint: false,
            productId: '',
            shippingMethods: [],
            coverImages: [],
            weight: 0,
            volume: {
                width: 0,
                height: 0,
                length: 0,
            },
            readyToShip: false
        };
    });


    const [products, setProducts] = useState<Array<ProductItemVO>>(skeletonData)
    const productList = useRef<Array<ProductItemVO>>([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)


    async function getBlogList() {
        setLoading(true)
        const res = await doGet(`https://api.customtee.co/api/customteeBlogList/`, { page: 1 }, undefined)

        if (res.success && res.data.length > 0) {
            setBlog({
                title: res.data[0].title,
                cover_image: res.data[0].cover_image,
                id: res.data[0].id,
                seo_url: res.data[0].seo_url,
            })
            setLoading(false)
        }

    }

    async function getProList() {
        let url = `https://api.pairdrop.com/api/products/?customizable=1&page=${page}&customtee_web=1&ready_to_ship=0`;

        const respose = await fetch(url);
        const result = await respose.json();

        productList.current = []
        result.results.forEach((item: any) => {
            productList.current.push(parseProduct(item))
        })
        productList.current = productList.current.slice(0, 15)
        setProducts(productList.current.slice(0, 3))
    }

    useEffect(() => {

        getProList()
        getBlogList()
    }, [])


    return (
        <Box
            w-100p
            column
            boxSizing="border-box"
            p8
        >

            <h1
                style={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '50px',
                    color: '#518DD9',
                    fontWeight: 'bold',
                    lineHeight: '52px'
                }}
            >
                Welcome to Custom Tee!
            </h1>

            <Box
                w="100%"
                flex
                alignItems="center"
                justifyContent="space-between"
                flexWrap
                css={{
                    background: '#EFEFEF',
                    borderRadius: '10px',
                    padding: '20px 40px'
                }}
            >
                <Box
                    w="70%"
                    css={{
                        fontFamily: 'Segoe UI, Segoe UI',
                        fontSize: '20px',
                        color: '#363636',
                        fontWeight: 'bold',
                        lineHeight: '30px'
                    }}
                >
                    Link your Shopify store, design products, and start earning with Custom Tees to unlock a 20% rebate.
                </Box>
                <Button
                    variant="contained"
                    style={{
                        borderRadius: '20px',
                    }}
                    onClick={onToConnect}
                >
                    Link my store
                </Button>
            </Box>

            <Box
                w="100%"
                flex
                flexWrap
                justifyContent="space-between"
                css={{
                    marginTop: '50px',
                }}
            >
                <Box
                    flex
                    column
                    toCenter
                    boxSizing="border-box"
                    w={['100%', '100%', '100%', '30%', '30%']}
                    css={{
                        background: '#518DD9',
                        borderRadius: '10px',
                        padding: '20px'
                    }}
                >
                    <Box
                        css={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            color: '#ffffff',
                            fontWeight: '600',
                            fontSize: '40px',
                            lineHeight: '42px'
                        }}
                    >
                        Start creating your first product.
                    </Box>

                    <Button
                        style={{
                            color: '#518DD9',
                            background: '#ffffff',
                            borderRadius: '30px',
                            width: '100%',
                            padding: '10px 0',
                            fontSize: '30px',
                            marginTop: '70px'
                        }}
                        variant="contained"
                        onClick={onToCreate}
                    >
                        Start creating
                    </Button>
                </Box>

                <Box
                    w={['100%', '100%', '100%', '69%', '69%']}
                    relative
                    css={{
                        background: '#EFEFEF',
                        borderRadius: '10px',
                        paddingBottom: '50px'
                    }}
                >

                    <ItemList
                        list={products}
                        onSelect={(item) => {
                            const { name, id } = item
                            const newName = name.toLocaleLowerCase().split(' ').join('-')
                            navigator(`/products/${id}/${newName}`)
                        }}
                    />

                    <Pagination
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                        count={5}
                        page={page}
                        boundaryCount={2}
                        color="primary"
                        onChange={(_, page) => {
                            setPage(page)
                            setProducts(productList.current.slice((page - 1) * 3, page * 3))
                        }}
                    />
                </Box>
            </Box>

            <Box
                w="100%"
                css={{
                    background: '#EFEFEF',
                    borderRadius: '18px 18px 0 0',
                    marginTop: '50px',
                }}
            >

                <Box
                    w="100%"
                    h="600px"
                    flex
                    flexWrap
                    bgWhite
                    justifyContent="space-between"
                >

                    <Box
                        w={['100%', '100%', '100%', '48%', '48%']}
                        css={{
                            background: '#EFEFEF',
                            borderRadius: '10px',
                            padding: '30px',
                            marginTop: '10px'
                        }}
                    >

                        <Box
                            flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                flex
                                alignItems="center"
                            >
                                <img
                                    src="/icons/icon-text.webp"
                                    alt="icon_text"
                                    style={{
                                        width: '39px',
                                        height: '39px'
                                    }} />

                                <span
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Custom Tee Blog
                                </span>
                            </Box>

                            <Button
                                onClick={() => {
                                    navigator('/blog')
                                }}
                                variant="text"
                            >
                                See more
                            </Button>
                        </Box>

                        <Divider
                            style={{
                                padding: '10px 0'
                            }}
                        />

                        {
                            loading ?
                                <>
                                    <Skeleton
                                        variant="rectangular"
                                        width={'100%'}
                                        height={330}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={'100%'}
                                        height={40}
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    />
                                </>
                                : <>
                                    <img
                                        src={blog.cover_image}
                                        style={{
                                            width: '100%',
                                            height: '330px',
                                            objectFit: 'cover',
                                            marginTop: '30px'
                                        }}
                                        alt="blog"
                                    />

                                    <p
                                        style={{
                                            fontFamily: 'Segoe UI, Segoe UI',
                                            color: '#636363',
                                            fontSize: '18px',
                                            lineHeight: '20px'
                                        }}
                                    >
                                        {blog.title}
                                    </p>
                                </>
                        }


                        <Box
                            w="100%"
                            flex
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    borderRadius: '20px'
                                }}
                                onClick={() => {
                                    navigator(`/blog/${blog.id}/${blog.seo_url}`)
                                }}
                            >
                                Read article
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        w={['100%', '100%', '100%', '48%', '48%']}
                        css={{
                            background: '#EFEFEF',
                            borderRadius: '10px',
                            padding: '30px',
                            marginTop: '10px'
                        }}
                    >
                        <Box
                            flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                flex
                                alignItems="center"
                            >
                                <img
                                    src="/icons/icon-video.webp"
                                    alt="icon_video"
                                    style={{
                                        width: '39px',
                                        height: '39px'
                                    }}
                                />

                                <span
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Tutorial: Connecting to Your Shopify Store
                                </span>
                            </Box>

                        </Box>

                        <Divider
                            style={{
                                padding: '10px 0'
                            }}
                        />

                        <StyledIframe
                            style={{
                                marginTop: '30px'
                            }}
                            loading="lazy"
                            allowFullScreen
                            w="100%"
                            h="360px"
                            src="https://www.youtube.com/embed/GIx9diIKUCc"
                            title="Custom Tee Integration for Shopify"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></StyledIframe>
                    </Box>

                </Box>
            </Box>

        </Box >
    )
}

export default NewDashboardHome;