import { Box } from "@fower/react";
import { FC, useEffect, useState } from "react";

const Loading: FC = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 1000); // Change every 0.5 seconds

        return () => clearInterval(intervalId);
    }, []);

    const svgs = [
        '/svgs/loading1.svg',
        '/svgs/loading2.svg',
        '/svgs/loading3.svg',
    ];

    return (
        <Box
            column
            toCenter
            w="100%"
            h="100vh"
            bgWhite
        >
            <img
                src={svgs[currentIndex]}
                alt={`loadingSvg${currentIndex + 1}`}
                style={{
                    width: '145px',
                    height: '145px',
                }}
            />
            <Box
                color="#518DD9"
                fontWeight="bold"
                mt4
                css={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '24px',
                }}
            >loading...</Box>
        </Box>
    )
}

export default Loading;