import { Box } from '@fower/react';
import { FC, useEffect, useState } from 'react';
import { CATEGORIES, CategoryInfo } from '../../TypeDeclare';
import AccessoriesIcon from './svg-icons/AccessoriesIcon';
import AllIcon from './svg-icons/AllIcon';
import ApparelIcon from './svg-icons/ApparelIcon';
import ElectronicsIcon from './svg-icons/ElectronicsIcon';
import HomeIcon from './svg-icons/HomeIcon';
import KidsIcon from './svg-icons/KidsIcon';
import MenIcon from './svg-icons/MenIcon';
import WomenIcon from './svg-icons/WomenIcon';

const getIconClass = (name: string) => {
    switch (name) {
        case 'All':
            return AllIcon;
        case 'Women':
            return WomenIcon;
        case 'Men':
            return MenIcon;
        case 'Apparel':
            return ApparelIcon;
        case 'Accessories':
            return AccessoriesIcon;
        case 'Electronics':
            return ElectronicsIcon;
        case 'Home':
            return HomeIcon;
        case 'Kids':
            return KidsIcon;
    }
    return KidsIcon;
};

type SideBarTabProps = {
    index: number;
    category: CategoryInfo;
    selected: boolean;
    onClick: (category: CategoryInfo) => void;
};

const SideBarTab: FC<SideBarTabProps> = ({
    category,
    selected,
    onClick,
}) => {
    const IconClass = getIconClass(category.parentName);
    const iconColorSelected = '#4384D6';
    const iconcolor = 'rgba(0,0,0,0.6)';
    return (
        <Box
            h12
            row
            toCenterY
            cursorPointer
            onClick={() => {
                onClick(category);
            }}
            borderRight={selected ? 'solid 4px #4384D6' : '0px'}
        >
            <IconClass iconcolor={selected ? iconColorSelected : iconcolor} />
            <Box ml6 color={selected ? '#4384D6' : 'gray500'} fontBold={selected}>
                {category.parentName}
            </Box>
        </Box>
    );
};

type SideBarProps = {
    onChange: (category: CategoryInfo) => void;
    defaultValue?: CategoryInfo;
};
const SideTabBar: FC<SideBarProps> = ({ onChange, defaultValue }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    useEffect(() => {
        if (defaultValue) {
            setSelectedIndex(Number(defaultValue.id))
        }
    }, [defaultValue])
    return (
        <Box column pl2>
            {CATEGORIES.map((t, index) => {
                return (
                    <SideBarTab
                        key={`${t.id}-${index}`}
                        index={index}
                        category={t}
                        selected={selectedIndex === index}
                        onClick={(category) => {
                            setSelectedIndex(index);
                            onChange(category);
                        }}
                    />
                );
            })}
        </Box>
    );
};

export default SideTabBar;
