import { Box } from '@fower/react';
import { styled } from '@fower/styled';
import styles from './LandingSection.module.css';

const KeyPoints = () => {
    const StyledImage = styled('img', {
        objectFit: 'cover',
    });
    return (
        <Box w='100%' toCenter column style={{}} p5>
            <Box className={styles.keypointsTitle} hidden display--md="flex">Why Choose Us</Box>
            <Box className={styles.keypointsTitleSmall} hidden--md>Why Choose Us</Box>

            <Box style={{ background: 'linear-gradient( 90deg, #518DD9 0%, #A1CAFF 100%)' }} w11 h1 mt2></Box>
            <Box column row--xl mt5>
                <Box column w='400px' pt8 pb8 pl5 pr5 spaceY3 mr10 mt5 ml10 style={{ background: '#D1E5FF', borderRadius: '18px', boxShadow: '3px 3px 6px 1px rgba(0,0,0,0.16)' }}>

                    <Box className={styles.keypointReasonTitle}>Custom Tee Brand Guarantees Premium Quality</Box>
                    <Box className={styles.keypointReasonContent}>We take pride in selecting high-quality, durable, and long-lasting fabrics for producing Custom Tee Brand products</Box>
                    <StyledImage src='/icons/icon_source.webp' loading='lazy' w='100%' style={{ objectFit: 'fill' }} alt='source' />
                </Box>
                <Box column w='400px' pt8 pb8 pl5 pr5 spaceY3 mr10 mt5 ml10 style={{ background: '#D1E5FF', borderRadius: '18px', boxShadow: '3px 3px 6px 1px rgba(0,0,0,0.16)' }}>
                    <Box className={styles.keypointReasonTitle}>Ships from California Warehouse, Members Only</Box>
                    <Box className={styles.keypointReasonContent}>We offer exclusive shipping options from our California warehouse, available only to select members.</Box>
                    <StyledImage src='/icons/icon_step2.webp' loading='lazy' w='100%' style={{ objectFit: 'fill' }} alt='source' />
                </Box>
                <Box column w='400px' pt8 pb8 pl5 pr5 spaceY3 mr10 mt5 ml10 style={{ background: '#D1E5FF', borderRadius: '18px', boxShadow: '3px 3px 6px 1px rgba(0,0,0,0.16)' }}>
                    <Box className={styles.keypointReasonTitle}>Custom Branding Solutions for Your Brand</Box>
                    <Box className={styles.keypointReasonContent}>There are several fully customizable branding options available for your brand</Box>
                    <StyledImage src='/icons/icon_step3.webp' loading='lazy' w='100%' style={{ objectFit: 'fill' }} alt='source' />
                </Box>
            </Box>
        </Box>
    );
};

export default KeyPoints;
