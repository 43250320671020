import { FC, useContext } from 'react'
import { createContext } from 'react'
import { DashboardStore } from './DashboardStore'

const DashboardContext = createContext<DashboardStore | null>(null)

export const DashboardProvider: FC = ({ children }) => {
  const store = new DashboardStore()
  return (
    <DashboardContext.Provider value={store}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashbardStore = (): DashboardStore => {
  const store = useContext(DashboardContext)
  if (store == null) {
    throw new Error(`useDashbardStore must be used within a DashboardProvider`)
  }
  return store
}
