import { FC, useState } from "react";
import HomeHeader from "../home/Header";
import { Box } from "@fower/react";
import HomeFooter from "../home/Footer";
import LoginModal from "../../common/LoginModal";
import { LoginMode } from "../../TypeDeclare";


const PrivacyPolicy: FC = () => {
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />

            <Box
                w="80%"
            >

                <h1>Custom Tee Privacy Policy</h1>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Custom Tee "the App” provides print-on-demand fulfillment service "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Personal Information the App Collects</strong></span></p>
                <p className="p3"><span className="s1">When you install the App, we are automatically able to access certain types of information from your Shopify account:</span></p>
                <p className="p3"><span className="s1">"read_products", "read_orders", "write_products", "read_inventory", "write_inventory", “write_fulfillments”, "read_assigned_fulfillment_orders", “write_assigned_fulfillment_orders", “write_orders", "read_third_party_fulfillment_orders", "write_third_party_fulfillment_orders"</span></p>
                <p className="p3"><span className="s1">Additionally, we collect the following types of personal information from you and/or your customers once you have installed the App: Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information; Information about individuals who visit your store, such as their IP address, web browser details, time zone, and information about the cookies installed on the particular device.</span></p>
                <p className="p3"><span className="s1">We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit&nbsp;http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>How Do We Use Your Personal Information?</strong></span></p>
                <p className="p3"><span className="s1">We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services. Sharing Your Personal Information</span></p>
                <p className="p3"><span className="s1">Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</span></p>
                <p className="p3"><span className="s1">Behavioural Advertising As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at&nbsp;http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</span></p>
                <p className="p3"><span className="s1">You can opt out of targeted advertising. Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:&nbsp;<a style={{
                    color: '#555555',

                }} href="http://optout.aboutads.info/"><span className="s3">http://optout.aboutads.info/</span></a></span></p>
                <p className="p3"><span className="s1">Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</span></p>
                <p className="p3"><span className="s1">Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</span></p>
                <p className="p3"><span className="s1">Data Retention When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</span></p>
                <p className="p3"><span className="s1">Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at hello@customtee.co or by mail using the details provided below:</span></p>
                <p className="p2"><span className="s1"> ◦ 400 Concar Dr, San Mateo, CA 9440</span></p>

                <p className="p2">&nbsp;</p>
                <p className="p2">&nbsp;</p>
                <h1>LARGEGO, INC Privacy Policy</h1>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">This Privacy Policy applies to the shopping sites and apps operated by LARGEGO, INC and its corporate affiliates, including Custom Tee, SnapMalls, and to any other site or service that is operated by LARGEGO, INC and links to it (“Services”). This Privacy Policy uses the terms “our” “us” and “we” to refer to LARGEGO, INC Inc. or LARGEGO, INC and “you” or “user(s)” to refer to individuals described by the information that LARGEGO, INC collects and uses, such as customers and merchants. Please read this document carefully to learn more about how we collect, use, share and protect information, including information that may describe you personally. This Privacy Policy does not apply to the practices of companies we don't own or control, or people that we don't manage. For example, it does not apply to the use of purchaser shipping information by a merchant. Any capitalized terms we use in this Privacy Policy without defining them have the definitions given to them in the&nbsp;</span><span className="s2">Terms of Use</span><span className="s1"> which govern Your use of the Services.</span></p>
                <p className="p3"><span className="s1">We operate several marketplaces, including :Custom Tee, that allow merchants to list items and users to find and purchase items, often at a discount from retail prices. We collect and use purchaser and merchant information, including for payment methods and shipping address, to support these experiences. We also use information to find items that we think will be of interest to buyers and to find the right price for each item.</span></p>
                <p className="p3"><span className="s1"><strong>By using or accessing the Services, you acknowledge that we will collect, use, and share your information as described in this Privacy Policy.</strong>&nbsp;You have choices about whether to provide us with information and how we use that information. You may choose not to provide us with information, but your choice(s) may prevent you from using the Services or limit your use of certain features. For example, if you do not provide us with a payment method, you may not be able to make purchases. See Section 7 for more information about your choices.</span></p>
                <p className="p3"><span className="s1">We're constantly trying to improve our Services, so we may need to update this Privacy Policy. We may update this Privacy Policy to reflect changes in the law, the Services, our companies or advances in technology. We will alert you to a change by placing a notice on the Services or by sending an email. Our use of the information we collect is subject to the Privacy Policy in effect at the time such information is used.</span></p>
                <p className="p3"><span className="s1"><strong>1.&nbsp;</strong></span><span className="s2"><strong>Information We Collect &amp; Use</strong></span><span className="s1"><strong>&nbsp;-&nbsp;</strong>Some of the information we collect is provided by you, some is collected automatically (typically from devices) and some may be collected from other sources.</span></p>
                <p className="p3"><span className="s1"><strong>A. Information You Provide –</strong>&nbsp;When you use the Services you often provide information to us, such as when you create an account or a store, make or fulfill a purchase, leave a review, or request customer support. Examples of the information you may provide are:</span></p>
                <p className="p4"><span className="s1"> ◦ Name</span></p>
                <p className="p4"><span className="s1"> ◦ Email Address</span></p>
                <p className="p4"><span className="s1"> ◦ Payment Method or Financial Account Information</span></p>
                <p className="p4"><span className="s1"> ◦ Shipping Address</span></p>
                <p className="p4"><span className="s1"> ◦ Phone Number</span></p>
                <p className="p4"><span className="s1"> ◦ Social network account credentials</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>B. Information We Collect Automatically –&nbsp;</strong>When you use the Services, such as when you visit our sites, and install or use our apps, we automatically collect information about how you use the Services and the devices you use to access the Services. Examples of the information we collect automatically are:</span></p>
                <p className="p4"><span className="s1"> ◦ IP Address</span></p>
                <p className="p4"><span className="s1"> ◦ Location information - we and our service providers may automatically collect general location information from your computer or mobile device. We will ask your permission before collecting your precise GPS location information.</span></p>
                <p className="p4"><span className="s1"> ◦ Social network account and profile data (when you use social login)</span></p>
                <p className="p4"><span className="s1"> ◦ Unique Device Identifiers, including MAC Address, Ad IDs, and device attributes, such as operating system and browser type</span></p>
                <p className="p4"><span className="s1"> ◦ Usage Data, such as: web log data, referring and exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date and time you used our Services, the frequency of your use of our Services, error logs, and other similar information</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>C. Analytics, Advertising &amp; Technical Data</strong></span></p>
                <p className="p3"><span className="s1"><strong>Analytics –&nbsp;</strong>We use Google Analytics or other providers to understand better how individuals and their devices interact with the Services. Learn more about&nbsp;<a style={{
                    color: '#555555',

                }} href="https://policies.google.com/technologies/partner-sites"><span className="s3">How Google uses data when you visit a partner site or app.</span></a></span></p>
                <p className="p3"><span className="s1"><strong>Cookies and Related Technologies.</strong>&nbsp;We may use cookies, which are text files containing small amounts of information that are downloaded on your device, or related technologies, such as web beacons, local shared objects and tracking pixels to store or collect information (“Cookies”). Cookies can help us learn about your online activity including on other sites or services.</span></p>
                <p className="p3"><span className="s1"><strong>Ads for Products and Services.</strong>&nbsp;We may use services, like Facebook, to serve tailored ads to you about the LARGEGO, INC services, our companies or products we are developing. We may also support or allow the delivery of advertising for the products and services of other companies. We allow ad networks and other participants in the online advertising sector (“Advertisers”) to use and access their own Cookies on your computer or other device(s) you use to access our Services. They may also access and use unique device identifiers, such as IDFA. For example:</span></p>
                <p className="p4"><span className="s1"> ◦ We may deliver a file to you through the Services (known as a&nbsp;<strong>"web beacon"</strong>) from an ad network. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Because your web browser must request these advertisements and web beacons from the ad network's servers, these companies can view, edit, or set their own cookies, just as if you had requested a web page from their site.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">We do not have access to Cookies placed or read by Advertisers, and this Privacy Policy does not govern the use of those cookies and related technologies. See the Choice information in Section 7 for more information.</span></p>
                <p className="p3"><span className="s1"><strong>Third-Party Content, Links, or Plug-Ins.</strong></span></p>
                <p className="p4"><span className="s1"> ◦ The Services may have links to third-party websites or apps, which may have privacy policies that differ from our own. We are not responsible for the practices of such sites.</span></p>
                <p className="p4"><span className="s1"> ◦ The Services may also offer you the ability to interact with social plugins from social media sites, which may allow us and/or the social media site to receive data from or about you. In some cases, we may know that you clicked on a social plugin, such as a Twitter Follow button, or receive other information from the social media sites. Similarly, if you have previously provided personal information to a third-party operating a plug-in on the Services, then such third-party may recognize you on the Services. Your use of social network plugins is subject to each social media site's privacy policy, which may be different from ours, so please read these policies carefully to understand their policies and your options. As with linked sites, we have no control over the information that is collected, stored, or used by social network plugins, and are not responsible for the practices of such sites.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>D. Other Sources –&nbsp;</strong>We may gather information, including demographic and statistical information from third parties, such as business partners, marketers, researchers, analysts. We may attribute this information to you based on your assignment to certain statistical groups. We may use this information to supplement the other information that we collect in order to derive your possible interests and to provide more relevant experiences for you within the Services and improve our products, analytics, and advertising.</span></p>
                <p className="p3"><span className="s1"><strong>2.&nbsp;</strong></span><span className="s2"><strong>How We Use Information &amp; Lawful Bases</strong></span></p>
                <p className="p3"><span className="s1">In general, we collect, use and store (“process”) your information to provide the Services, to fix and improve the Services, to develop new services, and to market our companies and their products and services. Some specific examples of how we use information are:</span></p>
                <p className="p4"><span className="s1"> ◦ Collect payment</span></p>
                <p className="p4"><span className="s1"> ◦ Communicate with you - using email, sms, push notifications or platform messaging (like Whatsapp) - about your account or activity or to market product listings, features, or events.</span></p>
                <p className="p4"><span className="s1"> ◦ Test changes in the Services and develop new features</span></p>
                <p className="p4"><span className="s1"> ◦ Analyze use of the Services and personalize content, including ads and prices</span></p>
                <p className="p4"><span className="s1"> ◦ Fix problems you may have with the Services or a particular purchase, including answering support questions and resolving disputes</span></p>
                <p className="p4"><span className="s1"> ◦ Prevent, detect, investigate and respond to fraud, unauthorized access/use of the Services, breaches of terms and policies, or other wrongful behavior</span></p>
                <p className="p4"><span className="s1"> ◦ Comply with any procedures, laws, and regulations which apply to us, including those that set retention periods</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Lawful Bases –&nbsp;</strong>for purposes of European data protection law, we normally process personal data where:</span></p>
                <p className="p4"><span className="s1"> ◦ we need to perform the contract we are about to enter into or have entered into with you, such as when we process a payment</span></p>
                <p className="p4"><span className="s1"> ◦ it is in our legitimate interests (or those of a third party) and your data protection interests and fundamental rights and freedoms do not override those interests, such as when we secure the Services, engage in fraud prevention, and direct marketing</span></p>
                <p className="p4"><span className="s1"> ◦ we need to comply with a legal or regulatory obligation</span></p>
                <p className="p4"><span className="s1"> ◦ we have your consent to do so. We do not generally rely on consent as a lawful basis for processing your personal data, but you have the right to withdraw consent to marketing at any time by contacting us</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Other Uses -&nbsp;</strong>We may combine the information we collect (“aggregate”) or remove pieces of information (“de-identify”) to limit or prevent identification of any particular user or device, which can help support our research and marketing efforts. This Privacy Policy does not apply to our use of such aggregated or de-identified information.</span></p>
                <p className="p3"><span className="s1"><strong>3.&nbsp;</strong></span><span className="s2"><strong>Location of Data Processing</strong></span><span className="s1"><strong>&nbsp;–&nbsp;</strong>United States and elsewhere</span></p>
                <p className="p3"><span className="s1">Information that we collect and use is normally processed outside of Europe. By using the Services you:</span></p>
                <p className="p4"><span className="s1"> ◦ Acknowledge that your information will be processed as described in this Privacy Policy; and</span></p>
                <p className="p4"><span className="s1"> ◦ Consent to having your information transferred to our affiliates and facilities in the United States or elsewhere or to facilities of third parties with whom we share information as described in this Privacy Policy.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>4.&nbsp;</strong></span><span className="s2"><strong>Retention of Personal Data/Information</strong></span></p>
                <p className="p3"><span className="s1">We keep your information for the time period required to complete the purposes for which it is processed or satisfy legal retention requirements. The length of time for which we retain information depends on the purposes for which we collected and use it or the requirements of applicable laws.</span></p>
                <p className="p3"><span className="s1"><strong>5.&nbsp;</strong></span><span className="s2"><strong>Information Sharing</strong></span></p>
                <p className="p3"><span className="s1">We do not rent, sell or share information except as described in this Privacy Policy. We may share your information as described in this section:</span></p>
                <p className="p3"><span className="s1"><strong>Agents/Service Providers.</strong>&nbsp;We employ other companies and people to perform tasks on our behalf and need to share information with them so they can perform tasks and services. For example, we may use a payment processing company to receive and process your payment when you make a purchase using the Services or we may use a cloud services provider to store data.</span></p>
                <p className="p3"><span className="s1"><strong>Affiliates.</strong>&nbsp;We may share your information with other companies under common ownership or control with LARGEGO, INC Inc. These companies use your information as described in this Privacy Policy.&nbsp;</span></p>
                <p className="p3"><span className="s1"><strong>Merchants &amp; Users.</strong>&nbsp;Information about merchants and users (purchasers) is shared in certain contexts. For example, merchant store information is made available to users and user reviews of a store are made available to merchants and other users. Also, when users complete a purchase using the Services, we normally share information needed to fulfill the purchase, such as shipping address and phone number, with the seller (usually a merchant) and its service provider(s), such as a shipping company. Information shared with sellers and their service providers is subject to their own policies.</span></p>
                <p className="p3"><span className="s1"><strong>Advertisers:</strong>&nbsp;We allow advertisers, including merchants ("<strong>Advertisers</strong>"), to choose the types of users who will see their advertisements or promotional offers based on demographic information about our users. We might allow Advertisers to display their ads to users with similar usage patterns or similar geographic locations. For example, if you are located in Austin, TX we may show you ads or offers for businesses in Austin, TX. If an advertiser asks us to show an ad to a certain audience or audience segment and you respond to that ad, the advertiser may conclude that you fit the description of the audience they were trying to reach.</span></p>
                <p className="p3"><span className="s1"><strong>With Other Users:</strong></span></p>
                <p className="p4"><span className="s1"> ◦ Any information you may disclose on the Services, in blogs, on message boards, in chat rooms, or on other public areas on the Services or other third-party websites or apps that the Services may link to, becomes public information accessible to other users and visitors. Please exercise caution when disclosing personal information in these public areas.</span></p>
                <p className="p4"><span className="s1"> ◦ Certain user profile information, including your name, photo, location, purchase history, items on your wishlist, reviews you have written (including any photos you upload with such reviews), and the list of people you follow or that follow you, may be displayed to other users to facilitate user interaction within the Services or address your request for our services. Please remember that any content you upload to your public user profile, along with any personal information or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your user name may also be displayed to other users if and when you send messages or comments or upload images or videos through the Services and other users can contact you through messages and comments. Additionally, if you sign into the Services through a third party social networking site or service, such as Facebook, your list of "friends" from that site or service may be automatically imported to the Services, and such "friends," if they are also registered users of the Services, may be able to access certain non-public information you have entered in your Services user profile. Again, we do not control the policies and practices of any other third party site or service.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Business Transfers:</strong>&nbsp;We may choose to buy or sell assets and may share or transfer user information (including personal information) in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, user information could be one of the assets transferred to or acquired by a third party.</span></p>
                <p className="p3"><span className="s1"><strong>Legal Requirements, Protection of Wish and Others:</strong>&nbsp;We reserve the right to access, read, preserve, and disclose any information that we in good faith believe is necessary to comply with law or court order; enforce or apply our&nbsp;</span><span className="s2">Terms of Use</span><span className="s1">&nbsp;and other agreements; or protect the rights, property, or safety of LARGEGO, INC, our employees, our users, or others.</span></p>
                <p className="p3"><span className="s1"><strong>Consent.</strong>&nbsp;We may share your information in other ways if you have asked us to do so or have given consent.</span></p>
                <p className="p3"><span className="s1"><strong>6.&nbsp;</strong></span><span className="s2"><strong>Security</strong></span></p>
                <p className="p3"><span className="s1">Accounts are protected by a password for your privacy and security. If you access your account via a third party site or service, such as Facebook, you may have additional or different sign-on protections via that third party site or service. We recommend that you choose an appropriate password, safeguard your password and limit access to the devices on which you access your account.</span></p>
                <p className="p3"><span className="s1">We use reasonable organizational and technical measures intended to protect the privacy of your account and personal information we use or store, but the Internet and our Services are not 100% secure. We cannot guarantee complete privacy or security for the information that you provide or that we collect.</span></p>
                <p className="p3"><span className="s1"><strong>7.&nbsp;</strong></span><span className="s2"><strong>Choices &amp; Rights</strong></span></p>
                <p className="p3"><span className="s1"><strong>Access, Review, Correct -</strong>&nbsp;Through your account "Settings," you are able to access, edit or delete information you've provided to us, including:</span></p>
                <p className="p4"><span className="s1"> ◦ name and password</span></p>
                <p className="p4"><span className="s1"> ◦ email address</span></p>
                <p className="p4"><span className="s1"> ◦ physical address</span></p>
                <p className="p4"><span className="s1"> ◦ location</span></p>
                <p className="p4"><span className="s1"> ◦ phone number</span></p>
                <p className="p4"><span className="s1"> ◦ gender</span></p>
                <p className="p4"><span className="s1"> ◦ billing information</span></p>
                <p className="p4"><span className="s1"> ◦ user profile information, including images and videos you have uploaded to the Services</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">The information you can view, update, and delete may change as the Services change. If you have any questions about viewing or updating information we have on file about you, please contact us at support@customtee.co.</span></p>
                <p className="p3"><span className="s1"><strong>Marketing Communication Opt-out -</strong>&nbsp;To opt-out of marketing communications you can use any of the following methods.</span></p>
                <p className="p4"><span className="s1"><strong> ◦ Electronic Promotional Offers.</strong>&nbsp;If you do not want to receive emails from us regarding special promotions or offers, you may (1) follow the unsubscribe options at the bottom of each email; or (2) contact us at support@customtee.co and provide your email address along with the name of the newsletter from which you would like to unsubscribe.</span></p>
                <p className="p4"><span className="s1"><strong> ◦ Mobile Promotional Offers:</strong>&nbsp;When you provide us with your mobile number for marketing purposes, we may send you certain marketing alerts via text message. Standard data and message rates will apply. If you no longer wish to receive mobile alerts from us, you can follow the instructions provided in those messages or otherwise reply STOP to any alert we send.</span></p>
                <p className="p4"><span className="s1"><strong> ◦ Push Notifications:</strong>&nbsp;When you use the App, you may receive push notifications. If you prefer not to receive push notifications, you may adjust your settings on your mobile device to control whether you want to receive these alerts.</span></p>
                <p className="p4"><span className="s1"><strong> ◦ Settings -&nbsp;</strong>You can opt out of certain communications from us by changing your preferences under the "Settings" option of the applicable service.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Location Information -&nbsp;</strong>If you do not want us to see or access your device location, you can turn off location sharing on your device, change your device privacy settings, or decline to share location on your browser.</span></p>
                <p className="p3"><span className="s1"><strong>Cookies, Analytics &amp; Ads</strong></span></p>
                <p className="p4"><span className="s1"> ◦ You may be able to opt-out of certain web beacon tracking and other Cookie activity by adjusting the settings on your browser, including Do Not Track settings.</span></p>
                <p className="p4"><span className="s1"> ◦ To opt-out of Google Analytics you can install the&nbsp;<a style={{
                    color: '#555555',

                }} href="https://tools.google.com/dlpage/gaoptout"><span className="s3">Google Analytics Opt-out Browser Add-on</span></a></span></p>
                <p className="p4"><span className="s1"> ◦ To exercise choices for tailored advertising, please visit the following sites. If you opt-out, you may still receive advertising content, but it will not be tailored to you.</span></p>
                <p className="p5"><span className="s1"> ◦ Network Advertising Initiative (NAI) - You may use the NAI opt out tool&nbsp;<a style={{
                    color: '#555555',

                }} href="http://www.networkadvertising.org/choices/"><span className="s3">here</span></a>, which will allow you to opt out of seeing personalized ads from NAI member companies.</span></p>
                <p className="p5"><span className="s1"> ◦ Digital Advertising Alliance (DAA) - You may opt out of receiving personalized ads from certain companies that perform ad targeting services, using the DAA website&nbsp;<a style={{
                    color: '#555555',

                }} href="http://www.aboutads.info/choices/"><span className="s3">here</span></a>.</span></p>
                <p className="p5"><span className="s1"> ◦ European Interactive Advertising Digital Alliance (EDAA) – You can learn more about online advertising and opt out at the&nbsp;<a style={{
                    color: '#555555',

                }} href="http://www.youronlinechoices.com/"><span className="s3">Your Online Choices</span></a>&nbsp;website.</span></p>
                <p className="p4"><span className="s1"> ◦ For your mobile devices, please read your operating system’s instructions for complete instructions.</span></p>
                <p className="p5"><span className="s1"> ◦ iOS 7 or Higher:&nbsp;Go to your Settings &gt; Select Privacy &gt; Select Advertising &gt; Enable the “Limit Ad Tracking” setting</span></p>
                <p className="p5"><span className="s1"> ◦ For Android devices with OS 2.2 or higher and Google Play Services version 4.0 or higher:&nbsp;Open your Google Settings app &gt; Ads &gt; Enable “Opt out of interest-based advertising”</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Deactivate Account -</strong>&nbsp;You may request deactivation of your account by emailing us at support@customtee.co</span></p>
                <p className="p3"><span className="s1"><strong>Rights -</strong>&nbsp;If our processing of your personal data is covered by European data protection law, you typically have certain rights, including:</span></p>
                <p className="p4"><span className="s1"> ◦ You can access and review information associated with your account at any time through the “Settings” page or tab. You also can request the following information: how we collect and use your information and why; the categories of personal data involved; the categories of recipients of your personal data; how we received your personal data; and how long we use or store your personal data or the manner in which we determine relevant retention periods.</span></p>
                <p className="p4"><span className="s1"> ◦ You also have a right to correct your personal data. In certain situations, you can ask that we stop using your information or erase it or export it. You may also have a right to object to additional processing of your personal data.</span></p>
                <p className="p4"><span className="s1"> ◦ Where we rely on your consent to process your personal data, you have the right to not give your consent or (if you have provided consent) to withdraw consent at any time. Withdrawing consent will not affect the lawfulness of processing prior to the withdrawal. At any time, you can request that we stop using your information for direct marketing purposes.</span></p>
                <p className="p4"><span className="s1"> ◦ You have a right to raise questions or complaints with us as described below or with your local data protection authority at any time.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1"><strong>Complaints.</strong>&nbsp;We are committed to resolving valid complaints about your privacy and our collection or use of your personal data or information. For questions or complaints regarding our data use practices or Privacy Policy, please contact us using the email address listed in Section 10 of this Privacy Policy. </span></p>
                <p className="p3"><span className="s1">8.&nbsp;Children’s Privacy</span></p>
                <p className="p3"><span className="s1">We do not knowingly collect or solicit “personal information” (as defined by the U.S. Children’s Online Privacy Protection Act) from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information. If you believe that a child under 13 may have provided us personal information, please contact us at support@customtee.co</span></p>
                <p className="p3"><span className="s1">For residents of the EU and Switzerland, where processing of personal data is based on consent, we will not knowingly engage in that processing for users under the age of consent established by applicable member or data protection law. If we learn that we are engaged in that processing with such users, we will halt such processing and will take reasonable measures to promptly remove applicable information from our records.</span></p>
                <p className="p3"><span className="s1">If you are 13 or older, but have not reached your country’s age of majority (i.e. able to enter a contract), you should use the Services with permission from your parent(s) or guardian(s).</span></p>
                <p className="p3"><span className="s1"><strong>9.&nbsp;</strong></span><span className="s2"><strong>Do Not Track &amp; California Shine the Light</strong></span></p>
                <p className="p3"><span className="s1"><strong>Do Not Track Policy -</strong>&nbsp;Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not want your online activities tracked over time and across different websites. Our Services do not support Do Not Track requests at this time, which means that we collect information about your online activity both while you are using the Services and after you leave our Services.</span></p>
                <p className="p3"><span className="s1"><strong>California Residents -</strong>&nbsp;If you are a California resident, you may ask for a list of third parties that have received your information for direct marketing purposes during the previous calendar year. This list also contains the types of information shared. To make such a request, contact us at support@customtee.co</span></p>
                <p className="p3"><span className="s1"><strong>10.&nbsp;</strong></span><span className="s2"><strong>Contact Information</strong></span><span className="s1"><strong>&nbsp;-</strong>&nbsp;If you have any questions or concerns regarding this Privacy Policy or LARGEGO, INC’s use of your data, please send a detailed message to support@customtee.co. You may also write to us at:</span></p>
                <p className="p6">&nbsp;</p>
                <p className="p4"><span className="s1"> ◦ LARGEGO, INC Inc., PO BOX 1902, Burlingame, CA 94011</span></p>
                <p className="p2">&nbsp;</p>


            </Box>

            <HomeFooter />

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default PrivacyPolicy;