import { Box } from '@fower/react'
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import { FC, useEffect } from 'react'
import TopBar from '../top/TopBar'
import styles from './EditorCanvas.module.css'
import { useEditorStore } from '../../store/editor/useEditorStore'
import BottomBar from '../bottom/BottomBar'
import { observer } from 'mobx-react-lite'
import ToolBar from '../bottom/ToolBar'
import { ProductItemVO } from '../../../../TypeDeclare'
import { LinearProgress } from '@mui/material'

const EditorCanvas: FC<{ product?: ProductItemVO }> = observer(
    ({ product }) => {
        const store = useEditorStore()
        const { editor: editorFront, onReady: onFrontReady } = useFabricJSEditor()
        const { editor: editorBack, onReady: onBackReady } = useFabricJSEditor()
        useEffect(() => {
            if (editorFront !== undefined) {
                store.initEditor(editorFront, 'front')
            }
        }, [editorFront, store])
        useEffect(() => {
            if (editorBack !== undefined) {
                store.initEditor(editorBack, 'back')
            }
        }, [editorBack, store])

        useEffect(() => {
            if (editorFront && editorBack) {
                if (product) {
                    store.initProduct(product)
                    store.loadProductImages(product)
                }
            }
        }, [store, editorFront, editorBack, product])

        useEffect(() => {
            if (editorFront !== undefined && editorBack !== undefined) {
                store.initAll()
            }
        }, [store, editorFront, editorBack])
        useEffect(() => {
            const onResize = () => {
                store.resize(global.innerWidth)
            }
            global.addEventListener('resize', onResize)
            store.resize(global.innerWidth)
            return () => {
                global.removeEventListener('resize', onResize)
            }
        }, [store])
        useEffect(() => {
            return () => {
                console.log('Canvas leave')
                store.reset()
            }
        }, [store])

        return (
            <Box h="100%" w={['100%', '100%', '100%', '70%', '70%']} bgWhite toCenter>
                <Box
                    shadowInner
                    relative
                    h="100%"
                    w="100%"
                    column
                    toBetween
                    toCenterX
                    bg="#F4F6F7"
                >
                    <TopBar
                        readyToShip={product?.readyToShip || false}
                        onRedo={() => {
                            store.redo()
                        }}
                        onUndo={() => {
                            store.undo()
                        }}
                    />
                    <Box relative className={styles.aratio}>
                        <FabricJSCanvas
                            className={`${styles.fbcanvas} ${store.targetFace === 'front' ? styles.show : styles.hidden
                                }`}
                            onReady={onFrontReady}
                        />
                        <FabricJSCanvas
                            className={`${styles.fbcanvas} ${store.targetFace === 'back' ? styles.show : styles.hidden
                                }`}
                            onReady={onBackReady}
                        />
                        {store.loadingImage && <LinearProgress />}
                    </Box>
                    <Box
                        mt3
                        mb3
                        display={[
                            'inline-flex',
                            'inline-flex',
                            'inline-flex',
                            'none',
                            'none',
                        ]}
                    >
                        <ToolBar />
                    </Box>
                    <BottomBar product={product} />
                </Box>
            </Box>
        )
    },
)
export default EditorCanvas
