import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { SizeOption } from '../../../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { toJS } from 'mobx'

export type SizeRangeSelectionProps = {
  options: SizeOption[]
  onUpdate: (newRange: string) => void
}
const SizeRangeSelection: FC<SizeRangeSelectionProps> = observer(
  ({ options, onUpdate }) => {
    const [fromSize, setFromSize] = useState<string>('')
    const [toSize, setToSize] = useState<string>('')

    const [fromOptions, setFromOptions] = useState<Array<SizeOption>>([])
    const [toOptions, setToOptions] = useState<Array<SizeOption>>([])
    useEffect(() => {
      setFromOptions(toJS(options))
      setToOptions(toJS(options))
    }, [options])
    useEffect(() => {
      const a = options.find((o) => o.id === fromSize)
      const b = options.find((o) => o.id === toSize)
      if (a && b) {
        onUpdate(`${a.value} - ${b.value}`)
      }
    }, [fromSize, toSize, options, onUpdate])

    return (
      <Box column p5 w="100%" spaceY3>
        <Box>Size Range</Box>

        <Box row toCenterY spaceX3>
          <FormControl fullWidth>
            <InputLabel>From</InputLabel>
            <Select
              value={fromSize}
              label="From"
              onChange={(event: SelectChangeEvent) => {
                const sizeId = event.target.value as string
                const option = options.find((o) => o.id === sizeId)
                if (option) {
                  setFromSize(option.id)
                }
              }}
            >
              {fromOptions.map((option) => {
                return (
                  <MenuItem key={`from-${option.id}`} value={option.id}>
                    {option.value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>To</InputLabel>
            <Select
              value={toSize}
              label="To"
              onChange={(event: SelectChangeEvent) => {
                const sizeId = event.target.value as string
                const option = options.find((o) => o.id === sizeId)
                if (option) {
                  setToSize(option.id)
                }
              }}
            >
              {toOptions.map((option) => {
                return (
                  <MenuItem key={`to-${option.id}`} value={option.id}>
                    {option.value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  },
)
export default SizeRangeSelection
