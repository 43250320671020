import React, { useEffect, useRef, useState } from 'react';
import styles from './ImageCarousel.module.css';
import { Box } from '@fower/react';

interface ImageCarouselProps {
    images: string[];
    selected: number;
    imgClickHandle: (index: number) => void;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, imgClickHandle, selected }) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 1200px)').matches);
    const [showScrollbar, setShowScrollbar] = useState(false);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const customScrollbarRef = useRef<HTMLDivElement | null>(null);
    const customScrollbarThumbRef = useRef<HTMLDivElement | null>(null);

    const pixelsToInches = (pixels: number) => {
        const dpi = window.devicePixelRatio * 96; // 假设 DPI 为 96
        return pixels / dpi;
    };

    useEffect(() => {
        const container = containerRef.current;
        const customScrollbar = customScrollbarRef.current;
        const customScrollbarThumb = customScrollbarThumbRef.current;

        if (!container || !customScrollbar || !customScrollbarThumb) return; // 早期退出，如果元素不存在

        let isDragging = false;
        let startX: number;
        let scrollLeft: number;

        function updateScrollbar() {
            const containerWidth = container!.clientWidth;
            const containerScrollWidth = container!.scrollWidth;

            // 判断是否需要显示滚动条
            const shouldShowScrollbar = containerScrollWidth > containerWidth;
            setShowScrollbar(shouldShowScrollbar);

            if (shouldShowScrollbar) {
                const ratio = containerWidth / containerScrollWidth;
                const thumbWidth = Math.max(ratio * customScrollbar!.clientWidth, 20);
                customScrollbarThumb!.style.width = `${thumbWidth}px`;
                const scrollRatio = container!.scrollLeft / (containerScrollWidth - containerWidth);
                customScrollbarThumb!.style.left = `${scrollRatio * (customScrollbar!.clientWidth - thumbWidth)}px`;
            }
        }

        function syncScroll() {
            if (!customScrollbar || !customScrollbarThumb) return;
            const scrollRatio = container!.scrollLeft / (container!.scrollWidth - container!.clientWidth);
            customScrollbarThumb.style.left = `${scrollRatio * (customScrollbar.clientWidth - customScrollbarThumb.clientWidth)}px`;
        }

        container.addEventListener('scroll', syncScroll);

        container.addEventListener('wheel', (event) => {
            event.preventDefault();
            container.scrollLeft += event.deltaY;
        });

        customScrollbarThumb.addEventListener('mousedown', (event) => {
            isDragging = true;
            const thumbRect = customScrollbarThumb.getBoundingClientRect();
            startX = event.pageX - thumbRect.left;
            scrollLeft = container.scrollLeft;
            document.body.style.userSelect = 'none';
        });

        document.addEventListener('mousemove', (event) => {
            if (!isDragging) return;
            event.preventDefault();
            requestAnimationFrame(() => {
                const thumbRect = customScrollbar.getBoundingClientRect();
                const x = event.pageX - thumbRect.left;
                const thumbWidth = customScrollbarThumb.clientWidth;
                const maxScrollLeft = container.scrollWidth - container.clientWidth;
                const newLeft = Math.min(customScrollbar.clientWidth - thumbWidth, Math.max(0, x - startX));
                container.scrollLeft = Math.min(maxScrollLeft, Math.max(0, (newLeft / (customScrollbar.clientWidth - thumbWidth)) * maxScrollLeft));
                customScrollbarThumb.style.left = `${newLeft}px`;
            });
        });

        document.addEventListener('mouseup', () => {
            if (!isDragging) return;
            isDragging = false;
            document.body.style.userSelect = '';
        });

        updateScrollbar();
        window.addEventListener('resize', updateScrollbar);

        return () => {
            if (container) container.removeEventListener('scroll', syncScroll);
            if (customScrollbarThumb) customScrollbarThumb.removeEventListener('mousedown', () => { });
            if (document) {
                document.removeEventListener('mousemove', () => { });
                document.removeEventListener('mouseup', () => { });
            }
            window.removeEventListener('resize', updateScrollbar);
        };
    }, [images, selected]);

    useEffect(() => {
        const widthInInches = pixelsToInches(window.screen.width);
        const heightInInches = pixelsToInches(window.screen.height);
        const diagonalInInches = Math.sqrt(Math.pow(widthInInches, 2) + Math.pow(heightInInches, 2));
        setIsLargeScreen(diagonalInInches > 16);
    }, []);

    return (
        <div className={styles.containerWrapper}>
            <div className={styles.container} id="container" ref={containerRef}>
                <div className={styles.imageList} id="imageList">
                    {images.map((item, index) => (
                        <Box
                            onClick={() => imgClickHandle(index)}
                            key={index}
                            className={styles.imageItem}
                            w={isLargeScreen ? '100px' : '70px'}
                            h={isLargeScreen ? '100px' : '70px'}
                            style={{ border: index === selected ? '1px solid #518DD9' : '1px solid #CACDCE' }}
                        >
                            <img src={item} style={{ width: '100%', height: '100%' }} />
                        </Box>
                    ))}
                </div>
            </div>
            {showScrollbar && (
                <div className={styles.customScrollbar} id="customScrollbar" ref={customScrollbarRef}>
                    <div className={styles.customScrollbarThumb} id="customScrollbarThumb" ref={customScrollbarThumbRef}></div>
                </div>
            )}
        </div>
    );
};

export default ImageCarousel;
