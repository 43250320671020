import { Box } from '@fower/react';
import { styled } from '@fower/styled';
import { IconButton, Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, SetStateAction, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ColorOption } from '../../../../TypeDeclare';

export type CirclePickerProps = {
    colors: ColorOption[];
    selectedColor: ColorOption;
    circleSize: number;
    onChangeComplete: (color: ColorOption) => void;
    colorWheel?: boolean;
};

export type CircleItemProps = {
    color: string;
    circleSize: number;
    selectedColor: ColorOption;
    circleBorderColor: string;
    onChangeComplete: (color: string) => void;
};

const CircleItem: FC<CircleItemProps> = observer(
    ({
        color,
        circleBorderColor,
        circleSize,
        selectedColor,
        onChangeComplete,
    }) => {
        const selected = color.toLowerCase() !== selectedColor.value.toLowerCase()
        return (
            <Box
                circle={`${circleSize}px`}
                bg={color}
                borderColor={circleBorderColor}
                border={true}
                shadow={
                    !selected
                        ? '0 0 0 3px rgba(255, 255, 255, 1), 0 0 0 6px #518DD9'
                        : ''
                }
                cursor='pointer'
                onClick={() => {
                    onChangeComplete(color);
                }}
            ></Box>
        );
    }
);

const CirclePicker: FC<CirclePickerProps> = observer(
    ({ colors, selectedColor, circleSize, onChangeComplete, colorWheel }) => {
        const [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null);
        const isColorWheelOpen = Boolean(anchorEL);
        const colorWheelID = isColorWheelOpen ? 'simple-popover' : undefined;
        const StyledImage = styled('img');
        return (
            <Box w='100%'>
                <Box w='100%' row flexWrap space3>
                    {colors.map((c) => (
                        <CircleItem
                            key={c.id}
                            circleSize={circleSize}
                            selectedColor={selectedColor}
                            color={c.value}
                            circleBorderColor='rgba(0, 0, 0, 0.2);'
                            onChangeComplete={() => {
                                onChangeComplete(c);
                            }}
                        />
                    ))}
                    {colorWheel && (
                        <>
                            <IconButton
                                size='large'
                                aria-label='choose color'
                                component='div'
                                onClick={(event: {
                                    currentTarget: SetStateAction<HTMLElement | null>;
                                }) => {
                                    setAnchorEL(event.currentTarget);
                                }}
                                sx={{
                                    padding: '0px',
                                    margin: '0px',
                                    position: 'relative',
                                }}
                                edge='end'
                            >
                                <StyledImage
                                    aria-describedby={colorWheelID}
                                    left0
                                    top0
                                    absolute
                                    w='28px'
                                    h='28px'
                                    src='/icons/btn_color_select_ui.webp'
                                    alt='color wheel'
                                />
                            </IconButton>

                            <Popover
                                id={colorWheelID}
                                anchorEl={anchorEL}
                                open={isColorWheelOpen}
                                onClose={() => {
                                    setAnchorEL(null);
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <ChromePicker
                                    color={selectedColor.value}
                                    onChange={(color) => {
                                        onChangeComplete({
                                            id: '0',
                                            name: 'Customize',
                                            value: color.hex,
                                        });
                                    }}
                                />
                            </Popover>
                        </>
                    )}
                </Box>
            </Box>
        );
    }
);

export default CirclePicker;