import { FC, useContext } from 'react';
import { createContext } from 'react';
import { AuthStore } from './AuthStore';


const AuthContext = createContext<AuthStore | null>(null);

export const AuthProvider: FC = ({ children }) => {
  const store = new AuthStore();
  return (
    <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
  );
};


export const useAuthStore = (): AuthStore => {
  const store = useContext(AuthContext)
  if (store == null) {
    throw new Error(`useAuthStore must be used within a AuthProvider`)
  }
  return store
}