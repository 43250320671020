import { action, computed, makeObservable, observable } from 'mobx';
import parsePhoneNumber from 'libphonenumber-js';
export class UserInfoModule {
  @observable userName: string = '';
  @observable email: string = '';
  @observable phone: string = '';
  @observable address: string = '';
  @observable city: string = '';
  @observable state: string = '';
  @observable zipcode: string = '';
  @observable validating: boolean = false;
  @observable promotionCode: string = '';
  constructor() {
    makeObservable(this);
  }

  validateEmail(email: string): boolean {
    const result = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return result !== null;
  }
  generalCheck(value: string, len: number = 20) {
    if (!this.validating) return null;
    if (value.length > len) return 'Input too long';
    return value !== '' ? null : 'Can not be empty';
  }

  @action
  setUserName(value: string) {
    this.userName = value;
  }
  @action
  setEmail(value: string) {
    this.email = value;
  }
  @action
  setPhone(value: string) {
    this.phone = value;
  }

  @action
  setAddress(value: string) {
    this.address = value;
  }

  @action
  setPromotionCode(value: string) {
    this.promotionCode = value;
  }

  @action
  setCity(value: string) {
    this.city = value;
  }

  @action
  setState(value: string) {
    this.state = value;
  }
  @action
  setZipcode(value: string) {
    this.zipcode = value;
  }
  @action
  setValidating(value: boolean) {
    this.validating = value
  }
  @computed
  get hasUserNameError(): string | null {
    return this.generalCheck(this.userName, 50);
  }

  @computed
  get hasEmailError(): string | null {
    if (!this.validating) return null;
    if (this.email === '') return 'Can not be empty';
    const correct = this.validateEmail(this.email);
    return correct ? null : 'Invalid email';
  }
  @computed
  get hasPhoneError(): string | null {
    if (!this.validating) return null;
    if (this.phone === '') return 'Can not be empty';
    const correct = parsePhoneNumber(this.phone, 'US')?.isValid();
    return correct ? null : 'Invalid phone number';
  }
  @computed
  get hasAddressError(): string | null {
    return this.generalCheck(this.address, 100);
  }
  @computed
  get hasCityError(): string | null {
    return this.generalCheck(this.city, 50);
  }
  @computed
  get hasStateError(): string | null {
    return this.generalCheck(this.state, 10);
  }
  @computed
  get hasZipcodeError(): string | null {
    return this.generalCheck(this.zipcode, 10);
  }

  @action
  makeDemo(){
    this.userName = 'Tiger Wang';
    this.email = 'hecool108@gmail.com';
    this.phone = '(408) 621-8525';
    this.address = '2023 Becky ln';
    this.state = 'California';
    this.zipcode = '95993';
    this.city = 'Yuba City'
  }
}
