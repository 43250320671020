import { FC } from 'react'
import { Box } from '@fower/react'
import { useOriginalProductStore } from '../../store/useOriginalProductStore'
import { observer } from 'mobx-react-lite'
import InfoCard from './InfoCard'

const SNCMatrix: FC = observer(() => {
  const opStore = useOriginalProductStore()
  
  return (
    <Box w="70%" mt5 column toCenterX overflowYScroll overflowYAuto pb20 px10>
      <Box alignSelf='end' pr2>{opStore.allSubSKUProducts.length} variants in total</Box>
      {opStore.allSubSKUProducts.map((item, index) => {
        return (
          <InfoCard
            key={index}
            type="stripe"
            previewMode={false}
            color={item.color.value}
            size={item.size.value}
            quantity={item.quantity}
            onQuantityUpdate={(quantity) => {
              opStore.updateQuantityOfItem(item, quantity)
            }}
            skuPrice={item.skuPrice}
            onSkuPriceUpdate={(skuPrice) => {
              opStore.updateSKUPriceOfItem(item, skuPrice)
            }}
            sku={item.skuFull}
          />
        )
      })}
    </Box>
  )
})
export default SNCMatrix
