import { Box } from '@fower/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { ProductItemVO, SizeTableItemProps } from '../../TypeDeclare'
import StraightenIcon from '@mui/icons-material/Straighten'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import styles from './ProductList.module.css'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Button, Divider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useProductStore } from './store/useProdustListStore'
import IndicatorDot from '../../common/IndicatorDot'

export type DetailedBarProps = {
    data?: ProductItemVO
    sizeTableData: SizeTableItemProps[]
    onClose: () => void
    onNext?: () => void
}
const DetailedBar: FC<DetailedBarProps> = observer(
    ({ data, onClose, onNext }) => {
        // console.log(data?.name, data?.id);
        const navigator = useNavigate()
        const store = useProductStore()
        const [activeIndex, setActiveIndex] = useState<number>(0)
        const [sliderRef, setSliderRef] = useState<AliceCarousel | null>(null)
        const [item, setItem] = useState<ProductItemVO | undefined>(data)
        const canGoNext =
            store.product!.coverImages.length > 1 &&
            activeIndex < store.product!.coverImages.length - 1
        const canGoPrev = store.product!.coverImages.length > 1 && activeIndex !== 0
        const updateImages = useCallback(() => {
            const arr = store.product!.coverImages.map((item, index) => {
                return (
                    <img
                        draggable={false}
                        src={item}
                        alt={`${store.product!.name}-${index}`}
                        className={styles.itemImageLarge}
                    />
                )
            })
            return arr
        }, [store.product])
        const [items, setItems] = useState<any[]>(updateImages())
        useEffect(() => {
            const aRunner = async () => {
                await store.fillProductImages(store.product!.id)
                setItem(store.product)
                const items = updateImages()
                setItems(items)
            }
            aRunner()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store])
        if (item === undefined) return <></>
        return (
            <Box w="100vw" w--sm="480px" bgWhite column toTop relative>
                <Box absolute right2 top2 zIndex="1000" cursorPointer onClick={onClose}>
                    <CloseTwoToneIcon
                        fontSize="large"
                        sx={{ filter: 'drop-shadow(0px 0px 2px rgba(255,255,255,1))' }}
                    />
                </Box>
                <Box border={'solid 1px rgba(0,0,0,0.2)'} relative>
                    <AliceCarousel
                        activeIndex={activeIndex}
                        mouseTracking={true}
                        disableButtonsControls
                        disableDotsControls
                        infinite
                        items={items}
                        ref={(el) => {
                            setSliderRef(el)
                        }}
                        onSlideChanged={(e) => {
                            setActiveIndex(e.item)
                        }}
                    />

                    {canGoPrev && (
                        <Box
                            onClick={() => {
                                sliderRef?.slidePrev({})
                            }}
                        >
                            <ChevronLeftIcon
                                fontSize="large"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    cursor: 'pointer',
                                    filter: 'drop-shadow(0px 0px 2px rgba(255,255,255,1))',
                                }}
                            />
                        </Box>
                    )}
                    {canGoNext && (
                        <Box
                            onClick={() => {
                                sliderRef?.slideNext({})
                            }}
                        >
                            <ChevronRightIcon
                                fontSize="large"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: 0,
                                    cursor: 'pointer',
                                    filter: 'drop-shadow(0px 0px 2px rgba(255,255,255,1))',
                                }}
                            />
                        </Box>
                    )}
                </Box>
                {/* Indicator */}
                <Box h6 toCenter row spaceX1>
                    {store.product?.coverImages.map((image, index) => {
                        return (
                            <IndicatorDot
                                key={image}
                                selected={index === activeIndex}
                                onClick={() => {
                                    setActiveIndex(index)
                                    sliderRef?.slideTo(index)
                                }}
                            />
                        )
                    })}
                </Box>
                <Box ml6 mt2 color="#527282" fontBold text2XL>
                    ${item.pairPrize}
                </Box>
                <Typography
                    variant="h4"
                    sx={{
                        ml: 3,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    {item.name}
                </Typography>
                {/* <Box textXS ml6 mr4 gray600 className={styles.multiLine}>
          {item.desc}
        </Box> */}
                <Box
                    textXS ml6 mr4 gray600
                    toCenter
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                ></Box>
                <Box row toCenterY ml6 mt2>
                    <StraightenIcon fontSize="small" />
                    <Box ml3 textSM>{`${item.sizeRange}`}</Box>
                </Box>
                <Box row toCenterY ml6 mt2>
                    <LocalShippingIcon fontSize="small" />
                    <Box ml3 textSM>
                        Fast shipping
                    </Box>
                </Box>
                <Box row toCenterY ml6 mt2 mb3>
                    <AccessTimeFilledIcon fontSize="small" />
                    <Box ml3 textSM>
                        {item.daysInProduction} Days Turnaround
                    </Box>
                </Box>
                <Divider />
                {/* Size table */}
                {item.sizeTableImage && (
                    <img
                        src={item.sizeTableImage}
                        alt="sizetable"
                        className={styles.sizeTable}
                    />
                )}
                {/* <Box
          column
          w='100%'
          toCenter
          dangerouslySetInnerHTML={{ __html: item.sizeTableImage }}
        ></Box> */}

                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ m: 2 }}
                    onClick={() => {
                        // @ts-ignore
                        console.log(item.psd_sku)
                        // @ts-ignore
                        if (item.psd_sku) {
                            navigator(`/editor?productId=${item.id}`)
                        } else {
                            navigator(`/oldEditor?productId=${item.id}`)
                        }

                    }}
                >
                    {
                        !item.readyToShip ? "Start Designing" : "Prepare for shipping"
                    }
                </Button>
            </Box>
        )
    },
)
export default DetailedBar
