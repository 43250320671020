import { FC, useEffect, useRef, useState } from 'react'
import { Box } from '@fower/react'
import { useAuthStore } from '../../../store/useAuthStore'
import { HiddeFilePicker, StyledImage } from '../../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import EditableTextArea from '../../../common/EditableTextArea'
import imageCompression from 'browser-image-compression'
import { LARGE_PNG } from '../../../utils/ColorPreset'
import { AlertColor, Button } from '@mui/material'
import ProgressIndicator from '../../../common/ProgressIndicator'
import { useDashbardStore } from '../store/useDashboardStore'

const Account: FC = observer(() => {
    const authStore = useAuthStore()
    const dashboardStore = useDashbardStore()
    const filePicker = useRef(null)
    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messageType, setMessageType] = useState<AlertColor>('success')

    const onUpdate = async () => {
        setShowProgress(true)
        setUpdating(true)
        const result = await authStore.updateProfile()
        setUpdating(false)
        setMessage(result ? 'Updated!' : 'An error occurred, please try later.')
        setMessageType(result ? 'success' : 'error')
    }

    useEffect(() => {
        authStore.loadUserProfile().then(result => {
            dashboardStore.setLinkedShops(result.rawLinkedShops)
        })
    }, [authStore, dashboardStore])

    return (
        <Box w-100p h-100vh p10 column toCenterX spaceY10 relative>
            <Box
                absolute
                top0
                left0
                right0
                h={'300px'}
                bg={'#31375D'}
                zIndex={0}
            ></Box>
            <StyledImage
                src={authStore.profile?.profileImage}
                bgWhite
                border="rgba(10,10,10,0.5) 1px solid"
                circle24
                style={{
                    objectFit: 'contain',
                }}
                zIndex={1}
                onClick={() => {
                    if (filePicker && filePicker.current) {
                        const element = filePicker.current as HTMLInputElement
                        element.click()
                    }
                }}
            />
            {authStore.profile && (
                <Box
                    w-100p
                    spaceY10
                    column
                    bgWhite
                    zIndex={2}
                    p10
                    rounded
                    shadow
                    maxW-50p
                >
                    <Box>{authStore.user?.email}</Box>
                    <EditableTextArea
                        text={authStore.profile?.profilePath}
                        label="Subdomain Name"
                        onUpdate={(newValue) => {
                            authStore.updateProfilePath(newValue)
                        }}
                        immediateUpdate={true}
                    />
                    <a
                        href={`https://www.customtee.co/shop/${authStore.profile?.profilePath}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://www.customtee.co/shop/{authStore.profile?.profilePath}
                    </a>
                    <EditableTextArea
                        text={authStore.profile?.instagram}
                        label="Instagram ID"
                        onUpdate={(newValue) => {
                            authStore.updateInstagramID(newValue)
                        }}
                        immediateUpdate={true}
                    />
                    <a
                        href={`https://www.instagram.com/${authStore.profile?.instagram}/`}
                        target="_blank"
                        rel="noreferrer"
                    >https://www.instagram.com/{authStore.profile?.instagram}/</a>
                    <Button variant="contained" onClick={onUpdate}>
                        Update
                    </Button>
                </Box>
            )}
            <ProgressIndicator
                isOpen={showProgress}
                text='Updating Profile...'
                loading={updating}
                message={message}
                messageType={messageType}
                onClose={() => {
                    setShowProgress(false)
                }}
            />
            <HiddeFilePicker
                type="file"
                ref={filePicker}
                accept="image/png, image/jpeg"
                onChange={async (event) => {
                    if (event.target.files && event.target.files.length > 0) {
                        let compressedFile = event.target.files[0]
                        if (compressedFile.size > LARGE_PNG / 2) {
                            const options = {
                                maxSizeMB: 20,
                                maxWidthOrHeight: 3840,
                                useWebWorker: true,
                            }
                            compressedFile = await imageCompression(compressedFile, options)
                        }

                        const reader: FileReader = new FileReader()
                        reader.onload = (e) => {
                            if (e.target?.result && typeof e.target.result === 'string') {
                                authStore.updateProfileImage(e.target?.result, compressedFile)
                            }
                        }
                        reader.readAsDataURL(compressedFile)
                    }
                }}
                hidden
            />
        </Box>
    )
})
export default Account
