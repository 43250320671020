import { action, makeObservable, observable } from 'mobx';
import { EditorStore } from './EditorStore';
import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';
import { ColorOption, SUPPORTED_FONTS } from '../../../../TypeDeclare';

export class TextFontModule {
  @observable size: number = 28;
  @observable text: string = 'Hello';
  @observable font: string = SUPPORTED_FONTS[0];
  @observable color: ColorOption = { id: '0', name: 'White', value: '#ffffff' };
  @observable maxValue: number = 80;
  @observable minValue: number = 20;
  selectedObject?: fabric.Text;
  constructor(private readonly parent: EditorStore) {
    makeObservable(this);
  }
  saveState() {
    // this.parent.actionRecorder?.saveState(
    //   ActionType.CANVAS_CHANGED,
    //   this.parent.targetFace
    // );
  }
  @action
  setText(newText: string) {
    this.text = newText;
    if (this.selectedObject) {
      this.selectedObject.set('text', newText);
      this.selectedObject.canvas?.renderAll();
    }
    // this.saveState();
  }

  @action
  setSize(newSize: number) {
    this.size = newSize;
    if (this.selectedObject) {
      this.selectedObject.set('fontSize', newSize);
      this.selectedObject.canvas?.renderAll();
    }
    // this.saveState();
  }

  @action
  setColor(newColor: ColorOption) {
    this.color = newColor;
    if (this.selectedObject) {
      this.selectedObject.set('fill', newColor.value);
      this.selectedObject.canvas?.renderAll();
    }
    // this.saveState();
  }

  @action
  setFont(newFont: string) {
    fabric.util.clearFabricFontCache(this.font);
    this.font = newFont;
    if (this.selectedObject) {
      const myfont = new FontFaceObserver(this.font);
      myfont
        .load()
        .then(() => {
          this.selectedObject?.set('fontFamily', this.font);
          this.selectedObject?.canvas?.requestRenderAll();
        })
        .catch((e) => {});
    }
    // this.saveState();
  }

  @action
  updateWithSelected(from: fabric.Object): void {
    this.selectedObject = from as fabric.Text;
    const target = from as any;
    this.text = target.text;
    this.size = target.fontSize;
    this.color = { id: '0', name: 'Whatever', value: target.fill as string };
    this.font = target.fontFamily as string;
  }
  @action
  deactiveSelection(): void {
    this.selectedObject = undefined;
  }
}
