/* eslint-disable react/style-prop-object */
import { SvgIcon } from '@mui/material';
import { FC } from 'react';

const AddImageIcon: FC = (props) => {
  return (
    <SvgIcon
      sx={{
        width: '24px',
        height: '20px',
      }}
      {...props}
    >
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='cut_common' transform='translate(-27.000000, -14.000000)'>
          <g id='icon_add_image' transform='translate(27.000000, 14.000000)'>
            <rect
              id='矩形'
              fillOpacity='0'
              fill='#FF0F0F'
              x='0'
              y='0'
              width='26'
              height='26'
            ></rect>
            <circle
              id='椭圆形'
              fill='#6E8FA0'
              cx='5.99811074'
              cy='8'
              r='2'
            ></circle>
            <path
              d='M21.2481107,18 L17.666405,12.010854 C16.532556,10.1148895 14.0764068,9.49707277 12.1804422,10.6309218 C12.0030291,10.7370207 11.8341076,10.8567128 11.6751957,10.9889226 L3.24811074,18 L3.24811074,18 L21.2481107,18 Z'
              id='路径-19'
              fill='#6E8FA0'
            ></path>
            <path
              d='M20,3 C20.2761424,3 20.5261424,3.11192881 20.7071068,3.29289322 C20.8880712,3.47385763 21,3.72385763 21,4 L21,4 L21,17 C21,17.2761424 20.8880712,17.5261424 20.7071068,17.7071068 C20.5261424,17.8880712 20.2761424,18 20,18 L20,18 L2,18 C1.72385763,18 1.47385763,17.8880712 1.29289322,17.7071068 C1.11192881,17.5261424 1,17.2761424 1,17 L1,17 L1,4 C1,3.72385763 1.11192881,3.47385763 1.29289322,3.29289322 C1.47385763,3.11192881 1.72385763,3 2,3 L2,3 Z'
              id='矩形'
              stroke='#222222'
              strokeWidth='2'
            ></path>
            <polyline
              id='路径'
              stroke='#222222'
              strokeWidth='2'
              strokeLinejoin='round'
              points='22.9981107 7 24.9981107 7 24.9981107 22 4.99811074 22 4.99811074 20'
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AddImageIcon;
