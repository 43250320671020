import { FC } from 'react'
import { Box } from '@fower/react'
import { TagOption } from '../../../../TypeDeclare'

export type TagsProps = {
  tags: Array<TagOption>
  w?: string
}
const Tag: FC<{ tag: string }> = ({ tag }) => {
  return (
    <Box p2 bgGray400 rounded m1 white>
      {tag}
    </Box>
  )
}
const Tags: FC<TagsProps> = ({ tags, w = '100%' }) => {
  return (
    <Box border borderGray300 w={w} roundedSmall flex row flexWrap p3 m2 shadow>
      {tags.length === 0 ? (
        <Box w="100%" gray300 toCenter>
          No Tags
        </Box>
      ) : (
        <Box p2 bgGreen600 rounded m1 white>
          TAGS:
        </Box>
      )}
      {tags.map((tag) => {
        return <Tag key={tag.id} tag={tag.value} />
      })}
    </Box>
  )
}
export default Tags
