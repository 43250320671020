import { FC } from 'react'
import { Box } from '@fower/react'
import { StoreType, StyledImage } from '../../../TypeDeclare'
import { Button } from '@mui/material'

export type StoreConnectionCardProps = {
    type: StoreType
    onClick: (type: StoreType) => void
}
const StoreConnectionCard: FC<StoreConnectionCardProps> = ({
    type,
    onClick,
}) => {
    const icon = type === 'shopify' ? 'shopify_logo_whitebg.svg' : 'wix_black.svg'
    const iconWidth = type === 'shopify' ? '200px' : '120px'
    return (
        <Box
            column
            rounded
            border="rgba(0,0,0,0.5) 1px solid"
            p10
            w="280px"
            toCenter
            spaceY10
            bgWhite
        >
            <StyledImage w={iconWidth} alt="shopify logo" loading='lazy' src={`/icons/${icon}`} />
            <Button variant="outlined" size="large" onClick={() => {
                onClick(type)
            }}>
                Connect
            </Button>
        </Box>
    )
}
export default StoreConnectionCard
