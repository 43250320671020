import { Box } from '@fower/react'
import { Button, CircularProgress } from '@mui/material'
import { FC } from 'react'
import { useAuthStore } from '../../../../store/useAuthStore'
import { createCustomTeeProduct } from '../../../product_list/services/productService'
import { useProductStore } from '../../../product_list/store/useProdustListStore'
import { useEditorStore } from '../../store/editor/useEditorStore'
export type InfoBarProps = {
  readyToShip:boolean
}
const InfoBar: FC<InfoBarProps> = ({readyToShip}) => {
  const store = useEditorStore()
  const productStore = useProductStore()
  const authStore = useAuthStore()
  const onSubmit = async () => {
    if (!authStore.isLogin) {
      authStore.setShowLoginModalOnEditor(true)
      return
    }
    const fullColorImages = store.createFullColorPreviews(
      productStore.colorOptions,
    )
    const images = store.createAssets(fullColorImages.length + 1)
    // console.log('createAssets',images);
    
    const allResult = await store.uploadItem([...fullColorImages, ...images])
    const result = allResult.filter((r) => !r.alias.includes('fullcolor'))
    const fullColorResult = allResult
      .filter((r) => r.alias.includes('fullcolor'))
      .map((fc) => {
        const arr = fc.name.split('-')
        return {
          colorId: arr[0],
          colorName: arr[1],
          url: fc.url,
          face: fc.alias.split('_')[0],
        }
      })
    if (result.length === 0) return
    store.save('uploadedImages', JSON.stringify(result))
    const formData = await createCustomTeeProduct(
      store,
      productStore,
      result,
      fullColorResult,
      authStore.token,
    )
    const sku = formData['data']['product_sku']
    const newProductID = formData['data']['product_id']
    const urlTo = `/checkout?productId=${store.product?.id}&sku=${sku}&quantity=${productStore.quantity}&size=${productStore.size.id}&colorId=${store.templateColor.id}`
    store.purchaseURLAfterUpload = urlTo
    store.setNewCreatedProductId(newProductID)
    store.save('purchase_url', urlTo)
  }
  return (
    <Box w="100%" p1 toBottom display--lg="inline-flex" display="none">
      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        size="large"
        color="secondary"
        disabled={store.uploading}
      >
        {store.uploading ? 'Uploading' : (readyToShip ? 'Add to Store' : 'Continue')}
        {store.uploading && (
          <CircularProgress
            size={24}
            sx={{
              color: 'green',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-100px',
            }}
          />
        )}
      </Button>
    </Box>
  )
}

export default InfoBar
