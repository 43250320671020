import { FC } from 'react'
import { Box } from '@fower/react'
import { InputAdornment, TextField } from '@mui/material'
import EmptyNumberInput from './EmptyNumberInput'
import { observer } from 'mobx-react-lite'

export type InfoCardProps = {
  type: 'square' | 'stripe'
  previewMode: boolean
  color: string
  size: string
  quantity: number
  onQuantityUpdate: (quantity: number) => void
  skuPrice: number
  onSkuPriceUpdate: (skuPrice: number) => void
  sku: string
}

const InfoCard: FC<InfoCardProps> = observer(
  ({
    type,
    color,
    size,
    quantity,
    onQuantityUpdate,
    sku,
    skuPrice,
    onSkuPriceUpdate,
    previewMode,
  }) => {
    return (
      <Box
        h="120px"
        w="90%"
        minW="540px"
        shadow={'0px 0px 8px rgba(0, 0, 0, 0.25)'}
        roundedHuge
        bg={color}
        my3
      >
        <Box w="94%" h="100%" toCenter column shadowLarge roundedLarge bgWhite>
          <Box w="90%" toBetween mt3>
            <TextField
              value={color}
              InputProps={{
                readOnly: true,
              }}
              label={'Color'}
              variant="standard"
              sx={{ width: '20%' }}
            />
            <TextField
              value={size}
              InputProps={{
                readOnly: true,
              }}
              label={'Size'}
              variant="standard"
              sx={{ width: '20%' }}
            />
            <EmptyNumberInput
              value={quantity}
              label={'Quantity'}
              sx={{ width: '20%' }}
              previewMode={previewMode}
              onUpdate={(newValue) => {
                console.log('newValue', newValue)

                onQuantityUpdate(newValue)
              }}
            />
            <EmptyNumberInput
              value={skuPrice}
              label={'SKU Price'}
              sx={{ width: '20%' }}
              previewMode={previewMode}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              onUpdate={(newValue) => {
                onSkuPriceUpdate(newValue)
              }}
            />
          </Box>
          <TextField
            value={sku}
            InputProps={{
              readOnly: true,
            }}
            label={'SKU Code'}
            variant="standard"
            sx={{ width: '90%', mb: 2 }}
          />
        </Box>
      </Box>
    )
  },
)
export default InfoCard
