import { Box } from '@fower/react'
import { StyledImage, StyledSnackbar } from '../../TypeDeclare'
import LinkIcon from '@mui/icons-material/Link'
import LoginBox from '../../common/LoginBox'
import { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import { useAuthStore } from '../../store/useAuthStore'
import { useNavigate } from 'react-router-dom'
import { getURLParam } from '../../common/StringUtils'
import { useDashbardStore } from '../dashboard/store/useDashboardStore'
const Connect = () => {
    const authStore = useAuthStore()
    const dashboardStore = useDashbardStore()
    const navigator = useNavigate()
    const bindKey = getURLParam('k')
    const shop = getURLParam('shop')

    const onConnect = async () => {
        const result = await dashboardStore.bindShop(authStore.token, bindKey)
        if (result) {
            navigator('/dashboard')
        } else {
            handleResult(false, 'Can not connect to shop, please try again later')
        }
    }
    const handleResult = (success: boolean, errorMessage?: string) => {
        console.log('Connect', success, errorMessage);

        if (!success) {
            setErrorMessage(errorMessage!)
            setShowMessageBar(true)
        } else {
            onConnect()
        }
    }

    const [showMessageBar, setShowMessageBar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const handleMessageClose = () => {
        setShowMessageBar(false)
    }
    useEffect(() => {
        // if (authStore.isLogin) {
        // authStore.loadUserProfile().then((resultProfile) => {

        // dashboardStore.setLinkedShops(resultProfile.rawLinkedShops)
        // const connectedShop = resultProfile.rawLinkedShops.find(
        //   (rs) => rs.store_url === shop,
        // )
        // console.log(connectedShop);
        // if (connectedShop) {
        //   navigator('/')
        // }
        // })
        // }
    }, [authStore, dashboardStore, authStore.isLogin, shop, navigator])

    return (
        <Box w-100p h-100vh column row--lg>
            <StyledImage
                src="/images/tees.webp"
                w-100p
                w-50p--lg
                h-50p
                h-100p--lg
                style={{
                    objectFit: 'cover',
                }}
            />
            <Box
                w-100p
                w-50p--lg
                h-100p
                column
                spaceY5
                toCenter
                style={{
                    background: 'url("/images/connect-bg.webp") repeat',
                }}
            >
                <Box row spaceX5 toCenterY>
                    <StyledImage src="/icons/logo_macaw.webp" alt="logo" w10 h10 />
                    <LinkIcon fontSize="large" color="primary" />
                    <StyledImage src="/icons/shopify_logo_whitebg.svg" loading='lazy' alt="logo" h10 />
                </Box>
                <Box shadowHuge>
                    <LoginBox
                        refreshAfter={false}
                        onLoggedin={handleResult}
                        onSignedUp={handleResult}
                        onContinue={onConnect}
                        formOnly={true}
                        loginOnly={false}
                        preMode='login'
                    />
                </Box>
            </Box>
            <StyledSnackbar
                autoHideDuration={3000}
                open={showMessageBar}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </StyledSnackbar>
        </Box>
    )
}
const ConnectWrapper = () => {
    return <Connect />
}
export default ConnectWrapper
