import { FC, useCallback, useEffect, useRef, useState } from "react";

import { Box } from "@fower/react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import BlobEditDrawer from "./BlobEditDrawer";
import { doDelete, doGet, doPost } from "../../common/commonService";
import { useAuthStore } from "../../store/useAuthStore";


interface formDataType {
    title: string;
    description: string;
    cover_image: string | File | undefined
    seo_title: string
    seo_description: string
    seo_url: string
}

interface tableDataType {
    cover_image: string
    created_time: string
    description: string
    description_html: string
    id: number
    title: string
    updated_time: string
    user: {
        first_name: string
        profile_image: string
        user_id: number
    }
}

const BlobManage: FC = () => {

    const authStore = useAuthStore()

    const rowId = useRef<number>(0)

    const editId = useRef<number>(0)

    const [tableData, setTableData] = useState<tableDataType[]>([])

    const columns: GridColDef<(typeof tableData)[number]>[] = [
        {
            field: 'id',
            headerName: 'ID',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            width: 100
        },
        {
            field: 'cover_image',
            headerName: 'coverImage',
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ value }) => {
                return (
                    <img src={value} style={{ width: '50px', height: '50px' }} />
                )
            }
        },
        {
            field: 'title',
            headerName: 'title',
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'description',
            headerName: 'description',
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'created_time',
            headerName: 'createdTime',
            sortable: false,
            align: 'center',
            flex: 1,
            headerAlign: 'center'
        },
        {
            field: 'updated_time',
            headerName: 'updatedTime',
            sortable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'operation',
            headerName: 'Actions',
            sortable: false,
            width: 300,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            onClick={() => onDetail(params.row.id)}
                        >edit</Button>

                        <Button
                            onClick={() => {
                                rowId.current = params.row.id
                                setDelOpen(true)
                            }}
                        >delete</Button>
                    </>
                )
            }
        }

    ];

    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const [paginationModel, setPaginationModel] = useState({
        page: 0, // 当前页码（从0开始）
        pageSize: 20, // 每页显示的行数
        total: 0
    });


    const [drawerVisible, setDrawerVisible] = useState(false)
    const [delOpen, setDelOpen] = useState(false)
    const [params, setParams] = useState<formDataType>({
        title: '',
        description: '',
        cover_image: undefined,
        seo_title: '',
        seo_description: '',
        seo_url: ''
    })

    const [description_html, setDescriptionHtml] = useState('')

    const initData = useCallback(async (pagePram?: number | null) => {
        setLoading(true)

        const res = await doGet(`https://api.customtee.co/api/customteeBlogList/`, {
            page: pagePram || paginationModel.page + 1
        }, authStore.token)

        setTableData(res.data)
        setPaginationModel(pre => {
            return {
                ...pre,
                total: res.total_count
            }
        })
        setLoading(false)
    }, [])



    useEffect(() => {
        initData()
    }, [initData])

    function resetForm() {
        setDescriptionHtml('')
        setParams({
            title: '',
            description: '',
            cover_image: undefined,
            seo_title: '',
            seo_description: '',
            seo_url: ''
        })
        editId.current = 0
    }

    async function onSubmit() {

        if (!params.cover_image) {
            setSuccess(false)
            setMessage('Please upload the cover image')
            setMessageOpen(true)
            return
        }
        if (!params.title) {
            setSuccess(false)
            setMessage('Please fill in the title')
            setMessageOpen(true)
            return
        }
        if (!params.description) {
            setSuccess(false)
            setMessage('Please fill in the description')
            setMessageOpen(true)
            return
        }

        if (!description_html) {
            setSuccess(false)
            setMessage('Please fill in the Rich text')
            setMessageOpen(true)
            return
        }
        if (!params.seo_title) {
            setSuccess(false)
            setMessage('Please fill in the seo text')
            setMessageOpen(true)
            return
        }

        if (!params.seo_description) {
            setSuccess(false)
            setMessage('Please fill in the seo description')
            setMessageOpen(true)
            return
        }
        if (!params.seo_url) {
            setSuccess(false)
            setMessage('Please fill in the seo url')
            setMessageOpen(true)
            return
        }

        const formData = new FormData();
        formData.append('title', params.title)
        formData.append('cover_image', params.cover_image)
        formData.append('description', params.description)
        formData.append('description_html', description_html)
        formData.append('seo_title', params.seo_title)
        formData.append('seo_description', params.seo_description)
        formData.append('seo_url', params.seo_url)
        if (editId.current) {
            const res = await doPost(`https://api.customtee.co/api/customteeBlogUpdate/${editId.current}/`, formData, authStore.token)
            if (res.success) {
                setMessage('Update success')
                setSuccess(true)
                setMessageOpen(true)
                setDrawerVisible(false)

                await initData(1)
                setMessageOpen(false)
            }
        } else {
            const res = await doPost(`https://api.customtee.co/api/customteeBlogCreation/`, formData, authStore.token)

            if (res.success) {
                setMessage('Create success')
                setSuccess(true)
                setMessageOpen(true)
                setDrawerVisible(false)

                await initData(1)
                setMessageOpen(false)
            }
        }

        resetForm()

    }

    async function onDelete() {
        const formData = new FormData()
        const res = await doDelete(`https://api.customtee.co/api/customteeBlogUpdate/${rowId.current}/ `, formData, authStore.token)
        if (res.success) {
            setDelOpen(false)
            await initData()
        }

    }

    async function onDetail(id: number) {
        const res = await doGet(`https://api.customtee.co/api/customteeBlogDetail/${id}/`, null, authStore.token)

        if (res.success) {
            const { title, description, cover_image, description_html, id, seo_title, seo_description, seo_url } = res.data
            editId.current = id
            setParams({
                title,
                description,
                cover_image,
                seo_title, seo_description, seo_url
            })

            setDescriptionHtml(description_html)
            setDrawerVisible(true)
        }
    }

    return (
        <>
            <Button
                variant="outlined"
                style={{ width: '100px' }}
                onClick={() => {
                    setDrawerVisible(true)
                }}
            >Add Blob</Button>

            <Box w="100%" h="100%" mt5>
                <DataGrid
                    style={{ height: '600px', width: '100%' }}
                    rows={tableData}
                    columns={columns}

                    pagination
                    loading={loading}

                    onPageChange={(e) => {
                        setPaginationModel(pre => {
                            return {
                                ...pre,
                                page: e
                            }
                        })

                    }}
                    rowCount={paginationModel.total}
                    page={paginationModel.page}
                    pageSize={paginationModel.pageSize}
                    paginationMode="client" // 客户端分页模式
                    disableColumnMenu // Disables the column menu
                    disableColumnFilter // Disables column filtering
                    disableColumnSelector // Disables column selector (hides the toolbar)
                    disableDensitySelector // Disables the density selector in the toolbar

                />
            </Box>

            <Dialog
                open={delOpen}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this item? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDelOpen(false) }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onDelete} color="secondary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {
                drawerVisible && <BlobEditDrawer
                    visible={drawerVisible}
                    formData={params}
                    description_html={description_html}
                    onUpdateHtml={(data) => {
                        setDescriptionHtml(data)
                    }}
                    onUpdateFormData={(data) => {
                        setParams(data)
                    }}
                    onSubmit={onSubmit}
                    onClose={() => {
                        setParams({
                            title: '',
                            description: '',
                            cover_image: undefined,
                            seo_title: '',
                            seo_description: '',
                            seo_url: ''
                        })
                        setDrawerVisible(false)
                    }} />
            }

            {
                messageOpen &&
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={true}
                >
                    <Alert severity={success ? "success" : "error"}>
                        {message}
                    </Alert>
                </Snackbar>
            }
        </>
    )
}

export default BlobManage;