import { Box } from '@fower/react'
import { Drawer } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeaturedProductListItem, ProductItemVO } from '../../TypeDeclare'
import DetailedBar from '../product_list/DetailedBar'
import { useProductStore } from '../product_list/store/useProdustListStore'
import styles from './LandingSection.module.css'
import FeaturedList from './FeaturedList'

interface FeaturedProductsProps {
    onLoadComplete: () => void
}

const FeaturedProducts: FC<FeaturedProductsProps> = observer(({
    onLoadComplete
}) => {
    const store = useProductStore()
    const navigator = useNavigate()
    const [selectedItem, setSelectedItem] = useState<ProductItemVO | undefined>(
        undefined,
    )
    const [showDetailedBar, setShowDetailedBar] = useState<boolean>(false)
    const [featuredList, setFeaturedList] = useState<
        Array<FeaturedProductListItem>
    >([])
    useEffect(() => {
        store.clearEditorData()
        // store.reloadDC()
        store.reloadFeaturedProducts().then((res) => {
            console.log(JSON.stringify(res[0]))
            setFeaturedList(res)
            onLoadComplete()
        })
    }, [store])
    function extractProductId(url: string, defaultName: string) {
        if (url) {
            if (!url.includes('/')) {
                // 如果输入的字符串不包含斜杠，直接返回该字符串
                return url;
            }
            // 匹配最后一个斜杠后面的内容
            const regex = /\/([^/]+)\/?$/;
            const match = url.match(regex);
            return match ? match[1] : null;
        }
        return defaultName

    }
    return (
        <Box w--lg="80%" w="100%" column toCenter>
            {featuredList.map((feature) => {
                return (
                    <FeaturedList
                        key={feature.name}
                        feature={feature}
                        onSelect={(item) => {
                            store.setProduct(item)
                            setSelectedItem(store.product)
                            //   setShowDetailedBar(true)
                            const { seo_url, id, name } = item
                            const cpName = name.toLocaleLowerCase().split(' ').join('-')

                            const newName = extractProductId(seo_url!, cpName)
                            // navigator(`/detail?productId=${item.id}&data=${selectedItem ? JSON.stringify(selectedItem) : ''}`)
                            navigator(`/products/${id}/${newName}`)
                        }}
                    />
                )
            })}
            <Box
                className={styles.developerChoiceShowMore}
                style={{
                    background: '#F3F3F5'
                }}
                rounded
                p2
                pr5
                pl5
                cursorPointer
                mb10
                onClick={() => {
                    navigator('/catalog/all')
                }}
            >
                Show more
            </Box>
            <Drawer
                anchor="right"
                open={showDetailedBar}
                onClose={() => {
                    setShowDetailedBar(false)
                    setSelectedItem(undefined)
                }}
            >
                <DetailedBar
                    data={selectedItem}
                    sizeTableData={store.sizeTableList}
                    onClose={() => {
                        setShowDetailedBar(false)
                        setSelectedItem(undefined)
                    }}
                />
            </Drawer>
        </Box>
    )
})
export default FeaturedProducts
