import { FC } from 'react'
import { Box } from '@fower/react'
import { PaymentCardInfoVO, } from '../../../TypeDeclare'
import { Button } from '@mui/material'

export type PaymentItemProps = {
    data: PaymentCardInfoVO
    onAddNewCard?: () => void
    onDelete?: (data: PaymentCardInfoVO) => void
    onSelected?: (data: PaymentCardInfoVO) => void
    canDelete?: boolean
}


const NewPaymentItem: FC<PaymentItemProps> = ({
    data,
    onDelete,
    onSelected,
    canDelete = true,
}) => {

    return (
        <Box
            w="100%"
            h="170px"
            justifyContent="center"
            column
            css={{
                borderRadius: '8px',
                border: '1px solid #707070',
                marginBottom: '20px'
            }}
            onClick={() => {
                onSelected && onSelected(data)
            }}
        >
            <Box
                flex alignItems="center"
                justifyContent="space-between"
                h="100%"
                relative
                style={{
                    padding: '22px'
                }}
            >
                <Box
                    flex
                    column
                    alignItems="flex-start"
                >
                    <img src="/icons/icon-card.webp" alt="icon_card" style={{
                        width: '44px',
                        height: '34px',
                    }} />

                    <span style={{ marginTop: '20px' }}>{data.name}</span>


                    <Box gray500 textSM toCenterY style={{ marginTop: '20px' }}>
                        {data.cardNumber}
                    </Box>
                </Box>

                {canDelete && (
                    <Box
                        absolute
                        style={{
                            top: '0',
                            right: '0'
                        }}
                        onClick={() => {
                            onDelete && onDelete(data)
                        }}
                    >
                        <Button variant='text'>Delete</Button>
                    </Box>
                )}
            </Box>

        </Box>
    )
}

export default NewPaymentItem;