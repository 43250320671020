import { FC, useCallback, useEffect } from 'react'
import { Box } from '@fower/react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useAuthStore } from '../../../store/useAuthStore'
import { observer } from 'mobx-react-lite'
import { useDashbardStore } from '../store/useDashboardStore'
import { StyledPaper } from '../../../TypeDeclare'

export type StoresListProps = {}
const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'url', headerName: 'Name', width: 300 },
  {
    field: 'platform',
    headerName: 'Platform',
    width: 140,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.platform.toUpperCase()
    },
  },
]
const StoresList: FC<StoresListProps> = observer(() => {
  const authStore = useAuthStore()
  const dashbardStore = useDashbardStore()

  const refreshProfile = useCallback(async () => {
    const result = await authStore.loadUserProfile()
    dashbardStore.setLinkedShops(result.rawLinkedShops)
  }, [authStore, dashbardStore])
  useEffect(() => {
    refreshProfile().then(null)
  }, [refreshProfile])

  return (
    <Box h="50vh" p10 column>
      {dashbardStore.linkedShops.length === 0 && (
        <StyledPaper toCenter elevation={1}>
          <Box h96 toCenter>
            No stores yet
          </Box>
        </StyledPaper>
      )}
      {dashbardStore.linkedShops.length !== 0 && (
        <DataGrid
          rows={dashbardStore.linkedShops}
          columns={columns}
          checkboxSelection={false}
          rowsPerPageOptions={[5]}
          pageSize={5}
          rowHeight={64}
        />
      )}
    </Box>
  )
})
export default StoresList
