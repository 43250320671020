import { Box } from "@fower/react";
import { Typography } from "@mui/material";
import { FC } from "react";

interface ExploreProps {
    onAction: (e: any) => void;
}

const Explore: FC<ExploreProps> = ({ onAction }) => {
    return (
        <Box cursorPointer onClick={(e) => {
            onAction(e);
        }}>
            <Typography variant="h3" color={'#4384D6'}>
                Solutions
            </Typography>
        </Box>
    )
}

export default Explore;