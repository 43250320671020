import { fabric } from 'fabric';
import { Image } from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { EditorStore } from './EditorStore';
import { ImageModule } from './ImageModule';
import { TextFontModule } from './TextFontModule';
import { ActionType } from './UnifyActionRecorder';
import FontFaceObserver from 'fontfaceobserver';
import { ColorOption, FaceType } from '../../../../TypeDeclare';
import { loadBase64Image } from '../../../product_list/services/productService';
import { changeDpiDataUrl } from '../../../../common/ImageUtils';

export type ImageData = {
    name: string;
    data: string;
};

export class CTModule {
    @observable color: ColorOption = {
        id: '10',
        value: '#ffffff',
        name: 'White',
    };
    @observable templatePath = '';
    @observable editor?: FabricJSEditor;
    @observable hasSelectedObject: boolean = false;
    @observable selectedObjectType?: string;
    @observable loadingImage: boolean = true;
    selectedTextFont: TextFontModule;
    newTextFont: TextFontModule;
    imageMask?: fabric.Rect;
    mainGroup?: fabric.Group;
    selectedImage: ImageModule;
    teeTemplateImage?: Image;
    offSet: {
        xOff: number;
        yOff: number;
        pW: number;
        pH: number;
    } = { xOff: 0, yOff: 0, pW: 0.3, pH: 0.4 };
    @observable editableFrame?: fabric.Rect;

    ow: number = 0;
    oh: number = 0;
    constructor(
        public readonly face: FaceType,
        private readonly parent: EditorStore
    ) {
        makeObservable(this);
        this.selectedTextFont = new TextFontModule(parent);
        this.newTextFont = new TextFontModule(parent);
        this.selectedImage = new ImageModule(parent);
    }

    @computed
    get edited(): boolean {
        if (this.editor) {
            return this.editor?.canvas.getObjects().length !== 0;
        }
        return false;
    }
    @action
    updateHasSelectedObject() {
        if (this.editor) {
            const target = this.editor?.canvas.getActiveObject() as fabric.Object;
            if (target) {
                // target.set('borderColor', 'gray');
                // target.set('cornerColor', 'gray');

                if (target.type === 'text') {
                    this.selectedTextFont.updateWithSelected(target);
                    this.editor.canvas.renderAll();
                } else if (target.type === 'rect') {
                    this.selectedImage.updateWithSelected(target);
                    this.editor.canvas.renderAll();
                }
                this.selectedObjectType = target.type;
                this.editor.canvas.bringToFront(target);
            } else {
                this.selectedObjectType = undefined;
            }
            this.hasSelectedObject = target !== undefined && target !== null;
        }
    }

    @action
    initEditor(e: FabricJSEditor) {
        if (this.editor !== undefined) {
            return;
        }
        this.editor = e;
        this.ow = e.canvas.getWidth();
        this.oh = e.canvas.getHeight();

        e.canvas.on('mouse:up', (e) => {
            this.updateHasSelectedObject();
        });

        this.editor.canvas.enableRetinaScaling = true;
        this.editor.canvas.imageSmoothingEnabled = true;
        this.editor.canvas.controlsAboveOverlay = true;
        this.editor.canvas.calcOffset();
    }
    addFullEditableArea(w: number, h: number, t: number, l: number) {
        this.imageMask = new fabric.Rect({
            top: t,
            left: l,
            width: w,
            height: h,
            originX: 'left',
            originY: 'top',
            absolutePositioned: true,
        });
    }
    addEditableArea(w: number, h: number, t: number, l: number) {
        if (this.editableFrame) {
            this.editor?.canvas.remove(this.editableFrame);
        }
        if (this.parent.product?.readyToShip) {
            return;
        }
        this.imageMask = new fabric.Rect({
            top: t,
            left: l,
            width: w,
            height: h,
            originX: 'left',
            originY: 'top',
            rx: 10,
            ry: 10,
            absolutePositioned: true,
        });
        this.editableFrame = new fabric.Rect({
            top: t,
            left: l,
            width: w,
            height: h,
            originX: 'left',
            originY: 'top',
            fill: '',
            stroke: 'white',
            shadow: new fabric.Shadow({
                color: 'rgba(0,0,0,0.6)',
                blur: 5,
                offsetX: 5,
                offsetY: 5,
                affectStroke: false,
            }),
            strokeWidth: 3,
            strokeDashArray: [8],
            absolutePositioned: true,
            rx: 10,
            ry: 10,
            evented: false,
            selectable: false,
        });
        this.editor?.canvas.add(this.editableFrame);
    }
    @action
    addImage(imageData: string): void {
        const format: string = imageData.split(';')[0].split(':')[1];
        const cw = this.editor?.canvas.getWidth();
        const ch = this.editor?.canvas.getHeight();
        fabric.util.loadImage(imageData, (image) => {
            const rect = new fabric.Rect({
                width: image.width,
                height: image.height,
                originX: 'center',
                originY: 'center',
                rx: 0,
                ry: 0,
                clipPath: this.imageMask,
                borderColor: '#000',
                strokeWidth: 0,
                cornerStrokeColor: '#000',
                cornerColor: '#000',
                cornerStyle: 'circle',
                data: {
                    format,
                },
            });
            this.editor?.canvas.add(rect);
            rect.set(
                'fill',
                new fabric.Pattern({
                    source: image,
                    repeat: 'no-repeat',
                })
            );

            if (ch && cw) {
                rect.scaleToWidth(ch / 4);
            }
            rect.center();
            this.editor?.canvas.bringToFront(rect);
            this.editor?.canvas.setActiveObject(rect);
            this.editor?.canvas.renderAll();
            this.parent.actionRecorder?.saveState(
                ActionType.CANVAS_CHANGED,
                this.face
            );
        });
    }
    @action
    addText(): void {
        const textObj = new fabric.Text(this.newTextFont.text, {
            fill: this.newTextFont.color.value,
            fontSize: this.newTextFont.size,
            clipPath: this.imageMask,
            fontFamily: this.newTextFont.font,
            cornerStrokeColor: '#000',
            cornerColor: '#000',
            cornerStyle: 'circle',
            borderColor: '#000',
        });
        if (this.editor) {
            const cw = this.editor?.canvas.getWidth();
            const ch = this.editor?.canvas.getHeight();
            textObj.left = (cw - textObj.width!) / 2; //+ 20;
            if (this.editableFrame) {
                textObj.top =
                    this.editableFrame!?.top! + this.editableFrame?.height! / 2; //(ch - textObj.height!) / 2;
            } else {
                textObj.top = (ch - textObj.height!) / 2;
            }
            textObj.padding = 10;
            this.editor?.canvas.add(textObj);
            this.editor?.canvas.bringToFront(textObj);
            this.editor?.canvas.setActiveObject(textObj);
            const myfont = new FontFaceObserver(this.newTextFont.font);
            myfont
                .load()
                .then(() => {
                    textObj.set('fontFamily', this.newTextFont.font);
                    this.editor?.canvas?.requestRenderAll();
                })
                .catch((e) => { });
            this.parent.actionRecorder?.saveState(
                ActionType.CANVAS_CHANGED,
                this.face
            );
        }
    }
    @action
    initTeeTemp(
        path: string,
        xOff: number,
        yOff: number,
        pW: number,
        pH: number
    ): void {
        this.templatePath = path;
        if (this.teeTemplateImage) {
            const bgObj = this.editor?.canvas.backgroundImage;
            if (bgObj) {
                const bg = bgObj as fabric.Image;
                if (bg.backgroundColor !== this.color.value) {
                    bg.backgroundColor = this.color.value;
                    this.editor?.canvas.renderAll();
                }
            }
            return;
        }

        if (path === '') return;
        this.loadingImage = true;
        loadBase64Image(path).then((imageData: string) => {
            if (this.parent.product?.isFullPrint) {
                this.initFullPrint(imageData, xOff, yOff, pW, pH);
            } else {
                this.initNonFullPrint(imageData, xOff, yOff, pW, pH);
            }
        });
    }
    initFullPrint(
        imageData: string,
        xOff: number,
        yOff: number,
        pW: number,
        pH: number
    ) {
        fabric.Image.fromURL(imageData, (image) => {
            const ch = this.editor?.canvas.getHeight();
            const cw = this.editor?.canvas.getWidth();
            this.editor?.canvas.setBackgroundColor('#FFF', () => { });
            if (ch !== undefined && cw !== undefined) {
                image.scaleToHeight(ch);
                image.setPositionByOrigin(
                    new fabric.Point(cw / 2, ch / 2),
                    'center',
                    'center'
                );
            }
            this.offSet = { xOff, yOff, pW, pH };
            const fw = image.width! * image.scaleX!;
            const fh = image.height! * image.scaleY!;
            const ft = (this.oh - fh) / 2;
            const fl = (this.ow - fw) / 2;
            this.addFullEditableArea(fw, fh, ft, fl);
            this.teeTemplateImage = image;
            this.editor?.canvas.setOverlayImage(image, () => {
                this.editor?.canvas.renderAll();
                this.parent.actionRecorder?.initData();
            });
            runInAction(() => {
                this.loadingImage = false;
            });
        });
    }
    initNonFullPrint(
        imageData: string,
        xOff: number,
        yOff: number,
        pW: number,
        pH: number
    ) {
        fabric.Image.fromURL(imageData, (image) => {
            const ch = this.editor?.canvas.getHeight();
            const cw = this.editor?.canvas.getWidth();
            image.backgroundColor = this.color.value;
            if (ch !== undefined && cw !== undefined) {
                image.scaleToHeight(ch);
                image.setPositionByOrigin(
                    new fabric.Point(cw / 2, ch / 2),
                    'center',
                    'center'
                );
            }
            this.offSet = { xOff, yOff, pW, pH };
            const fw = image.width! * image.scaleX! * pW;
            const fh = image.height! * image.scaleY! * pH;
            const ft = image.height! * image.scaleY! * 0.2 + yOff * image.scaleY!;
            const fl = (this.ow - fw) / 2 + xOff * image.scaleX!;
            this.addEditableArea(fw, fh, ft, fl);
            this.teeTemplateImage = image;
            this.editor?.canvas.setBackgroundImage(image, () => {
                this.editor?.canvas.renderAll();
                this.parent.actionRecorder?.initData();
            });
            runInAction(() => {
                this.loadingImage = false;
            });
        });
    }
    @action
    resetEditableFrame() {
        const newEF = this.findEF();
        if (newEF) {
            this.editableFrame = newEF;
        }
    }
    findEF() {
        const objs = this.editor?.canvas.getObjects();
        const newEF = objs?.find((o) => {
            return !o.get('selectable') && !o.get('evented');
        });
        return newEF;
    }
    deleteSelectedElement() {
        this.editor?.canvas.remove(this.editor?.canvas.getActiveObject());
    }

    @action
    changeTemplateColor(newColor: ColorOption) {
        this.color = newColor;
        this.initTeeTemp(
            this.templatePath,
            this.offSet.xOff,
            this.offSet.yOff,
            this.offSet.pW,
            this.offSet.pH
        );
    }
    @action
    resize() {
        if (this.editor && this.editor.canvas) {
            this.editor.canvas.renderAll();
        }
    }

    toBase64(): ImageData | undefined {
        const data = this.editor?.canvas.toDataURL({
            quality: 1,
            format: 'jpeg',
            top: this.teeTemplateImage?.top,
            left: this.teeTemplateImage?.left,
            width: this.teeTemplateImage?.width! * this.teeTemplateImage?.scaleX!,
            height: this.teeTemplateImage?.height! * this.teeTemplateImage?.scaleY!,
        });

        if (data) {
            return {
                name: `${this.face}-preview`,
                data: data.split(',')[1],
            };
        }
        return undefined;
    }
    @action
    toggleMask(on: boolean) {
        const ef = this.findEF();
        ef?.set('evented', true);
        ef?.set('selectable', true);
        if (!on) {
            ef?.set('visible', false);
        } else {
            ef?.set('visible', true);
        }
        ef?.canvas?.renderAll();
        ef?.set('evented', false);
        ef?.set('selectable', false);
    }
    getAssetsData(): Array<ImageData> {
        const results: Array<ImageData> = [];
        const obs = this.editor?.canvas.getObjects();
        const count: Map<string, number> = new Map<string, number>();
        count.set('image', 0);
        count.set('text', 0);
        count.set('rect', 0);
        obs?.forEach((o: fabric.Object) => {
            if (o === this.editableFrame) {
                return;
            }
            o.set('clipPath', undefined);
            let data = '';
            if (o.type === 'text') {
                o.width = o.width! + 20;
                o.scaleToWidth(2000);
                o.height = o.height! + 20;
                data = o.toDataURL({
                    format: 'png',
                    enableRetinaScaling: true,
                    withoutShadow: false,
                    left: -12,
                });
                data = changeDpiDataUrl(data, 300);
            } else {
                const extData = o.get('data');
                if (extData && extData['format'] === 'image/png') {
                    data = o.toDataURL({
                        format: 'png',
                        withoutTransform: true,
                    });
                } else {
                    data = o.toDataURL({
                        format: 'jpeg',
                        quality: 1,
                        withoutTransform: true,
                    });
                }
                data = changeDpiDataUrl(data, 150);
            }
            if (data.trim() === 'data:,') return;
            if (o.type) {
                const c = count.get(o.type);
                if (data) {
                    const item: ImageData = {
                        name: `${this.face}-${o.type}-${c}`,
                        data: data.split(',')[1],
                    };
                    results.push(item);
                    count.set(o.type, c! + 1);
                }
            }
            o.set('clipPath', this.imageMask);
        });
        return results;
    }
    clearActiveObject() {
        this.editor?.canvas.discardActiveObject();
    }
    reset() {
        this.editor = undefined;
        this.teeTemplateImage = undefined;
    }
}
