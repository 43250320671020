import { Box } from '@fower/react'
import { CircularProgress, Divider, Tab, Tabs, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useEditorStore } from '../../store/editor/useEditorStore'
import TabPanel from '../common/TabPanel'
import { AddElementActionBar } from './AddElementActionBar'
import ColorPalette from './ColorPalette'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import TextElementInspector from './TextElementInspector'
import ImageInspector from './ImageInspector'
import InfoBar from './InfoBar'
import SizePicker from './SizePicker'
import { useProductStore } from '../../../product_list/store/useProdustListStore'
import { getFontColorValues } from '../../../../utils/ColorPreset'
import PQuantityPicker from './PQuantityPicker'
import { ProductItemVO } from '../../../../TypeDeclare'
import styles from '../../../product_list/ProductList.module.css'
export type AddElementPanelProps = {
    product?: ProductItemVO
    loadingProduct: boolean
}
const AddElementPanel: FC<AddElementPanelProps> = observer(
    ({ product, loadingProduct }) => {
        const store = useEditorStore()
        const productStore = useProductStore()
        const [tabIndex, setTabIndex] = useState<number>(0)

        const outterBoxRef = useRef(null)
        const innerBoxRef = useRef(null)
        const onTabChange = (_: React.SyntheticEvent, index: number) => {
            setTabIndex(index)
        }
        useEffect(() => {
            if (store.hasSelectedObject) {
                if (store.selectedObjectType === 'text') {
                    setTabIndex(1)
                } else if (store.selectedObjectType === 'rect') {
                    setTabIndex(2)
                }
            } else {
                setTabIndex(0)
            }
        }, [store.hasSelectedObject, store.selectedObjectType])
        useEffect(() => {
            if (productStore.colorOptions.length > 0) {
                const defaultColor = productStore.colorOptions[0]
                store.changeTemplateColor(defaultColor)
            }
        }, [productStore.colorOptions, store])
        useEffect(() => {
            if (productStore.sizeOptions.length > 0) {
                const defaultSize = productStore.sizeOptions[0]
                productStore.setSize(defaultSize)
            }
        }, [productStore, productStore.sizeOptions, store])
        const topShippingMethod = product?.shippingMethods.find(
            (s) => s.id === 'Economy Shipping',
        )
        console.log('loadingProduct', loadingProduct);

        if (loadingProduct) {
            return (
                <Box
                    ref={outterBoxRef}
                    h={['100%', '100%', '100%', '100%']}
                    w={['100%', '100%', '100%', '30%']}
                    column
                    toCenterX
                    borderLeft-1
                    zIndex--lg="101"
                    zIndex={store.showBottomMenu ? '101' : '-1'}
                    toCenter
                    position--lg="inherit"
                    position="fixed"
                    bg--lg="white"
                    bg="rgba(0,0,0,0.5)"
                >
                    <Box column toCenter spaceY10>
                        <Box>Loading product info</Box>
                        <CircularProgress color="secondary" />
                    </Box>
                </Box>
            )
        }
        return (
            <Box
                ref={outterBoxRef}
                h={['100%', '100%', '100%', '100%']}
                w={['100%', '100%', '100%', '30%']}
                column
                toCenterX
                borderLeft-1
                zIndex--lg="101"
                zIndex={store.showBottomMenu ? '101' : '-1'}
                toBetween
                position--lg="inherit"
                position="fixed"
                bg--lg="white"
                bg="rgba(0,0,0,0.5)"
                onClick={(e) => {
                    const closeTarget = e.target === outterBoxRef.current
                    if (closeTarget) {
                        store.setShowBottomMenu(false)
                    }
                }}
            >
                <Box
                    ref={innerBoxRef}
                    w="100%"
                    h="100%"
                    bgWhite
                    column
                    toCenterX
                    shadowHuge
                    roundedTopRightHuge
                    roundedTopLeftHuge
                    roundedNone--lg
                    top="20vh"
                    top0--1g
                    position--lg="inherit"
                    position="fixed"
                    overflowAuto
                >
                    <AddElementActionBar
                        styles={{
                            'display--lg': 'inline-flex',
                            display: 'none',
                        }}
                        product={product}
                    />
                    <Box w="100%" display--lg="inline-flex" display="none">
                        <Divider
                            variant="fullWidth"
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Box>
                    {!product?.readyToShip && (
                        <Box
                            toLeft
                            w="100%"
                            ml10
                            mt2--lg
                            mt5
                            display--lg="inline-flex"
                            display="none"
                        >
                            <Typography variant="h5">Edit</Typography>
                        </Box>
                    )}
                    <Box toCenter w="100%" pr5 pl5 hidden={product?.readyToShip}>
                        <Tabs
                            value={tabIndex}
                            variant="fullWidth"
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgb(212, 212, 212)',
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#4384D6', // 修改选中下划线的颜色
                                },

                                '& .Mui-selected': {
                                    color: '#4384D6', // 选中状态的 Tab 文字颜色
                                },
                            }}
                            onChange={onTabChange}
                        >
                            {!product?.readyToShip && (
                                <>
                                    <Tab
                                        label="Product"
                                        sx={{
                                            fontFamily: 'Avenir Heavy',
                                        }}
                                    />
                                    <Tab
                                        label="Text"
                                        sx={{
                                            fontFamily: 'Avenir Heavy',
                                        }}
                                        disabled={
                                            store.selectedTextFont.selectedObject === undefined
                                        }
                                    />
                                    <Tab
                                        label="Image"
                                        sx={{
                                            fontFamily: 'Avenir Heavy',
                                        }}
                                        disabled={
                                            store.tee.selectedImage.selectedObject === undefined
                                        }
                                    />
                                </>
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0}>
                        <Box column>
                            <ColorPalette
                                loadingImage={store.loadingImage}
                                colorOptions={productStore.colorOptions}
                                selectedColor={store.templateColor}
                                onColorChanged={(newColor) => {
                                    store.changeTemplateColor(newColor)
                                }}
                            />
                            <SizePicker
                                sizes={productStore.sizeOptions}
                                selectedSize={productStore.size}
                                onSelect={(newValue) => {
                                    productStore.setSize(newValue)
                                }}
                            />
                            <PQuantityPicker
                                value={productStore.quantity}
                                onUpdate={(newValue) => {
                                    productStore.setQuantity(newValue)
                                }}
                                maxValue={10}
                                minValue={1}
                            />
                            {product?.readyToShip && (
                                <Box column p2>
                                    <Box rounded bgGray100 p4 column spaceY2>
                                        <Box row toCenterY spaceX2>
                                            <LocalShippingIcon fontSize="small" />
                                            <Box textXS>{topShippingMethod?.id} costs $8.95</Box>
                                        </Box>
                                        <Box row toCenterY spaceX2>
                                            <AccessTimeFilledIcon fontSize="small" />
                                            <Box textXS>
                                                3-5 Business Days Express Shipping is Avaliable
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        textXS
                                        m4
                                        gray600
                                        h="100%"
                                        flexGrow={2}
                                        className={styles.multiLine}
                                    >
                                        {product.desc}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <TextElementInspector
                            onColorChange={(newColor) => {
                                store.selectedTextFont.setColor(newColor)
                            }}
                            onSizeChange={store.selectedTextFont.setSize.bind(
                                store.selectedTextFont,
                            )}
                            onTextChange={store.selectedTextFont.setText.bind(
                                store.selectedTextFont,
                            )}
                            onFontChange={store.selectedTextFont.setFont.bind(
                                store.selectedTextFont,
                            )}
                            font={store.selectedTextFont.font}
                            text={store.selectedTextFont.text}
                            size={store.selectedTextFont.size}
                            color={store.selectedTextFont.color}
                            colors={getFontColorValues()}
                            minValue={store.selectedTextFont.minValue}
                            maxValue={store.selectedTextFont.maxValue}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <ImageInspector
                            rx={store.tee.selectedImage.rx}
                            ry={store.tee.selectedImage.ry}
                            onRadiusChange={(value: number, type: 'x' | 'y') => {
                                store.tee.selectedImage.setCornerRadius(value, type)
                            }}
                        />
                    </TabPanel>
                </Box>
                <InfoBar readyToShip={product?.readyToShip || false} />
            </Box>
        )
    },
)
export default AddElementPanel
