import { Box } from "@fower/react";
import { FC } from "react";
import { SizeOption } from "../../../TypeDeclare";

interface SizePickerProps {
    defaultValue: SizeOption,
    list: SizeOption[],
    onSelect: (newValue: SizeOption) => void;
}

const SizePicker: FC<SizePickerProps> = ({ defaultValue, list, onSelect }) => {

    return (
        <Box flex flexWrap>
            {
                list.map((size) => {
                    return (
                        <Box w="62px" h="62px"
                            key={size.id}
                            flex
                            toCenter
                            textCenter
                            mr2
                            mb2
                            onClick={() => onSelect(size)}
                            color={size.id === defaultValue.id ? 'white' : '#518DD9'}
                            style={{
                                boxShadow: '2px 3px 6px 1px rgba(0,0,0,0.16)',
                                borderRadius: '8px',
                                fontSize: '14px',
                                cursor: 'pointer',
                                background: size.id === defaultValue.id ? '#518DD9' : 'white'
                            }}>
                            {size.value}
                        </Box>
                    )
                })
            }

        </Box>
    )
}

export default SizePicker;