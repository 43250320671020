import { Box } from "@fower/react";
import { Button, Typography } from "@mui/material";
import { FC } from "react";
import styles from "./detailPane.module.css"
import SizePicker from "../productDetail/components/SizePicker";
import ColorPalette from "../editor/components/right/ColorPalette";
import { ColorOption, SizeOption } from "../../TypeDeclare";

import PQuantityPicker from "../editor/components/right/PQuantityPicker";
import { observer } from "mobx-react-lite";
import ProductSliderOnColor from "./ProductSliderOnColor";


interface param {
    id: string
    name: string
    desc: string
    pairPrize: string
    daysInProduction: string
    is_expired: boolean
    days_left: number
}

interface BetterstyleDetailPaneProps {
    loading: boolean;
    item: param,
    sizeList: SizeOption[],
    selectSize: SizeOption,
    colorOptions: ColorOption[],
    selectedColor: ColorOption,
    imgList: string[],
    onColorChanged: (colorObj: ColorOption) => void
    onSizeChanged: (sizeObj: SizeOption) => void
    qty: number
    onUpdateQty: (value: number) => void
    onBuy: () => void
}

const BetterstyleDetailPane: FC<BetterstyleDetailPaneProps> = observer(
    ({
        item,
        sizeList,
        selectSize,
        colorOptions,
        selectedColor,
        imgList,
        onColorChanged,
        onSizeChanged,
        qty,
        onUpdateQty,
        onBuy
    }) => {


        const styleObj: Record<string, Object> = {
            description: {
                fontSize: '18px',
                fontWeight: '600',
                color: '#7B7B7B',
                lineHeight: '22px',
                fontFamily: 'Segoe UI, Segoe UI',
                marginTop: '20px'
            },
            price: {
                color: '#518DD9',
                fontWeight: 'bold',
                fontSize: '24px',
                fontFamily: 'Segoe UI, Segoe UI',
                marginTop: '20px'
            },
            buyButton: {
                width: '80%',

                borderRadius: '35px',
                fontFamily: 'Segoe UI, Segoe UI',
                fontSize: '28px'
            }
        }

        return (
            <>
                <Box
                    w="80%"
                    flex flexWrap
                >
                    <Box w={['100%', '100%', '100%', '46%', '46%']}>
                        {/* <img src={showImage} style={{ width: '100%', objectFit: 'cover' }} /> */}
                        <ProductSliderOnColor imgList={imgList} />
                    </Box>

                    <Box w={['0%', '0%', '0%', '4%', '4%']}></Box>

                    <Box w={['100%', '100%', '100%', '50%', '50%']} flex column>
                        <Typography component="h1" color="#363636" fontWeight={"bold"} style={{ fontSize: '24px' }}>
                            {item.name}
                        </Typography>
                        {/* <Box style={styleObj.description}>
                        {item.desc}
                    </Box> */}
                        <Box style={styleObj.price}>
                            ${item.pairPrize}
                        </Box>

                        <Box flex alignItems="center" style={{ marginTop: '22px' }}>
                            <img src="/icons/icon-car.webp" alt="icon_car" style={{ width: '30px', height: '23px' }} />
                            <span style={{ fontSize: '14px', marginLeft: '8px' }}>Fast shipping</span>
                        </Box>
                        <Box flex alignItems="center" style={{ marginTop: '22px' }}>
                            <img src="/icons/time.webp" alt="time" style={{ width: '30px', height: '30px' }} />
                            <span style={{ fontSize: '14px', marginLeft: '8px' }}> {
                                item.is_expired ? 'EXPIRED' : item.days_left > 0 ? `${item.days_left} days left` : 'ACTIVE'
                            }</span>
                        </Box>
                        <Box flex alignItems="center" style={{ marginTop: '20px' }}>
                            <img src="/icons/icon-time.webp" alt="icon_time" style={{ width: '28px', height: '33px' }} />
                            <span style={{ fontSize: '14px', marginLeft: '8px' }}>
                                <span style={{ color: '#FF0000', fontWeight: 'bold' }}>{item.daysInProduction} Days</span> Turnaround<br />
                            </span>
                        </Box>

                        <Typography color="#373737" fontWeight={'bold'} style={{ fontSize: '20px', margin: '30px 0 10px 0' }}>
                            Color
                        </Typography>

                        <ColorPalette
                            loadingImage={false}
                            selectedColor={selectedColor}
                            colorOptions={colorOptions}
                            onColorChanged={(newColor) => {
                                onColorChanged(newColor);
                            }}
                        />

                        <Typography color="#373737" fontWeight={'bold'} style={{ fontSize: '20px', margin: '10px 0 10px 0' }}>
                            Size
                        </Typography>

                        <SizePicker
                            list={sizeList}
                            defaultValue={selectSize}
                            onSelect={(newSize) => {
                                onSizeChanged(newSize)
                            }}
                        />

                        <Typography color="#373737" fontWeight={'bold'} style={{ fontSize: '20px', margin: '10px 0 10px 0' }}>
                            QTY
                        </Typography>

                        <PQuantityPicker
                            value={qty}
                            onUpdate={(value) => {
                                onUpdateQty(value)
                            }}
                            maxValue={100}
                            minValue={1}
                        />


                        <Box>
                            <Button
                                variant="contained"
                                disabled={item.is_expired}
                                style={styleObj.buyButton}
                                onClick={() => {
                                    onBuy()
                                    // const sku = `${item.productId}-${selectedSize.id}-${selectedColor.id}`
                                    // const urlTo = `/checkout?productId=${item.id}&sku=${sku}&quantity=${store.quantity}&size=${selectedSize.id}&colorId=${selectedColor.id}&shippingMethod=${selectedShippingMethod?.id}`
                                    // navigator(urlTo)

                                }}
                            >Buy</Button>
                        </Box>
                    </Box>


                </Box>


                <Box
                    w="100%"
                    textCenter
                    className={styles.parentBox}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{ __html: item.description_html }}></Box>
            </>
        )
    }
)

export default BetterstyleDetailPane;