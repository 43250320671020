import { Box } from '@fower/react'
import { Button, Divider, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import CopyBar from '../../common/CopyBar'
import { OrderInStoreVO, PreviewImageItem } from '../../TypeDeclare'
import { PreviewImagesBar } from '../checkout/PreviewImagesBar'

const OrderDetail: FC<{
  data?: OrderInStoreVO
  onAddTrackingLink: (newLink: string) => void
}> = observer(({ data, onAddTrackingLink }) => {
  const [trackingLink, setTrackingLink] = useState<string | undefined>(
    data?.trackingLink,
  )
  const getPreviewImages = (): PreviewImageItem[] => {
    const result = data?.product_images.map(
      (image): PreviewImageItem => {
        return {
          url: image,
          name: image,
          fileName: image,
          alias: image,
          format: 'png',
        }
      },
    )
    if (result) return result
    return []
  }
  return (
    <Box w="640px" h="100%" bgWarmGray100 p5 spaceY1>
      <PreviewImagesBar imageData={getPreviewImages()} onClick={() => {}} />
      <CopyBar label="Order Number" text={data?.order_id} />
      <CopyBar label="Order Name" text={data?.order_name} />
      <CopyBar label="Status" text={data?.status} />
      <CopyBar label="Status Comment" text={data?.status_comment} />
      <CopyBar
        label="Paid time"
        text={new Date(Date.parse(data?.paid_time!)).toLocaleString()}
      />
      <Divider />
      <CopyBar label="Amount Charged" text={data?.amount_charged.toString()} />
      <CopyBar label="Quantity" text={data?.quantity.toString()} />
      <CopyBar label="Product Price" text={data?.product_price.toString()} />
      <CopyBar label="Product Name" text={data?.product_name} />
      <CopyBar label="Product ID" text={data?.product_id} />
      <CopyBar label="Product SKU" text={data?.product_sku} />
      <Divider />
      <CopyBar label="Recipient" text={data?.recipient} />
      <CopyBar label="Email" text={data?.email} />
      <CopyBar label="State" text={data?.state} />
      <CopyBar label="City" text={data?.city} />
      <CopyBar label="Address" text={data?.address} />
      <CopyBar label="Zip" text={data?.zip} />
      <Divider />
      <Box row spaceX2>
        <TextField
          label="Tracking Link"
          variant="outlined"
          onChange={(event) => {
            setTrackingLink(event.target.value as string)
          }}
          value={trackingLink}
          sx={{
            flexGrow: 2,
          }}
        />
        <Button variant="contained" onClick={() => {
          onAddTrackingLink(trackingLink || '')
        }}>
          Update
        </Button>
      </Box>
    </Box>
  )
})
export default OrderDetail
