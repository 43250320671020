import { Box } from "@fower/react";
import { FC, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode } from "../../TypeDeclare";
import HomeFooter from "../home/Footer";
import LoginModal from "../../common/LoginModal";


const Copyright: FC = () => {
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />

            <Box
                w="80%"
            >

                <h1><strong>Custom Tee Copyright Dispute Policy</strong></h1>
                <p className="p2">&nbsp;</p>
                <p className="p2"><span className="s1">Effective date: 10/8/2018</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">In accordance with the DMCA, we’ve adopted the policy below toward copyright infringement. We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.</span></p>
                <p className="p3"><span className="s1">Remember that your use of Custom Tee’s Services is at all times subject to the </span><span className="s2">Terms of Use</span><span className="s1">, which incorporates this Copyright Dispute Policy. Any terms we use in this Policy without defining them have the definitions given to them in the </span><span className="s2">Terms of Use</span><span className="s1">.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p4"><span className="s1"><em> 1 </em></span><span className="s3"><em>Procedure for Reporting Copyright Infringements.</em></span><span className="s1"> If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to Custom Tee’s Designated Agent to Receive Notification of Claimed Infringement (our “Designated Agent,” whose contact details are listed below):</span></p>
                <p className="p5"><span className="s1"> a. A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</span></p>
                <p className="p5"><span className="s1"> b. Identification of works or materials being infringed;</span></p>
                <p className="p5"><span className="s1"> c. Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Custom Tee is capable of finding and verifying its existence;</span></p>
                <p className="p5"><span className="s1"> d. Contact information about the notifier including address, telephone number and, if available, email address;</span></p>
                <p className="p5"><span className="s1"> e. A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</span></p>
                <p className="p5"><span className="s1"> f. A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p4"><span className="s1"><em> 2 </em></span><span className="s3"><em>Once Proper Bona Fide Infringement Notification Is Received by the Designated Agent.</em></span><span className="s1"> Upon receipt of a proper notice of copyright infringement, we reserve the right to:</span></p>
                <p className="p5"><span className="s1"> a. remove or disable access to the infringing material;</span></p>
                <p className="p5"><span className="s1"> b. notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</span></p>
                <p className="p5"><span className="s1"> c. terminate such content provider's access to the Services if he or she is a repeat offender.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p4"><span className="s1"><em> 3 </em></span><span className="s3"><em>Procedure to Supply a Counter-Notice to the Designated Agent.</em></span><span className="s1"> If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner's agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:</span></p>
                <p className="p5"><span className="s1"> a. A physical or electronic signature of the content provider;</span></p>
                <p className="p5"><span className="s1"> b. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</span></p>
                <p className="p5"><span className="s1"> c. A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</span></p>
                <p className="p5"><span className="s1"> d. Content provider's name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider’s address is located, or, if the content provider's address is located outside the United States, for any judicial district in which Custom Tee is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p4"><span className="s1"> 4 If a counter-notice is received by the Designated Agent, Custom Tee may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that Custom Tee may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Custom Tee's discretion.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p2"><span className="s1"><strong>Please contact Custom Tee's Designated Agent at the following address:</strong></span></p>
                <p className="p3"><span className="s1">Custom Tee Designated DMCA Agent</span></p>
                <p className="p3"><span className="s1">Email: support@customtee.co</span></p>
                <p className="p3"><span className="s1">Address: PO BOX 1902, Burlingame CA94011</span></p>


            </Box>
            <HomeFooter />

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default Copyright;