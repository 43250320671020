export class LS {
  save(key: string, data: string) {
    localStorage.setItem(key, data);
  }
  load(key: string): string | null {
    const data = localStorage.getItem(key);
    return data
  }
  delete(key: string): void {
    localStorage.removeItem(key)
  }
  clearEditorData(){
    this.delete('front-data')
    this.delete('back-data')
  }
}
