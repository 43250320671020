import { Box } from '@fower/react';
import { Delete, Redo, Undo } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useEditorStore } from '../../store/editor/useEditorStore';
import IconButton from '../common/IconButton';
export interface HistoryBarProps {
    readyToShip: boolean
    onRedo: () => void;
    onUndo: () => void;
}
const HistoryBar: FC<HistoryBarProps> = observer(({ readyToShip, onRedo, onUndo }) => {
    const store = useEditorStore();
    const canRedo = store.actionRecorder ? store.actionRecorder.canRedo : false;
    const canUndo = store.actionRecorder ? store.actionRecorder.canUndo : false;
    const onDelete = () => {
        store.deleteSelectedElement();
    };
    if (readyToShip) {
        return <></>
    }
    return (
        <>
            <Box
                pr4
                toLeft
                toCenterY
                spaceX2
                display={['none', 'none', 'none', 'inline-flex', 'inline-flex']}
            >
                <IconButton startIcon={<Undo />} onClick={onUndo} disabled={!canUndo}>
                    Un-do
                </IconButton>
                <IconButton startIcon={<Redo />} onClick={onRedo} disabled={!canRedo}>
                    Re-do
                </IconButton>
                <IconButton
                    disabled={!store.hasSelectedObject}
                    startIcon={<Delete />}
                    onClick={onDelete}
                >
                    Delete
                </IconButton>
            </Box>
            <Box
                pr4
                toLeft
                toCenterY
                spaceX5
                display={['inline-flex', 'inline-flex', 'inline-flex', 'none', 'none']}
            >
                <Box onClick={onUndo}>
                    <Undo sx={{
                        color: canUndo ? '#4384D6' : 'rgb(212, 212, 212)'
                    }} />
                </Box>
                <Box onClick={onRedo}>
                    <Redo
                        sx={{
                            color: canRedo ? '#4384D6' : 'rgb(212, 212, 212)'
                        }}
                    />
                </Box>
                <Box onClick={onDelete}>
                    <Delete
                        sx={{
                            color: store.hasSelectedObject ? '#4384D6' : 'rgb(212, 212, 212)'
                        }} />
                </Box>
            </Box>
        </>
    );
});
export default HistoryBar;
