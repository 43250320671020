import { FC, useState } from "react"
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { Box } from "@fower/react";
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface returnData { sku: string, layerName: string }

interface PasUploaderDialogProps {
    psdOpen: boolean
    handlePsdDialogClose: () => void
    getSkuHandle: (data: returnData) => void
}

const PsdUploaderDialog: FC<PasUploaderDialogProps> = ({
    psdOpen,
    handlePsdDialogClose,
    getSkuHandle
}) => {
    const Authorization = 'Basic d4a372e3dce39582a93fce2fa545d25b6b2478c50ebb9c36bef8f56f54aa0a75'

    const [progress, setProgress] = useState(0);
    const [progressVisible, setProgressVisible] = useState(false);
    const [messageVisible, setMessageVisible] = useState(false);

    const [psdName, setPsdName] = useState('');
    const [psdUrl, setpsdUrl] = useState('');
    const [message, setMessage] = useState('');

    const [urlName, setUrlName] = useState('');


    function generateDateAndRandomNumber() {
        // 获取当前日期
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
        const day = String(date.getDate()).padStart(2, '0');


        // 格式化日期和随机数字
        const formattedDate = `${year}${month}${day}`;


        return formattedDate;
    }

    /**
     *
     */

    function handleFileChange(e: any) {

        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        axios.post('https://foxpsd.com/api/v1/upload', formData, {
            headers: { Authorization },
            onUploadProgress: ProgressEvent => {
                let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)

                setProgressVisible(true)
                setProgress(progress)
            }
        }).then(response => {

            if (response.status === 200 && response.data.code === 10000) {
                setProgressVisible(false)
                const url = response.data.data[0].url
                setpsdUrl(url)
                setUrlName(generateDateAndRandomNumber() + '/' + response.data.data[0].filename)
            }
        })
    }

    function submitHandle() {
        if (!psdName) {
            setMessageVisible(true)
            setMessage('Please enter PSD Name')
            return
        }
        if (!psdUrl) {
            setMessageVisible(true)
            setMessage('Please upload PSD file')
            return
        }

        axios.post('https://foxpsd.com/api/psd/save', {
            title: psdName,
            url: psdUrl,
            type: 1
        }, {
            headers: { Authorization }
        }).then(response => {

            if (response.status === 200 && response.data.code === 10000) {
                const sku = response.data.data.sku
                getSkuHandle({
                    sku,
                    layerName: ''
                })
                handlePsdDialogClose()
            }
        })
    }

    return (
        <>
            <BootstrapDialog
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handlePsdDialogClose()
                    }
                }
                }
                aria-labelledby="customized-dialog-title"
                open={psdOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add PSD
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handlePsdDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ width: '600px' }}>
                    <Box w="100%" mt5>
                        <Box alignSelf='end' pb2>PSD Name*</Box>
                        <TextField
                            onChange={(e) => {
                                setPsdName(e.target.value)
                            }}
                            value={psdName}
                            style={{ width: '100%' }} label="PSD Name" variant="outlined" />
                    </Box>

                    <Box w="100%" mt5>
                        <Box alignSelf='end' pb2>Select PSD file*</Box>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Select PSD file
                            <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".psd" />
                        </Button>
                        {progressVisible && <LinearProgress variant="determinate" style={{ marginTop: '10px' }} value={progress} />}
                        {urlName ? urlName : ''}
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={submitHandle}>
                        Save PSD
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <Snackbar
                open={messageVisible}
                message={message}
                onClose={() => setMessageVisible(false)}
                key={'message'}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                autoHideDuration={1200}
            />
        </>
    )
}

export default PsdUploaderDialog