/* eslint-disable react/style-prop-object */
import { SvgIcon } from '@mui/material';
import { FC } from 'react';

const HomeIcon: FC<{iconcolor:string}> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='cut_common' transform='translate(-1397.000000, -346.000000)'>
          <g
            id='icon_home_check'
            transform='translate(1397.000000, 346.000000)'
          >
            <rect id='矩形' x='0' y='0' width='24' height='24'></rect>
            <g id='Group' transform='translate(2.000000, 3.000000)'>
              <path
                d='M-1.34475764e-13,9.90089701 L8.52059524,1.29999937 C8.76578571,1.05261935 9.10507143,0.9 9.48009524,0.9 C9.85466667,0.9 10.1939524,1.05261935 10.4395952,1.29999937 L19,9.90089701'
                id='Shape'
                stroke={props.iconcolor}
              ></path>
              <path
                d='M9.27380952,-2.01767806e-12 L16.9152977,7.63415656 L16.9152977,16.218048 C16.9152977,17.2021929 16.1244141,18 15.1380535,18 L11.5915238,17.999 L11.5916093,12.6000851 C11.5916093,11.2071303 10.6729973,10.0675806 9.51145583,9.97864537 L9.36504677,9.97305809 C8.13524208,9.97305809 7.13848427,11.1490905 7.13848427,12.6000851 L7.13848427,12.6000851 L7.13752381,17.999 L3.58676803,18 C2.60522315,18 1.80952381,17.2038216 1.80952381,16.218048 L1.80952381,7.63415656 L9.27380952,-2.01767806e-12 Z'
                id='形状结合'
                fill={props.iconcolor}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default HomeIcon;
