import { FC } from 'react'
import { Box } from '@fower/react'

export type IndicatorDotProps = {
  selected: boolean
  onClick: () => void
}
const IndicatorDot: FC<IndicatorDotProps> = ({ selected, onClick }) => {
  return (
    <Box
      transitionAll
      easeInOut
      duration-400
      w={selected ? '18px' : '4px'}
      bg={selected ? '#000000' : 'rgba(0,0,0,0.3)'}
      h="4px"
      roundedFull
      cursorPointer
      onClick={onClick}
    ></Box>
  )
}
export default IndicatorDot
