/* eslint-disable react/style-prop-object */
import { SvgIcon } from '@mui/material';
import { FC } from 'react';

const AddTextIcon: FC = (props) => {
  return (
    <SvgIcon
      sx={{
        width: '24px',
        height: '20px',
      }}
      {...props}
    >
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='cut_common' transform='translate(-73.000000, -12.000000)'>
          <g id='icon_add_text' transform='translate(73.000000, 12.000000)'>
            <rect
              id='矩形'
              fill='#D8D8D8'
              opacity='0'
              x='4.54747351e-13'
              y='0'
              width='26'
              height='26'
            ></rect>
            <rect
              id='矩形'
              fill='#6E8FA0'
              x='15'
              y='1'
              width='9'
              height='9'
              rx='2'
            ></rect>
            <path
              d='M24.9901786,14.9849145 C24.3901786,14.9849145 23.9964286,14.5906805 23.9964286,13.989943 L20.9964286,13.989943 L20.9964286,21.9926249 C21.5964286,21.9926249 21.9901786,22.3895407 21.9901786,22.9875964 C21.9901786,23.5883339 21.5964286,23.9825679 20.9964286,23.9825679 L18.9901786,23.9825679 C18.3901786,23.9825679 17.9964286,23.5883339 17.9964286,22.9875964 C17.9964286,22.3868589 18.3901786,21.9926249 18.9901786,21.9926249 L18.9901786,13.989943 L15.9901786,13.989943 C15.9901786,14.5906805 15.5964286,14.9849145 14.9964286,14.9849145 C14.39375,14.9849145 14,14.5906805 14,13.989943 L14,12.9949715 C14,12.394234 14.39375,12 14.9964286,12 L24.9901786,12 C25.5928571,12 25.9866071,12.394234 25.9866071,12.9949715 L25.9866071,13.989943 C25.9866071,14.5906805 25.5928571,14.9849145 24.9901786,14.9849145 Z'
              id='路径'
              fill='#242424'
              fillRule='nonzero'
            ></path>
            <path
              d='M17.9901786,7.98491452 C17.3901786,7.98491452 16.9964286,7.59068052 16.9964286,6.98994301 L10.9964286,6.98994301 L10.9866071,21.9845793 L11.9964286,21.9926249 C12.5964286,21.9926249 12.9901786,22.3895407 12.9901786,22.9875964 C12.9901786,23.5883339 12.5964286,23.9825679 11.9964286,23.9825679 L6.99017857,23.9825679 C6.39017857,23.9825679 5.99642857,23.5883339 5.99642857,22.9875964 C5.99642857,22.3868589 6.39017857,21.9926249 6.99017857,21.9926249 L7.98660714,21.9845793 L7.98660714,6.98457928 L1.99017857,6.98994301 C1.99017857,7.59068052 1.59642857,7.98491452 0.996428571,7.98491452 C0.39375,7.98491452 -3.37507799e-14,7.59068052 -3.37507799e-14,6.98994301 L-3.37507799e-14,4.99497151 C-3.37507799e-14,4.39423399 0.39375,4 0.996428571,4 L17.9901786,4 C18.5928571,4 18.9866071,4.39423399 18.9866071,4.99497151 L18.9866071,6.98994301 C18.9866071,7.59068052 18.5928571,7.98491452 17.9901786,7.98491452 Z'
              id='路径备份'
              fill='#222222'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AddTextIcon;
