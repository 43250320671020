import { action, makeObservable, observable } from 'mobx';
import { EditorStore } from './EditorStore';
import { ActionType } from './UnifyActionRecorder';

export class ImageModule {
  @observable rx: number = 0.1;
  @observable ry: number = 0.1;
  selectedObject?: fabric.Rect;
  constructor(private readonly parent: EditorStore) {
    makeObservable(this);
  }
  saveState() {
    this.parent.actionRecorder?.saveState(
      ActionType.CANVAS_CHANGED,
      this.parent.targetFace
    );
  }
  @action
  setCornerRadius(value: number, type: 'x' | 'y') {
    if (this.selectedObject) {
      // if (type === 'x') {
      //   const w = this.selectedObject.width!
      //   this.rx = value;
      //   this.selectedObject.set('rx', value * w);
      // } else if (type === 'y') {
      //   const h = this.selectedObject.height!
      //   this.ry = value;
      //   this.selectedObject.set('ry', value * h);
      // }

      const h = this.selectedObject.height!;
      this.rx = value;
      this.ry = value;
      this.selectedObject.set('rx', value * h);
      this.selectedObject.set('ry', value * h);

      this.selectedObject.canvas?.renderAll();
    }
    this.saveState();
  }

  @action
  updateWithSelected(from: fabric.Object): void {
    this.selectedObject = from as fabric.Rect;
    this.rx = Number(
      (this.selectedObject.rx! / this.selectedObject.width!).toFixed(2)
    );
    this.ry = Number(
      (this.selectedObject.ry! / this.selectedObject.height!).toFixed(2)
    );
  }
  @action
  deactiveSelection(): void {
    this.selectedObject = undefined;
  }
}
