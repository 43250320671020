
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { SUPPORTED_FONTS } from "../../../../TypeDeclare";
import ColorPicker from "../left/ColorPicker";
import { title } from "../../store/editor/NewEditorUtil";

interface FontDialogProps {
    open: boolean
    handleClose: () => void
    addText: () => void
    font: string
    fontColor: string
    fontChange: (font: string) => void
    colorChange: (color: string) => void
}

const FontDialog: FC<FontDialogProps> = ({
    open, handleClose, addText, font, fontChange, colorChange, fontColor
}) => {
    return (
        <Dialog open={open} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                Edit Font
            </DialogTitle>
            <DialogContent>
                <div style={title}>Font</div>
                <Select
                    onChange={(e: any) => {
                        fontChange(e)
                    }}
                    sx={{
                        mt: 1,
                        mb: 2,
                        fontFamily: font,
                        width: '80%',
                    }}
                    value={font || SUPPORTED_FONTS[0]}
                >
                    {SUPPORTED_FONTS.map((f) => (
                        <MenuItem key={f} value={f} sx={{ fontFamily: f }}>
                            {f}
                        </MenuItem>
                    ))}
                </Select>

                <div style={title}>Color</div>
                <Box sx={{ display: 'flex', width: '80%', mt: 1 }}>
                    <ColorPicker
                        selectedColor={fontColor}
                        defaultValue={fontColor}
                        onChangeComplete={colorChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        addText()
                        handleClose()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default FontDialog