import { Box } from '@fower/react';
import { FC, useRef, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { HiddeFilePicker } from '../../../../TypeDeclare';
import imageCompression from 'browser-image-compression';

type ImageUploaderProps = {
    image: File | undefined;
    onSelect: (selectedImage: File) => void;
    onDelete: (selectedImage: File) => void;
};

const ImageUploader: FC<ImageUploaderProps> = ({
    image,
    onSelect,
    onDelete,
}) => {
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const filePicker = useRef<HTMLInputElement>(null);
    const LARGE_JPG: number = 20000000;
    const LARGE_PNG: number = 20000000;
    const onAddImage = () => {
        if (filePicker.current) {
            filePicker.current.value = '';
            const element = filePicker.current as HTMLElement;
            element.click();
        }
    };

    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files.length > 0) {
            let compressedFile = event.target.files[0];
            if (
                compressedFile.type === 'image/jpeg' &&
                compressedFile.size > LARGE_JPG
            ) {
                alert('The image file can not be larger than 20MB');
                return;
            }
            if (
                compressedFile.type === 'image/png' &&
                compressedFile.size > LARGE_PNG
            ) {
                alert('The image file can not be larger than 20MB');
                return;
            }
            if (compressedFile.size > LARGE_PNG / 2) {
                const options = {
                    maxSizeMB: 20,
                    maxWidthOrHeight: 3840,
                    useWebWorker: true,
                };
                compressedFile = await imageCompression(compressedFile, options);
            }
            if (compressedFile) {
                onSelect(compressedFile); // Notify the parent component about the selected image
            }
        }
    };

    const handleDelete = () => {
        image && onDelete(image);
    };
    const getImageData = () => {
        if (image === undefined) return '';
        if (typeof image === 'string') return image;
        return URL.createObjectURL(image);
    };
    return (
        <Box
            display='flex'
            flexWrap='wrap'
            h='160px'
            style={{ aspectRatio: '1' }}
            toCenter
        >
            <Box
                h='90%'
                style={{ aspectRatio: '1' }}
                relative
                onMouseEnter={() => setShowDelete(true)}
                onMouseLeave={() => setShowDelete(false)}
            >
                {!image ? (
                    <Box
                        h='100%'
                        style={{ aspectRatio: '1' }}
                        toCenter
                        absolute
                        onClick={onAddImage}
                        cursorPointer
                        bgWhite
                        borderGray300
                        border-1
                        roundedLarge
                    >
                        <AddPhotoAlternateIcon sx={{ height: '40%', width: '40%' }} />
                    </Box>
                ) : (
                    <Box
                        as='img'
                        src={getImageData()}
                        absolute
                        h='100%'
                        w='100%'
                        p2
                        borderGray300
                        border-1
                        roundedLarge
                        style={{ objectFit: 'contain' }}
                        alt='uploaded'
                        zIndex-10
                    />
                )}

                {image && showDelete && (
                    <Box
                        absolute
                        toCenter
                        zIndex-20
                        h='100%'
                        w='100%'
                        roundedLarge
                        bgGray200
                        opacity-50
                        onClick={() => {
                            handleDelete();
                            setShowDelete(false);
                        }}
                        cursorPointer
                    >
                        <DeleteForeverIcon />
                    </Box>
                )}
            </Box>
            <HiddeFilePicker
                type='file'
                ref={filePicker}
                accept='image/png, image/jpeg'
                hidden
                onChange={handleImageUpload}
            />
        </Box>
    );
};

export default ImageUploader;
