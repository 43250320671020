import { Box } from "@fower/react";
import { FC, useState } from "react";
import HomeHeader from "../home/Header";
import HomeFooter from "../home/Footer";
import { LoginMode } from "../../TypeDeclare";
import LoginModal from "../../common/LoginModal";


const ReturnPolicy: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />
            <Box
                w="80%"
            >

                <h1>Return Policy</h1>
                <p className="p3">&nbsp;</p>
                <p className="p2"><span className="s1">We want you to be completely satisfied with your purchase on Custom Tee and our family of apps. Our 5-Star Guarantee means we're committed to providing customers with the most delightful shopping experience possible. We're always available and happy to help with any questions. If you need to request a refund, please contact our Customer Support Team within 30 days of delivery or latest estimated delivery date.</span></p>
                <p className="p3">&nbsp;</p>
                <p className="p4"><strong><span className="s1">How does the return policy work?</span></strong></p>
                <p className="p5"><span className="s1">To start a return, simply email us at support@customtee.co</span></p>
                <p className="p6"><span className="s1"> ◦ Customers may request a refund on any order within 30 days of delivery or latest estimated delivery date.</span></p>
                <p className="p6"><span className="s1"> ◦ Our customer service team strives to respond to all requests within 72 hours or less. We're always available to help!</span></p>
                <p className="p6"><span className="s1"> ◦ Once an order has been placed, if you change your mind, you may cancel your order by emailing us at support@customtee.co before it's been shipped.</span></p>
                <p className="p6"><span className="s1"> ◦ After an item has shipped, if you’re unsatisfied for any imperfect condition, you can always contact us for further assistance.</span></p>
                <p className="p7">&nbsp;</p>
                <p className="p4"><strong><span className="s1">How will I receive my refund?</span></strong></p>
                <p className="p6"><span className="s1"> ◦ Refunds are processed back to the original payment method used to purchase the order.</span></p>
                <p className="p6"><span className="s1"> ◦ Typically, you can expect your payment provider to credit you for your refund within 14 business days. If you are having trouble locating your refund, we recommend contacting your payment provider for further assistance.</span></p>
                <p className="p6"><span className="s1"> ◦ All Custom Tee gift cards are valid as a one-time use only. Unfortunately, we cannot credit Custom Tee gift cards back to your account once they’ve been used.</span></p>
                <p className="p6"><span className="s1"> ◦ Custom Tee does not provide reimbursement for costs related to return shipping, customs fees, taxes, postal fees or VAT related costs.</span></p>
                <p className="p8">&nbsp;</p>
                <p className="p4"><strong><span className="s1">How are my promotions applied?</span></strong></p>
                <p className="p6"><span className="s1"> ◦ Custom Tee gift cards cannot be applied to shipping costs. Gift cards expire at midnight on the expiration date.</span></p>
                <p className="p6"><span className="s1"> ◦ Promotions are limited time offers and valid based on the terms of the offer. Promotions are not reissued once they have expired.</span></p>


            </Box>
            <HomeFooter />

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default ReturnPolicy;