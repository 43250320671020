import Cookies from 'js-cookie';

export const getURLParam = (key: string): string => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params[key] !== undefined) {
    return String(params[key]);
  }
  return '';
};

export const getURLParams = (): any => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export function makeid(length: number) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const imageDataToHexArray = (
  w: number,
  h: number,
  imageData: ImageData
): string[] => {
  const arr = [];
  for (let x = 0; x < w; x++) {
    for (let y = 0; y < h; y) {
      const index = (x + y * imageData.width) * 4;
      const r = imageData.data[index];
      const g = imageData.data[index + 1];
      const b = imageData.data[index + 2];
      const hex = rgbToHex(r, g, b);
      arr.push(hex);
    }
  }
  return arr;
};
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const getCTKey = () => {
  const currenctKey = Cookies.get('ct_campaign');
  return currenctKey;
};

export const resetCTKey = () => {
  const k = makeid(32);
  Cookies.set('ct_campaign', k);
};

export const initCTKey = () => {
  if (getCTKey() === undefined) {
    resetCTKey();
  }
};

export const cap0 = (v: string) => {
  if (v.length === 0) return v;
  return `${v.charAt(0).toUpperCase()}${v.substring(1)}`;
};

export const bankCardNumber = (
  v: string,
  joinWith: string = ' ',
  hiddenArea: boolean = true
): string => {
  if (v.length === 0) return 'invalid card number';
  const arr: string[] = [];
  if(hiddenArea){
    const lastTo = v.length - 4;
    const to = lastTo / 4;
    
    for (let index = 0; index < to; index++) {
      const d = (lastTo - index * 4)
      if(d < 4){
        arr.push('****'.substring(0, d));
      }else{
        arr.push('****')
      }
    } 
    arr.push(v.substring(lastTo))
    return arr.join(joinWith);
  }
  const to = v.length / 4;
  for (let index = 0; index < to; index++) {
    arr.push(v.substring(index * 4, (index + 1) * 4));
  }
  return arr.join(joinWith);
};

export const fill2 = (str:string) => {
  if(str.length === 1){
    return `0${str}`
  }
  return str
} 