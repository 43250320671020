import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { Backdrop, CircularProgress, Divider, Drawer } from '@mui/material'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import { useDashbardStore } from '../store/useDashboardStore'
import { useAuthStore } from '../../../store/useAuthStore'
import {
    DBShopifyOrderVO,
    ShopifyFulfillmentDetailVO,
} from '../../../TypeDeclare'
import ShopifyOrderDetailItem from './ShopifyOrderDetailItem'

export type ShopifyOrderDetailModalProps = {
    isOpen: boolean
    onClose: () => void
    shopifyToken?: string
    orderId?: string
    orderData: DBShopifyOrderVO | undefined
}
const ShopifyOrderDetailModal: FC<ShopifyOrderDetailModalProps> = ({
    isOpen,
    onClose,
    shopifyToken,
    orderId,
    orderData,
}) => {
    const dashbardStore = useDashbardStore()
    const authStore = useAuthStore()
    const [details, setDetails] = useState<Array<ShopifyFulfillmentDetailVO>>([])
    const [open, setOpen] = useState(false)
    const getProductCost = () => {
        const result = details.reduce((prev, currentItem) => {
            return Number(currentItem.cost_price * currentItem.quantity) + prev
        }, 0)
        return result
    }
    const getShipping = () => {
        const result = details.reduce((prev, currentItem) => {
            return Number(currentItem.shipping) + prev
        }, 0)
        return result
    }
    const getTotalCosts = () => {
        return getProductCost() + getShipping()
    }
    useEffect(() => {
        if (!isOpen) return
        if (shopifyToken && orderId && orderData)
            setOpen(true)
        dashbardStore
            .getShopifyOrderDetail(authStore.token, shopifyToken as string, orderId as string)
            .then((list) => {
                setDetails(list)
                setOpen(false)
            })
    }, [authStore.token, dashbardStore, orderId, shopifyToken, isOpen, orderData])

    if (!shopifyToken || !orderId) {
        return <></>
    }
    const doClose = () => {
        setDetails([])
        onClose()
    }

    const styles = {
        key: {
            fontFamily: 'Segoe UI, Segoe UI',
            color: '#000000',
            fontWeight: '600'
        },
        val: {
            fontFamily: 'Segoe UI, Segoe UI',
            color: '#7D7D7D',
            fontWeight: '600'
        }
    }

    return (
        <Drawer anchor="right" open={isOpen} onClose={doClose}>
            <Box w="100vw" w--sm="840px" bgWhite row toTop relative>
                <Box absolute right2 top2 zIndex="1000" cursorPointer onClick={doClose}>
                    <CloseTwoToneIcon fontSize="large" />
                </Box>
                <Box column w-100p pr2 pl2 pt12 spaceY2>
                    {details.map((detail) => {
                        return (
                            <ShopifyOrderDetailItem
                                data={detail}
                                isOrder={true}
                                key={detail.product_sku}
                                order={orderData!}
                            />
                        )
                    })}
                    {
                        details.length > 0 &&
                        (
                            <Box column w-100p pl4 pr4 spaceY3>
                                <Box row toBetween>
                                    <Box style={styles.key}>Product Costs:</Box>
                                    <Box style={styles.val}>${getProductCost()}</Box>
                                </Box>
                                <Box row toBetween>
                                    <Box style={styles.key}>Shipping:</Box>
                                    <Box style={styles.val}>${getShipping()}</Box>
                                </Box>

                                <Box row toBetween>
                                    <Box style={styles.key}>Total costs:</Box>
                                    <Box style={styles.val}>${getTotalCosts()}</Box>
                                </Box>
                            </Box>
                        )
                    }

                </Box>
            </Box>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Drawer>
    )
}
export default ShopifyOrderDetailModal
