import { Box } from '@fower/react'
import { FC, useEffect, useRef, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { HiddeFilePicker } from '../../../../TypeDeclare'
import { compressImage } from '../../../../common/ImageUtils'

type ImageReEditProps = {
  data: string | File | undefined
  onDelete: (data: string | File) => void
  onFilesSelected: (files: File[]) => void
  multiple: boolean
}

const ImageReEdit: FC<ImageReEditProps> = ({
  data,
  onDelete,
  onFilesSelected,
  multiple
}) => {
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const filePicker = useRef<HTMLInputElement>(null)
  const onAddImage = () => {
    if (filePicker.current) {
      filePicker.current.value = ''
      const element = filePicker.current as HTMLElement
      element.click()
    }
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const tasks: Array<Promise<File>> = []
      for (let index = 0; index < event.target.files.length; index++) {
        const f = event.target.files[index]
        tasks.push(compressImage(f))
      }
      const files = await Promise.all(tasks)
      onFilesSelected(files)
    }
  }

  const handleDelete = () => {
    data && onDelete(data)
  }
  const getImageData = () => {
    if (typeof data === 'string') {
      return data
    }
    if (typeof data === 'object') {
      return URL.createObjectURL(data)
    }
    return undefined
  }
  useEffect(() => {
    return
  }, [])
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      h="120px"
      style={{ aspectRatio: '1' }}
      toCenter
    >
      <Box
        h="90%"
        style={{ aspectRatio: '1' }}
        relative
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        {!data ? (
          <Box
            h="100%"
            style={{ aspectRatio: '1' }}
            toCenter
            absolute
            onClick={onAddImage}
            cursorPointer
            bgWhite
            borderGray300
            border-1
            roundedLarge
          >
            <AddPhotoAlternateIcon sx={{ height: '40%', width: '40%' }} />
          </Box>
        ) : (
          <Box
            as="img"
            src={getImageData()}
            absolute
            h="100%"
            w="100%"
            borderGray300
            border-1
            roundedLarge
            style={{ objectFit: 'cover' }}
            alt="uploaded"
            zIndex-10
          />
        )}

        {data && showDelete && (
          <Box
            absolute
            toCenter
            zIndex-20
            h="100%"
            w="100%"
            roundedLarge
            bgGray200
            opacity-50
            onClick={() => {
              handleDelete()
              setShowDelete(false)
            }}
            cursorPointer
          >
            <DeleteForeverIcon />
          </Box>
        )}
      </Box>
      <HiddeFilePicker
        type="file"
        ref={filePicker}
        accept="image/png, image/jpeg"
        hidden
        multiple={multiple}
        onChange={handleImageUpload}
      />
    </Box>
  )
}

export default ImageReEdit
