import { FC } from 'react'
import { Box } from '@fower/react'
import { ColorOption } from '../../../../TypeDeclare'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@fower/styled'
import tinycolor from 'tinycolor2'

export type ColorPadProps = {
  colorOptions: Array<ColorOption>
  loading: boolean
  selectedColors: Array<ColorOption>
  onColorsUpdated: (colors: Array<ColorOption>) => void
  onRemoveColor: (color: ColorOption) => void
}
const StyledLI = styled('li')
const ColorPad: FC<ColorPadProps> = ({
  colorOptions,
  loading,
  selectedColors,
  onColorsUpdated,
  onRemoveColor,
}) => {
  return (
    <Box column spaceY2 p5 w="100%" overflowYScroll overflowYAuto>
      <Box alignSelf='end'>{selectedColors.length} colors picked</Box>
      <Autocomplete
        multiple
        options={colorOptions}
        getOptionLabel={(option) => option.name}
        defaultValue={selectedColors}
        value={selectedColors}
        loading={loading}
        size="small"
        renderInput={(params) => <TextField {...params} label="Pick Colors" />}
        renderTags={(value: Array<ColorOption>) => {
          
          return value.map((option, index) => {
            const brightness = tinycolor(option.value).getBrightness()
            const textColor = brightness > 160 ? '#000' : '#fff'
            return (
              <Box
                border-1
                borderGray300
                key={index}
                p2
                roundedHuge
                bg={option.value}
                color={textColor}
                textSM
                row
                flex
                toCenter
                spaceX1
                m1
              >
                <Box>{option.name}</Box>
                <IconButton
                  onClick={() => {
                    onRemoveColor(option)
                  }}
                  size="small"
                >
                  <Close color="info" fontSize="small" />
                </IconButton>
              </Box>
            )
          })
        }}
        renderOption={(props, option) => {
          const brightness = tinycolor(option.value).getBrightness()
          return (
            <StyledLI {...props} key={option.id}>
              <Box
                rounded
                p2
                w="100%"
                h="100%"
                bg={option.value}
                color={brightness > 160 ? '#000' : '#fff'}
                border
                borderGray200
              >
                {option.name}
              </Box>
            </StyledLI>
          )
        }}
        onChange={(_, value) => {
          onColorsUpdated(value)
        }}
      />
    </Box>
  )
}
export default ColorPad
