import { FC, useState } from 'react'
import { Box } from '@fower/react'
import GuideDoc from './GuideDoc'
import { StoreType } from '../../../TypeDeclare'
import StoreConnectionCard from './StoreConnectionCard'

export type PlatformsProps = {}
const Platforms: FC<PlatformsProps> = () => {
  const [selectedStore, setSelectedStore] = useState<StoreType>(undefined)
  return (
    <Box>
      {selectedStore !== undefined && (
        <GuideDoc
          type={selectedStore}
          onBack={() => {
            setSelectedStore(undefined)
          }}
        />
      )}
      {selectedStore === undefined && (
        <Box w-100p p10 column spaceY10>
          <Box fontBold text2XL>
            Connect new store, choose a platform
          </Box>
          <StoreConnectionCard
            type="shopify"
            onClick={(type) => {
              setSelectedStore(type)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
export default Platforms
