import { FC, useState } from 'react'
import { Box } from '@fower/react'
import {
    useStripe, useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js'
import { Alert, Button, Dialog } from '@mui/material'
import { useDashbardStore } from '../store/useDashboardStore'
import { StyledSnackbar } from '../../../TypeDeclare'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

export type AddPaymentInfoProps = {
    isShow: boolean
    onComplete: (updated: boolean) => void
}
const AddPaymentInfo: FC<AddPaymentInfoProps> = ({ isShow, onComplete }) => {
    const dashboardStore = useDashbardStore()
    const stripe = useStripe()
    const elements = useElements()
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [inProgress, setInProgress] = useState<boolean>(false)
    const handleMessageClose = () => {
        setShowMessageBar(false)
    }

    const handleSubmit = async () => {

        if (!stripe || !elements) return

        const card1 = elements.getElement(CardNumberElement)

        if (card1 === null) return
        setInProgress(true)
        const { error, token } = await stripe.createToken(card1, {})

        if (error) {
            console.log('Error:', error)
            setErrorMessage(error.message!)
            setShowMessageBar(true)
            setInProgress(false)
        } else {
            const result = await dashboardStore.saveStripeToken(token.id, token.card!)
            setInProgress(false)
            if (result) {
                onComplete(true)
            } else {
                setErrorMessage('Can not save card info!')
                setShowMessageBar(true)
            }
        }
    }

    const elementOptions = {
        style: {
            empty: {
                backgroundColor: 'none'
            },
            base: {
                width: '100%',
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Segoe UI, Segoe UI',
                '::placeholder': {
                    color: '#9b9b9b',
                },
                padding: '20px', // Adjust padding to control spacing inside the input
                border: '1px solid #000', // Add border around the input
                borderRadius: '4px', // Rounded corners

            },
            invalid: {
                color: '#e5424d', // Change color when input is invalid
                iconColor: '#e5424d',
            },
        },
    };

    return (
        <Dialog
            open={isShow}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '18px'
                }
            }}
        >
            <Box
                w="100%"
                w--md="600px"
                relative
                column
                toCenter
            >
                <CloseIcon
                    onClick={() => {
                        onComplete(false)
                    }}
                    style={{
                        position: 'absolute',
                        right: '30px',
                        top: '30px',
                        cursor: 'pointer',
                        color: '#000000',
                        fontSize: '24px',
                        zIndex: '100'
                    }}
                />
                <Box
                    w="100%"
                    pl10
                    pr10
                    css={{
                        fontFamily: 'Segoe UI, Segoe UI',
                        fontWeight: '600',
                        color: '#000000',
                        fontSize: '24px',
                        lineHeight: '28px',
                        paddingTop: '24px'
                    }}
                >
                    Add a credit/debit card to your account
                </Box>
                <Box
                    w="100%"
                    h="100%"
                    column
                    p10
                >
                    <Box
                        w="100%"
                        h="100%"
                        flex
                        column
                        toBetween
                    >

                        <Box
                            w="100%"
                        >
                            <label>Card Number*</label>
                            <Box
                                css={{
                                    padding: '10px 4px',
                                    borderRadius: '8px',
                                    height: '40px',
                                    marginTop: '10px'
                                }}
                                relative
                                border="1px solid #C4C4C4"
                            >
                                <img
                                    src="/icons/icon-card.webp"
                                    alt="icon_card"
                                    style={{
                                        width: '30px',
                                        height: '23px',
                                        position: 'absolute',
                                        left: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                />
                                <Box
                                    style={{
                                        marginLeft: '50px'
                                    }}
                                >
                                    <CardNumberElement
                                        options={elementOptions}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            flex
                            flexWrap="wrap"
                            justifyContent="space-between"
                        >
                            <Box
                                w={['100%', '100%', '100%', '49%', '49%']}
                                css={{
                                    marginTop: '30px'
                                }}
                            >
                                <label>Expiry Date*</label>
                                <Box
                                    css={{
                                        padding: '10px 4px',
                                        borderRadius: '8px',
                                        height: '40px',
                                        marginTop: '10px'
                                    }}
                                    border="1px solid #C4C4C4"
                                >
                                    <CardExpiryElement
                                        options={elementOptions}
                                    />
                                </Box>
                            </Box>
                            <Box
                                w={['100%', '100%', '100%', '49%', '49%']}
                                css={{
                                    marginTop: '30px'
                                }}
                            >
                                <label>Security Code (CVC)*</label>
                                <Box
                                    css={{
                                        padding: '10px 4px',
                                        borderRadius: '8px',
                                        height: '40px',
                                        marginTop: '10px'
                                    }}
                                    border="1px solid #C4C4C4"
                                    relative
                                >
                                    <CardCvcElement
                                        options={elementOptions}
                                    />

                                    <Tooltip
                                        title="3-digit code on the back of the card"
                                        placement="top"
                                    >
                                        <HelpOutlineIcon
                                            style={{
                                                position: 'absolute',
                                                right: '5px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                    </Tooltip>

                                </Box>
                            </Box>
                        </Box>

                        <Box
                            css={{
                                marginTop: '30px'
                            }}
                            row w="100%"
                            flex
                            flexWrap="wrap"
                            justifyContent="space-between"
                        >
                            <Button
                                variant="contained"
                                style={{
                                    background: '#8D8D8D',
                                    color: '#FFFFFF',
                                    borderRadius: '20px',
                                    width: '48%'
                                }}
                                onClick={() => {
                                    onComplete(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{
                                    borderRadius: '20px',
                                    width: '48%'
                                }}
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={inProgress}
                            >
                                {inProgress ? 'Adding...' : 'Add'}
                            </Button>
                        </Box>

                        <Box
                            flex
                            toCenter
                            css={{
                                marginTop: '30px'
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Segoe UI, Segoe UI',
                                    fontSize: '20px',
                                    color: '#686868',
                                    display: 'inline-block', // 保持在同一行
                                    whiteSpace: 'nowrap', // 禁止换行
                                }}
                            >
                                Secure Payment with
                            </span>
                            <img
                                style={{
                                    marginLeft: '10px'
                                }}
                                src="/images/stripe.webp"
                                alt="stripe"
                            />
                        </Box>

                    </Box>

                </Box>
            </Box>
            <StyledSnackbar
                autoHideDuration={3000}
                open={showMessageBar}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </StyledSnackbar>
        </Dialog >
    )
}
export default AddPaymentInfo
