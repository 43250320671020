import { Box } from "@fower/react";
import { FC, useEffect, useState } from "react";
import HomeHeader from "../../home/Header";
import { LoginMode } from "../../../TypeDeclare";
import styles from '../corporate/index.module.css'
import { Button } from "@mui/material";
import HomeFooter from "../../home/Footer";
import LoginModal from "../../../common/LoginModal";
import FeaturedList from "../../home/FeaturedList";
import { useNavigate } from "react-router-dom";
import { parseFeaturedProduct } from "../../product_list/services/productService";
import { useAuthStore } from "../../../store/useAuthStore";

const Personal: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const navigator = useNavigate()
    const authStore = useAuthStore()

    function navigateToUrl() {
        if (!authStore.isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator('/dashboard?section=Account')
        }
    }

    function extractProductId(url: string, defaultName: string) {
        if (url) {
            if (!url.includes('/')) {
                // 如果输入的字符串不包含斜杠，直接返回该字符串
                return url;
            }
            // 匹配最后一个斜杠后面的内容
            const regex = /\/([^/]+)\/?$/;
            const match = url.match(regex);
            return match ? match[1] : null;
        }
        return defaultName

    }

    const feature = {
        name: 'These products might be suitable for you',
        desc: '',
        "products": [
            {
                "id": 52325,
                "product_id": "GBSHE64E42",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "16.60",
                "cost_price": "16.60",
                "pair_price": "7.65",
                "original_price": "17.21",
                "name": "Laptop Sleeve",
                "short_description": "Introducing our Fleece-Lined Laptop Sleeve, where every detail is meticulously crafted. Designed specifically for laptop storage, this sleeve combines exceptional functionality with portability. Its sleek appearance not only enhances your style but also reflects your sophisticated taste.\r\n\r\nMade from high-quality, scratch-resistant fabric, it resists fading and wrinkles while providing excellent sweat absorption. Durable and soft to the touch, this laptop sleeve ensures your device stays protected without compromising on comfort. Experience the perfect blend of elegance and practicality with our thoughtfully designed laptop sleeve, tailored for those who appreciate quality and style.",
                "description": "Introducing our Fleece-Lined Laptop Sleeve, where every detail is meticulously crafted. Designed specifically for laptop storage, this sleeve combines exceptional functionality with portability. Its sleek appearance not only enhances your style but also reflects your sophisticated taste.\r\n\r\nMade from high-quality, scratch-resistant fabric, it resists fading and wrinkles while providing excellent sweat absorption. Durable and soft to the touch, this laptop sleeve ensures your device stays protected without compromising on comfort. Experience the perfect blend of elegance and practicality with our thoughtfully designed laptop sleeve, tailored for those who appreciate quality and style.",
                "target_count": 2,
                "category_info": {
                    "id": 30,
                    "name": "Home"
                },
                "category": 30,
                "salesend_time": null,
                "created_time": "Oct 25, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBSHE64E42/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "One size - One size",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_B5vVudEwyzPI.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_ga1DSMzAB7DF.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_B5vVudEwyzPI.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9CNXZWdWRFd3l6UEkucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -8.95
            },
            {
                "id": 52211,
                "product_id": "GBPFRCZZ18",
                "seo_url": "https://www.customtee.co/products/productId/Premium-Unisex-Beach-Shorts-Short Style-Summer-unisex-shorts",
                "seo_title": "Premium Unisex Beach Shorts Short Style-sport shorts​-Outdoor unisex shorts",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "20.95",
                "cost_price": "20.95",
                "pair_price": "8.95",
                "original_price": "20.14",
                "name": "Premium Unisex Beach Shorts Short Style",
                "short_description": "Production Technique: Heat transfer printing\r\nProduction Material: Polyester\r\nWeight: 250g\r\n· The fabric is soft, comfortable, quick-drying, durable, and resistant to pilling\r\n· Elastic waistband with adjustable drawstring for a customizable fit\r\n· Soft lining reduces friction and enhances comfort\r\n· Two side pockets for storing wallet, keys, phone, or other small items\r\n· Loose fit for a relaxed and comfortable wearing experience, enhancing your lifestyle. Suitable for beach outings, countryside vacations, surfing, and pool activities\r\n· It is recommended to hand wash or machine wash, avoiding prolonged soaking and bleaching. Do not exceed a detergent temperature of 45ºC.\r\n· The provided measurements are obtained by laying the shorts flat, and a deviation of 1-3cm is considered normal due to different measurement methods.",
                "description": "Production Technique: Heat transfer printing\r\nProduction Material: Polyester\r\nWeight: 250g\r\n· The fabric is soft, comfortable, quick-drying, durable, and resistant to pilling\r\n· Elastic waistband with adjustable drawstring for a customizable fit\r\n· Soft lining reduces friction and enhances comfort\r\n· Two side pockets for storing wallet, keys, phone, or other small items\r\n· Loose fit for a relaxed and comfortable wearing experience, enhancing your lifestyle. Suitable for beach outings, countryside vacations, surfing, and pool activities\r\n· It is recommended to hand wash or machine wash, avoiding prolonged soaking and bleaching. Do not exceed a detergent temperature of 45ºC.\r\n· The provided measurements are obtained by laying the shorts flat, and a deviation of 1-3cm is considered normal due to different measurement methods.",
                "target_count": 2,
                "category_info": {
                    "id": 96,
                    "name": "Shorts"
                },
                "category": 96,
                "salesend_time": null,
                "created_time": "Oct 15, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBPFRCZZ18/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "S - 3XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_ouuaBxvNmSIi.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_mmgZhC55xDXT.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_eMm9hZ9rJYgq.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9lTW05aFo5ckpZZ3EucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -12.0
            },
            {
                "id": 52308,
                "product_id": "GBHWSL93DL",
                "seo_url": "https://www.customtee.co/products/productId/productId",
                "seo_title": "",
                "is_customizable": 1,
                "is_fullprint": 1,
                "default_price": "24.95",
                "cost_price": "24.95",
                "pair_price": "12.95",
                "original_price": "29.14",
                "name": "All-Over Print Hawaiian Shirt",
                "short_description": "Made to have a boxy fit and a notch lapel collar that are perfect for any laidback scenario, these shirts come with a handy chest pocket and a 95% polyester and 5% spandex fabric for silky comfort.",
                "description": "Made to have a boxy fit and a notch lapel collar that are perfect for any laidback scenario, these shirts come with a handy chest pocket and a 95% polyester and 5% spandex fabric for silky comfort.",
                "target_count": 2,
                "category_info": {
                    "id": 95,
                    "name": "Shirts"
                },
                "category": 95,
                "salesend_time": null,
                "created_time": "Oct 24, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBHWSL93DL/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "XXS - 5XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_PWD0VECiQY4H.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_GeQrZX0fwviT.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_PWD0VECiQY4H.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9QV0QwVkVDaVFZNEgucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -12.0
            },
            {
                "id": 52221,
                "product_id": "GBYGJ9DQMJ",
                "seo_url": "https://www.customtee.co/products/productId/Heavyweight-Women's-Crewneck-Sweatshirt-Women's-graphic-long-sleeve-t-shirts",
                "seo_title": "Heavyweight Women's Crewneck Sweatshirt-Trendy women's long sleeve tops",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "28.90",
                "cost_price": "28.90",
                "pair_price": "9.95",
                "original_price": "22.39",
                "name": "Heavyweight Women's Crewneck Sweatshirt",
                "short_description": "Crafted from premium-quality fabric, this heavyweight women's crewneck sweatshirt is soft and gentle on the skin, providing exceptional comfort. The fine workmanship and precise stitching ensure durability and a polished finish. Its stylish and versatile design makes it easy to pair with a variety of outfits, perfect for any occasion.",
                "description": "Crafted from premium-quality fabric, this heavyweight women's crewneck sweatshirt is soft and gentle on the skin, providing exceptional comfort. The fine workmanship and precise stitching ensure durability and a polished finish. Its stylish and versatile design makes it easy to pair with a variety of outfits, perfect for any occasion.",
                "target_count": 2,
                "category_info": {
                    "id": 22,
                    "name": "Tops"
                },
                "category": 22,
                "salesend_time": null,
                "created_time": "Oct 17, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBYGJ9DQMJ/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "S - 5XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_GZ1nAn1g0dP5.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_Sp74qagqfAu3.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_lNksEuKWweWW.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_GZ1nAn1g0dP5.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9HWjFuQW4xZzBkUDUucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
            {
                "id": 52130,
                "product_id": "GBAJZEYRO6",
                "seo_url": "https://www.customtee.co/products/productId/Best-Men's-T-Shirts-for-Summer-2024-280GSM-Heavyweight-T-shirts",
                "seo_title": "Best Men's T-Shirts for Summer 2024 | Custom & 100% Cotton Options",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "27.65",
                "cost_price": "27.65",
                "pair_price": "15.65",
                "original_price": "35.21",
                "name": "280GSM Heavyweight T-shirts",
                "short_description": "280g heavyweight T-shirt, made from 100% pure cotton for superior comfort and durability. Available in a variety of rich colors, this tee boasts an excellent texture and feel. Its structured design ensures a flattering fit with a crisp, elevated look. Whether dressed up or down, this versatile T-shirt complements any style with ease, making it a must-have for every wardrobe. Perfect for those who appreciate quality, comfort, and timeless style in their everyday wear.",
                "description": "280g heavyweight T-shirt, made from 100% pure cotton for superior comfort and durability. Available in a variety of rich colors, this tee boasts an excellent texture and feel. Its structured design ensures a flattering fit with a crisp, elevated look. Whether dressed up or down, this versatile T-shirt complements any style with ease, making it a must-have for every wardrobe. Perfect for those who appreciate quality, comfort, and timeless style in their everyday wear.",
                "target_count": 2,
                "category_info": {
                    "id": 38,
                    "name": "Tee"
                },
                "category": 38,
                "salesend_time": null,
                "created_time": "Oct 10, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBAJZEYRO6/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "XXS - 2XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_XTXbhd3Z88ar.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_Ptgvperx7EhW.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_1Vuhh39fcAnm.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_Nn82dl9Ph13t.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9ObjgyZGw5UGgxM3QucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -12.0
            },
            {
                "id": 52019,
                "product_id": "GBLVPAN9XU",
                "seo_url": "https://www.customtee.co/products/productId/ /unisex-fleece-hoodie-cotton-polyester-blend",
                "seo_title": "Unisex Fleece Hoodie Soft and Warm Cotton Polyester Blend Durable Fit",
                "is_customizable": 1,
                "is_fullprint": 0,
                "default_price": "35.40",
                "cost_price": "35.40",
                "pair_price": "16.45",
                "original_price": "37.01",
                "name": "Unisex 250g Premium Fleece Hoodie",
                "short_description": "Premium unisex fleece hoodie with a front pouch pocket and matching flat drawstrings. It’s soft, stylish, and perfect for the cooler evenings. 50%cotton, 50% polyester",
                "description": "Premium unisex fleece hoodie with a front pouch pocket and matching flat drawstrings. It’s soft, stylish, and perfect for the cooler evenings. 50%cotton, 50% polyester",
                "target_count": 2,
                "category_info": {
                    "id": 78,
                    "name": "Hoodies"
                },
                "category": 78,
                "salesend_time": null,
                "created_time": "Sep 30, 2024",
                "product_link": "https://www.pairdrop.com/campaigns/GBLVPAN9XU/",
                "cpc": null,
                "shipping_fee": null,
                "size_range": "S - 5XL",
                "days_in_production": 2,
                "commission_rate": null,
                "status": 1,
                "ready_to_ship": 0,
                "total_comment": 0,
                "total_likes": 0,
                "up_votes": 0,
                "down_votes": 0,
                "avg_score": 0.0,
                "total_orders": 0,
                "order_text": "",
                "cover_images": [
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_i6Krq92SvbDl.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_fcuuvjM1fByw.png",
                    "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_uynt6QjYbh68.png"
                ],
                "size_images": {},
                "type": 2,
                "image_urls": [
                    {
                        "id": 0,
                        "image": "https://pairdrop-general.s3.amazonaws.com/media/customTee/customtee_i6Krq92SvbDl.png",
                        "thumbnail": "https://d16gq9ymkfpxod.cloudfront.net/eyJidWNrZXQiOiAicGFpcmRyb3AtZ2VuZXJhbCIsICJrZXkiOiAibWVkaWEvY3VzdG9tVGVlL2N1c3RvbXRlZV9pNktycTkyU3ZiRGwucG5nIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNjQwLCAiaGVpZ2h0IjogNjQwLCAiZml0IjogImluc2lkZSJ9fX0=",
                        "comment": ""
                    }
                ],
                "is_onsale": false,
                "profit": -18.95
            },
        ]
    }

    const [data, setData] = useState<any>(undefined)


    // ======================


    useEffect(() => {
        const ps = feature.products
        const targetProducts = ps.map((p: any) => {
            return parseFeaturedProduct(p);
        });
        setData({
            name: 'These products might be suitable for you',
            desc: '',
            products: targetProducts
        })

    }, [])
    // ======================

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => {
                }}
            />

            <Box
                w='100%'
                // h='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#DAF6F8',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                // toCenter
                >
                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >
                        <h1
                            className={styles.title}
                            style={{ minHeight: '100px' }}
                        >
                            Personalize Your Life
                        </h1>
                        <p
                            className={styles.description}
                        >
                            Turn Your Ideas or Thoughts into Reality, or Commemorate What You Want to Remember.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigateToUrl()
                            }}
                        >
                            Get started
                        </Button>
                    </Box>


                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/explore-personal-banner.webp"
                            alt="explore_personal_banner"
                            width={'100%'}
                            loading="lazy"
                        />
                    </Box>
                </Box>


            </Box>

            <Box
                flex
                mt14
            >
                <Box
                    display--lg="block"
                    hidden
                >
                    <img
                        src="/images/explore-woman.webp"
                        alt="explore_man"
                        className={styles.exploreMan}
                    />
                </Box>

                <Box
                    w={['100%', '100%', '100%', '850px', '850px']}
                    h={['auto', 'auto', 'auto', "480px", "480px"]}
                    boxSizing="border-box"
                    css={{
                        background: '#DAF6F8',
                        borderRadius: '18px',
                        padding: '30px 70px',
                    }}
                >

                    <h2 className={styles.exploreTitle}>
                        Gift
                    </h2>
                    <p className={styles.exploreText}>
                        Perhaps You Want to Gift Your Unique Ideas to Friends or Commemorate Something Special;
                        On-Demand Printing Will Be Your Perfect Choice.
                    </p>
                    <p className={styles.exploreText}>
                        On-Demand Printing Ensures That Each Item Is Unique and Perfectly Reflects Your Commemoration of
                        Special Moments.
                    </p>
                </Box>
            </Box>

            <Box w="80%" css={{ marginTop: '60px' }}>
                {
                    data && <FeaturedList
                        // @ts-ignore
                        feature={data}
                        onSelect={(item) => {

                            const { seo_url, id, name } = item
                            const cpName = name.toLocaleLowerCase().split(' ').join('-')

                            const newName = extractProductId(seo_url!, cpName)

                            navigator(`/products/${id}/${newName}`)
                        }}
                    />
                }
            </Box>

            <Button
                variant="outlined"
                style={{ margin: '0 0 50px 0' }}
                onClick={() => {
                    navigator('/catalog')
                }}
            >
                View more
            </Button>


            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default Personal;