import { Box } from '@fower/react'
import { FC, useState } from 'react'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Alert, Link, Snackbar } from '@mui/material'
export type CopyBarProps = {
  label: string
  text?: string
  isURL?: boolean
}
const CopyBar: FC<CopyBarProps> = ({ text, label, isURL = false }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const handleClose = () => {
    setShowMessage(false)
  }
  return (
    <Box row spaceX2 p0>
      <Box fontBold textSM>
        {label}:
      </Box>
      {!isURL ? (
        <Box black textSM>
          {text}
        </Box>
      ) : (
        <Link
          href={text}
          target='_blank'
        >
          {text}
        </Link>
      )}

      <Box
        onClick={() => {
          navigator.clipboard.writeText(text || '').then(() => {
            setShowMessage(true)
          })
        }}
      >
        <CopyAllIcon fontSize="small" color="primary" />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showMessage}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
    </Box>
  )
}
export default CopyBar
