import { Box } from '@fower/react'
import {
    Grid,
    ImageList,
    ImageListItem,
    Skeleton,
    Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styles from './ProductList.module.css'
// import StraightenIcon from '@mui/icons-material/Straighten'
import { ProductItemVO } from '../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import { styled } from '@fower/styled'
type ItemViewProps = {
    data: ProductItemVO
    onClick: (item: ProductItemVO) => void
}
const StyledImage = styled('img')
const ItemView: FC<ItemViewProps> = ({ data, onClick }) => {
    const { name, images, sizeRange, pairPrize, descShorten, coverImages, desc } = data

    const imagesToShow =
        coverImages.length >= 2 ? coverImages : images.map((img) => img.url)
    if (imagesToShow.length < 2) {
        imagesToShow.push(images[0].url!)
    }
    const [show2nd, setShow2nd] = useState<boolean>(false)
    return (
        <Box
            minW="280px"
            h="480px"

            column
            overflowHidden

            relative
            cursorPointer
            onClick={() => {
                onClick(data)
            }}
        >
            <Box w="100%" h="296px" relative top0 left0 overflowHidden>
                <StyledImage
                    loading='lazy'
                    src={imagesToShow[0]}
                    alt={name}
                    className={`${styles.itemImage} ${show2nd ? styles.fadeOut : styles.fadeIn
                        }`}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',

                        borderRadius: '18px'
                    }}
                    absolute
                    top0
                    left0
                />
                <StyledImage
                    src={imagesToShow[1]}
                    alt={name}
                    loading='lazy'
                    className={`${styles.itemImage} ${styles.popUpFade}`}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }}
                    absolute
                    top0
                    left0
                    onMouseEnter={() => {
                        setShow2nd(true)
                    }}
                    onMouseLeave={() => {
                        setShow2nd(false)
                    }}
                />
            </Box>
            <Typography
                title={name}
                variant="h4"
                style={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '2',

                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: '50px',
                    // maxWidth: '280px'
                }}
                sx={{
                    m: 1,
                }}
            >
                {name}
            </Typography>
            <Box
                title={desc}
                style={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#636363'
                }}
                textXS ml2 mr4 mb2 h12 className={styles.multiLine}>
                {descShorten}
            </Box>

            <Box row toCenterY ml1 style={{
                fontFamily: 'Segoe UI, Segoe UI',
                fontWeight: '500'
            }}>
                {/* <StraightenIcon fontSize="small" /> */}
                <Box ml1 textSM color="#518DD9">{`${sizeRange}`}</Box>
            </Box>
            <Box ml2 mt2 color="#518DD9" style={{
                fontFamily: 'Segoe UI, Segoe UI',
                fontWeight: 'bold',
                fontSize: '18px'
            }}>
                ${pairPrize}
            </Box>
        </Box>
    )
}

export type ItemListProps = {
    list: ProductItemVO[]
    onSelect: (item: ProductItemVO) => void
}
const ItemList: FC<ItemListProps> = observer(({ list, onSelect }) => {
    const getCV = () => {
        if (global.innerWidth > 1075) return 3
        if (global.innerWidth > 640) return 2
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    return (
        <ImageList
            sx={{
                width: '100%',
                // height: '100%',
                marginTop: '0px',
                padding: '5px',
            }}
            variant="quilted"
            cols={columns}
            rowHeight={500}
        >
            {list.map((item, index) => {
                if (item.id === '-1') {
                    return (
                        <ImageListItem key={index.toString()}>
                            <Grid key={index.toString()} height={480} width={'100%'}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={296}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={184}
                                    sx={{
                                        bgcolor: 'grey.200',
                                    }}
                                />
                            </Grid>
                        </ImageListItem>
                    )
                }
                return (
                    <ImageListItem key={item.id}>
                        <ItemView
                            data={item}
                            onClick={(data) => {
                                onSelect(data)
                            }}
                        />
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
})
export default ItemList
