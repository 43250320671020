import { Box } from "@fower/react"
import { FC, useState } from "react"
import HomeHeader from "../../home/Header"
import { LoginMode } from "../../../TypeDeclare"
import HomeFooter from "../../home/Footer"
import LoginModal from "../../../common/LoginModal"
import { Button, Typography } from "@mui/material"
import styles from '../corporate/index.module.css'
import FeaturedList from "../../home/FeaturedList"
import { useNavigate } from "react-router-dom"
import { useAuthStore } from "../../../store/useAuthStore"

const Creators: FC = () => {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const navigator = useNavigate()
    const authStore = useAuthStore()

    function navigateToUrl() {
        if (!authStore.isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator('/dashboard?section=Products')
        }
    }

    const list = [
        {
            title: 'Create',
            desc: 'Use the exclusive store provided by Customtee to turn your ideas into tangible products.',
            src: '/images/creator-step1.webp'
        }, {
            title: 'Spread',
            desc: 'Connect to your social media to spread your products.',
            src: '/images/creator-step2.webp'
        }, {
            title: 'Revenue',
            desc: 'We’ll handle your production and shipping while you earn the profit.',
            src: '/images/creator-step3.webp'
        }
    ]

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => {
                }}
            />

            <Box
                w='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#E9DDAE',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                >
                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >
                        <h1
                            className={styles.title}
                        >
                            Launch a Free
                            Store
                        </h1>
                        <p
                            className={styles.description}
                        >
                            Sell products through your exclusive store provided by CustomTee, and let more fans see your ideas.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigateToUrl()
                            }}
                        >
                            Launch Store
                        </Button>
                    </Box>


                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/explore-creators-banner.webp"
                            alt="explore_banner"
                            width={'100%'}
                            loading="lazy"
                        />
                    </Box>
                </Box>


            </Box>

            <Typography
                variant="h2"
                gutterBottom
                fontWeight="bold"
                textAlign="center"
                margin='50px 0'
                style={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '40px'
                }}
            >
                Create All the Products You Can Think Of
            </Typography>

            <Box
                w='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#E9DDAE',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/creator-banner.webp"
                            alt="explore_banner"
                            width={'100%'}
                            loading="lazy"
                        />
                    </Box>

                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >
                        <p
                            className={styles.description}
                        >
                            Use Customtee to instantly customize over 400 physical products. Customize your ideas, and we’ll handle the fulfillment for your fans’ experience.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigator('/catalog')
                            }}
                        >
                            Start Customizing
                        </Button>
                    </Box>

                </Box>


            </Box>

            <Typography
                variant="h2"
                gutterBottom
                fontWeight="bold"
                margin='50px 0'
                textAlign="center"
                style={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontSize: '40px'
                }}
            >
                How to Begin?
            </Typography>

            <Box
                w="80%"
                flex
                flexWrap
            >

                {
                    list.map((item, index) => {
                        return (
                            <Box
                                w={['100%', '100%', '100%', 'calc(100% / 3)', 'calc(100% / 3)']}
                                flex
                                column
                            >
                                <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    fontWeight="bold"
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '26px'
                                    }}
                                >
                                    {item.title}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    margin='20px 0'
                                    height="100px"
                                    style={{
                                        fontFamily: 'Segoe UI, Segoe UI',
                                        fontSize: '20px',
                                    }}
                                >
                                    {item.desc}
                                </Typography>

                                <img
                                    src={item.src}
                                    alt={item.title}
                                    loading="lazy"
                                    width='314px'
                                />
                            </Box>
                        )
                    })
                }

            </Box>

            <Button
                variant="contained"
                style={{ margin: '50px 0', width: '340px', height: '70px', backgroundColor: '#518DD9', borderRadius: '52px', fontSize: '30px' }}
                onClick={() => {
                    navigator('/catalog')
                }}
            >
                Get Started Now
            </Button>


            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default Creators