import { Box } from "@fower/react";
import { FC, useState } from "react";
import HomeHeader from "../../home/Header";
import { LoginMode } from "../../../TypeDeclare";
import HomeFooter from "../../home/Footer";
import styles from '../corporate/index.module.css'
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LoginModal from "../../../common/LoginModal";
import CheckIcon from '@mui/icons-material/Check';
import styleObj from './index.module.css'
import { styled } from '@fower/styled'
import { useAuthStore } from "../../../store/useAuthStore";

const StyledIframe = styled('iframe')
const ShopifySellers: FC = () => {


    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const navigator = useNavigate()
    const authStore = useAuthStore()

    function navigateToUrl() {
        if (!authStore.isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator('/dashboard?section=Stores')
        }
    }

    const liList = [
        'Seamless Integration',
        'On-Demand Production',
        'Diverse Customization',
        'High-Quality Printing',
        'Fast Fulfillment',
        'Great Customer Support',
        'High Flexibility'
    ]

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => {
                }}
            />

            <Box
                w='100%'
                flex
                justifyContent="center"
                css={{
                    background: '#D9E9AE',
                }}
            >
                <Box
                    w="80%"
                    flex
                    flexWrap
                    justifyContent="space-between"
                // toCenter
                >
                    <Box
                        w={['100%', '100%', '100%', '50%', '50%']}
                        flex
                        column
                    >

                        <img
                            src="/images/shopify-logo.webp"
                            alt="shopify_logo"
                            style={{ width: '324px', marginTop: '60px' }}
                            loading={'lazy'}
                        />

                        <h1
                            className={styles.title}
                        >
                            Complete Support Solutions for Your Shopify Store
                        </h1>
                        <p
                            className={styles.description}
                        >
                            Directly Connect CustomTee to Your Store.
                        </p>

                        <Button
                            variant="contained"
                            className={styles.buttonStart}
                            onClick={() => {
                                navigateToUrl()
                            }}
                        >
                            Connect now
                        </Button>
                    </Box>


                    <Box
                        w={['100%', '100%', '100%', '40%', '40%']}
                        h="100%"
                    >
                        <Box h={['20px', '20px', '20px', '0', '0']}></Box>
                        <img
                            src="/images/shopify-banner.webp"
                            alt="shopify_banner"
                            width={'100%'}
                            style={{
                                maxWidth: '472px'
                            }}
                            loading="lazy"
                        />
                    </Box>
                </Box>


            </Box>

            <Box
                w={"80%"}
                column
                toCenter
            >
                <h2
                    className={styleObj.h2Style}
                >
                    How to Sell on Shopify
                </h2>

                <Box
                    flex
                    flexWrap
                    w={"100%"}
                    justifyContent="space-around"
                >
                    <Box
                        flex
                        column
                        toCenter
                    >
                        <img
                            src="/images/design-your-product.webp"
                            alt="design_your_product"
                            style={{ width: '196px' }}
                        />
                        <Box h={""}></Box>
                        <p className={styleObj.pStyle}>Design Your Product</p>
                    </Box>

                    <Box
                        flex
                        column
                        toCenter
                    >
                        <img
                            src="/images/connect-to-shopify-store.webp"
                            alt="connect_to_shopify_store"
                            style={{ width: '142px', height: '137px' }}
                        />

                        <p className={styleObj.pStyle}>Connect to Shopify Store</p>
                    </Box>

                    <Box
                        flex
                        column
                        toCenter
                    >
                        <img
                            src="/images/start-your-earnings.webp"
                            alt="start_your_earnings"
                            style={{ width: '145px', height: '137px' }}
                        />

                        <p className={styleObj.pStyle}>Start Your Earnings</p>
                    </Box>
                </Box>
            </Box>

            <Box
                w={'100%'}
                flex
                column
                toCenter
                css={{
                    background: '#EEECD8'
                }}
            >

                <h2
                    className={styleObj.h2Style}
                >
                    Why Choose CustomTee?
                </h2>

                <Box
                    flex
                    flexWrap
                    justifyContent={'space-around'}
                    w={'80%'}
                >
                    <Box
                        w={['100%', '100%', '100%', '30%', '30%']}
                        flex
                        column
                        justifyContent="center"
                    >
                        {
                            liList.map((item, index) => {
                                return (
                                    <p
                                        key={index}
                                        className={styleObj.liStyle}
                                        style={{
                                            marginTop: '20px'
                                        }}
                                    >
                                        <CheckIcon
                                            style={{ color: '#518DD9' }}
                                        />
                                        <span
                                            className={styleObj.pStyle}
                                            style={{
                                                marginLeft: '10px'
                                            }}
                                        >{item}</span>
                                    </p>
                                )
                            })
                        }
                    </Box>

                    <img
                        src="/images/choose-shopify-reson.webp"
                        alt="choose_shopify_reson"
                        style={{ maxWidth: '509px', width: '100%' }}
                    />
                </Box>
            </Box>


            <StyledIframe
                loading="lazy"
                mt16
                mb10
                w--lg="640px"
                w--md="800px"
                w="100%"
                h--lg="360px"
                h--md="450px"
                h="360px"
                allowFullScreen
                src="https://www.youtube.com/embed/GIx9diIKUCc"
                title="Custom Tee Integration for Shopify"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></StyledIframe>

            <Box
                flex
                toCenter
                w={["100%", "100%", "100%", "686px", "686px"]}
                textCenter
                css={{
                    fontFamily: 'Segoe UI, Segoe UI',
                    fontWeight: 'bold',
                    fontSize: '40px',
                    color: '#000000',
                    lineHeight: '50px',
                    marginTop: '50px'
                }}
            >
                Connect CustomTee to Your Shopify
                Store and Start Your On-Demand
                Customization Business
            </Box>


            <Box
                w={"234px"}
                h={"102px"}
                flex
                toCenter
                cursorPointer
                css={{
                    margin: '50px 0',
                    background: `url(${process.env.PUBLIC_URL}/images/icon-btn-right.webp)`,
                    backgroundSize: 'cover',
                    color: '#ffffff',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    fontFamily: 'Segoe UI, Segoe UI'
                }}
                onClick={() => {
                    navigateToUrl()
                }}
            >
                Get started
            </Box>


            <HomeFooter />
            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default ShopifySellers