import { FC } from 'react'
import { Box } from '@fower/react'
import { Button, CircularProgress, Dialog, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { observer } from 'mobx-react-lite'

export type UploadAction = 'buy' | 'dashboard' | 'retry' | 'cancel' | 'sell'
export type UploaderModalProps = {
  showUpload: boolean
  uploading: boolean
  uploadError: boolean
  uploadingProgress: number
  onAction: (action: UploadAction) => void
}
const UploaderModal: FC<UploaderModalProps> = observer(
  ({ showUpload, uploading, uploadError, uploadingProgress, onAction }) => {
    const uploaded = !uploading && !uploadError
    return (
      <Dialog open={showUpload} onClose={() => {}}>
        <Box w="100%" w--md="400px" p5 h="300px" column toCenter spaceY10>
          {uploaded ? (
            <Box column toCenterX spaceY5>
              <Typography variant="h5">Product created.</Typography>
              <Typography variant="h6">What is your next plan?</Typography>
              <Box row spaceX3>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAction('buy')
                  }}
                >
                  Buy it.
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAction('sell')
                  }}
                >
                  Sell it.
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAction('dashboard')
                  }}
                >
                  To Dashboard.
                </Button>
              </Box>
            </Box>
          ) : (
            <Box column toCenterX spaceY5 relative>
              {!uploadError ? (
                <CircularProgress
                  variant="determinate"
                  value={uploadingProgress}
                  size={'4rem'}
                />
              ) : (
                <ErrorIcon fontSize="large" color="error" />
              )}
              {!uploadError && (
                <Box absolute top6 left0 right0 flex toCenter>
                  <Typography variant="h6">
                    {uploadingProgress.toFixed(0)}%
                  </Typography>
                </Box>
              )}

              <Typography variant="h5">
                {uploadError ? 'Something is wrong' : 'Uploading your file…'}
              </Typography>
              <Typography textAlign="center">
                {uploadError
                  ? 'Oops, sorry, there is an error, pls check your network and try again.'
                  : 'Do not close this window until your upload is complete.'}
              </Typography>
              <Button
                onClick={() => {
                  onAction(uploadError ? 'retry' : 'cancel')
                }}
              >
                {uploadError ? 'retry' : 'cancel'}
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    )
  },
)
export default UploaderModal
