import { FC, useCallback, useState } from 'react'
import { Box } from '@fower/react'
import {
    DBShopifyOrderVO,
    PreviewImageItem,
    ShopifyFulfillmentDetailVO,
} from '../../../TypeDeclare'
import { Button, Divider, TextField } from '@mui/material'
import { format } from 'date-fns'
import { PreviewImagesBar } from '../../checkout/PreviewImagesBar'

export type ShopifyOrderDetailItemProps = {
    data: ShopifyFulfillmentDetailVO
    order: DBShopifyOrderVO
    hasTrackingLink?: boolean
    onAddTrackingLink?: (newLink: string) => Promise<boolean>
    isOrder?: boolean
}
const ShopifyOrderDetailItem: FC<ShopifyOrderDetailItemProps> = ({
    data,
    order,
    hasTrackingLink = false,
    onAddTrackingLink,
    isOrder = false
}) => {
    const [trackingLink, setTrackingLink] = useState<string>(
        data.shipping_label || '',
    )
    const getPreviewImages = useCallback((): PreviewImageItem[] => {
        return data.product_images.split(',').map((pi, i) => {
            return {
                name: `assets-${i}`,
                url: pi,
                fileName: '',
                alias: '',
                format: 'png',
            }
        })
    }, [data.product_images])

    const styles = {
        key: {
            fontFamily: 'Segoe UI, Segoe UI',
            color: '#000000',
            fontWeight: '600'
        },
        val: {
            fontFamily: 'Segoe UI, Segoe UI',
            color: '#7D7D7D',
            fontWeight: '600'
        }
    }

    return (
        <Box pt4 pl4 pr4 column rounded spaceY3>
            <Box toBetween>
                <Box column spaceY3>
                    <Box row toBetween>
                        <Box style={styles.key}>Order ID:</Box> <Box style={styles.val}>{data.fulfillment_order_id}</Box>
                    </Box>
                    <Box row toBetween>
                        <Box style={styles.key}>SKU:</Box> <Box style={styles.val}>{data.product_sku}</Box>
                    </Box>
                </Box>

            </Box>
            <PreviewImagesBar imageData={getPreviewImages()} onClick={() => { }} />
            <Box row toBetween>
                <Box style={styles.key}>Product name:</Box> <Box style={styles.val}>{data.name}</Box>
            </Box>
            <Box row toBetween>
                <Box style={styles.key}>Variants: </Box>
                <Box style={styles.val}>{data.variant_title}</Box>
            </Box>

            <Box row toBetween>
                <Box style={styles.key}>Quantity: </Box>
                <Box style={styles.val}>{data.quantity}</Box>
            </Box>

            <Box row toBetween>
                <Box style={styles.key}>Production Cost:</Box> <Box style={styles.val}>$ {data.cost_price}</Box>
            </Box>

            <Box row toBetween>
                <Box style={styles.key}>Sent to production:</Box>{' '}
                <Box style={styles.val}>{format(order.updateTime, 'MM/dd/yy HH:mm:ss')}</Box>
            </Box>

            {
                isOrder &&
                <Box row toBetween>
                    <Box style={styles.key}>Tracking Link:</Box>{' '}
                    <Box style={styles.val}>
                        <a href={data.shipping_label!} target='_blank'>{data.shipping_label}</a>
                    </Box>
                </Box>
            }

            <Divider />
            <Box row toBetween>
                <Box style={styles.key}>Customer:</Box>
                <Box style={styles.val}>{`${order.destination.first_name || ''} ${order.destination.last_name || ''
                    }`}</Box>
            </Box>
            <Box>
                <Box

                    style={{
                        lineBreak: 'auto',
                        lineHeight: '32px',
                        ...styles.key
                    }}
                >
                    Shipping address:
                </Box>
                <Box style={styles.val}>{`${order.destination.address1 ? `${order.destination.address1},` : ''
                    }${order.destination.address2 ? `${order.destination.address2},` : ''
                    } ${order.destination.city ? `${order.destination.city},` : ''} ${order.destination.province ? `${order.destination.province}` : ''
                    } ${order.destination.zip || ''} ${order.destination.country || ''
                    }`}</Box>
            </Box>
            <Box row toBetween>
                <Box style={styles.key}>Phone number: </Box>
                <Box style={styles.val}>{order.destination.phone}</Box>
            </Box>

            <Divider />

            {hasTrackingLink && (
                <Box row spaceX2>
                    <TextField
                        label="Tracking Link"
                        variant="outlined"
                        onChange={(event) => {
                            setTrackingLink(event.target.value as string)
                        }}
                        value={trackingLink}
                        sx={{
                            flexGrow: 2,
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (onAddTrackingLink) {
                                onAddTrackingLink(trackingLink || '').then((success) => {
                                    if (!success) {
                                        setTrackingLink('')
                                    }
                                })
                            }
                        }}
                    >
                        Update
                    </Button>
                </Box>
            )}
        </Box>
    )
}
export default ShopifyOrderDetailItem
