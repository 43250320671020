import { Box } from "@fower/react";
import { FC, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode } from "../../TypeDeclare";
import HomeFooter from "../home/Footer";
import LoginModal from "../../common/LoginModal";


const MerchantGuide: FC = () => {
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />

            <Box
                w="80%"
            >

                <h1 className="p1"><strong><span className="s1">Merchant Payment</span></strong></h1>
                <p className="p2">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>Any order that is refunded before the supplier has fulfilled it is not eligible for payment.&nbsp;</strong></span></p>
                <p className="p1">&nbsp;</p>
                <p className="p1"><span className="s1">The following must be done&nbsp;within 5 days of the order date in order to be paid for that order:</span></p>
                <p className="p3"><span className="s1"> 1 Fulfill the approved ("unrefunded") order.</span></p>
                <p className="p3"><span className="s1"> 2 Mark the approved order as shipped.</span></p>
                <p className="p3"><span className="s1"> 3 Provide a </span><span className="s2">valid tracking ID </span><span className="s1">for the approved order.</span></p>
                <p className="p1"><span className="s1">An approved order is any order that is not refunded and has not yet been shipped.&nbsp;If the supplier completes these three steps, the&nbsp;order will be eligible for payment.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p1"><span className="s1">If the order is refunded before the supplier is able to complete these steps, they will not be paid for the order. It is the supplier's responsibility to ensure that an order they are fulfilling is not refunded, as Pairdrop sends the supplier an email notification as soon as an approved order is refunded.</span></p>
                <p className="p1"><span className="s1">Suppliers, therefore, should not fulfill orders that have been refunded, as they will not be paid if the refund has occurred before fulfillment is complete.</span></p>
                <p className="p2">&nbsp;</p>
                <p className="p1"><span className="s1">To ensure you get paid for your orders, please fulfill your order as soon as possible following the steps outlined above.</span></p>
                <p className="p2">&nbsp;</p><p className="p2">&nbsp;</p>
                <h1 className="p1"><strong><span className="s1">Supplier Criteria</span></strong></h1>
                <p className="p2">&nbsp;</p>
                <p>Suppliers can be manufacturers, brand owners, retailers, crafters, creators, and artists. Suppliers must create, manufacture, or own distribution/retail rights to the products they supply on Pairdrop. Suppliers are expected to be self-serve and drop-ship orders themselves.</p>
                <p className="p2">&nbsp;</p>
                <h2 className="p4"><span className="s1">Prohibited items</span></h2>
                <p className="p3"><span className="s1">Every supplier is responsible for following the laws that apply to you, your shop and your items, including any shipping restrictions for your items. </span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">There are some types of items that we don't allow on Pairdrop's platform, even if they are legal and otherwise meet Pairdrop's selling criteria. Some things just aren't in the spirit of Pairdrop. The following types of items may not be listed on Pairdrop:</span></p>
                <p className="p5"><span className="s1"> • Counterfeit products</span></p>
                <p className="p5"><span className="s1"> • Items whose copyright you do not own or hold (copyrighted to someone else)</span></p>
                <p className="p5"><span className="s1"> • Services:&nbsp;Any service that does not yield a new, tangible, physical item</span></p>
                <p className="p5"><span className="s1"> • Virtual goods and digital goods: items that are not tangible or must be delivered electronically</span></p>
                <p className="p5"><span className="s1"> • Gift cards, physical or digital</span></p>
                <p className="p5"><span className="s1"> • Alcohol</span></p>
                <p className="p5"><span className="s1"> • Tobacco and other smokeable products including electronic cigarettes</span></p>
                <p className="p5"><span className="s1"> • Lighters &nbsp;</span></p>
                <p className="p5"><span className="s1"> • Dangerous chemicals</span></p>
                <p className="p5"><span className="s1"> • Piercing gun and tattoo gun</span></p>
                <p className="p5"><span className="s1"> • Bike and&nbsp;motorcycle helmet</span></p>
                <p className="p5"><span className="s1"> • Drugs, medical drug claims about an item, drug paraphernalia</span></p>
                <p className="p5"><span className="s1"> • Live animals, illegal animal products</span></p>
                <p className="p5"><span className="s1"> • Plant seeds&nbsp;</span></p>
                <p className="p5"><span className="s1"> • Human remains or body parts (excluding hair and teeth)</span></p>
                <p className="p5"><span className="s1"> • Pornography or adult/sexually explicit/obscene material</span></p>
                <p className="p5"><span className="s1"> • Firearms and/or weapons</span></p>
                <p className="p5"><span className="s1"> • Child carseat, child harness, and recalled toys.</span></p>
                <p className="p5"><span className="s1"> • Nudity</span></p>
                <p className="p5"><span className="s1"> • Hate crime and items or listings that promote, support or glorify hatred toward or otherwise demean people based upon: race, ethnicity, religion, gender, gender identity, disability, or sexual orientation; including items or content that promote organizations with such views</span></p>
                <p className="p3"><span className="s1">Please note that Pairdrop serves an audience that may include children as young as 13 years of age. Products that may be inappropriate for children to view or buy are therefore not appropriate for Pairdrop. </span></p>
                <p className="p3"><span className="s1">We reserve the right to remove products that we determine are not within the spirit of Pairdrop. Such products will be removed from the site, and the supplier's selling privileges may be suspended and/or terminated.</span></p>
                <p className="p2">&nbsp;</p><p className="p2">&nbsp;</p>
                <h1 className="p1"><span className="s1"><strong>Seller Policies</strong></span></h1>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Please keep the following things in mind as you set up your account with Pairdrop and add products to the Pairdrop platform:</span></p>
                <p className="p5"><span className="s1"> • Do not place items on Pairdrop if you do not own or have permission for the trademark, copyright or patent</span></p>
                <p className="p5"><span className="s1"> • Do not make illegal use of photographs or written text. This includes but is not limited to copyright infringement.</span></p>
                <p className="p5"><span className="s1"> • You may not use mature, profane or racist language or images in your product listings or seller name.</span></p>
                <p className="p5"><span className="s1"> • You may not purchase your own products through the Pairdrop platform. This is considered fraudulent and unethical behavior.</span></p>
                <p className="p5"><span className="s1"> • You may not use the Pairdrop brand to promote your listings. This includes, but is not limited to, your display name and your product listing titles.&nbsp;</span></p>
                <p className="p3"><span className="s1">Members who do not comply with Pairdrop's policies may be subject to review, which can result in suspension of account privileges and/or termination.</span></p>
                <p className="p2">&nbsp;</p>
                <h2 className="p1"><span className="s1">Listings</span></h2>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">All listings on Pairdrop should be clear, accurate and detailed. Accurate photos, descriptions and listing information are critical to selling on Pairdrop. Check out the FAQ for more information on how to upload your products. Keep these policies in mind as you list and describe your products:</span></p>
                <p className="p5"><span className="s1"> • Listing descriptions and photos must accurately describe the item for sale so users know what is included in the purchase.</span></p>
                <p className="p5"><span className="s1"> • You must be the copyright holder or licensed to sell the products you upload.</span></p>
                <p className="p5"><span className="s1"> • A listing may not be created for the sole purpose of sharing photographs or other information with the community.</span></p>
                <p className="p5"><span className="s1"> • A listing may not be created solely as an advertisement. This includes notices of sales or promotions in your shop.</span></p>
                <p className="p5"><span className="s1"> • Items must not be listed as available for rental or lease.</span></p>
                <p className="p5"><span className="s1"> • You may group items as a set into a single listing if the items are being sold and shipped together.</span></p>
                <p className="p5"><span className="s1"> • All listings on Pairdrop must be for a tangible object.</span></p>
                <p className="p5"><span className="s1"> • You may not use Pairdrop to direct shoppers to your own or another online selling venue to purchase the same items as listed in your Pairdrop shop, as this may constitute fee avoidance. This includes posting links/URLs or providing information sufficient to locate the other online venue(s). Directing Pairdrop buyers outside of Pairdrop negates the supplier's partnership with Pairdrop.</span></p>
                <p className="p5"><span className="s1"> • If an item listing is removed due to counterfeit, you may not alter that product listing to a new item.</span></p>
                <p className="p5"><span className="s1"> • A listing must not be conditional upon the purchase of another listing in your shop (for example: saying "this item may only be purchased along with another item in my shop" is not allowed). This includes listings for item upgrades, shipping upgrades, and gift wrapping upgrades.</span></p>
                <p className="p3"><span className="s1">Listings that do not comply with Pairdrop's policies may be removed from or suspended on Pairdrop. Members who do not comply with Pairdrop's policies may be subject to review, which can result in suspension of account privileges and/or termination.</span></p>
                <p className="p2">&nbsp;</p>
                <h2 className="p1"><span className="s1">Pricing&nbsp;</span></h2>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Suppliers will indicate the price of each item when uploading products onto the platform. We always try to make sure that the final price is either the target retail price or something very close to it. However, there may be a difference in the price uploaded and the price users see on Pairdrop. We do this to optimize exposure and number of transactions. We will make sure the final checkout price is as close to the retail price as possible.</span></p>
                <p className="p2">&nbsp;</p>
                <h2 className="p1"><span className="s1">Shipping</span></h2>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Fast and reliable shipping is critical to customer satisfaction. We ask that you fulfill orders in 1-3 days and ship items using a trusted package delivery company.</span></p>
                <p className="p3"><span className="s1">Pairdrop will refund customers automatically if their order is not shipped within 5 days of purchase. We will send multiple alerts to the email address you've provided before these orders are auto-refunded.&nbsp;</span></p>

            </Box>

            <HomeFooter />

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}

export default MerchantGuide;