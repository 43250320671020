/* eslint-disable react/style-prop-object */
import { SvgIcon } from '@mui/material';
import { FC } from 'react';

const AllIcon: FC<{ iconcolor: string }> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='cut_common' transform='translate(-1397.000000, -51.000000)'>
          <g id='icon_all_check' transform='translate(1397.000000, 51.000000)'>
            <rect id='矩形' x='0' y='0' width='24' height='24'></rect>
            <rect
              id='矩形'
              fill={props.iconcolor}
              x='3'
              y='3'
              width='8'
              height='8'
              rx='2'
            ></rect>
            <rect
              id='矩形备份-10'
              fill={props.iconcolor}
              x='3'
              y='13'
              width='8'
              height='8'
              rx='2'
            ></rect>
            <rect
              id='矩形备份-9'
              fill={props.iconcolor}
              x='13'
              y='3'
              width='8'
              height='8'
              rx='2'
            ></rect>
            <rect
              id='矩形备份-11'
              fill={props.iconcolor}
              x='13'
              y='13'
              width='8'
              height='8'
              rx='2'
            ></rect>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AllIcon;
