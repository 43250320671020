import { Box } from '@fower/react'
import {
    AlertColor,
    Button,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    Tab,
    Tabs,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SetProductPropsModal from '../../../common/SetProductPropsModal'
import { useAuthStore } from '../../../store/useAuthStore'
import {
    LinkedShop,
    MyProductItemVO,
    ProductType,
    ShopifyActionType,
    StyledPagination,
    StyledPaper,
} from '../../../TypeDeclare'
import { useProductStore } from '../../product_list/store/useProdustListStore'
import Cart from './Cart'
import DBProductItem from './DBProductItem'
import { useDashbardStore } from '../store/useDashboardStore'
import ProgressIndicator from '../../../common/ProgressIndicator'
import LinkedStoreSelect from '../../../common/LinkedStoreSelect'
import { loadUserProfile } from '../../../store/authService'
import SimpleBackdrop from './SimpleBackDrop'
export type ProductsPoolProps = {
    onToStore: () => void
}
const getCV = (): number => {
    if (global.innerWidth > 1075) return 3
    if (global.innerWidth > 640) return 2
    return 1
}
const ProductsPool: FC<ProductsPoolProps> = observer(({ onToStore }) => {
    const dashbardStore = useDashbardStore()
    const productStore = useProductStore()
    const authStore = useAuthStore()
    const navigator = useNavigate()
    const columns = getCV()

    const navigate = useNavigate()
    const [selectedItem, setSelectedItem] = useState<MyProductItemVO>()

    const [backDropOpen, setBackDropOpen] = useState(false)

    const [showCart, setShowCart] = useState<boolean>(false)
    const [showEditProduct, setShowEditProduct] = useState<boolean>(false)
    const [productType, setProductType] = useState<ProductType>('all')
    const [selectedShop, setSelectedShop] = useState<LinkedShop | undefined>()

    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messageType, setMessageType] = useState<AlertColor>('info')
    const [shopifyMessage, setShopifyMessage] = useState<string>(
        'Publishing to Shopify',
    )

    useEffect(() => {
        loadUserProfile(authStore.token).then(res => {
            localStorage.setItem('shopInfo', JSON.stringify(res))
        })

        loadUserProfile(authStore.token).then(result => {
            localStorage.setItem('profile_path', result.profile_path)
        })
    }, [])

    const refreshProfile = useCallback(async () => {
        const result = await authStore.loadUserProfile()
        dashbardStore.setLinkedShops(result.rawLinkedShops)
        if (dashbardStore.linkedShops.length !== 0) {
            const shopTo = dashbardStore.linkedShops[0]
            setSelectedShop(shopTo)
        }
    }, [authStore, dashbardStore])

    const refreshProducts = useCallback(
        async (page: number) => {
            if (productType !== 'shopified') {
                await dashbardStore.getMyProducts(authStore.token, productType, page)
            }
        },
        [productType, dashbardStore, authStore.token],
    )

    const refreshShopifyProducts = useCallback(
        async (shopToken: string, page: number) => {
            dashbardStore.getShopifyProducts(authStore.token, shopToken, page)
        },
        [authStore.token, dashbardStore],
    )

    const updateShopifyProducts = useCallback(
        async (
            productId: string,
            shopTo: LinkedShop,
            action: ShopifyActionType,
        ) => {
            setShowProgress(true)
            if (action === 'add') {
                setShopifyMessage('Publishing to Shopify')
            } else {
                setShopifyMessage('Removing from Shopify')
            }
            setUpdating(true)
            const result = await dashbardStore.updateShopifyProduct(
                authStore.token,
                shopTo.ctToken!,
                productId,
                action,
            )
            setUpdating(false)
            if (result) {
                if (action === 'add') {
                    setMessage('Product added to Shopify')
                    setMessageType('success')
                } else {
                    setMessage('Product removed from Shopify')
                    setMessageType('success')
                }
                setProductType('shopified')
                refreshShopifyProducts(
                    selectedShop?.ctToken!,
                    dashbardStore.currentPage,
                )
            } else {
                setMessage('Error')
                setMessageType('error')
            }
        },
        [
            authStore.token,
            dashbardStore,
            refreshShopifyProducts,
            selectedShop?.ctToken,
        ],
    )
    const refreshAllProducts = useCallback(
        (page: number) => {
            if (productType !== 'shopified') {
                refreshProducts(page).then(null)
            } else {
                refreshShopifyProducts(selectedShop?.ctToken!, page).then(null)
            }
        },
        [
            productType,
            refreshProducts,
            refreshShopifyProducts,
            selectedShop?.ctToken,
        ],
    )

    useEffect(() => {

        if (!authStore.profile) return
        refreshAllProducts(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.token, productType, JSON.stringify(authStore.profile)])
    useEffect(() => {
        refreshProfile().then(null)
    }, [dashbardStore, refreshProfile])

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const [list, setList] = useState<{
        category_name: string,
        cover_image: string,
        default_price: number,
        name: string,
        product_id: string,
        short_description: string,
    }[]>([
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
        {
            category_name: '',
            cover_image: '',
            default_price: 0,
            name: '',
            product_id: '',
            short_description: '',
        },
    ])

    return (
        <Box h="100%" column p10 spaceY5>
            <Box toBetween>
                <Button
                    variant="contained"
                    style={{ background: '#4384D6' }}
                    onClick={() => {
                        navigator('/catalog/all')
                    }}
                >
                    Add New Product
                </Button>
                <Tabs
                    value={productType}

                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#4384D6', // 修改选中下划线的颜色
                        },
                        '& .MuiTab-root': {
                            color: 'gray', // 默认 Tab 文字颜色
                        },
                        '& .Mui-selected': {
                            color: '#4384D6', // 选中状态的 Tab 文字颜色
                        },
                    }}
                    onChange={(_event, newValue: ProductType) => {
                        setProductType(newValue)
                    }}
                >
                    <Tab label="All" {...a11yProps(0)} value="all" />
                    <Tab label="Custom Tee Store " {...a11yProps(1)} value="customtee" />
                    <Tab label="Shopify Store" {...a11yProps(2)} value="shopified" />
                </Tabs>
            </Box>

            {productType === 'shopified' && selectedShop && (
                <LinkedStoreSelect
                    selectedShop={selectedShop}
                    linkedShops={dashbardStore.linkedShops}
                    onSelectedNewShop={(targetShop) => {
                        setSelectedShop(targetShop)
                        refreshShopifyProducts(
                            targetShop.ctToken,
                            dashbardStore.currentPage,
                        )
                    }}
                />
            )}



            {!dashbardStore.loadingProduct && (
                <ImageList
                    sx={{
                        width: '100%',
                        height: '100%',
                        marginTop: '0px',
                        padding: '5px',
                    }}
                    variant="quilted"
                    cols={columns}
                    rowHeight={500}
                >


                    {dashbardStore.products.map((p, index) => {
                        return (
                            <DBProductItem
                                item={p}
                                type={productType}
                                key={`${p.id}-${index}`}
                                hasBindedShop={authStore.hasBindedShop}
                                productType={productType}
                                onBuy={(item: MyProductItemVO) => {
                                    setBackDropOpen(true)
                                    productStore.loadMyProdcut(item).then((updatedItem) => {
                                        setBackDropOpen(false)
                                        setSelectedItem(updatedItem)
                                        setShowCart(true)
                                    })
                                }}
                                onView={(item: MyProductItemVO) => {
                                    const name = item.name.split(' ').join('-').toLowerCase()
                                    navigate(`/shop/${localStorage.getItem('profile_path')}/${name}-${item.productId}`)

                                }}
                                onEdit={(item: MyProductItemVO) => {
                                    setBackDropOpen(true)
                                    productStore.loadMyProdcut(item).then((newItem) => {
                                        setBackDropOpen(false)
                                        setSelectedItem(newItem)
                                        setShowEditProduct(true)
                                        productStore.setProduct(newItem)
                                    })
                                }}
                                onOutOfStore={(item: MyProductItemVO) => {
                                    productStore
                                        .putOutOfStore(authStore.token, item.id)
                                        .then((res) => {
                                            dashbardStore.removeProductFromStore(item.id)
                                        })
                                }}
                                onShopifyAction={(item, action) => {
                                    if (authStore.hasBindedShop) {
                                        if (action === 'add' || action === 'remove') {
                                            updateShopifyProducts(
                                                item.id,
                                                selectedShop!,
                                                action,
                                            ).then(null)
                                        } else if (action === 'view') {
                                            dashbardStore
                                                .getShopifyProductLink(item.id, selectedShop?.ctToken!)
                                                .then((link: string) => {
                                                    window.open(link, '_blank')
                                                })
                                        }
                                    } else {
                                        onToStore()
                                    }
                                }}
                            />
                        )
                    })}
                </ImageList>
            )}
            {
                dashbardStore.loadingProduct && <ImageList
                    sx={{
                        width: '100%',
                        height: '100%',
                        marginTop: '0px',
                        padding: '5px',
                    }}
                    variant="quilted"
                    cols={columns}
                    rowHeight={500}
                >
                    {list.map((item, index) => (
                        <ImageListItem key={index.toString()}>
                            <Grid
                                height={480}
                                width="100%"
                            >
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width="100%"
                                    height={296}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width="100%"
                                    height={184}
                                    sx={{
                                        bgcolor: 'grey.200',
                                    }}
                                />
                            </Grid>
                        </ImageListItem>
                    ))}


                </ImageList>
            }
            {dashbardStore.products.length === 0 && !dashbardStore.loadingProduct && (
                <StyledPaper toCenter elevation={1}>
                    <Box h96 toCenter>
                        No products yet
                    </Box>
                </StyledPaper>
            )}

            <Cart
                isOpen={showCart}
                onClose={() => {
                    setShowCart(false)
                }}
                item={selectedItem}
            />
            <SetProductPropsModal
                isOpen={showEditProduct}
                product={selectedItem}
                onClose={() => {
                    setShowEditProduct(false)
                }}
                onSubmit={async (title, desc, price, toCT, toShopify, description_html, shopTo) => {
                    const newItem: MyProductItemVO = {
                        ...selectedItem!,
                        name: title,
                        descShorten: desc,
                        price: price.toString(),
                    }

                    if (toCT) {
                        if (!selectedItem?.onSaleCustomtee) {
                            await productStore.sellProduct(
                                authStore.token,
                                selectedItem?.id!,
                                title,
                                desc,
                                true,
                                price,
                                description_html
                            )
                        }
                    } else {
                        await productStore.editProduct(
                            authStore.token,
                            selectedItem?.id!,
                            title,
                            desc,
                            price,
                            description_html
                        )
                    }
                    dashbardStore.updateSingleProduct(newItem as MyProductItemVO)
                    if (toShopify && shopTo) {
                        if (!selectedItem?.onSaleShopify) {
                            await updateShopifyProducts(selectedItem?.id!, shopTo, 'add')
                        } else {
                            setMessage('Product alreay in shopify store')
                            setMessageType('warning')
                        }
                    }
                    setShowEditProduct(false)
                }}
                canShopify={true}
                toConnect={() => {
                    onToStore()
                }}
            />
            <ProgressIndicator
                isOpen={showProgress}
                text={shopifyMessage}
                loading={updating}
                message={message}
                messageType={messageType}
                onClose={() => {
                    setShowProgress(false)
                }}
            />
            <StyledPagination
                count={dashbardStore.pageCount}
                page={dashbardStore.currentPage}
                onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
                    refreshAllProducts(value)
                }}
                variant="outlined"
                color="primary"
                fixed
                right2
                bottom2
            />

            <SimpleBackdrop open={backDropOpen} />
        </Box>
    )
})
export default ProductsPool
