import { FC } from "react"
import { ColorOption } from "../../../TypeDeclare";
import { Box } from "@fower/react";


interface ShowColorPaletteProps {
    colorOptions: ColorOption[]
}

const ShowColorPalette: FC<ShowColorPaletteProps> = ({ colorOptions }) => {

    return (
        <Box w="100%" flex alignItems="center" style={{ marginTop: '10px' }}>
            {
                colorOptions.map((color, index) => {
                    return (
                        <Box w="32px" h="32px" style={{ borderRadius: '50%', background: color.value, border: color.value === '#ffffff' ? '1px solid #000000' : 'none', marginLeft: index === 0 ? '0' : '-8px' }} key={color.id}></Box>
                    )
                })
            }
        </Box>
    )
}

export default ShowColorPalette;