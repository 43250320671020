import { FabricJSEditor } from 'fabricjs-react'
import { action, computed, makeObservable, observable } from 'mobx'
import { LS } from '../../../../common/LocalStorage'
import { FaceType } from '../../../../TypeDeclare'
import { EditorStore } from './EditorStore'
export enum ActionType {
  INIT,
  CANVAS_CHANGED,
  FACE_CHANGED,
  TEE_COLOR_CHANGED,
}
export type ActionRecord = {
  type: ActionType
  snapshots: { data: string; face: FaceType }[]
  face: FaceType
}
export class UnifyActionRecorder extends LS {
  @observable private currentState?: ActionRecord
  @observable private stateStack: ActionRecord[] = [] //Undo stack
  @observable private redoStack: ActionRecord[] = [] //Redo stack
  @observable private locked: boolean = false //Determines if the state can currently be saved.
  private initCount = 0
  private maxCount: number = 100
  constructor(
    private editorFront: FabricJSEditor,
    private editorBack: FabricJSEditor,
    private readonly parent: EditorStore,
  ) {
    super()
    makeObservable(this)
    this.updateEditor(editorFront, editorBack)
    const dataF = this.load('front-data')
    const dataB = this.load('back-data')
    if (dataF) {
      this.editorFront.canvas.loadFromJSON(dataF, () => {
        this.locked = false
      })
    }
    if (dataB) {
      this.editorBack.canvas.loadFromJSON(dataB, () => {
        this.locked = false
      })
    }
  }
  
  
  updateEditor(front: FabricJSEditor, back:FabricJSEditor){
    const onFrontModifid = () => {
      this.saveState(ActionType.CANVAS_CHANGED, 'front')
    }  
    const onBackModifid = () => {
      this.saveState(ActionType.CANVAS_CHANGED, 'back')
    }
    const cf :any = this.editorBack.canvas as any
    const cb :any = this.editorBack.canvas as any
    
    cf['__eventListeners']['object:modified'] = []
    cb['__eventListeners']['object:modified'] = []
    
    this.editorFront = front;
    this.editorBack = back;
    this.editorFront.canvas.on('object:modified', onFrontModifid)
    this.editorBack.canvas.on('object:modified', onBackModifid)
  }

  @computed
  get count(): number {
    return this.stateStack.length
  }
  @action
  saveState(type: ActionType, face?: FaceType): ActionRecord {
    console.log('saveState', type, face);
    
    let newRecord: ActionRecord = {
      type: ActionType.CANVAS_CHANGED,
      snapshots: [],
      face: this.parent.targetFace,
    }
    if (!this.locked) {
      if (type === ActionType.CANVAS_CHANGED) {
        if (face) {
          newRecord = this.singleSave(type, face)
        }
      } else if (
        type === ActionType.TEE_COLOR_CHANGED ||
        type === ActionType.FACE_CHANGED
      ) {
        newRecord = this.dualSave(type)
      }
    }
    return newRecord
  }

  @action
  singleSave(type: ActionType, face: FaceType): ActionRecord {
    const canvas =
      face === 'front' ? this.editorFront.canvas : this.editorBack.canvas
    const newData = canvas.toJSON(['evented', 'selectable', 'scaleX', 'scaleY'])
    if (this.stateStack.length === this.maxCount) {
      this.stateStack.shift()
    }
    // console.log('add state single', type);
    this.stateStack.push(this.currentState!)
    this.currentState = {
      type,
      snapshots: [{ data: newData, face }],
      face,
    }
    this.redoStack.length = 0
    // this.save(`${face}-data`, JSON.stringify(newData))
    return this.currentState
  }

  @action
  dualSave(type: ActionType): ActionRecord {
    const canvasFront = this.editorFront.canvas
    const canvasBack = this.editorBack.canvas
    const dataFront = canvasFront.toJSON([
      'evented',
      'selectable',
      'scaleX',
      'scaleY',
    ])
    const dataBack = canvasBack.toJSON([
      'evented',
      'selectable',
      'scaleX',
      'scaleY',
    ])

    if (this.stateStack.length === this.maxCount) {
      this.stateStack.shift()
    }
    // console.log('add state dual', type);
    this.stateStack.push(this.currentState!)
    this.currentState = {
      type,
      snapshots: [
        { data: dataFront, face: 'front' },
        { data: dataBack, face: 'back' },
      ],
      face: this.parent.targetFace,
    }
    this.redoStack.length = 0
    // this.save(`front-data`, JSON.stringify(dataFront))
    // this.save(`back-data`, JSON.stringify(dataBack))
    return this.currentState
  }
  @action
  initData() {
    if (this.initCount >= 1) return
    this.initCount++
    const canvasFront = this.editorFront.canvas
    const canvasBack = this.editorBack.canvas
    const dataFront = canvasFront.toJSON([
      'evented',
      'selectable',
      'scaleX',
      'scaleY',
    ])
    const dataBack = canvasBack.toJSON([
      'evented',
      'selectable',
      'scaleX',
      'scaleY',
    ])
    this.currentState = {
      type: ActionType.INIT,
      snapshots: [
        { data: dataFront, face: 'front' },
        { data: dataBack, face: 'back' },
      ],
      face: this.parent.targetFace,
    }
    this.stateStack.push(this.currentState!)
  }

  @computed
  get canUndo(): boolean {
    return this.stateStack.length > 1
  }
  @computed
  get canRedo(): boolean {
    return this.redoStack.length > 0
  }

  @action
  undo(callback?: Function) {
    if (this.stateStack.length > 0)
      this.applyState(this.redoStack, this.stateStack.pop(), callback)
  }

  @action
  redo(callback?: Function) {
    if (this.redoStack.length > 0)
      this.applyState(this.stateStack, this.redoStack.pop(), callback)
  }

  @action
  private applyState(
    stack: ActionRecord[],
    newState: ActionRecord | undefined,
    callBack?: Function,
  ) {
    
    //Push the current state
    if (newState === undefined) return
    if (this.currentState) {
      stack.push(this.currentState)
    }
    this.currentState = newState
    this.locked = true
    if (newState.face === 'front') {
      this.parent.toTeeFront()
    } else {
      this.parent.toTeeBack()
    }
    
    for (let index = 0; index < newState.snapshots.length; index++) {
      const snapshot = newState.snapshots[index]
      const canvas =
        snapshot.face === 'front'
          ? this.editorFront.canvas
          : this.editorBack.canvas
      canvas.loadFromJSON(snapshot.data, () => {
        if (callBack !== undefined) callBack()
        this.locked = false
      })
    }
  }
  reload() {
    if (!this.currentState) return
    for (let index = 0; index < this.currentState.snapshots.length; index++) {
      const snapshot = this.currentState.snapshots[index]
      const canvas =
        snapshot.face === 'front'
          ? this.editorFront.canvas
          : this.editorBack.canvas
      canvas.loadFromJSON(snapshot.data, () => {})
    }
  }
  clear(){
    this.currentState = undefined;
    this.stateStack = [];
    this.redoStack = [];
    this.initCount = 0;
  }
}
