import { Button, Drawer, FormControl, InputAdornment, InputLabel, LinearProgress, OutlinedInput } from "@mui/material";
import { FC, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import QuillEditor from '../../pages/dashboard/upload_product/components/Editor'
import ImageUploader from "../dashboard/upload_product/components/ImageUploader";
import { useOriginalProductStore } from "../dashboard/store/useOriginalProductStore";

interface formDataType {
    title: string;
    description: string;
    cover_image: string | File | undefined
    seo_title: string
    seo_description: string
    seo_url: string
}

interface BlobEditDrawerProps {
    visible: boolean;
    onClose: () => void;
    formData: formDataType;
    onUpdateFormData: (form: formDataType) => void;
    onSubmit: () => void
    description_html: string,
    onUpdateHtml: (val: string) => void
}

const BlobEditDrawer: FC<BlobEditDrawerProps> = ({
    visible, onClose, formData, onUpdateFormData, onSubmit, description_html, onUpdateHtml
}) => {

    const opStore = useOriginalProductStore()

    const [uploading, setUploading] = useState(false)

    async function handleImageSelected(selectedImage: File) {
        setUploading(true)
        const res = await opStore.uploadImages([selectedImage], () => { })
        if (res.length) {

            onUpdateFormData({
                ...formData,
                cover_image: res[0].url
            })
            setUploading(false)
        }

    }

    function deleteImage() {
        onUpdateFormData({
            ...formData,
            cover_image: undefined
        })
    }

    return (
        <Drawer
            anchor={'right'}
            open={visible}
        >
            <Box
                component='div'
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    padding: '10px'
                }}
            >
                <CloseIcon
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                />
            </Box>
            <Box
                component="form"
                sx={{ '& .MuiTextField-root': { width: '700px', p: 1, boxSizing: 'border-box' } }}
                noValidate
                autoComplete="off"
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '700px' }}>
                    {uploading && <LinearProgress />}
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <ImageUploader
                            image={formData.cover_image as File}
                            onSelect={(selectedImage) => handleImageSelected(selectedImage)}
                            onDelete={() => {
                                deleteImage()
                            }}
                        />
                    </div>

                    <TextField
                        label="title"
                        value={formData.title}
                        onChange={(e) => {
                            const value = e.target.value;
                            onUpdateFormData({
                                ...formData,
                                title: value
                            })
                        }}
                    />

                    <TextField
                        label="description"
                        multiline
                        value={formData.description}
                        rows={4}
                        onChange={(e) => {
                            const value = e.target.value;
                            onUpdateFormData({
                                ...formData,
                                description: value
                            })
                        }}
                    />

                    <TextField
                        label="seo title"
                        value={formData.seo_title}
                        onChange={(e) => {
                            const value = e.target.value;
                            onUpdateFormData({
                                ...formData,
                                seo_title: value
                            })
                        }}
                    />
                    <TextField
                        label="seo description"
                        multiline
                        value={formData.seo_description}
                        rows={4}
                        onChange={(e) => {
                            const value = e.target.value;
                            onUpdateFormData({
                                ...formData,
                                seo_description: value
                            })
                        }}
                    />
                    <FormControl sx={{ m: 1 }} variant="outlined" >
                        <InputLabel htmlFor="outlined-adornment-amount">seo url</InputLabel>
                        <OutlinedInput
                            value={formData.seo_url}
                            onChange={(e) => {
                                const value = e.target.value;
                                onUpdateFormData({
                                    ...formData,
                                    seo_url: value
                                })
                            }}
                            label="seo url"
                            startAdornment={<InputAdornment position="start">{`https://create.customtee.co/blog/:id/`}</InputAdornment>}
                        />

                    </FormControl>


                    <div style={{ marginLeft: '8px' }}>
                        <QuillEditor
                            html={description_html}
                            onGetHtml={(data) => {
                                onUpdateHtml(data)
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                        <Button variant="outlined" style={{ marginRight: '20px' }} onClick={onClose}>Cancel</Button>
                        <Button variant="contained" onClick={onSubmit}>Submit</Button>
                    </div>
                </div>
            </Box>
        </Drawer>
    )
}

export default BlobEditDrawer;