import {Box} from '@fower/react'
import {observer} from 'mobx-react-lite'
import {FC, useEffect, useState} from 'react'
import LoginModal from '../../common/LoginModal'
import {LoginMode} from '../../TypeDeclare'
import HomeFooter from './Footer'
import HomeHeader from './Header'
import KeyPoints from './KeyPoints'
import LandingSection from './LandingSection'
import IntroVideo from './IntroVideo'
import {useAuthStore} from '../../store/useAuthStore'
// import { LiveChatWidget } from '@livechat/widget-react'
import FeaturedProducts from './FeaturedProducts'
import {Helmet} from 'react-helmet'
import CloseIcon from '@mui/icons-material/Close';
import NewPopupGuide from './NewPopupGuide'
import Loading from '../../components/loading/Loading'
import {useNavigate} from "react-router-dom";

const Home: FC = observer(() => {
    const [showPopupAD, setShowPopupAD] = useState<boolean>(false)
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')
    const {isLogin} = useAuthStore()
    const navigator = useNavigate()

    const [downOpen, setDownOpen] = useState<boolean>(true)

    const canonicalUrl = global.location.href;

    const [loadingStates, setLoadingStates] = useState({
        child1: true,
    });

    const handleChildLoad = (childKey = 'child1') => {
        setLoadingStates((prevStates) => ({
            ...prevStates,
            [childKey]: false,
        }));
    };


    useEffect(() => {
        const saved = localStorage.getItem('ct-ad-showed')

        if (!saved && !isLogin) {
            setShowPopupAD(true)
        }
    }, [isLogin])
    return (
        <div style={loadingStates.child1 ? {
            maxHeight: '100vh',
            overflow: 'hidden',
        } : {}}>
            {
                loadingStates.child1 && <Loading/>
            }
            <Helmet>
                <title>Custom Tee Co. - Print on Demand Services</title>
                {/* Meta Description: */}
                <meta name="description"
                      content="Custom Tee Co. offers leading print-on-demand services for ecommerce businesses and individuals, providing custom t-shirts, hats, mugs, bags and more. Sign up for free and start your custom product business today."/>

                {/* Meta Keywords: */}
                <meta name="keywords"
                      content="print on demand, custom t-shirts, ecommerce solutions, custom products, personalized items, custom printing, dropshipping, custom apparel, personalized gifts, print on demand services"/>

                {/* Open Graph Tags (for social media): */}
                <meta property="og:title" content="Custom Tee Co. - Print on Demand Services"/>
                <meta property="og:description"
                      content="Custom Tee Co. offers leading print-on-demand services for ecommerce businesses and individuals, providing custom t-shirts, hats, mugs, bags, hoodies and more. Sign up for free and start your custom product business today."/>
                <meta property="og:image" content="http://www.customtee.co/path/to/your/image.jpg"/>
                <meta property="og:url" content="http://www.customtee.co"/>
                <meta property="og:type" content="website"/>
                <link rel="canonical" href={canonicalUrl}/>
            </Helmet>
            <Box w="100%" toCenterX column>
                <HomeHeader
                    fullWidth={false}
                    page={'home'}
                    onLogin={(mode: LoginMode) => {
                        setPreMode(mode)
                        setShowLogin(true)
                    }}
                    onShowMenu={() => {
                    }}
                />
                <LandingSection
                    onLogin={() => {
                        if (!isLogin) {
                            setShowLogin(true)
                        } else {
                            navigator('/dashboard')
                        }

                    }}
                />
                <KeyPoints/>

                <Box w="100%" column toCenter pt20>
                    <Box
                        hidden display--md="flex"
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '34px',
                            color: '#000000',
                            fontWeight: 'bold',
                            lineHeight: '54px'
                        }}
                        textCenter
                    >Custom Tee Shopify App</Box>
                    <Box
                        hidden--md
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '20px',
                            color: '#000000',
                            fontWeight: 'bold',
                            lineHeight: '54px'
                        }}
                        textCenter
                    >Custom Tee Shopify App</Box>
                    <Box
                        w="80%"
                        textCenter
                        style={{
                            fontFamily: 'Segoe UI, Segoe UI',
                            fontSize: '20px',
                            color: '#7B7B7B',
                            fontWeight: '600',
                            marginTop: '20px',
                            lineHeight: '25px'
                        }}
                    >Simply integrate your Shopify store with Custom Tee's fulfillment solution in just a few clicks.
                        We've launched our Shopify app!</Box>
                </Box>

                <IntroVideo/>

                <Box h="20px"></Box>

                {/* <DeveloperChoiceProducts /> */}
                <FeaturedProducts
                    onLoadComplete={() => handleChildLoad('child1')}
                />

                {
                    downOpen &&
                    <Box
                        w="80%"
                        flex
                        flexWrap
                        justifyContent="space-between"
                        boxSizing="border-box"
                        css={{
                            background: '#D1E5FF',
                            boxShadow: '6px 3px 6px 1px rgba(0,0,0,0.16)',
                            borderRadius: '10px',
                            padding: '40px'
                        }}
                        relative
                    >
                        <CloseIcon
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '20px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setDownOpen(false)
                            }}
                        />

                        <Box
                            flex
                            column
                            w={['100%', '100%', '100%', 'calc(100% - 400px)', 'calc(100% - 400px)']}
                            mb={['20px', '20px', '20px', '0px', '0px']}
                        >
                            <Box
                                css={{
                                    fontFamily: 'Segoe UI, Segoe UI',
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '30px',
                                    lineHeight: '32px',
                                }}
                                mb={['20px', '20px', '20px', '0px', '0px']}
                            >
                                Never miss a deal with the Custom Tee app
                            </Box>

                            <Box
                                w={['100%', '100%', '100%', '20%', '20%']}
                                mt="28px"
                            >
                                <img src="/images/pj.webp" loading='lazy' alt=""/>
                            </Box>

                            <Box
                                css={{
                                    color: '#7B7B7B',
                                    fontFamily: 'Segoe UI, Segoe UI',
                                    fontSize: '20px',
                                    lineHeight: '32px',
                                    fontWeight: '600'
                                }}
                                mt="28px"
                                mb={['20px', '20px', '20px', '0px', '0px']}
                            >
                                With a 4.6/5 rating and 360+ five-star reviews on the Apple Store, our CustomTee Print
                                On Demand app is loved by users for its ease and convenience. Join them today—download
                                our Shopify app and start creating custom products seamlessly!
                            </Box>

                        </Box>

                        <img src="/images/downImg.webp" loading='lazy' alt="downland"
                             style={{width: '270px', marginRight: '50px'}}/>

                    </Box>
                }

                <Box h="50px"></Box>

                <HomeFooter/>
                <LoginModal
                    isOpen={showLogin}
                    refreshAfter={false}
                    redirectToDashboardAfter={true}
                    preMode={preMode}
                    onClose={() => {
                        setShowLogin(false)
                    }}
                />
                <NewPopupGuide isOpen={showPopupAD} onClose={() => {
                    localStorage.setItem('ct-ad-showed', 'true')
                    setShowPopupAD(false)
                    setShowLogin(true)
                    setPreMode('signup')
                }}/>
                {/* <LiveChatWidget license='15929757' /> */}
            </Box>
        </div>
    )
})
export default Home
