import { Box } from '@fower/react'
import { Divider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { OrderDetailVO, ShippingMethod } from '../../TypeDeclare'
import ProductShippingMothedPicker from '../../common/ProductShippingMothedPicker'
import ShippingFeeDetail from '../../common/ShippingFeeDetail'

export type OrderDetailItemProps = {
  productId: string
  unitPrice: number
  quantity: number
  item: OrderDetailVO
  hasBottomDivider: boolean
  onUpdate: (newShippingMethod: ShippingMethod) => void
}
const OrderDetailShippingMethodItem: FC<OrderDetailItemProps> = observer(
  ({
    item,
    hasBottomDivider = true,
    onUpdate,
    quantity,
    productId,
    unitPrice,
  }) => {
    const hasDiscount = item.discount !== undefined && item.discount !== 0
    return (
      <>
        <Box toCenterY p3 w="100%" row toBetween>
          <Typography
            variant="h6"
            sx={{
              color: 'gray',
            }}
          >
            {item.title}
          </Typography>
          <ProductShippingMothedPicker
            value={item.shippingMethod?.id}
            options={item.shippingMethodOptions || []}
            onChange={(newValue) => {
              const newShippingMethod = item.shippingMethodOptions!.find(
                (sm) => sm.id === newValue,
              )
              //TODO: update
              if (newShippingMethod) {
                onUpdate(newShippingMethod)
              }
            }}
            p={0}
          />
        </Box>
        <Divider variant="middle" />
        <ShippingFeeDetail
          discount={item.discount}
          quantity={quantity}
          shippingMethod={item.shippingMethod}
          productId={productId}
          unitPrice={!hasDiscount ? unitPrice : Number(item.value)}
        />
        {hasBottomDivider && <Divider variant="middle" />}
      </>
    )
  },
)
export default OrderDetailShippingMethodItem
