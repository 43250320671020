import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDashbardStore, } from "../store/useDashboardStore";
import { useAuthStore } from "../../../store/useAuthStore";
import { Box } from "@fower/react";
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress, IconButton, ImageList } from "@mui/material";
import AddPaymentInfo from "./AddPaymentInfo";
import NewPaymentItem from "./NewPaymentItem";
import { getPaymentInfoList } from "../services/dashboardService";
import { PaymentCardInfoVO } from "../../../TypeDeclare";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

const NewPaymentInfoList: FC = () => {
    const dashboardStore = useDashbardStore()
    const authStore = useAuthStore();
    const [showAddNewCard, setShowAddNewCard] = useState<boolean>(false)
    const [list, setList] = useState<PaymentCardInfoVO[]>([])
    const [delOpen, setDelOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const delId = useRef<string>('')

    const getCV = () => {
        if (global.innerWidth > 1075) return 5
        if (global.innerWidth > 640) return 4
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())

    const reloadCards = async () => {
        setLoading(true)
        const cards = await getPaymentInfoList(authStore.token!);
        setList(cards)
        setLoading(false)
    }
    useEffect(() => {
        reloadCards()

        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    return (
        <ImageList
            sx={{
                width: '100%',
                height: '100%',
                marginTop: '0px',
                padding: '5px',
            }}
            variant="quilted"
            cols={columns}
            rowHeight={500}
        >

            {list.map((card) => {
                return (
                    <NewPaymentItem
                        key={card.id}
                        data={card}
                        onAddNewCard={() => {
                            setShowAddNewCard(true)
                        }}
                        onDelete={(item) => {

                            delId.current = item.id
                            setDelOpen(true)
                        }}
                    />
                )
            })}

            <Box
                w="100%"
                h="170px"
                flex
                toCenter
                css={{
                    borderRadius: '8px',
                    border: '1px solid #707070',
                    cursor: 'pointer',
                }}
                onClick={() => setShowAddNewCard(true)}
            >
                <IconButton aria-label="add">
                    <AddIcon />
                </IconButton>

            </Box>

            <AddPaymentInfo
                isShow={showAddNewCard}
                onComplete={(updated) => {
                    if (updated) {
                        reloadCards()
                    }
                    setShowAddNewCard(false)
                }}
            />

            <DeleteConfirmationDialog
                open={delOpen}
                onClose={() => {
                    setDelOpen(false)
                }}
                onConfirm={() => {
                    dashboardStore.deleteStripeCard(delId.current).then(() => {
                        reloadCards()
                        setDelOpen(false)
                    })
                }}
            />

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </ImageList>
    )
}

export default NewPaymentInfoList;