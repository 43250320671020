import { Alert, Dialog } from '@mui/material'
import { FC, useState } from 'react'
import { LoginMode, StyledSnackbar } from '../TypeDeclare'
import LoginBox from './LoginBox'
import { useNavigate } from 'react-router-dom'

export type LoginModalProps = {
    refreshAfter: boolean
    redirectToDashboardAfter?: boolean
    isOpen: boolean
    preMode?: LoginMode
    onClose: () => void
    onSignUp?: (userName: string, pwd: string, pwd2: string) => void
}
const LoginModal: FC<LoginModalProps> = ({
    isOpen,
    onClose,
    refreshAfter,
    preMode,
    redirectToDashboardAfter = false,
}) => {
    const navigator = useNavigate()

    const [showMessageBar, setShowMessageBar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const handleMessageClose = () => {
        setShowMessageBar(false)
    }
    const handleResult = (success: boolean, errorMessage?: string) => {
        if (!success) {
            setErrorMessage(errorMessage!)
            setShowMessageBar(true)
        } else {
            if (refreshAfter) {
                navigator(0);
            } else {
                if (redirectToDashboardAfter) {
                    navigator('/dashboard')
                    return
                }
                onClose()
            }
        }
    }
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '900px', maxWidth: '900px' } }}
            open={isOpen}>
            <LoginBox
                refreshAfter={refreshAfter}
                onClose={onClose}
                onLoggedin={handleResult}
                onSignedUp={handleResult}
                preMode={preMode}
            />
            <StyledSnackbar
                autoHideDuration={3000}
                open={showMessageBar}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </StyledSnackbar>
        </Dialog>
    )
}
export default LoginModal
