import { FC } from 'react'
import EmptyNumberInput from './EmptyNumberInput'
import { InputAdornment } from '@mui/material'
import { Volume } from '../../../../TypeDeclare'
import { Box } from '@fower/react'

export type ShippingInfoProps = {
  weight: number
  volume: Volume
  onWeightChange?: (value: number) => void
  onVolumeChange?: (value: number, field: 'width' | 'height' | 'length') => void
  previewMode: boolean
}
const ShippingInfo: FC<ShippingInfoProps> = ({
  weight,
  volume,
  onWeightChange,
  onVolumeChange,
  previewMode,
}) => {
  return (
    <Box column spaceY2 p5>
      <EmptyNumberInput
        value={weight}
        label={'Weight'}
        previewMode={previewMode}
        endAdornment={<InputAdornment position="start">lbs</InputAdornment>}
        onUpdate={(newValue) => {
          onWeightChange && onWeightChange(newValue)
        }}
      />
      <Box row spaceX2>
        <EmptyNumberInput
          value={volume.width}
          label={'Width'}
          sx={{ width: '100%' }}
          previewMode={previewMode}
          endAdornment={<InputAdornment position="start">cm</InputAdornment>}
          onUpdate={(newValue) => {
            onVolumeChange && onVolumeChange(newValue, 'width')
          }}
        />
        <EmptyNumberInput
          value={volume.length}
          label={'Length'}
          sx={{ width: '100%' }}
          previewMode={previewMode}
          endAdornment={<InputAdornment position="start">cm</InputAdornment>}
          onUpdate={(newValue) => {
            onVolumeChange && onVolumeChange(newValue, 'length')
          }}
        />

        <EmptyNumberInput
          value={volume.height}
          label={'Height'}
          sx={{ width: '100%' }}
          previewMode={previewMode}
          endAdornment={<InputAdornment position="start">cm</InputAdornment>}
          onUpdate={(newValue) => {
            onVolumeChange && onVolumeChange(newValue, 'height')
          }}
        />
      </Box>
    </Box>
  )
}
export default ShippingInfo
