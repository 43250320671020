import { FC, useState } from 'react';
import { Box } from '@fower/react';
import { Tab, Tabs } from '@mui/material';
import NewPaymentInfoList from './NewPaymentInfoList';

const Payments: FC = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
    const handleTabChange = (_event: React.SyntheticEvent, value: number) => {
        setCurrentTabIndex(value)
    }
    return (
        <Box w-100p p10 column toCenterX spaceY10>
            <Box w-100p toRight>
                <Tabs value={currentTabIndex} onChange={handleTabChange}>
                    <Tab label="Payment methods" />
                    {/* <Tab label="Transactions" /> */}
                </Tabs>
            </Box>
            {
                currentTabIndex === 0 && <NewPaymentInfoList />
            }
        </Box>
    );
};
export default Payments