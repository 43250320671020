import { Box } from '@fower/react'
import { FC, useCallback, useEffect, useState } from 'react'
import ImageReEdit from '../pages/dashboard/upload_product/components/ImageReEdit'
import { observer } from 'mobx-react-lite'
import { removeDuplicateFiles } from './FileUtils'


export type ImagesSelectorProps = {
    title: string
    images: Array<string | undefined>
    upTo: number
    onUpdate: (imagesUndergo: Array<string | File>) => void
}

const ImagesSelector: FC<ImagesSelectorProps> = observer(
    ({ title, images, upTo, onUpdate }) => {
        const [imagesUndergo, setImagesUndergo] = useState<
            Array<string | File | undefined>
        >([])
        const reset = useCallback(() => {
            let imagesToAdd = images
            if (images.length < upTo) {
                imagesToAdd = [...images, undefined]
            }
            setImagesUndergo(imagesToAdd)
        }, [images, upTo])
        useEffect(() => {
            if (images) {
                reset()
            } else {
                setImagesUndergo([])
            }
        }, [images, reset, upTo])
        return (
            <>
                <Box m2>{title}</Box>
                <Box row w="100%" flexWrap>
                    {imagesUndergo.map((image, i) => {
                        return (
                            <ImageReEdit
                                key={`cover-${i}`}
                                multiple={true}
                                data={image}
                                onDelete={(data) => {
                                    const keep: Array<string | File> = imagesUndergo.filter(
                                        (ci) => {
                                            if (ci === undefined) return false
                                            if (typeof ci === 'string' && typeof data === 'string') {
                                                return ci !== data
                                            }
                                            if (typeof ci === 'object' && typeof data === 'object') {
                                                return ci.name !== data.name
                                            }
                                            return true
                                        },
                                    ) as Array<string | File>
                                    if (keep.length < upTo) {
                                        setImagesUndergo([...keep, undefined])
                                    } else {
                                        setImagesUndergo(keep)
                                    }
                                    onUpdate(keep)
                                }}
                                onFilesSelected={(files) => {

                                    const uploadedURLs = imagesUndergo.filter(
                                        (ci) => typeof ci === 'string',
                                    )
                                    const currentFiles = removeDuplicateFiles([
                                        ...imagesUndergo.filter((ci) => typeof ci === 'object'),
                                        ...files,
                                    ] as File[]).slice(0, upTo - uploadedURLs.length)

                                    const keep: Array<string | File> = [
                                        ...uploadedURLs,
                                        ...currentFiles,
                                    ] as Array<string | File>
                                    if (keep.length < upTo) {
                                        setImagesUndergo([...keep, undefined])
                                    } else {
                                        setImagesUndergo(keep)
                                    }
                                    onUpdate(keep)
                                }}
                            />
                        )
                    })}
                </Box>
            </>
        )
    },
)
export default ImagesSelector
