import {Box} from "@fower/react";
import {Button, Popover, Typography} from "@mui/material";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {ProductItemVO} from "../../../TypeDeclare";
import {useProductStore} from "../../product_list/store/useProdustListStore";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./styles.css"
import {useNavigate} from "react-router-dom";
import ShowColorPalette from "./ShowColorPalette";

const DetailPane: FC = () => {
    const productStore = useProductStore()
    const navigator = useNavigate()
    const data = undefined;
    const [item, setItem] = useState<(ProductItemVO & { psd_sku?: Array<string> }) | undefined>(data)
    const carouselRef = useRef<AliceCarousel>(null);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const updateImages = useCallback(() => {
        const arr = productStore.product!.coverImages.map((item, index) => {
            return (
                <Box textCenter key={index}>
                    <img
                        draggable={false}
                        src={item}
                        alt={`${productStore.product!.name}-${index}`}
                        style={{maxWidth: '478px', height: '100%', width: '100%'}}
                    />
                </Box>
            )
        })
        return arr
    }, [productStore.product])
    const [items, setItems] = useState<any[]>(updateImages())
    const [fee, setFee] = useState(0)
    useEffect(() => {
        const aRunner = async () => {
            await productStore.fillProductImages(productStore.product!.id)
            setItem(productStore.product)
            console.log(productStore.product)
            if (productStore.product) {
                // @ts-ignore
                setFee(productStore.product!.shippingMethods.find(item => {
                    return item.id === 'Economy Shipping'
                }).fee)
            }

            const items = updateImages()
            setItems(items)
        }

        aRunner()
    }, [productStore])

    // 监听窗口大小变化
    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {

                carouselRef.current._handleResized();  // 调用 refresh 方法来重新调整 Carousel
            }
        };

        // 绑定 resize 事件
        window.addEventListener('resize', handleResize);

        // 清除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function setImgWidthTo80(html: string) {
        // Parse the HTML string into a DOM structure
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Select all img elements
        const images = doc.querySelectorAll("img");

        images.forEach(img => {
            // Get current style and ensure width is set to 80%
            const style = img.getAttribute("style") || "";
            if (style.includes("width:")) {
                // Replace width if it exists
                img.setAttribute("style", style.replace(/width:\s*\d+%?/, "width: 80%"));
            } else {
                // Add width if it doesn't exist
                img.setAttribute("style", style + " width: 80%;");
            }
        });

        // Return modified HTML as a string
        return doc.body.innerHTML;
    }


    const renderPrevButton = () => (
        <ChevronLeftIcon style={{
            position: 'absolute',
            left: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '40px',
            cursor: 'pointer'
        }}/>

    );

    const renderNextButton = () => (
        <ChevronRightIcon style={{
            position: 'absolute',
            right: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '40px',
            cursor: 'pointer'
        }}/>

    );

    if (item === undefined) return <></>
    return (
        <>
            <Box w="80%" style={{marginTop: '20px'}} className="responsive-box">
                <Box w={['100%', '100%', '100%', '38%', '38%']}>
                    <Typography component="h1" color="#1F5BA7" fontWeight={"bold"} style={{fontSize: '24px'}}>
                        {item.name}
                    </Typography>

                    <Box w="100%" h="180px" flex justifyContent="space-between" style={{marginTop: '15px'}}>
                        <Box w="49%" h="100%" flex column justifyContent="center"
                             style={{background: '#F8F8F8', borderRadius: '4px', paddingLeft: '22px'}}>
                            <span style={{fontSize: '20px', color: '#030A1E'}}>Price</span>
                            <Typography color="#000000" fontWeight={'bold'}
                                        style={{fontSize: '30px', marginTop: '17px'}}>
                                ${item.pairPrize}
                            </Typography>

                        </Box>
                        <Box w="49%" h="100%" flex column justifyContent="center"
                             style={{background: '#F8F8F8', borderRadius: '4px', paddingLeft: '22px'}}>
                            <Box flex alignItems="center">
                                <img src="/icons/icon-rule.webp" alt="icon_rule"
                                     style={{width: '30px', height: '30px'}}/>
                                <span style={{fontSize: '14px', marginLeft: '8px'}}>{`${item.sizeRange}`}</span>
                            </Box>

                            <Box flex alignItems="center" style={{marginTop: '20px'}}>
                                <img src="/icons/icon-time.webp" alt="icon_time"
                                     style={{width: '28px', height: '33px'}}/>
                                <span style={{fontSize: '14px', marginLeft: '8px'}}>{item.daysInProduction} Days Turnaround
                                </span>
                            </Box>

                            <Box flex alignItems="center" style={{marginTop: '17px'}}>
                                <img src="/icons/icon-car.webp" alt="icon_car" style={{width: '30px', height: '23px'}}/>
                                <span style={{fontSize: '14px', marginLeft: '8px', lineHeight: '18px'}}>Shipping starts at:
                                    ${fee} <span style={{color: '#518DD9', cursor: 'pointer'}}
                                                 onClick={handleClick}>More</span></span>
                            </Box>

                            <Popover
                                sx={{
                                    '& .MuiPopover-paper': {
                                        background: '#D9D9D9'
                                    }
                                }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {
                                    item.shippingMethods.map((item) => {
                                        return (
                                            <Typography
                                                key={item.id}
                                                style={{
                                                    padding: '12px 12px 0 12px',
                                                    fontSize: '16px',
                                                    color: '#000000',
                                                    fontWeight: '400'
                                                }}
                                            >
                                                {item.id}:&nbsp;Delivery
                                                in &nbsp;{item.weightComment}<br/> ${item.fee}
                                            </Typography>
                                        )
                                    })
                                }
                            </Popover>

                        </Box>
                    </Box>


                    <Typography color="#373737" fontWeight={'bold'} style={{fontSize: '20px', marginTop: '30px'}}>
                        Color
                    </Typography>
                    <ShowColorPalette
                        colorOptions={productStore.colorOptions}
                    />

                    <Typography color="#373737" fontWeight={'bold'} style={{fontSize: '20px', marginTop: '30px'}}>
                        Size
                    </Typography>

                    {item.sizeTableImage && (
                        <img
                            src={item.sizeTableImage}
                            alt="sizetable"
                            style={{width: '100%', marginTop: '20px'}}
                        />
                    )}

                    {/* <img
                        src="/images/size.png"
                        alt="size"
                        style={{ width: '100%', marginTop: item.sizeTableImage ? '20px' : '0' }}
                    /> */}

                </Box>

                <Box w={['0', '0', '0', '2%', '2%']}></Box>

                <Box w={['100%', '100%', '100%', '60%', '60%']} flex column h="610px">
                    <Box w="100%" flex>
                        <Box w={['100%', '100%', '100%', '75%', '75%']}>
                            <AliceCarousel
                                infinite
                                mouseTracking
                                items={items}
                                ref={carouselRef}  // 通过 ref 获取 AliceCarousel 实例
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                            />
                        </Box>

                        <Box
                            display--lg="block"
                            hidden
                            w={['25%', '25%', '25%', '25%', '25%']}
                            style={{
                                overflowY: 'scroll', height: '478px', scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}>
                            {
                                productStore.product!.coverImages.map(img => {
                                    return (
                                        <Box
                                            className="imgList"
                                            style={{width: '100%'}}
                                            key={img}
                                        >
                                            <img
                                                draggable={false}
                                                src={img}
                                                alt={`${productStore.product!.name}-image`}
                                                style={{objectFit: 'contain', width: '100%', height: '100%'}}


                                            />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>

                    <Box w={['100%', '100%', '100%', '75%', '75%']} flex toCenter>
                        <Button style={{
                            background: '#4384D6',
                            fontSize: '20px',
                            cursor: 'pointer',
                            width: '60%',
                            borderRadius: '36px'
                        }} variant="contained" onClick={() => {
                            if (item.psd_sku && item.psd_sku.length > 0) {
                                navigator(`/editor?productId=${item.id}`)
                            } else {
                                navigator(`/oldEditor?productId=${item.id}`)
                            }

                        }}>Start Designing</Button>
                    </Box>

                </Box>


            </Box>

            <Box w="80%" mt8 textCenter>
                <Typography className="description" color="#373737" style={{fontSize: '16px'}}
                            dangerouslySetInnerHTML={{__html: setImgWidthTo80(item.description_html!)}}>
                </Typography>
            </Box>
        </>
    )
}

export default DetailPane;