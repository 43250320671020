import { Box } from '@fower/react'
import {
    Button,
    Grid,
    ImageList,
    ImageListItem,
    Skeleton,
    Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styles from '../../../product_list/ProductList.module.css'
import { OriginalProductItemVO } from '../../../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import { styled } from '@fower/styled'

type ItemViewProps = {
    data: OriginalProductItemVO
    onClick: (item: OriginalProductItemVO) => void
}
const StyledImage = styled('img')
const ItemView: FC<ItemViewProps> = ({ data, onClick, children }) => {
    const { name, coverImages, descShorten, pairPrice } = data

    return (
        <Box
            minW="280px"
            h="480px"
            border={(data.status !== 1) ? 'solid 3px rgba(0,0,0,0.2)' : 'solid 3px rgb(67, 132, 214)'}
            column
            overflowHidden
            bg={'#F4F6F7'}
            relative
            cursorPointer
            onClick={() => {
                onClick(data)
            }}
        >
            <StyledImage
                src={coverImages[0]}
                alt={name}
                className={styles.itemImage}
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '296px',
                }}
            />
            <Typography
                variant="h4"
                sx={{
                    m: 2,
                }}
            >
                {name}
            </Typography>
            <Box textXS ml4 mr4 mb2 gray600 h12 className={styles.multiLine}>
                {descShorten}
            </Box>
            <Box ml4 mt7 color="#527282" fontBold>
                ${pairPrice}
            </Box>

            <Box w="100%" flex justifyContent="flex-end">
                {children}
            </Box>
        </Box>
    )
}

export type HistoryProductsProps = {
    list: OriginalProductItemVO[]
    onSelect: (item: OriginalProductItemVO) => void
    onDelete?: (item: OriginalProductItemVO) => void
    onAdd?: (item: OriginalProductItemVO) => void
}
const HistoryProducts: FC<HistoryProductsProps> = observer(({ list, onSelect, onDelete, onAdd }) => {
    const getCV = () => {
        if (global.innerWidth > 1700) return 3
        if (global.innerWidth > 1400) return 2
        return 1
    }

    const [columns, setColumns] = useState<number>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    return (
        <ImageList
            sx={{
                width: '100%',
                height: '100%',
                marginTop: '0px',
                padding: '5px',
            }}
            variant="quilted"
            cols={columns}
            rowHeight={500}
        >
            {list.map((item, index) => {
                if (item.id === '-1') {
                    return (
                        <ImageListItem key={index.toString()}>
                            <Grid key={index.toString()} height={480} width={'100%'}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={296}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={184}
                                    sx={{
                                        bgcolor: 'grey.200',
                                    }}
                                />
                            </Grid>
                        </ImageListItem>
                    )
                }
                return (
                    <ImageListItem key={item.id}>
                        <ItemView
                            data={item}
                            onClick={(data) => {
                                onSelect(data)
                            }}
                        >
                            {
                                (item.status !== 1) &&
                                <Box
                                    flex
                                    justifyContent="flex-end"
                                    w="30px"
                                >
                                    <Button
                                        variant='contained'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onDelete && onDelete(item)

                                        }}
                                    >Delete</Button>
                                </Box>
                            }

                            {/* {
                                (item.status === 1 && item.is_featured === 0) &&
                                <Box
                                    flex
                                    justifyContent="flex-end"
                                    w="30px"
                                >
                                    <Button
                                        variant='contained'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onAdd && onAdd(item)

                                        }}
                                    >add</Button>
                                </Box>
                            } */}
                        </ItemView>
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
})
export default HistoryProducts