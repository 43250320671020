import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { LS } from '../../../common/LocalStorage';
import { getURLParams } from '../../../common/StringUtils';
import {
    CATEGORIES,
    CategoryInfo,
    ColorOption,
    ColorSetVO,
    DEVELOPER_CHOICE,
    FeaturedProductListItem,
    MyProductItemVO,
    ProductItemVO,
    ShippingMethod,
    SizeOption,
    SizeTableItemProps,
    ZeroVolume,
} from '../../../TypeDeclare';
import { updateProduct } from '../../dashboard/services/dashboardService';
import {
    getFeaturedProducts,
    getProductByID,
    getProductByKeyword,
    getProducts,
    parseFeaturedProduct,
    parseProduct,
    parseShippingMethods,
} from '../services/productService';

const skeletonProducts: ProductItemVO[] = [
    '-1',
    '-1',
    '-1',
    '-1',
    '-1',
    '-1',
    '-1',
    '-1',
    '-1',
].map((id, i) => {
    return {
        id: id,
        name: '',
        category: { id, parentName: '', category: 0, tag: 0 },
        type: '',
        images: [],
        descShorten: '',
        desc: '',
        sizeRange: '',
        price: '',
        daysInProduction: 0,
        sizeTableImage: '',
        colorId: '',
        shippingFee: '',
        isFullPrint: false,
        productId: '',
        shippingMethods: [],
        coverImages: [],
        weight: 0,
        volume: {
            width: 0,
            height: 0,
            length: 0,
        },
        readyToShip: false
    };
});
const CRITICAL_POINT = 0.5 * 0.5 * 0.5;

export class ProductStore extends LS {
    @observable product?: ProductItemVO;
    @observable products: ProductItemVO[] = skeletonProducts;
    @observable sku: string = '';
    @observable size: SizeOption = { id: '0', value: '' };
    @observable quantity: number = 1;
    @observable countryCode: string = 'US';
    @observable targetCategory: CategoryInfo = CATEGORIES[0];
    @observable keyword: string = '';
    @observable colorOptions: ColorOption[] = [];
    @observable sizeOptions: SizeOption[] = [];
    @observable currentPage: number = 1;
    @observable pageCount: number = 1;
    @observable readyToShip: boolean = false;

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get shippingFeeFactor(): 'volume' | 'weight' {
        const v = this.product?.volume || ZeroVolume;
        const cv = v.height * v.length * v.width * this.quantity;
        if (cv > CRITICAL_POINT) {
            return 'volume';
        }
        return 'weight';
    }
    @computed
    get shippingMethods(): ShippingMethod[] {
        //TODO: need to differentiate by factor
        // if(this.shippingFeeFactor === 'volume'){

        // }
        return this.product?.shippingMethods || [];
    }
    @computed
    get list(): ProductItemVO[] {
        if (this.keyword === '') {
            return this.products;
        }
        return this.products.filter((p) => {
            return p.name.toLowerCase().includes(this.keyword.toLowerCase());
        });
    }
    @computed
    get sizeTableList(): SizeTableItemProps[] {
        return [
            {
                id: '1',
                size: 'S',
                length: '27',
                chest: '38-41',
                sleeveLength: '33 ½',
            },
            {
                id: '2',
                size: 'M',
                length: '28',
                chest: '42-45',
                sleeveLength: '34 ½',
            },
            {
                id: '3',
                size: 'L',
                length: '29',
                chest: '46-49',
                sleeveLength: '35 ½',
            },
            {
                id: '4',
                size: 'XL',
                length: '30',
                chest: '50-53',
                sleeveLength: '36 ½',
            },
            {
                id: '5',
                size: '2XL',
                length: '31',
                chest: '54-58',
                sleeveLength: '37 ½',
            },
        ];
    }

    @action
    setCountryCode(value: string) {
        this.countryCode = value;
    }
    @action
    setKeyword(newValue: string) {
        this.keyword = newValue;
    }

    @action
    setSize(newValue: SizeOption) {
        this.size = newValue;
    }

    @action
    setQuantity(newValue: number) {
        this.quantity = newValue;
    }

    @action
    setSku(newValue: string) {
        this.sku = newValue;
    }

    @action
    setProduct(value: ProductItemVO | undefined): boolean {
        if (value === undefined) return false;
        this.product = value;
        return true;
    }

    @action
    parseProducts(list: any[]) {
        list.forEach((element) => {
            this.addProduct(parseProduct(element));
        });
    }
    @action
    parseDCProducts(list: any[]) {
        const grouped = list.reduce((prev, current) => {
            const p = parseProduct(current);
            if (prev[p.category.parentName] === undefined) {
                prev[p.category.parentName] = [p];
            } else {
                prev[p.category.parentName].push(p);
            }
            return prev;
        }, {});
        const groupedArray = [];
        for (const key in grouped) {
            if (Object.prototype.hasOwnProperty.call(grouped, key)) {
                const element = grouped[key];
                groupedArray.push(element.sort(() => Math.random() - 0.5));
            }
        }
        groupedArray
            .sort(() => Math.random() - 0.5)
            .slice(0, DEVELOPER_CHOICE.length)
            .forEach((element) => {
                this.addProduct(element[0]);
            });
    }
    @action
    async fillProductImages(id: string) {
        if (this.product?.images.length === 2) return;
        const data = await getProductByID(id);
        const produtRawData = data['product'];
        runInAction(() => {
            this.reparsePresets(data);
        });
        if (produtRawData['image_urls'].length <= 1) return;
        runInAction(() => {
            this.product = parseProduct(produtRawData);
        });
    }

    @action
    async loadSingleProdcut(id: string): Promise<ProductItemVO> {
        return new Promise(async (resolve) => {
            const data = await getProductByID(id);
            const element = data['product'];
            runInAction(() => {
                this.reparsePresets(data);
                this.product = parseProduct(element);
                this.addProduct(this.product);
                resolve(this.product);
            })
        });
    }
    async loadMyProdcut(from: MyProductItemVO): Promise<MyProductItemVO> {
        return new Promise(async (resolve) => {
            const data = await getProductByID(from.id);

            const element = data['product'];
            const sms: ShippingMethod[] = parseShippingMethods(element);
            this.reparsePresets(data);
            runInAction(() => {
                resolve({
                    ...from,
                    description_html: element['description_html'],
                    costPrice: element['cost_price'],
                    pairPrize: element['pair_price'],
                    shippingMethods: sms,
                    daysInProduction: element['days_in_production']
                });
            });
        });
    }

    async loadMyProdcutByID(mvoId: string): Promise<MyProductItemVO> {
        return new Promise(async (resolve) => {
            const data = await getProductByID(mvoId);
            const element = data['product'];
            const pvo = parseProduct(element) as MyProductItemVO
            const sms: ShippingMethod[] = parseShippingMethods(element);
            this.reparsePresets(data);
            runInAction(() => {
                resolve({
                    ...pvo,
                    shippingMethods: sms,
                });
            });
        });
    }

    @action
    addSizeOption(element: SizeOption) {
        const target = this.sizeOptions.find((e) => {
            return e.id === element.id;
        });
        if (!target) {
            this.sizeOptions.push(element);
        }
    }

    @action
    addColorOption(element: ColorOption) {
        const target = this.colorOptions.find((e) => {
            return e.id === element.id;
        });
        if (!target) {
            this.colorOptions.push(element);
        }
    }
    @action
    addProduct(vo: ProductItemVO) {
        const existence = this.products.find((p) => {
            return p.id === vo.id;
        });
        if (!existence) {
            this.products.push(vo);
        }
    }
    @action
    reparsePresets(element: any) {
        this.product = parseProduct(element.product);
        this.sizeOptions = [];
        this.colorOptions = [];
        const inventory: any[] = element['inventory'] as any[];
        inventory.forEach((item, index) => {
            const dimensions = item['dimensions'] as any[];
            dimensions.forEach((d) => {
                if (d['attribute_name'] === 'Size') {
                    this.addSizeOption({
                        id: String(d['id']),
                        value: String(d['value']),
                    });
                } else if (d['attribute_name'] === 'Color') {
                    this.addColorOption({
                        id: String(d['id']),
                        value: String(d['comment']),
                        name: String(d['value']),
                    });
                }
            });
        });
    }

    @action
    setCategory(value: CategoryInfo) {
        this.targetCategory = value;
    }
    @action
    setCurrentPage(value: number) {
        this.currentPage = value;
    }
    @action
    setReadyToShip(value: boolean) {
        this.readyToShip = value;
    }
    @action
    async reloadByCategory(value: CategoryInfo, page: number): Promise<boolean> {
        try {
            runInAction(() => {
                this.products = skeletonProducts;
            });
            const data = await getProducts(value, page, this.readyToShip);
            runInAction(() => {
                this.products = [];
                this.pageCount = Math.ceil(Number(data['count']) / 60);
                this.parseProducts(data['results']);
                // this.targetCategory = value;
                // this.currentPage = page;
            });
            return true;
        } catch {
            return false;
        }
    }
    @action
    async reloadByKeywoard(keyword: string, page: number): Promise<boolean> {
        try {
            runInAction(() => {
                this.products = skeletonProducts;
            });
            const data = await getProductByKeyword(keyword, page);
            runInAction(() => {
                this.products = [];
                this.pageCount = Math.ceil(Number(data['count']) / 60);
                this.parseProducts(data['results']);
                this.currentPage = page;
            });
            return true;
        } catch {
            return false;
        }
    }

    @action
    reloadDC() {
        const p1 = getProducts(this.targetCategory, 1, this.readyToShip);
        const p2 = getProducts(this.targetCategory, 2, this.readyToShip);
        Promise.all([p1, p2]).then((data) => {
            const allProduct = [...data[0].results, ...data[1].results];
            runInAction(() => {
                this.products = [];
                this.parseDCProducts(allProduct);
            });
        });
    }

    async reloadFeaturedProducts() {

        const res = await getFeaturedProducts();
        const sections: any[] = res.sections;
        const products: any = res.products;
        const featuredList: Array<FeaturedProductListItem> = sections.map((section) => {
            const { section_name, section_description } = section;
            const ps = products[section_name]
            const targetProducts: Array<ProductItemVO> = ps.map((p: any) => {
                return parseFeaturedProduct(p);
            });
            const featureItem: FeaturedProductListItem = {
                name: section_name,
                desc: section_description,
                products: targetProducts
            }
            return featureItem
        });
        return featuredList;
    }
    @action
    parseFromURLParams() {
        const params = getURLParams();
        this.sku = params['sku'];
        this.quantity = Number(params['quantity']);
    }

    async sellProduct(
        token: string,
        productId: string,
        title: string,
        desc: string,
        onSale: boolean,
        price: number,
        description_html: string,
    ) {
        const formData = new FormData();
        formData.append('name', title);
        formData.append('short_description', desc);
        formData.append('sell_price', String(price));
        formData.append('buy_only', onSale ? '0' : '1');
        formData.append('description_html', description_html);
        const result = await updateProduct(token, productId, formData);
        return result;
    }

    async editProduct(
        token: string,
        productId: string,
        title: string,
        desc: string,
        price: number,
        description_html: string,
    ) {
        const formData = new FormData();
        formData.append('name', title);
        formData.append('short_description', desc);
        formData.append('sell_price', String(price));
        formData.append('description_html', description_html);
        const result = await updateProduct(token, productId, formData);
        return result;
    }
    async putOutOfStore(token: string, productId: string) {
        const formData = new FormData();
        formData.append('buy_only', '1');
        const result = await updateProduct(token, productId, formData);
        return result;
    }

    async getProductByID(id: string): Promise<void> {
        const productData = await getProductByID(id);
        this.reparsePresets(productData);
    }
    getSelectionColorSubSet(
        color: ColorOption,
        item: ProductItemVO
    ): ColorSetVO[] {
        const targets = item.fullColorSet?.filter((fcs) => {
            return fcs.colorId === color.id;
        });
        return targets || [];
    }
    uppdateCachedImageByColor(fcs: ColorSetVO[]) {
        const result = fcs.map((fc, index) => {
            return {
                alias: `${fc.face}_image`,
                fileName: `base64_image_${index}`,
                name: fc.face,
                url: fc.url,
            };
        });
        this.save('uploadedImages', JSON.stringify(result));
    }
}
