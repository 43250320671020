import { FabricJSEditor } from 'fabricjs-react';
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { TextFontModule } from './TextFontModule';
import { ImageData, CTModule } from './CTModule';
import { EditorService } from '../../services/editorService';
import { ActionType, UnifyActionRecorder } from './UnifyActionRecorder';
import {
    ColorOption,
    FaceType,
    PreviewImageItem,
    ProductItemVO,
} from '../../../../TypeDeclare';
import { LS } from '../../../../common/LocalStorage';
import ReactGA from 'react-ga';
export class EditorStore extends LS {
    @observable targetFace: FaceType = 'front';
    @observable product?: ProductItemVO;
    @observable actionRecorder?: UnifyActionRecorder;
    @observable frontTeeStore: CTModule = new CTModule('front', this);
    @observable backTeeStore: CTModule = new CTModule('back', this);
    @observable showBottomMenu: boolean = false;
    @observable uploadingProgress: number = 0;
    @observable uploading: boolean = false;
    @observable uploadError: boolean = false;
    @observable showUpload: boolean = false;
    purchaseURLAfterUpload: string = '';
    @observable newCreatedProductId: string = '';
    productLoaded: boolean = false;
    cacheColor?: ColorOption = undefined;

    service: EditorService = new EditorService();
    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get tee(): CTModule {
        return this.targetFace === 'front' ? this.frontTeeStore : this.backTeeStore;
    }
    @computed
    get colorName(): string {
        return this.tee.color.name;
    }
    @computed
    get templateColor(): ColorOption {
        return this.tee.color;
    }
    @computed
    get selectedTextFont(): TextFontModule {
        return this.tee.selectedTextFont;
    }

    @computed
    get newTextFont(): TextFontModule {
        return this.tee.newTextFont;
    }

    @computed
    get hasSelectedObject(): boolean {
        return this.tee.hasSelectedObject;
    }

    @computed
    get selectedObjectType(): string | undefined {
        return this.tee.selectedObjectType;
    }

    @computed
    get loadingImage(): boolean {
        if (!this.product) return false;
        if (this.product.images.length <= 1) {
            return this.frontTeeStore.loadingImage;
        }
        return this.frontTeeStore.loadingImage || this.backTeeStore.loadingImage;
    }

    @action
    setShowBottomMenu(value: boolean): void {
        this.showBottomMenu = value;
    }
    @action
    initProduct(value: ProductItemVO) {
        this.product = value;
    }
    @action
    initEditor(e: FabricJSEditor, targetFace: FaceType) {
        if (targetFace === 'front') {
            this.frontTeeStore.initEditor(e);
        } else {
            this.backTeeStore.initEditor(e);
        }
    }
    @action
    initAll() {
        if (this.frontTeeStore.editor && this.backTeeStore.editor && this.product) {
            if (!this.actionRecorder) {
                this.actionRecorder = new UnifyActionRecorder(
                    this.frontTeeStore.editor,
                    this.backTeeStore.editor,
                    this
                );
            }
        }
    }
    loadProductImages(newProduct: ProductItemVO) {
        if (this.productLoaded) return
        this.productLoaded = true;


        newProduct.images.forEach((image) => {
            if (image.face !== undefined) {
                this.initTeeTemp(
                    image.url,
                    image.face as FaceType,
                    image.xOff,
                    image.yOff,
                    image.pW,
                    image.pH
                );
            }
        });
        if (this.cacheColor) {
            this.changeTemplateColor(this.cacheColor, false, 2)
            this.cacheColor = undefined
        }
    }

    @action
    setNewCreatedProductId(value: string) {
        this.newCreatedProductId = value;
    }

    addImage(imageData: string): void {
        this.tee.addImage(imageData);
    }

    addText(): void {
        this.tee.addText();
    }

    @action
    initTeeTemp(
        path: string,
        targetFace: FaceType,
        xOff: number,
        yOff: number,
        pW: number,
        pH: number
    ): void {
        if (targetFace === 'front') {
            this.frontTeeStore.initTeeTemp(path, xOff, yOff, pW, pH);
        } else {
            this.backTeeStore.initTeeTemp(path, xOff, yOff, pW, pH);
        }
    }

    deleteSelectedElement() {
        this.tee.deleteSelectedElement();
    }
    @action
    toTeeFront(needRecord: boolean = true) {
        console.log('toTeeFront', needRecord);

        if (this.targetFace === 'front') return;
        this.targetFace = 'front';
        if (needRecord) {
            this.actionRecorder?.saveState(ActionType.FACE_CHANGED);
        }
    }
    @action
    toTeeBack(needRecord: boolean = true) {
        if (this.targetFace === 'back') return;
        this.targetFace = 'back';
        if (needRecord) {
            this.actionRecorder?.saveState(ActionType.FACE_CHANGED);
        }
    }

    @action
    changeTemplateColor(newColor: ColorOption, needRecord: boolean = true, from: number = 1) {

        if (!this.productLoaded) {
            this.cacheColor = newColor
            return;
        }
        // console.log('EditorStore changeTemplateColor ', needRecord, from);
        this.frontTeeStore.changeTemplateColor(newColor);
        this.backTeeStore.changeTemplateColor(newColor);
        if (needRecord) {
            this.actionRecorder?.saveState(ActionType.TEE_COLOR_CHANGED, 'front');
        }
    }

    toBase64(): ImageData | undefined {
        return this.tee.toBase64();
    }

    createAssets(countFrom: number = 3): PreviewImageItem[] {
        const result: PreviewImageItem[] = [];
        let count = countFrom;
        let imageCount = 1;
        if (this.frontTeeStore.edited) {
            const front = this.frontTeeStore.toBase64();
            result.push({
                fileName: `base64_image_${count}`,
                name: 'front',
                data: front!.data,
                alias: 'front_image',
                format: 'png',
            });
            count++;
            const frontAssets = this.frontTeeStore.getAssetsData();
            frontAssets.forEach((a) => {
                result.push({
                    fileName: `base64_image_${count}`,
                    name: a.name,
                    data: a.data,
                    alias: `image${imageCount}`,
                    format: 'png',
                });
                count++;
                imageCount++;
            });
        } else {
            result.push({
                fileName: `base64_image_${count}`,
                name: 'front',
                url: this.product?.images[0].url,
                alias: 'front_image',
                format: 'png',
            });
            count++;
        }
        if (Number(this.product?.images.length) >= 2) {
            if (this.backTeeStore.edited) {
                const back = this.backTeeStore.toBase64();
                const backAssets = this.backTeeStore.getAssetsData();
                result.push({
                    fileName: `base64_image_${count}`,
                    name: 'back',
                    data: back!.data,
                    alias: 'back_image',
                    format: 'png',
                });
                count++;
                backAssets.forEach((b) => {
                    result.push({
                        fileName: `base64_image_${count}`,
                        name: b.name,
                        data: b.data,
                        alias: `image${imageCount}`,
                        format: 'png',
                    });
                    count++;
                    imageCount++;
                });
            } else {
                result.push({
                    fileName: `base64_image_${count}`,
                    name: 'back',
                    url: this.product?.images[1].url,
                    alias: 'back_image',
                    format: 'png',
                });
                count++;
            }
        }
        this.frontTeeStore.toggleMask(true);
        this.backTeeStore.toggleMask(true);
        return result;
    }

    createFullColorPreviews(colorOptions: ColorOption[]): PreviewImageItem[] {
        this.frontTeeStore.toggleMask(false);
        this.backTeeStore.toggleMask(false);
        const currentColor = this.frontTeeStore.color;
        const result: PreviewImageItem[] = [];
        let imageCount = 1;
        let count = 1;
        colorOptions.forEach((color) => {
            this.changeTemplateColor(color, false, 3);
            const front = this.frontTeeStore.toBase64();
            result.push({
                fileName: `base64_image_${count}`,
                name: `${color.id}-${color.name}`,
                data: front!.data,
                alias: `front_fullcolor_image_${imageCount}`,
                format: 'jpeg',
            });
            count++;
            if (this.product?.images.length === 2) {
                const back = this.backTeeStore.toBase64();
                result.push({
                    fileName: `base64_image_${count}`,
                    name: `${color.id}-${color.name}`,
                    data: back!.data,
                    alias: `back_fullcolor_image_${imageCount}`,
                    format: 'jpeg',
                });
                count++;
            }
            imageCount++;
        });
        this.changeTemplateColor(currentColor, false, 4);
        return result;
    }
    @action
    async uploadItem(items: PreviewImageItem[]) {
        const needUploadCount = items.reduce((prev, current, _index) => {
            if (current.data !== undefined) {
                return prev + 1;
            }
            return prev;
        }, 0);
        if (needUploadCount === 0) {
            return items;
        }
        runInAction(() => {
            this.uploadingProgress = 0;
            this.uploading = true;
            this.showUpload = true;
        });
        try {
            const result = await this.service.uploadImageItems(items, (p) => {
                runInAction(() => {
                    this.uploadingProgress = p * 100;
                });
            });
            if (result.length === 0) {
                ReactGA.event({
                    category: 'Editor',
                    action: 'uploadFail',
                    label: 'Network Error',
                });
                runInAction(() => {
                    this.uploadError = true;
                });
                return [];
            }
            runInAction(() => {
                this.uploading = false;
            });
            ReactGA.event({
                category: 'Editor',
                action: 'uploaded',
                label: JSON.stringify(result),
            });
            return result;
        } catch (error) {
            runInAction(() => {
                this.uploading = false;
            });
            ReactGA.event({
                category: 'Editor',
                action: 'uploadFail',
                label: JSON.stringify(error),
            });
            return [];
        }
    }
    @action
    resetUpload() {
        runInAction(() => {
            this.uploading = false;
            this.uploadingProgress = 0;
            this.uploadError = false;
        });
    }
    @action
    closeUpload() {
        runInAction(() => {
            this.showUpload = false;
        });
    }

    redo() {
        this.tee.clearActiveObject();
        this.tee.updateHasSelectedObject();
        if (this.actionRecorder) {
            this.actionRecorder.redo(() => {
                this.tee.resetEditableFrame();
            });
        }
    }
    undo() {
        this.tee.clearActiveObject();
        this.tee.updateHasSelectedObject();
        if (this.actionRecorder) {
            this.actionRecorder.undo(() => {
                this.tee.resetEditableFrame();
            });
        }
    }
    resize(width: number) {
        if (width >= 1024) {
            this.setShowBottomMenu(true);
        } else {
            this.setShowBottomMenu(false);
        }
    }
    reset() {
        this.toTeeFront(false)
        this.productLoaded = false;
        this.actionRecorder?.clear();
        this.actionRecorder = undefined;
        this.cacheColor = undefined;
        this.frontTeeStore.reset();
        this.backTeeStore.reset();
    }
}