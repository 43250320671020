import { FC, useContext } from 'react'
import { createContext } from 'react'
import { OriginalProductStore } from './OriginalProductStore'

const theStore = new OriginalProductStore()
const OriginalProductContext = createContext<OriginalProductStore>(theStore)

export const OriginalProductProvider: FC = ({ children }) => {
  return (
    <OriginalProductContext.Provider value={theStore}>
      {children}
    </OriginalProductContext.Provider>
  )
}

export const useOriginalProductStore = (): OriginalProductStore => {
  const store = useContext(OriginalProductContext)
  if (store == null) {
    throw new Error(`useOriginalProductStore must be used within a OriginalProductProvider`)
  }
  return store
}
