import {Box} from '@fower/react';
import {FC} from 'react';
import ToolBar from './ToolBar';
import {observer} from 'mobx-react-lite';
import MobileToolBar from './MobileToolBar';
import {ProductItemVO} from '../../../../TypeDeclare';

export type BottomBarProps = {
  product?: ProductItemVO
}
const BottomBar: FC<BottomBarProps> = observer(({product}) => {
  return (
    <Box
      h20--sm
      h16
      bgWhite
      w='100%'
      toCenter
      border-1
      toBetween
      shadowHuge
      roundedTopRightHuge
      roundedNone--lg
      roundedTopLeftHuge
    >
      <Box display={
        ['none', 'none', 'none', 'inline-flex', 'inline-flex']
      }>
        <ToolBar/>
      </Box>
      <Box display={
        ['inline-flex', 'inline-flex', 'inline-flex', 'none', 'none']
      }>
        <MobileToolBar product={product}/>
      </Box>
    </Box>
  );
});
export default BottomBar;
