import { Box } from "@fower/react";
import { FC, useEffect, useRef, useState } from "react";
import { ColorOption, LoginMode, SizeOption } from "../../TypeDeclare";
import { useNavigate, useParams } from "react-router-dom";
import BetterstyleDetailPane from "./BetterstyleDetailPane";
import { observer } from "mobx-react-lite";
import HomeFooter from "../home/Footer";
import { doGet } from "../../common/commonService";
import { useAuthStore } from "../../store/useAuthStore";
import LoginModal from "../../common/LoginModal";
import Loading from "../../components/loading/Loading";

interface param {
    id: string
    name: string
    desc: string
    pairPrize: string
    daysInProduction: string
    is_expired: boolean
    days_left: number
}

const BetterstyleDetail: FC = observer(() => {

    const navigator = useNavigate()

    const { productId } = useParams()

    const [loading, setLoading] = useState<boolean>(true)

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const [item, setItem] = useState<param>({
        id: '',
        name: '',
        desc: '',
        pairPrize: '0',
        daysInProduction: '0',
        is_expired: false,
        days_left: 0
    })

    const [sizeList, setSizeList] = useState<SizeOption[]>([])
    const [selectSize, setSelectSize] = useState<SizeOption>({
        id: '',
        value: ''
    })

    const [colorOptions, setColorOptions] = useState<ColorOption[]>([])
    const [selectedColor, setSelectedColor] = useState<ColorOption>({
        id: '',
        value: '',
        name: ''
    })

    const [imgList, setImgList] = useState<string[]>([])

    const [qty, setQty] = useState<number>(1)
    const sku = useRef('')

    const fullColorDict = useRef<Record<string, string[]>>({})
    const mappingDict = useRef<Record<string, {
        dimension1: string,
        dimension2: string,
        product_sku: string,
        sale_price: number
    }>>({})

    function getStoreName(url: string) {
        // Use a regular expression to capture the store name between "/shop/" and the next "/"
        const match = url.match(/\/shop\/([^/]+)/);
        return match ? match[1] : null; // Return the captured name or null if not found
    }

    useEffect(() => {
        const profile_path = getStoreName(global.location.href)
        localStorage.setItem('profile_path', profile_path!)
        const pro = productId?.split('-')
        const newProductId = pro![pro!.length - 1]


        doGet(`https://api.customtee.co/api/customteeShopView/${newProductId}/`, { shop: profile_path }).then(({ data }) => {

            const { mapping, dimension1, dimension2
            } = data.inventory

            mappingDict.current = mapping

            const sizeOptions = dimension1.map((item: { id: any; value: any; }) => {
                return {
                    id: item.id,
                    value: item.value
                }
            })
            setSizeList(sizeOptions)

            setSelectSize(sizeOptions[0])

            const colorList = dimension2.map((item: { id: any; comment: any; value: any; }) => {
                return {
                    id: item.id,
                    value: item.comment,
                    name: item.value
                }
            })
            setColorOptions(colorList)

            setSelectedColor(colorList[0])

            if (data.product.full_color_dict) {
                fullColorDict.current = data.product.full_color_dict
                if (Object.keys(fullColorDict.current).length === 1) {

                    setImgList(Object.values(fullColorDict.current)[0])
                } else {
                    setImgList(data.product.full_color_dict[colorList[0].id])
                }
            } else {
                setImgList([data.product.cover_image])
            }

            const skuObj = mappingDict.current[sizeOptions[0].id + '-' + colorList[0].id]
            sku.current = skuObj.product_sku
            const pairPrize = skuObj.sale_price

            const result = {
                id: data.product.id,
                name: data.product.name,
                desc: data.product.description,
                pairPrize: pairPrize + '',
                daysInProduction: data.product.days_in_production,
                is_expired: data.product.is_expired,
                days_left: data.product.days_left,
                description_html: data.product.description_html
            }
            setItem(result)
            setLoading(false)
        })

    }, [productId])

    useEffect(() => {

        const skuObj = mappingDict.current[selectSize.id + '-' + selectedColor.id]
        if (skuObj) {
            sku.current = skuObj.product_sku
            const pairPrize = skuObj.sale_price
            setItem(pre => {
                return {
                    ...pre,
                    pairPrize: pairPrize + '',
                }
            })
        }

    }, [selectSize, selectedColor])

    function onColorChanged(color: ColorOption) {
        setSelectedColor(color)

        if (Object.keys(fullColorDict.current).length === 1) {
            setImgList(Object.values(fullColorDict.current)[0])
        } else if (Object.keys(fullColorDict.current).length === 0) {

        } else {
            setImgList(fullColorDict.current[color.id])
        }


    }

    function onSizeChanged(size: SizeOption) {
        setSelectSize(size)
    }

    function onUpdateQty(value: number) {
        setQty(value)
    }

    const { isLogin } = useAuthStore()

    function onBuy() {
        if (!isLogin) {
            setPreMode('login')
            setShowLogin(true)
        } else {
            navigator(`/shopCheckout?sku=${sku.current}&quantity=${qty}`)
        }

    }

    function goBack() {
        const profile_path = getStoreName(global.location.href)
        navigator('/shop/' + profile_path, { replace: true })
    }


    return (
        <Box
            w="100%"
            toCenterX
            column
            style={loading ? {
                maxHeight: '100vh',
                overflow: 'hidden',
            } : {}}
        >

            {
                loading && <Loading />
            }


            {
                !loading && <>
                    <Box
                        w="80%"
                        flex
                        alignItems="center"
                        style={{
                            cursor: 'pointer'
                        }}

                    >
                        <img
                            src="/icons/icon-back.webp"
                            alt="icon_back"
                            width={'32px'}
                            height={'32px'}
                            onClick={() => goBack()}
                        />
                        <span
                            style={{
                                fontSize: '20px',
                                marginLeft: '5px'
                            }}
                            onClick={() => goBack()}
                        >
                            BACK
                        </span>
                    </Box>

                    <BetterstyleDetailPane
                        onColorChanged={onColorChanged}
                        onSizeChanged={onSizeChanged}
                        imgList={imgList}
                        item={item}
                        loading={loading}
                        sizeList={sizeList}
                        selectSize={selectSize}
                        colorOptions={colorOptions}
                        selectedColor={selectedColor}
                        qty={qty}
                        onUpdateQty={onUpdateQty}
                        onBuy={onBuy}
                    />

                    <Box mt10></Box>

                    <HomeFooter />
                </>
            }

            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={false}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />


        </Box>
    )
})

export default BetterstyleDetail;