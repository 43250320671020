import { FC } from 'react';
import { Box } from '@fower/react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from '@fower/styled';
import HistoryBar from '../bottom/HistoryBar';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export interface TopBarProps {
    readyToShip: boolean
    onRedo: () => void,
    onUndo: () => void,
}

const TopBar: FC<TopBarProps> = ({
    onRedo, onUndo, readyToShip
}) => {
    const navigator = useNavigate();
    const StyledArrowBack = styled(KeyboardBackspaceIcon);
    return (
        <Box
            h20
            toBetween
            toCenterY
            bgWhite
            w='100%'
            borderBottomBlack
            borderBottom-1
        >
            <Box row toCenterY cursorPointer onClick={() => {
                navigator(-1);
            }}>
                <StyledArrowBack ml5 />
                <Button sx={{
                    'color': '#000'
                }}>Back</Button>
            </Box>

            <HistoryBar
                readyToShip={readyToShip}
                onRedo={onRedo}
                onUndo={onUndo}
            />
        </Box>
    );
};
export default TopBar;
