import { Box, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { ShippingMethod } from '../TypeDeclare'

export type ProductShippingMothedPickerProps = {
    value?: string
    onChange: (value: string) => void
    options: ShippingMethod[]
    p: number
    styleObj?: Record<string, string>
}
const ProductShippingMothedPicker: FC<ProductShippingMothedPickerProps> = ({
    value,
    options,
    onChange,
    p,
    styleObj = {}
}) => {
    return (
        <Box style={styleObj} p={p}>
            <Select
                value={value}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
            >
                {options.map((o) => {
                    return (
                        <MenuItem key={o.id} value={o.id}>
                            {o.id} {o.weightComment}
                        </MenuItem>
                    )
                })}
            </Select>
        </Box>
    )
}
export default ProductShippingMothedPicker
