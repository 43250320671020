import { Box } from "@fower/react";
import { Button } from "@mui/material";
import { FC, useEffect, useState } from "react";

interface SearchOrderProps {
    onSearch: (orderNum: string) => void
}

const SearchOrder: FC<SearchOrderProps> = ({ onSearch }) => {
    const [orderNum, setOrderNum] = useState<string>('');

    useEffect(() => {
        if (!orderNum) {
            onSearch(orderNum)
        }
    }, [orderNum])

    return (
        <Box
            w={['100%', '100%', '100%', '50%', '50%']}
            h="50px"
            flex
            alignItems="center"
            justifyContent="space-between"
            css={{
                borderRadius: '24px',
                border: '1px solid #518DD9'
            }}
        >

            <input
                value={orderNum}
                onChange={(e) => setOrderNum(e.target.value)}
                type="text"
                placeholder="Enter order number"
                style={{
                    border: 'none',
                    outline: 'none',
                    width: '60%',
                    marginLeft: '20px'
                }}
            />

            <Button
                style={{
                    borderRadius: '22px',
                    height: '100%'
                }}
                onClick={() => onSearch(orderNum)}
                variant="contained"
            >Search Order</Button>

        </Box>
    )
}

export default SearchOrder;