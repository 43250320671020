import { Box } from '@fower/react';
import { FC } from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      w='100%'
      h='100%'
      backgroundSize='100%'
    >
      {value === index && (
        <Box p3>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
