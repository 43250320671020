import React, { FC } from 'react'
import { Box } from '@fower/react'
import { SizeOption } from '../../../../TypeDeclare'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'

export type SizePadProps = {
  sizeOptions: Array<SizeOption>
  loading: boolean
  selectedSizes: Array<SizeOption>
  onSizesUpdated: (sizes: Array<SizeOption>) => void
  onRemoveSize: (size: SizeOption) => void
}

const SizePad: FC<SizePadProps> = ({
  sizeOptions,
  loading,
  selectedSizes,
  onSizesUpdated,
  onRemoveSize,
}) => {
  return (
    <Box column spaceY2 p5 w="100%" overflowYScroll overflowYAuto>
      <Box alignSelf='end'>{selectedSizes.length} size(s) picked</Box>
      <Autocomplete
        multiple
        options={sizeOptions}
        getOptionLabel={(option) => option.value}
        defaultValue={selectedSizes}
        value={selectedSizes}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params} placeholder={'Pick Sizes'} />
        )}
        size="small"
        renderTags={(value: Array<SizeOption>) => {
          return value.map((option, index) => (
            <Box
              key={index}
              p2
              roundedHuge
              bgGray500
              white
              textSM
              row
              flex
              toCenter
              spaceX1
              m1
            >
              <Box>{option.value}</Box>
              <IconButton
                onClick={() => {
                  onRemoveSize(option)
                }}
                size="small"
              >
                <Close color="info" fontSize="small" />
              </IconButton>
            </Box>
          ))
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.value}
            </li>
          )
        }}
        onChange={(_, value) => {
          onSizesUpdated(value)
        }}
      />
    </Box>
  )
}
export default SizePad
